import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebaseAuth } from '../../providers/FirebaseProvider';
import { signInWithEmailAndPassword } from 'firebase/auth';

import logo from '../../assets/images/logo-with-name.svg';
import { PageTitle } from '../../components/nav/PageTitle';
import { useCurrentAuth } from '../../providers/CurrentAuthProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { Loader } from '../../components/generic/Loader';
import { MessageBox, MessageBoxType } from '../../components/layout/MessageBox';
import {
  useCompanyNameQuery,
  useRegisterAccountMutation,
} from '../../graphql/generated';
import PasswordInput from '../../components/generic/form/PasswordInput';
import { LoaderFullscreen } from '../../components/layout/Loader';

import { AppRoutes } from '../AppRoutes';
import {
  isConflict,
  isNotAcceptable,
} from '../../services/ErrorGraphqlService';
import { useTranslation } from '@hooks/useTranslation';
import { withRouter } from '../withRouter';

type RegisterScreenProps = {
  params?: {
    companyId: string;
    token: string;
  };
};

export const RegisterScreen = withRouter<RegisterScreenProps>(({ params: { companyId, token } }) => {
  const navigate = useNavigate();
  const firebaseAuth = useFirebaseAuth();
  const currentAuth = useCurrentAuth();
  const { currentUser } = useCurrentUser();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayConfirmInvitationButton, setDisplayConfirmInvitationButton] =
    useState(false);
  const { t } = useTranslation();

  const [registerAccount] = useRegisterAccountMutation();

  useEffect(() => {
    if (isFormSubmitted) {
      if (currentAuth === undefined || currentUser === undefined) {
        setError(null);
        setIsLoading(true);
      } else if (currentAuth && currentUser === null) {
        setError(t('nav:login.account_error'));
        setIsLoading(false);
      } else {
        setError(t('nav:login.wrong_credentials'));
        setIsLoading(false);
      }
      if (currentAuth && currentUser) {
        navigate(AppRoutes.Home, {
          replace: true,
        });
      }
    } else {
      // Form not submitted, but user logged in -> we display a lighter invitation form
      if (currentAuth && currentUser) {
        setDisplayConfirmInvitationButton(true);
      }
    }
  }, [isFormSubmitted, currentAuth, navigate, currentUser]);

  const { data, loading } = useCompanyNameQuery({
    variables: {
      id: companyId!,
    },
    skip: !companyId,
  });

  // Check if invited by a company
  if (!companyId) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {t('nav:register.warning_invitation')}
      </MessageBox>
    );
  }

  // Loader
  if (loading) {
    return (
      <div className="w-full h-screen">
        <LoaderFullscreen />
      </div>
    );
  }

  // Check if company exist
  if (!data?.companyName?.id) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {t('nav:register.error_invitation_link')}
      </MessageBox>
    );
  }

  const confirmJoin = () => {
    registerAccount({
      variables: {
        input: {
          email: currentAuth?.email || '',
          password,
          token,
          firstName,
          lastName,
        },
      },
    })
      .then(() => {
        navigate(AppRoutes.Home, { replace: true });
        // TODO: avoid a force reload. We should refresh the CompanyList (contributors instead)
        window.location.reload();
      })
      .catch((err) => {
        if (isConflict(err)) {
          setError(t('nav:register.user_email_conflict'));
        } else if (isNotAcceptable(err)) {
          setError(t('nav:register.weak_credentials'));
        } else {
          console.error(err);
          setError(t('nav:register.error_on_user_registration'));
        }
        setIsFormSubmitted(true);
        setIsLoading(false);
      });
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    registerAccount({
      variables: {
        input: {
          token,
          email,
          password,
          firstName,
          lastName,
        },
      },
    })
      .then((res) => {
        if (res.data?.registerAccount.authId) {
          signInWithEmailAndPassword(firebaseAuth, email, password)
            .catch((err) => {
              setError(t('nav:login.wrong_credentials'));
            })
            .finally(() => {
              setIsFormSubmitted(true);
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        if (isConflict(err)) {
          setError(t('nav:register.user_email_conflict'));
        } else if (isNotAcceptable(err)) {
          setError(t('nav:register.weak_credentials'));
        } else {
          console.error(err);
          setError(t('nav:register.error_on_user_registration'));
        }
        setIsLoading(false);
      });
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
    setError(null);
  };

  return (
    <>
      <PageTitle
        title={t('nav:register.invitation_title', {
          name: data?.companyName.name,
        })}
      />

      <div className="w-full h-screen overflow-scroll flex flex-col p-8 items-stretch">
        <div className="flex items-center gap-4">
          <img className="h-12 w-auto" src={logo} alt="Good Steps logo" />
          {data?.companyName?.logo && (
            <div className="flex items-center gap-4">
              <div className="font-title font-extrabold text-gray-500 text-xl">
                &
              </div>
              <div className="shrink-0 h-12 flex items-center justify-center">
                <img
                  src={data?.companyName?.logo}
                  className="shrink-0 h-full rounded-md"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="grow flex gap-8 justify-between items-center self-center max-w-6xl">
          <div className="">
            <h1>
              {t('nav:register.invitation_title', {
                name: data?.companyName.name,
              })}
            </h1>
            {displayConfirmInvitationButton ? (
              <div className="flex flex-col  gap-4">
                <p className="text-gray-500 mt-4">
                  {t('nav:register.invitation_message', {
                    name: data?.companyName.name,
                  })}
                </p>
                <button className="primary purple" onClick={confirmJoin}>
                  {t('nav:register.confirm_invitation')}
                </button>
                {error && (
                  <MessageBox type={MessageBoxType.Error}>
                    <p>{error}</p>
                  </MessageBox>
                )}
              </div>
            ) : (
              <>
                <p className="text-gray-500 mt-4">
                  {t('nav:register.invitation_message', {
                    name: data?.companyName.name,
                  })}
                </p>

                <div className="mt-8">
                  <form
                    name="loginForm"
                    className="space-y-6"
                    onSubmit={handleSubmit}
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-1">
                        <label htmlFor="firstName">
                          {t('global:firstname')}
                        </label>
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setError(null);
                          }}
                          required
                        />
                      </div>

                      <div className="space-y-1">
                        <label htmlFor="lastName">{t('global:lastname')}</label>
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            setError(null);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label htmlFor="email">{t('nav:login.email')}</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError(null);
                        }}
                        required
                      />
                    </div>

                    <div className="space-y-1">
                      <label htmlFor="password">
                        {t('nav:login.password')}
                      </label>
                      <PasswordInput
                        onChangeCallback={onPasswordChange}
                        value={password}
                      />
                    </div>

                    <div className="flex justify-center">
                      {isLoading ? (
                        <Loader className="w-10 h-10 text-black" />
                      ) : (
                        <input
                          className="primary purple w-full"
                          type="submit"
                          value="Créer votre compte"
                        />
                      )}
                    </div>
                    {error && (
                      <MessageBox type={MessageBoxType.Error}>
                        <p>{error}</p>
                      </MessageBox>
                    )}
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
