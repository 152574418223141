import { IroType } from '../../../graphql/generated';

export function iroTypenameToIroType(typename: string | undefined): IroType {
  switch (typename) {
    case 'RiskIro':
      return IroType.Risk;
    case 'OpportunityIro':
      return IroType.Opportunity;
    case 'PositiveImpactIro':
      return IroType.PositiveImpact;
    case 'NegativeImpactIro':
      return IroType.NegativeImpact;
    default:
      throw new Error(`Unknown Iro type: ${typename}`);
  }
}

export function iroTypeToTypename(iroType: IroType): string {
  switch (iroType) {
    case IroType.Risk:
      return 'RiskIro';
    case IroType.Opportunity:
      return 'OpportunityIro';
    case IroType.PositiveImpact:
      return 'PositiveImpactIro';
    case IroType.NegativeImpact:
      return 'NegativeImpactIro';
    default:
      throw new Error(`Unknown Iro type: ${iroType}`);
  }
}

export const getIroAssessmentPropertyName = (typename: string) => {
  switch (typename) {
    case 'RiskIro':
      return 'risk';
    case 'OpportunityIro':
      return 'opportunity';
    case 'PositiveImpactIro':
      return 'positiveImpact';
    case 'NegativeImpactIro':
      return 'negativeImpact';
    default:
      throw new Error(`Unknown Iro type: ${typename}`);
  }
};
