import React, { useEffect, useState } from 'react';
import {
  ApplyMaterialityThresholdsInput,
  DoubleMaterialityStaticMatrixDocument,
  MaterialityThresholds_StakeFragment,
  StandardEnum,
  useMaterialityThresholds_CompanyQuery,
  useMaterialityThresholds_SimulateMaterialityThresholdLazyQuery,
  useMaterialityThresholdsMutation,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { DoubleMaterialityStaticMatrix } from '../../publications/doubleMateriality/DoubleMaterialityStaticMatrix';
import { useDebounce } from '@hooks/useDebounce';
import { MatrixProvider } from '../../publications/doubleMateriality/MatrixContext';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import {
  MaterialityThresholds_Topic_Level1Fragment,
  useMaterialityThresholds_TopicsQuery,
} from '../../../../graphql/cms/generated';
import { getNumberOfDatapoints } from '../../gapAnalysis/summary/useGapAnalysisTopicDRTableColumns';
import { JustifyMaterialityThresholdButton } from '../../audit/justification/type/JustifyMaterialityThresholdButton';
import { useToast } from '../../../layout/Toast';
import { Loader } from '../../../generic/Loader';

export function MaterialityThresholds({
  companyId,
  readOnly = false,
}: {
  companyId: string;
  readOnly?: boolean;
}) {
  const toast = useToast();
  const { t } = useTranslation();

  const [updateMaterialityThresholdMutation, { loading }] =
    useMaterialityThresholdsMutation();

  const [impactMaterialityThreshold, setImpactMaterialityThreshold] = useState<
    number | null
  >(null);
  const [financialMaterialityThreshold, setFinancialMaterialityThreshold] =
    useState<number | null>(null);
  const [materialStakes, setMaterialStakes] =
    useState<MaterialityThresholds_StakeFragment[]>();
  const [materialEsrs, setMaterialEsrs] = useState<
    MaterialityThresholds_Topic_Level1Fragment[]
  >([]);
  const [materialDatapointsCount, setMaterialDatapointsCount] =
    useState<number>(0);
  const [isSimulating, setIsSimulating] = useState<boolean>(false);

  const { i18n } = useTranslation();
  const { data: cmsData, loading: cmsLoading } =
    useMaterialityThresholds_TopicsQuery({
      client: cmsClient,
      variables: {
        locale: i18n.language,
        standardSlug: StandardEnum.Csrd,
      },
      fetchPolicy: 'cache-and-network',
    });

  const companyQuery = useMaterialityThresholds_CompanyQuery({
    variables: {
      companyId,
    },
    onCompleted: (data) => {
      setImpactMaterialityThreshold(
        data.enterprise.impactMaterialityThreshold ?? null,
      );
      setFinancialMaterialityThreshold(
        data.enterprise.financialMaterialityThreshold ?? null,
      );
    },
    fetchPolicy: 'no-cache',
  });

  const referential = companyQuery.data?.enterprise?.referential;

  const updateMaterialityThreshold = (
    input: Partial<ApplyMaterialityThresholdsInput>,
  ) => {
    updateMaterialityThresholdMutation({
      variables: {
        input: {
          referentialId: referential?.id ?? '',
          ...input,
        },
      },
      refetchQueries: [
        {
          query: DoubleMaterialityStaticMatrixDocument,
          variables: {
            referentialId: referential?.id,
            companyId: companyId,
          },
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('materiality.threshold.success'));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [simulateMaterialityThresholds] =
    useMaterialityThresholds_SimulateMaterialityThresholdLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const debouncedImpactMaterialityThreshold = useDebounce(
    impactMaterialityThreshold,
  );
  const debouncedFinancialMaterialityThreshold = useDebounce(
    financialMaterialityThreshold,
  );

  useEffect(() => {
    if (
      debouncedFinancialMaterialityThreshold !== null &&
      debouncedImpactMaterialityThreshold !== null
    ) {
      setIsSimulating(true);
      void simulateMaterialityThresholds({
        variables: {
          input: {
            referentialId: referential?.id ?? '',
            financialMaterialityThreshold:
              debouncedFinancialMaterialityThreshold,
            impactMaterialityThreshold: debouncedImpactMaterialityThreshold,
          },
        },
        onCompleted: (data) => {
          if (data?.simulateMaterialityThresholds) {
            const materialStakes = data?.simulateMaterialityThresholds.filter(
              (item) => !item.isDisabled,
            );
            setMaterialStakes(materialStakes);
            countMaterialEsrs(materialStakes);
            setIsSimulating(false);
          }
        },
      });
    }
  }, [
    debouncedFinancialMaterialityThreshold,
    debouncedImpactMaterialityThreshold,
    simulateMaterialityThresholds,
    companyQuery.data?.enterprise?.referential?.id,
  ]);

  const countMaterialEsrs = (
    materialStakes: MaterialityThresholds_StakeFragment[],
  ) => {
    const cmsTopics = cmsData?.topics ?? [];
    const listOfTopicsOfMaterialStakes = materialStakes.flatMap(
      (stake) => stake.topicIds,
    );
    // Get all root topics from the CMS where stake is material
    const materialEsrs: MaterialityThresholds_Topic_Level1Fragment[] =
      cmsTopics.filter((topic) => {
        const allTopicIds = [
          topic?.documentId,
          ...(topic?.children?.map((child) => child?.documentId) ?? []),
          ...(topic?.children?.flatMap((child) =>
            child?.children?.map((childOfChild) => childOfChild?.documentId),
          ) ?? []),
        ];
        if (
          allTopicIds.some((id) => listOfTopicsOfMaterialStakes.includes(id))
        ) {
          return true;
        }
      }) as MaterialityThresholds_Topic_Level1Fragment[];
    setMaterialEsrs(materialEsrs);

    // Count datapoints of material ESRS
    const materialDisclosureRequirements = cmsTopics.flatMap((topic) => {
      if (materialEsrs.some((esr) => esr?.documentId === topic?.documentId)) {
        return topic?.disclosure_requirements;
      }
    });
    const datapointsCount = materialDisclosureRequirements.reduce((acc, dr) => {
      return dr ? acc + getNumberOfDatapoints(dr) : acc;
    }, 0);
    setMaterialDatapointsCount(datapointsCount);
  };

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4 justify-end">
        {referential && (
          <JustifyMaterialityThresholdButton
            referentialId={referential.id}
            readOnly={readOnly}
          />
        )}
        {!readOnly && (
          <button
            className="primary"
            disabled={loading}
            onClick={() => {
              updateMaterialityThreshold({
                financialMaterialityThreshold: financialMaterialityThreshold,
                impactMaterialityThreshold: impactMaterialityThreshold,
              });
            }}
          >
            {loading && <Loader />}
            {t('materiality.threshold.form.cta', {
              count: materialStakes?.length || 0,
            })}
          </button>
        )}
      </div>
      <div className="space-y-4 divide-y divide-gray-100">
        <div className="grid grid-cols-3 gap-4">
          <div className="space-y-1">
            <label className="form-input-label">
              {t('materiality.threshold.form.financialThreshold')}
            </label>
            <input
              className="form-input-text"
              type="number"
              min={0}
              max={4}
              step={0.1}
              value={financialMaterialityThreshold ?? ''}
              onChange={(e) => {
                setFinancialMaterialityThreshold(parseFloat(e.target.value));
              }}
              disabled={readOnly}
            />
            <p className="text-gray-500 text-sm">
              {t('materiality.threshold.form.threshold_limit', {
                min: 0,
                max: 4,
              })}
            </p>
          </div>
          <div className="space-y-1">
            <label className="form-input-label">
              {t('materiality.threshold.form.impactThreshold')}
            </label>
            <input
              className="form-input-text"
              type="number"
              min={0}
              max={4}
              step={0.1}
              value={impactMaterialityThreshold ?? ''}
              onChange={(e) => {
                setImpactMaterialityThreshold(parseFloat(e.target.value));
              }}
              disabled={readOnly}
            />
            <p className="text-gray-500 text-sm">
              {t('materiality.threshold.form.threshold_limit', {
                min: 0,
                max: 4,
              })}
            </p>
          </div>
          <div className="flex flex-col items-end relative">
            {isSimulating && (
              <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
                <LoaderFullscreen />
              </div>
            )}
            <div className="grid grid-cols-2 gap-y-0.5 gap-x-2 items-center">
              <div className="text-sm text-gray-500">
                {t('materiality.threshold.stats.material_stakes')}
              </div>
              <div className="font-bold">{materialStakes?.length}</div>
              <div className="text-sm text-gray-500">
                {t('materiality.threshold.stats.material_esrs')}
              </div>
              <div className="font-bold">{materialEsrs.length}</div>
              <div className="text-sm text-gray-500">
                {t('materiality.threshold.stats.material_datapoints')}
              </div>
              <div className="font-bold">{materialDatapointsCount}</div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <MatrixProvider initialStakeholderSegments={[]}>
            <DoubleMaterialityStaticMatrix
              {...{ financialMaterialityThreshold, impactMaterialityThreshold }}
            />
          </MatrixProvider>
        </div>
      </div>
    </div>
  );
}
