import roadmapIllustration from '../../../assets/images/illustrations/restitution/roadmap.png';
import React, { useState } from 'react';
import { useToast } from '../../layout/Toast';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import {
  RoadmapScreenDocument,
  useCreateRoadmapMutation,
} from '../../../graphql/generated';
import { Loader } from '../../generic/Loader';

export function CreateRoadmap({ readOnly }: { readOnly?: boolean }) {
  return (
    <div className="p-8 flex flex-col gap-4 w-full items-center justify-center bg-blue-50 h-full">
      <img src={roadmapIllustration} alt="" className="w-56 grayscale" />
      <h1>Cette entreprise n'a pas encore de feuille de route</h1>
      {!readOnly && (
        <>
          <p className="max-w-2xl text-center">
            Vous pouvez l'initialiser en cliquant sur le bouton ci-dessous.
          </p>
          <CreateRoadmapButton />
        </>
      )}
    </div>
  );
}

export function CreateRoadmapButton() {
  const toast = useToast();
  const projectContext = useProjectContext();
  const [createRoadmap] = useCreateRoadmapMutation();
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useProjectContext()?.enterprise?.id;
  const create = () => {
    setIsLoading(true);
    createRoadmap({
      variables: {
        input: {
          company: {
            id: companyId || '',
          },
        },
      },
      refetchQueries: [
        {
          query: RoadmapScreenDocument,
          variables: {
            companyId: companyId!,
          },
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage('Feuille de route créée');
        projectContext?.refreshEnterprise();
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button className="primary" onClick={create} disabled={isLoading}>
      {isLoading && <Loader />}
      <span>Créer une feuille de route</span>
    </button>
  );
}
