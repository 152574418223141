import { MinusIcon, PlusIcon } from '../../../../icons';
import React, { ReactNode } from 'react';
import { IroType } from '../../../../../graphql/generated';
import { useIroCriteriaValuesDefinitions } from '../../../../project/iro/evaluation/IroCriteriaValuesDefinitionsContext';
import { useCriteriaDefinitionDrawer } from '../../../../project/iro/evaluation/row/openCriteriaDefinitionDrawer';
import { CriteriaType } from '../../../../../types/iro.types';

export const IroAssessmentButtonGroup = ({
  criteria,
  label,
  iroType,
  children,
}: {
  criteria: CriteriaType;
  label: string;
  iroType: IroType;
  children: ReactNode;
}) => {
  const openCriteriaDefinitionDrawer = useCriteriaDefinitionDrawer();
  const iroCriteriaValuesDefinitions = useIroCriteriaValuesDefinitions();
  return (
    <div>
      <div className="flex items-center justify-end gap-4 w-full">
        <div className="w-56 flex items-center justify-evenly gap-2 py-4 pr-4 pl-2">
          <div className="rounded-full border-2 border-gray-900">
            <MinusIcon />
          </div>
          <hr className="border border-gray-900 w-8" />
          <div
            className="font-semibold cursor-pointer"
            onClick={() =>
              openCriteriaDefinitionDrawer(
                iroType,
                criteria,
                iroCriteriaValuesDefinitions.definitions || [],
              )
            }
          >
            {label}
          </div>
          <hr className="border border-gray-900 w-8" />
          <div className="rounded-full border-2 border-gray-900">
            <PlusIcon />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
