import React, { useMemo, useState } from 'react';
import {
  AssignQuestionsTable_MaturityEvaluationFragment,
  AssignQuestionsTable_QuestionsFragment,
  MaturityEvaluationAssignQuestions_MaturityEvaluationFragment,
  MaturityEvaluationRecipientsFragment,
  useMaturityEvaluationAssignQuestions_GenerateStakeholdersMutation,
} from '../../../graphql/generated';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { useModal } from '../../layout/Modal';
import { StringDropdownItem } from '../../generic/dropdown/StringDropdown.types';
import { Loader } from '../../generic/Loader';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { generatePath, NavLink, useNavigate } from 'react-router-dom';
import { useToast } from '../../layout/Toast';
import { SurveyQuestionIcon } from '../../survey/SurveyQuestionIcon';
import clsx from 'clsx';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PlusIcon, UserIcon } from '../../icons';
import { AddMeAsRecipientButton } from './recipients/AddMeAsRecipientButton';
import { AddMaturityEvaluationRecipientForm } from './recipients/AddMaturityEvaluationRecipientForm';
import { AppRoutes } from '../../../screens/AppRoutes';
import StringDropdown from '../../generic/dropdown/StringDropdown';

export function MaturityEvaluationAssignQuestions({
  maturityEvaluation,
}: {
  maturityEvaluation: MaturityEvaluationAssignQuestions_MaturityEvaluationFragment;
}) {
  const modal = useModal();

  const openManageStakeholdersModal = () => {
    modal.openModalWithComponent(
      <AddMaturityEvaluationRecipientForm
        maturityEvaluation={maturityEvaluation}
      />,
      'Ajouter un contact',
    );
  };

  const recipients: MaturityEvaluationRecipientsFragment[] =
    maturityEvaluation.maturityEvaluationRecipients || [];

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h5 className="flex items-center gap-2">
            <div>Définir vos contacts</div>
            <div className="tag purple">{recipients.length}</div>
          </h5>
          <div className="flex items-center gap-2">
            {recipients.length === 0 && (
              <AddMeAsRecipientButton survey={maturityEvaluation} />
            )}
            <button
              className={clsx(
                'purple',
                recipients.length === 0 ? 'primary' : 'secondary',
              )}
              onClick={() => openManageStakeholdersModal()}
            >
              <PlusIcon className="w-4 h-4" />
              <div>Ajouter un contact</div>
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4">
          {recipients.map((recipient) => (
            <div
              key={recipient.id}
              className="border border-gray-100 rounded-xl p-4 flex flex-col gap-4"
            >
              <div className="flex items-center gap-4">
                <div className="rounded-full bg-gray-900 w-10 h-10 flex items-center justify-center text-white font-title font-extrabold text-base lg:text-lg shrink-0">
                  <UserIcon />
                </div>
                <div className="space-y-1">
                  <div className="font-title font-extrabold flex items-center gap-1">
                    <span>{recipient.firstName}</span>
                    <span>{recipient.lastName}</span>
                  </div>
                  <p className="text-gray-500 text-sm">{recipient.email}</p>
                </div>
              </div>
            </div>
          ))}
          {recipients.length < 4 && (
            <>
              {[...Array(4 - recipients.length)].map((_, index) => (
                <div key={index} className="bg-gray-50 rounded-xl p-4">
                  <div className="flex items-center gap-4 h-full">
                    <div className="rounded-full bg-white text-gray-100 w-10 h-10 flex items-center justify-center font-title font-extrabold text-base lg:text-lg shrink-0">
                      <UserIcon />
                    </div>
                    <div className="space-y-3 w-full">
                      <div className="w-4/5 bg-gray-100 rounded-xl h-2"></div>
                      <div className="w-3/5 bg-gray-100 rounded-xl h-2"></div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {recipients.length > 0 && (
        <AssignQuestionsTable
          stakeholders={recipients}
          maturityEvaluation={maturityEvaluation}
        />
      )}
    </div>
  );
}

function AssignQuestionsTable({
  stakeholders,
  maturityEvaluation,
}: {
  stakeholders: MaturityEvaluationRecipientsFragment[];
  maturityEvaluation: AssignQuestionsTable_MaturityEvaluationFragment;
}) {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const toast = useToast();
  const projectContext = useProjectContext();

  const [generateStakeholdersSurveysMutation] =
    useMaturityEvaluationAssignQuestions_GenerateStakeholdersMutation();
  const [isAssigning, setIsAssigning] = useState(false);

  // Generate and memoize list of ALL sorted questions (children as well)
  const questions = useMemo(() => {
    return (
      (maturityEvaluation.questions || [])
        .toSorted((a, b) => a.position - b.position)
        .flatMap((question): AssignQuestionsTable_QuestionsFragment[] => {
          if (question.type === 'group') {
            return (
              (question.children || []).toSorted(
                (a, b) => a.position - b.position,
              ) || []
            );
          } else {
            return [question];
          }
        })

        .flatMap((question) =>
          question.children && question.children?.length > 0
            ? question.children
            : question,
        )
        // Dedup
        .filter(
          (question, index, self) =>
            self.findIndex((q) => q.id === question.id) === index,
        )
    );
  }, [maturityEvaluation.questions]);

  // Map of questions assigned to stakeholders
  // Initialized with: all questions assigned to the stakeholder having the same mail as the current user
  // Find stakeholder with same email as current user
  const currentUserStakeholder = stakeholders.find(
    (stakeholder) => stakeholder.email === currentUser?.auth?.email,
  );
  const [
    mapOfQuestionsAssignedToStakeholders,
    setMapOfQuestionsAssignedToStakeholders,
  ] = useState<Map<string, string>>(() => {
    const map = new Map<string, string>();
    questions.forEach((question) => {
      if (currentUserStakeholder) {
        map.set(question.id, currentUserStakeholder.id);
      } else {
        // Set it to the first stakeholder found
        map.set(question.id, stakeholders[0].id);
      }
    });
    return map;
  });

  // Generate list of stakeholderItems from recipients list
  const stakeholderItems: StringDropdownItem[] =
    stakeholders.map((recipient) => {
      return {
        id: recipient.id,
        label: `${recipient.firstName} ${recipient.lastName}`,
      };
    }) || [];

  const assignStakeholderToQuestion = (
    questionId: string,
    stakeholderId: string,
  ) => {
    // Update map of questions assigned to stakeholders
    const newMap = new Map(mapOfQuestionsAssignedToStakeholders);
    newMap.set(questionId, stakeholderId);
    setMapOfQuestionsAssignedToStakeholders(newMap);
  };

  const createSurveys = () => {
    setIsAssigning(true);

    // Transform the map of a list of stakeholderId -> list of assigned questions
    const mapOfStakeholdersAssignedToQuestions = new Map<string, string[]>();
    mapOfQuestionsAssignedToStakeholders.forEach(
      (stakeholderId, questionId) => {
        if (mapOfStakeholdersAssignedToQuestions.has(stakeholderId)) {
          const questions =
            mapOfStakeholdersAssignedToQuestions.get(stakeholderId) || [];
          questions.push(questionId);
          mapOfStakeholdersAssignedToQuestions.set(stakeholderId, questions);
        } else {
          mapOfStakeholdersAssignedToQuestions.set(stakeholderId, [questionId]);
        }
      },
    );

    generateStakeholdersSurveysMutation({
      variables: {
        input: {
          maturityEvaluationId: maturityEvaluation.id,
          stakeholdersToQuestions: Array.from(
            mapOfStakeholdersAssignedToQuestions.entries(),
          ).map(([stakeholderId, questionsId]) => {
            return {
              stakeholderId,
              questionsId,
            };
          }),
        },
      },
    })
      .then(() => {
        // Goto maturity evals screen
        navigate(AppRoutes.MaturityEvaluations);
        toast.openToastWithMessage('Les questionnaires ont été générés');
        // Make sure we reload company data
        if (maturityEvaluation.company) {
          projectContext?.updateEnterprise(maturityEvaluation.company.id, false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsAssigning(false));
  };

  return (
    <div className="space-y-4 pt-8">
      <div className="flex items-center justify-between">
        <h5 className="flex items-center gap-2">
          <div>Assigner les questions</div>
          <div className="tag purple">{questions.length}</div>
        </h5>

        <button
          disabled={isAssigning}
          className="primary purple"
          onClick={() => createSurveys()}
        >
          {isAssigning && <Loader />}
          <div>Générer les questionnaires</div>
        </button>
      </div>

      <MessageBox type={MessageBoxType.Info}>
        <div className="flex items-center justify-between w-full">
          <div>
            Envie d&apos;éditer les questions ? Rendez-vous sur l&apos;édition
            de l&apos;évaluation !
          </div>
          <div>
            <NavLink
              to={generatePath(AppRoutes.MaturityEvaluationItem, {surveyId: maturityEvaluation.id})}
              className="primary purple"
            >
              <button className="secondary small blue">
                Éditer l&apos;évaluation
              </button>
            </NavLink>
          </div>
        </div>
      </MessageBox>

      <table className="w-full">
        <thead>
          <tr>
            <th>Questions</th>
            <th className="w-3/12">Assignées à</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, index) => {
            // Find stakeholder assigned to question from map
            const assignedStakeholderId =
              mapOfQuestionsAssignedToStakeholders.get(question.id) || '';
            const assignedStakeholderItem =
              stakeholderItems.find(
                (item) => item.id === assignedStakeholderId,
              ) || null;
            return (
              <tr key={question.id}>
                <td>
                  <div className="flex items-center gap-4 font-semibold">
                    <div className="text-gray-300">{index + 1}</div>
                    <SurveyQuestionIcon questionType={question.type} />
                    <div className="space-y-1">
                      <div>{question.title}</div>
                    </div>
                  </div>
                </td>
                <td>
                  {stakeholderItems.length > 0 && (
                    <StringDropdown
                      availableItems={stakeholderItems}
                      item={assignedStakeholderItem}
                      setItem={(item) => {
                        if (item?.id) {
                          assignStakeholderToQuestion(question.id, item.id);
                        }
                      }}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
