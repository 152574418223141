import React, { FC, useState } from 'react';
import {
  AdminUserList_UserFragment,
  useAdminUserListQuery,
} from '../../../../graphql/generated';
import { AdminUserListItem } from './AdminUserListItem';
import { useTranslation } from '@hooks/useTranslation';
import { SearchIcon } from '../../../../components/icons';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { ADMIN_TABS, useAdminTabs } from '../../useAdminTabs';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import { AdminHero } from '../../AdminHero';

const AdminUsersList: FC = () => {
  const [users, setUsers] = useState<AdminUserList_UserFragment[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const adminTabs = useAdminTabs();

  const { loading } = useAdminUserListQuery({
    onCompleted: (data) => {
      setUsers(data.users);
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation();

  const filteredUsers = users.filter((user) => {
    const search = searchTerm.toLowerCase();
    return (
      user.firstName.toLowerCase().includes(search) ||
      user.lastName.toLowerCase().includes(search) ||
      user.auth?.email.toLowerCase().includes(search) ||
      user.company?.name?.toLowerCase().includes(search)
    );
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  return (
    <>
      <Breadcrumb />
      <TabNavigationLayout<ADMIN_TABS>
        tabs={adminTabs}
        activeTab={ADMIN_TABS.USERS}
        renderTabs={(tabsContent) => <AdminHero>{tabsContent}</AdminHero>}
      >
        <PageTitle title="Admin" />
        <div className="space-y-6 main-content p-8 w-full">
          <h1>{t('admin:users.list.title')}</h1>

          <form>
            <label
              htmlFor="search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <SearchIcon />
              </div>
              <input
                type="search"
                id="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('admin:users.list.searchPlaceholder')}
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-700"
              />
            </div>
          </form>

          <ul className="mt-4 space-y-2">
            {filteredUsers
              .filter((user) => !user.auth?.disabled)
              .map((user, index) => (
                <AdminUserListItem user={user} index={index} key={user.id} />
              ))}
          </ul>
          <div>
            <h2>{t('admin:users.list.disabledTitle')}</h2>
            <ul className="mt-4 space-y-2">
              {filteredUsers
                .filter((user) => user.auth?.disabled)
                .map((user, index) => (
                  <AdminUserListItem user={user} index={index} key={user.id} />
                ))}
            </ul>
          </div>
        </div>
      </TabNavigationLayout>
    </>
  );
};

export const AdminUsersListScreen = AdminUsersList;
