import {
  CreateTaskGroupButton_ChecklistFragment,
  useCreateTaskGroupButtonMutation,
} from '../../graphql/generated';
import React, { useState } from 'react';
import { useModal } from '../layout/Modal';
import { UpdateTaskGroupModal } from './UpdateTaskGroupModal';
import { Loader } from '../generic/Loader';

export function CreateTaskGroupButton({
  checklist,
  disabled = false,
}: {
  checklist: CreateTaskGroupButton_ChecklistFragment;
  disabled?: boolean;
}) {
  const modal = useModal();

  const [createTaskGroupMutation] = useCreateTaskGroupButtonMutation();
  const [isLoading, setIsLoading] = useState(false);

  const createTaskGroup = () => {
    setIsLoading(true);
    createTaskGroupMutation({
      variables: {
        input: {
          name: 'Nouvelle catégorie',
          checklist: {
            id: checklist.id,
          },
        },
      },
    })
      .then((res) => {
        if (res.data?.taskGroupCreate) {
          modal.openModalWithComponent(
            <UpdateTaskGroupModal taskGroup={res.data?.taskGroupCreate} />,
            'Modifier une catégorie',
          );
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button
      onClick={() => createTaskGroup()}
      className="secondary small purple"
      disabled={disabled || isLoading}
    >
      {isLoading && <Loader />}
      <span>Ajouter une catégorie</span>
    </button>
  );
}
