import { useProjectContext } from '../../../../../providers/ProjectContextProvider';
import { UseRefetchMaterialitiesDocument } from '../../../../../graphql/generated';

export const useRefetchMaterialities = () => {
  const projectContext = useProjectContext();
  return [
    {
      query: UseRefetchMaterialitiesDocument,
      variables: {
        id: projectContext?.enterprise?.referential?.id || '',
      },
    },
  ];
};
