import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  StringDropdownItem,
  StringDropdownProps,
} from '../../../generic/dropdown/StringDropdown.types';
import { SkeletonLoader } from '../../../generic/loader/SkeletonLoader';
import { ConsultingCompanyRoleEnum } from '../../../../graphql/generated';
import StringDropdown, {
  enumValuesToDropdownItems,
} from 'components/generic/dropdown/StringDropdown';

export function UserConsultingCompanyRoleDropdown(
  props: Omit<StringDropdownProps<any>, 'availableItems'>,
) {
  const { t } = useTranslation();
  const loading = false;

  if (loading) {
    return <SkeletonLoader />;
  }

  const availableItems: StringDropdownItem[] = enumValuesToDropdownItems(
    ConsultingCompanyRoleEnum,
    (key: string) => t(`enum:consultingCompanyRole.${key}`),
  );

  return (
    <div>
      <StringDropdown availableItems={availableItems} {...props} />
    </div>
  );
}

