import {
  AuthRolesAndExperimentalFeatures,
  CoachsSettingsScreenDocument,
  ConsultingCompanyRoleEnum,
  GiveUserAccess_EnterpriseFragment,
  useGiveConsultingCompanyRoleMutation,
  UserRowFragment,
} from '../../../../../graphql/generated';
import { RemoveConsultingCompanyUserButton } from './RemoveConsultingCompanyUserButton';
import React from 'react';
import { useToast } from '../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { UserConsultingCompanyRoleDropdown } from '../../roles/UserConsultingCompanyRoleDropdown';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { Tag } from '../../../../badges/Tag';
import { useRightDrawer } from '../../../../layout/RightDrawer';
import { SetUserProjectAccesses } from './SetUserProjectAccesses';
import { findRole } from '../../../../../services/CompanyService';
import { TooltipWrapper } from 'components/generic/TooltipWrapper';

export const UserRow = ({
  user,
  companyId,
  enterprises,
  isLastAdmin,
}: {
  user: UserRowFragment;
  companyId: string;
  enterprises: GiveUserAccess_EnterpriseFragment[];
  isLastAdmin: boolean;
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const role = findRole(user);
  const { hasRole } = usePermissionChecker();
  const [giveConsultingCompanyRole] = useGiveConsultingCompanyRoleMutation();
  const canManageConsultingCompany = hasRole(
    AuthRolesAndExperimentalFeatures.Administrator,
  );

  const getDateString = (dateAsString: string) => {
    const date = new Date(dateAsString);
    return date.toLocaleString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Europe/Paris',
    });
  };

  const { openDrawerWithComponent } = useRightDrawer();

  return (
    <tr>
      <td className="font-semibold">
        {user.firstName} {user.lastName} {user.auth?.disabled && '(disabled)'}
      </td>
      <td>{user.auth?.email}</td>
      <td>
        {user.auth?.lastSignInDate
          ? getDateString(user.auth?.lastSignInDate)
          : '-'}
      </td>
      <td>
        <div className="flex justify-between items-right gap-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              openDrawerWithComponent(
                <>
                  <div className="px-8 py-4 bg-gray-50 border-b border-gray-100">
                    <h3>
                      {t('consultingCompany.users.giveProjectAccess.title', {
                        name: `${user.firstName} ${user.lastName}`,
                      })}
                    </h3>
                  </div>
                  <SetUserProjectAccesses
                    user={user}
                    enterprises={enterprises}
                    readOnly={!canManageConsultingCompany}
                  />
                </>,
              );
            }}
          >
            <Tag color={'blue'}>{user?.userAccesses?.length} projets</Tag>
          </a>

          <TooltipWrapper
            label={
              isLastAdmin
                ? t('consultingCompany.users.lastAdmin.tooltip')
                : undefined
            }
          >
            <UserConsultingCompanyRoleDropdown
              disabled={!canManageConsultingCompany || isLastAdmin}
              item={{
                id: role,
                label: t(`enum:consultingCompanyRole.${role}`),
              }}
              setItem={(item) => {
                if (!item?.id) {
                  return;
                }
                giveConsultingCompanyRole({
                  variables: {
                    input: {
                      user: {
                        id: user.id,
                      },
                      consultingCompany: {
                        id: companyId!,
                      },
                      role: item.id as ConsultingCompanyRoleEnum,
                    },
                  },
                  refetchQueries: [
                    {
                      query: CoachsSettingsScreenDocument,
                      variables: {
                        id: companyId,
                      },
                    },
                  ],
                  onCompleted: (item) => {
                    toast.openToastWithMessage(
                      t('toast:project.users.updateRole.success'),
                    );
                  },
                });
              }}
            />
          </TooltipWrapper>
          <RemoveConsultingCompanyUserButton
            user={user}
            companyId={companyId}
            disabled={!canManageConsultingCompany || isLastAdmin}
          />
        </div>
      </td>
    </tr>
  );
};
