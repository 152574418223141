import { FormEvent, useEffect, useState } from 'react';
import {
  NavigateFunction,
  NavLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useFirebaseAuth } from '../../providers/FirebaseProvider';
import { signInWithEmailAndPassword } from 'firebase/auth';

import logo from '../../assets/images/logo-with-name.svg';
import { PageTitle } from '../../components/nav/PageTitle';
import { useCurrentAuth } from '../../providers/CurrentAuthProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { Loader } from '../../components/generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { MessageBox, MessageBoxType } from '../../components/layout/MessageBox';

export function LoginScreen() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const firebaseAuth = useFirebaseAuth();
  const currentAuth = useCurrentAuth();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentAuth && currentUser) {
      redirectToAskedPage(navigate, searchParams);
    } else {
      if (isFormSubmitted) {
        if (currentAuth && currentUser === null) {
          setError(t('nav:login.account_error'));
          setIsLoading(false);
        }
      }
    }
  }, [isFormSubmitted, currentAuth, currentUser, navigate, searchParams]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .catch((err) => {
        console.error(err);
        setError(t('nav:login.wrong_credentials'));
      })
      .finally(() => {
        setIsFormSubmitted(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageTitle title={t('nav:login.signin')} />

      <div className="w-full h-screen overflow-scroll flex flex-col p-8 items-stretch">
        <div>
          <img className="h-12 w-auto" src={logo} alt="Good Steps logo" />
        </div>
        <div className="grow flex gap-8 justify-between items-center self-center max-w-xl">
          <div>
            <h1>{t('nav:login.welcome')}</h1>
            <div className="mt-8">
              <form
                name="loginForm"
                className="space-y-6"
                onSubmit={handleSubmit}
              >
                <div className="space-y-1">
                  <label htmlFor="email">{t('nav:login.email')}</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError(null);
                    }}
                    required
                  />
                </div>

                <div className="space-y-1">
                  <label htmlFor="password">{t('nav:login.password')}</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError(null);
                    }}
                    required
                  />
                </div>

                <div className="flex justify-center">
                  {isLoading ? (
                    <Loader className="w-10 h-10 text-black" />
                  ) : (
                    <input
                      className="primary purple w-full"
                      type="submit"
                      value={t('nav:login.signin')}
                    />
                  )}
                </div>

                <div className="flex justify-center">
                  <NavLink
                    to="/forgot-password"
                    className="text-gray-500 italic text-sm"
                  >
                    {t('nav:login.password_forgotten')}
                  </NavLink>
                </div>

                {error && (
                  <MessageBox type={MessageBoxType.Error}>
                    <p>{error}</p>
                  </MessageBox>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function redirectToAskedPage(
  navigate: NavigateFunction,
  searchParams: URLSearchParams,
) {
  const destination = searchParams.get('destination');
  navigate(destination && destination[0] === '/' ? destination : '/', {
    replace: true,
  });
}
