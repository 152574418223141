import React, { useState } from 'react';
import {
  IroIdInput,
  SurveyQuestionSummaryFieldsFragment,
  useQuestionPillarStakeMutation,
} from '../../../../../../graphql/generated';
import { useToast } from '../../../../../layout/Toast';
import StakeDropdown from '../../../../../form/StakeDropdown';
import PillarDropdown from '../../../../../form/PillarDropdown';
import IroDropdown from '../../../../../form/IroDropdown';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionPillarStake({
  question,
  enableStakePicker,
  enablePillarPicker,
  enableIrosPicker,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  enableStakePicker: boolean;
  enablePillarPicker: boolean;
  enableIrosPicker?: boolean;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const [updateQuestionMutation] = useQuestionPillarStakeMutation();

  const [stakeId, setStakeId] = useState<string | null | undefined>(
    question.stake?.id,
  );
  const [pillarId, setPillarId] = useState<string | null | undefined>(
    question.pillar?.id,
  );

  const [iroIds, setIroIds] = useState<(string | null)[]>(
    question.iroAssessment?.iros?.map((iro) => iro.id) ?? [],
  );

  const updateStakeId = (newStakeId: string | null) => {
    setStakeId(newStakeId);
    setIroIds([]);
    updateStakeOptions(newStakeId, pillarId, []);
  };

  const updatePillarId = (newPillarId: string | null) => {
    setPillarId(newPillarId);
    setStakeId(null);
    setIroIds([]);
    updateStakeOptions(null, newPillarId, []);
  };

  const updateIroIds = (iroIds: (string | null)[]) => {
    setIroIds(iroIds);
    updateStakeOptions(stakeId, pillarId, iroIds);
  };

  const updateStakeOptions = (
    stakeId: string | null | undefined,
    pillarId: string | null | undefined,
    iroIds?: (string | null)[] | null | undefined,
  ) => {
    updateQuestionMutation({
      variables: {
        input: {
          id: question.id,
          stake: stakeId ? { id: stakeId } : null,
          pillar: pillarId ? { id: pillarId } : null,
          iroAssessment: {
            id: question.iroAssessment?.id,
            iros: iroIds
              ?.filter((iroId): iroId is string => !!iroId)
              .map((iroId): IroIdInput => {
                return {
                  id: iroId,
                };
              }),
          },
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
      })
      .catch((error) => {
        toast.openToastWithError(t('toast:survey.question.update.error'));
      });
  };

  return (
    <div className="space-y-4">
      <h3>{t('survey.edit.questions.type.common.pillarStakeInput.title')}</h3>
      <div className="grid grid-cols-2 gap-4">
        {enablePillarPicker && (
          <div>
            <label htmlFor="stake" className="form-input-label mb-2">
              {t('survey.edit.questions.type.common.pillarStakeInput.pillar')}
            </label>
            <PillarDropdown
              currentPillarId={pillarId}
              setPillarId={updatePillarId}
            />
          </div>
        )}
        {enableStakePicker && (
          <div>
            <label htmlFor="stake" className="form-input-label mb-2">
              {t(
                'translation:survey.edit.questions.type.common.pillarStakeInput.stake',
              )}
            </label>
            <StakeDropdown
              currentStakeId={stakeId}
              setCurrentStakeId={updateStakeId}
              isRequired={false}
              pillarId={pillarId}
            />
          </div>
        )}
        {enableIrosPicker && (
          <div className={'col-span-2'}>
            <label htmlFor="stake" className="form-input-label mb-2">
              {t('survey.edit.questions.type.common.pillarStakeInput.iro')}
            </label>
            {stakeId && (
              <IroDropdown
                currentStakeId={stakeId}
                iroIds={iroIds}
                setIroIds={updateIroIds}
                selectAllByDefault={true}
                requiresAtLeastOneIro={true}
              ></IroDropdown>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
