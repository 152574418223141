export const DataList = ({ items }: { items: ({ label: string; value: string | React.ReactNode }|undefined)[] }) => {
  return <dl className="sm:divide-y sm:divide-gray-200">
    {items.filter(item => item !== undefined).map((item) => (
      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">{item!.label}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item!.value}</dd>
      </div>
    ))}
  </dl>
}
