import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  useSimpleFinancialByStakeholderSegmentsMaterialityMatrixQuery,
  useUpdateCompanyMaterialityByStakeholderFilterMutation,
} from '../../../../../graphql/generated';
import { GroupedStakeholderSegmentDropdown } from '../../../stakeholder/dropdown/GroupedStakeholderSegmentDropdown';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../../layout/Loader';
import {
  StakesMaterialityChart,
  TStakeMateriality,
} from '../../../survey/questionsDataviz/stakesMaterialityChart/StakesMaterialityChart';
import { GroupedStringDropdownItem } from '../../../../generic/dropdown/GroupedStringDropdown';
import { useGroupedStakeholderSegments } from '../../../stakeholder/dropdown/useGroupedStakeholderSegments';
import { useSelectedStakeholderSegmentIds } from '../../../stakeholder/dropdown/useSelectedStakeholderSegmentIds';
import { getGroupedLabels } from '../../../../generic/dropdown/GroupedStringDropdown.utils';

export function SimpleMaterialityMatrix({
  readOnly = false,
}: {
  readOnly?: boolean;
}) {
  const projectContext = useProjectContext();
  const companyId = projectContext?.enterprise?.id ?? '';

  const {
    selectedSegmentIds,
    loading: loadingSelectedIds,
    error: errorSelectedIds,
  } = useSelectedStakeholderSegmentIds({ companyId });
  const {
    availableItems,
    loading: loadingAvailableItems,
    error: errorAvailableItems,
  } = useGroupedStakeholderSegments(companyId);

  if (errorSelectedIds || errorAvailableItems) {
    console.error(errorSelectedIds);
    console.error(errorAvailableItems);
    return <div>Error loading data</div>;
  }

  if (loadingSelectedIds || loadingAvailableItems) {
    return <LoaderFullscreen />;
  }

  return (
    <MaterialityMatrixChart
      companyId={companyId}
      selectedSegmentIds={selectedSegmentIds}
      availableItems={availableItems}
      readOnly={readOnly}
    />
  );
}

function MaterialityMatrixChart({
  companyId,
  selectedSegmentIds,
  availableItems,
  readOnly = false
}: {
  companyId: string;
  selectedSegmentIds: { x: string[]; y: string[] };
  availableItems: GroupedStringDropdownItem[];
  readOnly?: boolean;
}) {
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const computeMaterialityQuery =
    useSimpleFinancialByStakeholderSegmentsMaterialityMatrixQuery({
      variables: {
        input: {
          companyId,
        },
      },
      skip: !companyId,
      fetchPolicy: 'network-only',
    });

  const stakes =
    computeMaterialityQuery?.data?.financialByStakeholderMaterialities || [];

  const stakesMaterialities: TStakeMateriality[] = stakes.map((stake) => {
    const xMateriality = stake.materialities?.x?.value || 0;
    const yMateriality = stake.materialities?.y?.value || 0;
    return {
      stake,
      xMateriality,
      yMateriality,
      materialityScore: xMateriality * yMateriality,
    };
  });

  const xItems = availableItems.filter((item) =>
    selectedSegmentIds.x?.includes(item.id),
  );
  const yItems = availableItems.filter((item) =>
    selectedSegmentIds.y?.includes(item.id),
  );

  const [updateFilters] =
    useUpdateCompanyMaterialityByStakeholderFilterMutation();

  const onChange = (axis: 'x' | 'y', items: string[]) => {
    if (readOnly) {
      return;
    }
    setIsRefreshing(true);
    updateFilters({
      variables: {
        input: {
          id: companyId,
          xStakeholderSegments: axis === 'x' ? items : selectedSegmentIds.x,
          yStakeholderSegments: axis === 'y' ? items : selectedSegmentIds.y,
        },
      },
    })
      .catch((err) => console.error(err))
      .finally(() => {
        computeMaterialityQuery
          .refetch()
          .catch((err) => console.error(err))
          .finally(() => setIsRefreshing(false));
      });
  };

  if (computeMaterialityQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="space-y-4">
      <MaterialityFilters
        selectedSegmentIds={selectedSegmentIds}
        availableItems={availableItems}
        onChange={onChange}
        readOnly={readOnly}
      />
      <div className="relative">
        {isRefreshing && (
          <div className="border rounded-xl border-gray-100 absolute top-0 bottom-1/2 left-0 right-1/2 w-full h-full">
            <LoaderFullscreen />
          </div>
        )}
        <div className={isRefreshing ? 'opacity-10' : 'opacity-100'}>
          <StakesMaterialityChart
            stakesMaterialities={stakesMaterialities}
            options={{
              enableConsensusLine: true,
              enableZoom: true,
              xAxisLabel: getGroupedLabels(xItems),
              yAxisLabel: getGroupedLabels(yItems),
            }}
          />
        </div>
      </div>
    </div>
  );
}

function MaterialityFilters({
  selectedSegmentIds,
  availableItems,
  onChange,
  readOnly = false,
}: {
  selectedSegmentIds: { x: string[]; y: string[] };
  availableItems: GroupedStringDropdownItem[];
  onChange: (axis: 'x' | 'y', items: string[]) => void;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full">
      <div className="flex flex-col sm:flex-1 w-full sm:w-1/2">
        <label className="form-input-label">
          {t('charts.simple_materiality_matrix.x_axis')}
        </label>
        <GroupedStakeholderSegmentDropdown
          multiple
          handleChange={(items: GroupedStringDropdownItem[]) => {
            onChange(
              'x',
              items.map((item) => item.id),
            );
          }}
          selectedSegmentIds={selectedSegmentIds.x}
          availableItems={availableItems}
          readonly={readOnly}
        />
      </div>
      <div className="flex flex-col sm:flex-1 w-full sm:w-1/2">
        <label className="form-input-label">
          {t('charts.simple_materiality_matrix.y_axis')}
        </label>
        <GroupedStakeholderSegmentDropdown
          multiple
          handleChange={(items: GroupedStringDropdownItem[]) => {
            onChange(
              'y',
              items.map((item) => item.id),
            );
          }}
          selectedSegmentIds={selectedSegmentIds.y}
          availableItems={availableItems}
          readonly={readOnly}
        />
      </div>
    </div>
  );
}
