import React from 'react';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import { IRO_TABS, useIroTabs } from '../useIroTabs';
import { IroHeader } from '../IroHeader';
import { MaterialityThresholds } from '../../../../components/project/materiality/threshold/MaterialityThresholds';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { FeatureEnum, PermissionEnum } from '../../../../graphql/generated';

function IroMaterialityThreshold() {
  const projectContext = useProjectContext();
  const { isGranted } = usePermissionChecker();
  const readOnly = !isGranted(
    FeatureEnum.IroMaterialityThresholds,
    PermissionEnum.Write,
  );
  const { t } = useTranslation();
  const iroTabs = useIroTabs();

  if (projectContext?.enterprise?.id) {
    return (
      <>
        <PageTitle title={t('screen.iro.iro_materiality_threshold')} />
        <Breadcrumb />

        <TabNavigationLayout<IRO_TABS>
          tabs={iroTabs}
          activeTab={IRO_TABS.MATERIALITY_THRESHOLD}
          renderTabs={(tabsContent) => (
            <div>
              <IroHeader />
              {tabsContent}
            </div>
          )}
        >
          <div className="w-full p-8">
            <MaterialityThresholds
              companyId={projectContext.enterprise.id}
              readOnly={readOnly}
            />
          </div>
        </TabNavigationLayout>
      </>
    );
  }

  return null;
}

export const IroMaterialityThresholdScreen = withProjectContext(
  IroMaterialityThreshold,
  {
    requiredCompany: true,
  },
);
