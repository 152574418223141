import React from 'react';
import {
  MaturityQuestion_QuestionFragment,
  PillarCompletion_PillarFragment,
  PillarCompletion_QuestionFragment,
  PillarCompletion_ReferentialFragment,
  PillarCompletion_ResponseFragment,
} from '../../../../graphql/generated';
import clsx from 'clsx';
import { PieChart } from 'react-minimal-pie-chart';
import { readCssVar } from '../../../../services/TailwindService';
import { PillarIcon } from '../../../stake/PillarIcon';
import { TooltipWrapper } from '../../../generic/TooltipWrapper';
import { useTranslation } from '@hooks/useTranslation';

export function PillarsCompletion({
  referential,
  currentPillarId,
  setCurrentPillarId,
  response,
  questions,
}: {
  referential: PillarCompletion_ReferentialFragment;
  currentPillarId: string | null;
  setCurrentPillarId: (pillarId: string | null) => void;
  response: PillarCompletion_ResponseFragment | null | undefined;
  questions: MaturityQuestion_QuestionFragment[];
}) {
  const { translateProperty } = useTranslation();
  return (
    <div className="grid grid-cols-4 gap-2">
      {referential.pillars
        .toSorted((a, b) =>
          translateProperty(a, 'name').localeCompare(
            translateProperty(b, 'name'),
          ),
        )
        .map((pillar) => (
          <PillarCompletion
            pillar={pillar}
            key={pillar.id}
            currentPillarId={currentPillarId}
            setCurrentPillarId={setCurrentPillarId}
            response={response}
            questions={questions}
          />
        ))}
    </div>
  );
}

function PillarCompletion({
  pillar,
  currentPillarId,
  setCurrentPillarId,
  response,
  questions,
}: {
  pillar: PillarCompletion_PillarFragment;
  currentPillarId: string | null;
  setCurrentPillarId: (pillarId: string | null) => void;
  response: PillarCompletion_ResponseFragment | null | undefined;
  questions: PillarCompletion_QuestionFragment[];
}) {
  const pillarCompletion = computePillarCompletion(pillar, response, questions);
  const { translateProperty } = useTranslation();

  return (
    <div
      className={clsx(
        'rounded-lg border py-2 px-4 flex items-center gap-4 shadow-sm cursor-pointer hover:border-gray-900',
        currentPillarId === pillar.id
          ? 'border-gray-900 bg-gray-900 text-white'
          : 'border-gray-100 text-gray-900',
      )}
      key={pillar.id}
      onClick={() => {
        currentPillarId === pillar.id
          ? setCurrentPillarId(null)
          : setCurrentPillarId(pillar.id);
      }}
    >
      <div className="flex items-center gap-2 grow">
        <PillarIcon pillar={pillar} />
        <div
          className={clsx(
            'text-sm',
            currentPillarId === pillar.id && 'font-bold',
          )}
        >
          {translateProperty(pillar, 'name')}
        </div>
      </div>
      <div>
        <TooltipWrapper label={`${pillarCompletion}%`}>
          <PillarPieChart pillarCompletion={pillarCompletion} />
        </TooltipWrapper>
      </div>
    </div>
  );
}

function computePillarCompletion(
  pillar: PillarCompletion_PillarFragment,
  response: PillarCompletion_ResponseFragment | null | undefined,
  questions: PillarCompletion_QuestionFragment[],
) {
  const pillarStakesIds = pillar.stakes.map((stake) => stake.id);
  const pillarQuestions = questions.filter((question) => {
    if (question.stake?.id) {
      return pillarStakesIds.includes(question.stake?.id);
    }
    return false;
  });
  const pillarAnswers = response?.answers?.filter((answer) =>
    pillarQuestions.map((question) => question.id).includes(answer.question.id),
  );
  return pillarAnswers?.length && pillarQuestions.length
    ? Math.round((pillarAnswers.length / pillarQuestions.length) * 100)
    : 0;
}

function PillarPieChart({ pillarCompletion }: { pillarCompletion: number }) {
  return (
    <PieChart
      data={[
        {
          value: pillarCompletion,
          color:
            pillarCompletion === 100
              ? readCssVar('--color-green-500')
              : readCssVar('--color-gray-900'),
        },
      ]}
      startAngle={270}
      totalValue={100}
      labelPosition={0}
      lineWidth={40}
      className={'w-8 h-8 shrink-0'}
      animate={true}
      background={readCssVar('--color-gray-100')}
    />
  );
}
