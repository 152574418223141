import { useTranslation } from '@hooks/useTranslation';
import React from 'react';

import { EnterpriseItem } from './EnterpriseItem';
import { JoinEnterpriseList_EnterpriseFragment } from '../../../../graphql/generated';

export function EnterprisesList({
  enterprises,
}: {
  enterprises: JoinEnterpriseList_EnterpriseFragment[];
}) {
  const { t } = useTranslation();
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th>{t('translation:home.projects.archived.title')}</th>
          <th className="w-40"></th>
        </tr>
      </thead>
      <tbody>
        {enterprises.map((company) => (
          <EnterpriseItem company={company} key={company.id} />
        ))}
      </tbody>
    </table>
  );
}
