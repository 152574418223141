import {
  PreviewCompanyTemplate_CompanyFragment,
  useChooseTemplateMutation,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { useToast } from '../../../layout/Toast';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import React, { useState } from 'react';
import { ChevronRightIcon } from '../../../icons';
import { isSuperadminUser } from '../../../../services/CompanyService';
import { Loader } from '../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { Referential } from '../../stakes/Referential';
import { ChecklistPreview } from '../../../task/ChecklistPreview';
import { StakeholderList } from '../../stakeholder/mapping/StakeholderList';
import { Tag } from '../../../badges/Tag';

export const PreviewCompanyTemplate = ({
  template,
  setPreviewCompanyTemplateId,
  applyTemplateToCompanyId,
}: {
  template: PreviewCompanyTemplate_CompanyFragment;
  setPreviewCompanyTemplateId: (companyTemplateId: string | null) => void;
  applyTemplateToCompanyId: string;
}) => {
  const modal = useModal();
  const projectContext = useProjectContext();
  const { t } = useTranslation();
  const toast = useToast();
  const { currentUser } = useCurrentUser();

  const [importUserData, setImportUserData] = useState(false);

  const [isChoosingTemplate, setIsChoosingTemplate] = useState(false);
  const [chooseTemplateMutation] = useChooseTemplateMutation();
  const chooseTemplate = () => {
    setIsChoosingTemplate(true);
    void chooseTemplateMutation({
      variables: {
        input: {
          companyId: applyTemplateToCompanyId,
          templateId: template.id,
          importUserData,
        },
      },
    })
      .then(() => {
        modal.closeModal();
        toast.openToastWithMessage('Modèle importé avec succès 🎉');
        projectContext?.updateEnterprise(applyTemplateToCompanyId, false);
      })
      .finally(() => {
        setIsChoosingTemplate(false);
      });
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="bg-white flex items-center gap-2 p-4 justify-between sticky top-0 z-50 border-b border-gray-100">
        <button
          className="tertiary bg-transparent text-gray-400"
          onClick={() => setPreviewCompanyTemplateId(null)}
        >
          <ChevronRightIcon className="w-4 h-4 transform rotate-180" />
          <span>
            {t('project.create.chooseTemplateModal.previewTemplate.back')}
          </span>
        </button>
        <h4 className="text-center grow">
          {t('project.create.chooseTemplateModal.previewTemplate.title')}
        </h4>
        {isSuperadminUser(currentUser) && (
          <div className="flex items-center gap-2">
            <label htmlFor="importUserData" className="text-sm italic">
              {t(
                'project.create.chooseTemplateModal.previewTemplate.useTemplate.withDemoData',
              )}
            </label>
            <input
              type="checkbox"
              id="importUserData"
              className="form-input-checkbox"
              checked={importUserData}
              onChange={() => setImportUserData(!importUserData)}
            />
          </div>
        )}
        <button
          className="primary purple"
          onClick={chooseTemplate}
          disabled={isChoosingTemplate}
        >
          {isChoosingTemplate && <Loader />}
          <span>
            {t(
              'project.create.chooseTemplateModal.previewTemplate.useTemplate.button',
            )}
          </span>
        </button>
      </div>
      <ProjectTemplatePreview template={template} />
    </div>
  );
};

const ProjectTemplatePreview = ({
  template,
}: {
  template: PreviewCompanyTemplate_CompanyFragment;
}) => {
  const { t } = useTranslation();

  const enum ProjectTemplatePreviewNavItem {
    Referential = 'Referential',
    Methodology = 'Methodology',
    Survey = 'Survey',
    MaturityEvaluation = 'MaturityEvaluation',
    StakeholderMapping = 'StakeholderMapping',
  }

  const surveys = (template.surveys || []).filter(
    (survey) => survey.__typename === 'Survey',
  );
  const maturityEvaluations = (template.surveys || []).filter(
    (survey) => survey.__typename === 'MaturityEvaluation',
  );

  const [selectedNavItem, setSelectedNavItem] =
    useState<ProjectTemplatePreviewNavItem>(
      ProjectTemplatePreviewNavItem.Referential,
    );

  return (
    <div className="flex items-stretch w-full h-full overflow-scroll">
      <div className="w-3/12 divide-y divide-gray-100 overflow-scroll bg-gray-50">
        <div className="p-8 space-y-4">
          <div className="flex">
            <div className="tag blue font-bold">
              {t(
                'project.create.chooseTemplateModal.previewTemplate.nav.templateProject',
              )}
            </div>
          </div>
          <h2>{template.name}</h2>
          {template?.referential?.description && (
            <p className="text-gray-500 text-sm">
              {template.referential.description}
            </p>
          )}

          <div className="space-y-2">
            {template.referential && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem === ProjectTemplatePreviewNavItem.Referential
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(ProjectTemplatePreviewNavItem.Referential);
                }}
              >
                <div>
                  {t(
                    'project.create.chooseTemplateModal.previewTemplate.nav.referential',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
            {template.checklist && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem === ProjectTemplatePreviewNavItem.Methodology
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(ProjectTemplatePreviewNavItem.Methodology);
                }}
              >
                <div>
                  {t(
                    'project.create.chooseTemplateModal.previewTemplate.nav.methodology',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
            {template.stakeholderGroups.length > 0 && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem ===
                    ProjectTemplatePreviewNavItem.StakeholderMapping
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(
                    ProjectTemplatePreviewNavItem.StakeholderMapping,
                  );
                }}
              >
                <div>
                  {t(
                    'translation:project.create.chooseTemplateModal.previewTemplate.nav.stakeholderMapping',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
            {surveys.length > 0 && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem === ProjectTemplatePreviewNavItem.Survey
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(ProjectTemplatePreviewNavItem.Survey);
                }}
              >
                <div>
                  {t(
                    'translation:project.create.chooseTemplateModal.previewTemplate.nav.surveys',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
            {maturityEvaluations.length > 0 && (
              <div
                className={clsx(
                  'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                  selectedNavItem ===
                    ProjectTemplatePreviewNavItem.MaturityEvaluation
                    ? 'bg-gray-900 text-white border-gray-900'
                    : 'bg-white border-gray-100',
                )}
                onClick={() => {
                  setSelectedNavItem(
                    ProjectTemplatePreviewNavItem.MaturityEvaluation,
                  );
                }}
              >
                <div>
                  {t(
                    'translation:project.create.chooseTemplateModal.previewTemplate.nav.maturityEvaluation',
                  )}
                </div>
                <ChevronRightIcon />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-9/12 shadow-inner overflow-y-scroll">
        {selectedNavItem === ProjectTemplatePreviewNavItem.Referential &&
          template.referential && (
            <div className="p-8 w-full">
              <Referential
                referentialId={template.referential.id}
                readonly={true}
              />
            </div>
          )}
        {selectedNavItem === ProjectTemplatePreviewNavItem.Methodology &&
          template.checklist && (
            <div className="p-8 space-y-8">
              <h3>
                {t(
                  'project.create.chooseTemplateModal.previewTemplate.methodology.title',
                )}
              </h3>
              <ChecklistPreview checklistId={template.checklist.id} />
            </div>
          )}

        {selectedNavItem === ProjectTemplatePreviewNavItem.Survey &&
          surveys.length > 0 && (
            <div className="p-8 space-y-8">
              <h3 className="flex items-center gap-2">
                {t(
                  'translation:project.create.chooseTemplateModal.previewTemplate.nav.surveys',
                )}
                <Tag color={'blue'}>{surveys.length}</Tag>
              </h3>
              <div>
                <ul className="space-y-2">
                  {surveys.map((survey) => (
                    <li
                      key={survey.id}
                      className={clsx(
                        'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                      )}
                    >
                      <div>{survey.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

        {selectedNavItem === ProjectTemplatePreviewNavItem.MaturityEvaluation &&
          maturityEvaluations.length > 0 && (
            <div className="p-8 space-y-8">
              <h3 className="flex items-center gap-2">
                {t(
                  'translation:project.create.chooseTemplateModal.previewTemplate.nav.maturityEvaluation',
                )}
                <Tag color={'blue'}>{maturityEvaluations.length}</Tag>
              </h3>
              <div>
                <ul className="space-y-2">
                  {maturityEvaluations.map((maturityEvaluation) => (
                    <li
                      key={maturityEvaluation.id}
                      className={clsx(
                        'py-2 px-4 rounded-xl border text-sm font-bold flex items-center justify-between cursor-pointer hover:border-gray-900',
                      )}
                    >
                      <div>{maturityEvaluation.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

        {selectedNavItem === ProjectTemplatePreviewNavItem.StakeholderMapping &&
          template.stakeholderGroups && (
            <StakeholderList
              companyId={template.id}
              readonly
              gridClassname={'overflow-auto'}
            />
          )}
      </div>
    </div>
  );
};
