import { StakeDetailModal_StakeFragment } from '../../../graphql/generated';
import React, { useState } from 'react';
import { useModal } from '../../layout/Modal';
import { PillarIcon } from '../PillarIcon';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../../nav/Tabs';
import { StakeSurveysDetail } from './stakeSurveysDetail/StakeSurveysDetail';
import { StakeActionsDetail } from './StakeActionsDetail';
import { StakeMaturityDetail } from './StakeMaturityDetail';
import { StakeOverviewDetail } from './StakeOverviewDetail';
import { StakeIroDetail } from './stakeIroDetail/StakeIroDetail';
import { XIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export const enum STAKE_DETAILS_TABS {
  STAKE_OVERVIEW = 'STAKE_OVERVIEW',
  STAKE_SURVEYS = 'STAKE_SURVEYS',
  STAKE_MATERIALITY = 'STAKE_MATERIALITY',
  STAKE_MATURITY = 'STAKE_MATURITY',
  STAKE_ACTIONS = 'STAKE_ACTIONS',
}

export function openStakeDetailModal(
  modal: ReturnType<typeof useModal>,
  stake: StakeDetailModal_StakeFragment,
  openOnTab: STAKE_DETAILS_TABS,
) {
  modal.openModalWithComponent(
    <StakeDetailModal stake={stake} openOnTab={openOnTab} />,
    null,
    true,
    false,
    'w-3/5',
  );
}

export function StakeDetailModal({
  stake,
  openOnTab,
}: {
  stake: StakeDetailModal_StakeFragment;
  openOnTab: STAKE_DETAILS_TABS;
}) {
  const { t, translateProperty } = useTranslation();
  const modal = useModal();

  const tabs: TabItem[] = [
    {
      key: STAKE_DETAILS_TABS.STAKE_OVERVIEW,
      name: t('stake.detailModal.overview.tab'),
    },
    {
      key: STAKE_DETAILS_TABS.STAKE_SURVEYS,
      name: t('stake.detailModal.surveys.tab'),
    },
    {
      key: STAKE_DETAILS_TABS.STAKE_MATERIALITY,
      name: t('stake.detailModal.materiality.tab'),
    },
    {
      key: STAKE_DETAILS_TABS.STAKE_MATURITY,
      name: t('stake.detailModal.maturity.tab'),
    },
    {
      key: STAKE_DETAILS_TABS.STAKE_ACTIONS,
      name: t('stake.detailModal.actions.tab'),
    },
  ];

  // If openOnTab is not provided, we open on the first tab. Else we open on the tab provided.
  const openOnTabItem = openOnTab
    ? tabs.find((tab) => tab.key === openOnTab)
    : tabs[0];
  const [currentTab, setCurrentTab] = useState<TabItem>(
    openOnTabItem || tabs[0],
  );
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <div className="bg-white flex items-center p-4 justify-center sticky top-0 z-50 border-b border-gray-100 relative">
        <button
          className="tertiary bg-transparent text-gray-400 absolute left-4"
          onClick={() => modal.closeModal()}
        >
          <XIcon className="w-4 h-4" />
        </button>
        <div className="flex items-center gap-2">
          <PillarIcon pillar={stake.pillar} />
          <h6>{translateProperty(stake, 'name')}</h6>
        </div>
      </div>

      <div className="p-4 border-b border-gray-100">
        <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
      </div>

      <div className="bg-gray-50 grow p-4 overflow-y-scroll">
        {currentTab.key === STAKE_DETAILS_TABS.STAKE_OVERVIEW && (
          <StakeOverviewDetail stakeId={stake.id} />
        )}
        {currentTab.key === STAKE_DETAILS_TABS.STAKE_SURVEYS && (
          <StakeSurveysDetail stakeId={stake.id} />
        )}
        {currentTab.key === STAKE_DETAILS_TABS.STAKE_MATURITY && (
          <StakeMaturityDetail stakeId={stake.id} />
        )}
        {currentTab.key === STAKE_DETAILS_TABS.STAKE_ACTIONS && (
          <StakeActionsDetail stakeId={stake.id} />
        )}
        {currentTab.key === STAKE_DETAILS_TABS.STAKE_MATERIALITY && (
          <StakeIroDetail stakeId={stake.id} />
        )}
      </div>
    </div>
  );
}
