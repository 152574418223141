import { IroContextualMenu } from '../IroContextualMenu';
import React from 'react';
import {
  FeatureEnum,
  IroCellName_IroFragment,
  SurveyLanguage,
  ValueChainItemEnum,
} from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { MissingTranslationsWarningIcon } from '../../../../../generic/MissingTranslationsWarningIcon';
import { IroFragment } from '../IroRow';
import { hasHasPotentialNegativeHumanRightsImpactCriteria } from '../../../../../../types/iro.types';
import { BuildingIcon, ScaleIcon } from '../../../../../icons';
import { Tag } from '../../../../../badges/Tag';
import clsx from 'clsx';
import { IroConsultationResultsModal } from '../../results/IroConsultationResultsModal';
import { useModal } from '../../../../../layout/Modal';
import { TooltipWrapper } from '../../../../../generic/TooltipWrapper';
import { JustifyIroButton } from '../../../../audit/justification/type/JustifyIroButton';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export function IroNameCell({
  iro,
  readOnly = false,
  hasConsultationAssessments = false,
}: {
  iro: IroCellName_IroFragment & IroFragment;
  readOnly?: boolean;
  hasConsultationAssessments?: boolean;
}) {
  const { translateProperty, i18n, t } = useTranslation();
  const modal = useModal();
  const { isGranted } = usePermissionChecker();
  const openIroResultsModal = () => {
    modal.openModalWithComponent(
      <IroConsultationResultsModal iro={iro} />,
      t('iro.consultationResultsModal.title'),
      false,
      undefined,
      'w-3/4 p-0',
    );
  };

  return (
    <div className="flex items-center gap-1 justify-between">
      <div className="grow space-y-1">
        <div className="text-base">
          {translateProperty(iro, 'name')}
          {!readOnly && (
            <MissingTranslationsWarningIcon
              entity={iro}
              properties={['name']}
              languages={[i18n.language as SurveyLanguage]}
            />
          )}
        </div>
        <IroTags iro={iro} />
      </div>
      {!readOnly && (
        <>
          <div className="flex items-center gap-2">
            <div className="invisible group-hover:visible">
              <IroContextualMenu
                {...{
                  iro,
                }}
              />
            </div>
          </div>
          {(hasConsultationAssessments && (
            <button className="tertiary p-1" onClick={openIroResultsModal}>
              <ScaleIcon className={clsx('text-blue-500')} />
            </button>
          )) || (
            <TooltipWrapper
              label={t('iro.table.row.name.iroAssessment.noConsultation')}
            >
              <ScaleIcon className="w-3 h-3 text-gray-300" />
            </TooltipWrapper>
          )}
        </>
      )}
      {(!readOnly || isGranted(FeatureEnum.AuditTrail)) && (
        <JustifyIroButton iroId={iro.id} readOnly={readOnly} />
      )}
    </div>
  );
}

function IroTags({ iro }: { iro: IroCellName_IroFragment & IroFragment }) {
  const { t } = useTranslation();

  const potentialNegativeHumanRightsImpactCriteria =
    hasHasPotentialNegativeHumanRightsImpactCriteria(iro) &&
    iro.hasPotentialNegativeHumanRightsImpact === true;

  const hasOwnOperationsValueChainItem = iro.valueChainItems?.find(
    (valueChainItem) => valueChainItem === ValueChainItemEnum.OwnOperations,
  );

  return (
    <div className="flex items-center gap-1 flex-wrap">
      {potentialNegativeHumanRightsImpactCriteria && (
        <Tag color={'red'} small={true}>
          {t('iro.criteria.hasPotentialNegativeHumanRightsImpact')}
        </Tag>
      )}

      {(iro.valueChainItems || []).map((valueChainItem) => (
        <div key={valueChainItem} className="tag blue small">
          {t(`enum:valueChainItem.${valueChainItem}`)}
        </div>
      ))}

      {hasOwnOperationsValueChainItem &&
        (iro.organizationalUnits || []).length === 0 && (
          <Tag color="blue" small={true}>
            <BuildingIcon className="w-4 h-4" />
            {t(`enum:valueChainItem.global`)}
          </Tag>
        )}

      {hasOwnOperationsValueChainItem &&
        (iro.organizationalUnits || []).map((ou) => (
          <Tag key={ou.id} color="blue" small={true}>
            <BuildingIcon className="w-4 h-4" />
            {ou.name}
          </Tag>
        ))}
    </div>
  );
}
