import React from 'react';
import {
  AnalysisThemeCard_AnalysisThemeFragment,
  AnalysisThemeRow_AnalysisThemeFragment,
  AnalysisThemesTable_AnalysisThemeFragment,
  AnalysisThemesTreemap_AnalysisThemeFragment,
  useAnalysisThemesListQuery,
} from '../../../graphql/generated';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../layout/Loader';
import AnalysisThemeTag, {
  getThemeBgColor,
  getThemeTextColor,
} from './AnalysisThemeTag';
import { ProgressBar, ProgressBarStyles } from '../../generic/ProgressBar';
import { AnalysisThemeModal } from './AnalysisThemeModal';
import { useModal } from '../../layout/Modal';
import clsx from 'clsx';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { ChevronRightIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function AnalysisThemesList() {
  const projectContext = useProjectContext();
  const companyId = projectContext?.enterprise?.id;
  const query = useAnalysisThemesListQuery({
    variables: {
      companyId: companyId || '',
    },
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  const analysisThemes = query.data?.analysisThemes || [];

  return (
    <>
      {analysisThemes.length > 0 ? (
        <div className="divide-y divide-gray-100 space-y-8">
          <AnalysisThemesTreemap analysisThemes={analysisThemes} />
          <AnalysisThemesTable analysisThemes={analysisThemes} />
        </div>
      ) : (
        <MessageBox type={MessageBoxType.Info}>
          Aucun thème n'a été créé pour l'instant
        </MessageBox>
      )}
    </>
  );
}

function AnalysisThemesTreemap({
  analysisThemes,
}: {
  analysisThemes: AnalysisThemesTreemap_AnalysisThemeFragment[];
}) {
  const { t } = useTranslation();
  const totalValue = analysisThemes.flatMap(
    (analysisTheme) => analysisTheme.answers,
  ).length;

  const sortedAnalysisThemes = analysisThemes
    // Remove themes without mentions
    .filter((theme) => (theme.answers?.length || 0) > 0)
    // sort by number of mentions DESC
    .toSorted((a, b) => {
      const aCount = a.answers?.length || 0;
      const bCount = b.answers?.length || 0;
      return bCount - aCount;
    });

  return (
    <div className="space-y-4">
      <h2 className="title-h6 flex items-center gap-2">
        <span>{t('survey.results.themes.totalPerMention')}</span>
      </h2>
      <div className="columns-3 gap-2">
        {sortedAnalysisThemes.map((analysisTheme) => (
          <AnalysisThemeCard
            key={analysisTheme.id}
            theme={analysisTheme}
            totalValue={totalValue}
          />
        ))}
      </div>
    </div>
  );
}

function AnalysisThemeCard({
  theme,
  totalValue,
}: {
  theme: AnalysisThemeCard_AnalysisThemeFragment;
  totalValue: number;
}) {
  const modal = useModal();
  const openThemeModal = () => {
    modal.openModalWithComponent(
      <AnalysisThemeModal analysisThemeId={theme.id} />,
      'Thème',
      true,
      true,
      'w-8/12',
    );
  };

  const themeCount = theme.answers?.length || 0;

  const height = Math.round((400 * themeCount) / totalValue);

  return (
    <div
      onClick={openThemeModal}
      className={clsx(
        'mb-2 flex items-center justify-center gap-2 break-inside-avoid text-xs rounded-xl cursor-pointer',
        getThemeBgColor(theme.color, false, null),
        getThemeTextColor(theme),
      )}
      style={{ height: `${height}px` }}
    >
      <span>{theme.name}</span>
      <span className="font-bold">{themeCount}</span>
    </div>
  );
}

function AnalysisThemesTable({
  analysisThemes,
}: {
  analysisThemes: AnalysisThemesTable_AnalysisThemeFragment[];
}) {
  const { t } = useTranslation();
  const totalValue = analysisThemes.flatMap(
    (analysisTheme) => analysisTheme.answers,
  ).length;

  return (
    <div className="pt-8 space-y-4">
      <h2 className="title-h6 flex items-center gap-2">
        <span>{t('survey.results.themes.allThemes')}</span>
        <span className="tag gray">{analysisThemes.length}</span>
      </h2>
      <table className="w-full">
        <thead>
          <tr>
            <th>{t('survey.results.themes.table.theme')}</th>
            <th>{t('survey.results.themes.table.totalMentions')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {analysisThemes
            // sort by number of mentions DESC
            .toSorted((a, b) => {
              const aCount = a.answers?.length || 0;
              const bCount = b.answers?.length || 0;
              return bCount - aCount;
            })
            .map((analysisTheme) => (
              <AnalysisThemeRow
                key={analysisTheme.id}
                theme={analysisTheme}
                totalValue={totalValue}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

function AnalysisThemeRow({
  theme,
  totalValue,
}: {
  theme: AnalysisThemeRow_AnalysisThemeFragment;
  totalValue: number;
}) {
  const modal = useModal();
  const openThemeModal = () => {
    modal.openModalWithComponent(
      <AnalysisThemeModal analysisThemeId={theme.id} />,
      'Thème',
      true,
      true,
      'w-8/12',
    );
  };

  const themeCount = theme.answers?.length || 0;

  return (
    <tr onClick={openThemeModal}>
      <td className="w-4/12">
        <div className="flex font-semibold">
          <AnalysisThemeTag analysisTheme={theme} />
        </div>
      </td>
      <td className="font-semibold w-6/12">
        {themeCount > 0 ? (
          <div className="flex items-center gap-4 font-semibold max-w-md">
            <ProgressBar
              value={themeCount || 0}
              total={totalValue}
              style={ProgressBarStyles.BLACK_AND_WHITE}
            />
            {themeCount}
          </div>
        ) : (
          '-'
        )}
      </td>
      <td className="w-2/12">
        <div className="flex items-center justify-end">
          <button className="primary purple small" onClick={openThemeModal}>
            <ChevronRightIcon />
          </button>
        </div>
      </td>
    </tr>
  );
}
