import React, { useState } from 'react';
import RichTextEditorTextarea from '../../../generic/form/RichTextEditorTextarea';
import { Loader } from '../../../generic/Loader';
import {
  JustificationType,
  useJustificationFormModal_CreateJustificationMutation,
  useJustificationFormModal_LatestJustificationQuery,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { LoaderFullscreen } from '../../../layout/Loader';
import { useToast } from '../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { InnerHtml } from '../../../generic/InnerHtml';

export const JustificationFormModal = ({
  latestJustificationId,
  type,
  entityId,
  callback,
  readOnly = false,
}: {
  latestJustificationId: string | null | undefined;
  type: JustificationType;
  entityId: string;
  callback?: () => void;
  readOnly?: boolean;
}) => {
  const modal = useModal();
  const toast = useToast();
  const { t } = useTranslation();

  const [justificationContent, setJustificationContent] = useState('');

  const query = useJustificationFormModal_LatestJustificationQuery({
    variables: {
      id: latestJustificationId || '',
    },
    skip: !latestJustificationId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setJustificationContent(
        data?.getBaseJustificationById.justification || '',
      );
    },
  });

  const [justifyMutation, { loading }] =
    useJustificationFormModal_CreateJustificationMutation();

  const justify = () => {
    justifyMutation({
      variables: {
        input: {
          type,
          justification: justificationContent,
          entityId,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:audit.justification.saved'));
        modal.closeModal();
      })
      .catch((error) => {
        toast.openToastWithError(t('toast:audit.justification.error'));
      })
      .finally(() => callback && callback());
  };

  return (
    <div className="flex flex-col items-stretch gap-4">
      {query.loading ? (
        <LoaderFullscreen />
      ) : (
        <div className="max-h-96 overflow-y-scroll">
          {(!readOnly && (
            <RichTextEditorTextarea
              inlined={true}
              value={justificationContent}
              onChange={(value) => setJustificationContent(value)}
            />
          )) ||
            (justificationContent && (
              <div className={'m-10'}>
                <InnerHtml html={justificationContent} />
              </div>
            )) || (
              <div className={'text-center my-24'}>{t('global:noResult')}</div>
            )}
        </div>
      )}
      {!readOnly && (
        <div className="p-4 flex justify-between border-t border-gray-100">
          <button className="secondary" onClick={() => modal.closeModal()}>
            {t('global:cancel')}
          </button>
          <button className="primary" onClick={justify} disabled={loading}>
            {loading && <Loader />}
            {t('audit.justification.cta')}
          </button>
        </div>
      )}
    </div>
  );
};
