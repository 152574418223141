import {
  BaseIro,
  IroType,
  IrremediabilityValue,
  LikelihoodValue,
  NegativeImpactIro,
  OpportunityIro,
  PositiveImpactIro,
  RiskIro,
  ScaleValue,
  ScopeValue,
  TimeHorizon,
  WithIrremediabilityCriteria,
  WithLikelihoodCriteria,
  WithPotentialNegativeHumanRightsImpactCriteria,
  WithScaleCriteria,
  WithScopeCriteria,
} from '../graphql/generated';

export function isRiskIro<T extends RiskIro>(
  iro?: BaseIro | null | undefined,
): iro is T {
  return (iro as RiskIro)?.__typename === 'RiskIro';
}

export function isOpportunityIro<T extends OpportunityIro>(
  iro: BaseIro | null | undefined,
): iro is T {
  return (iro as T)?.__typename === 'OpportunityIro';
}

export function isPositiveImpactIro<T extends PositiveImpactIro>(
  iro: BaseIro | null | undefined,
): iro is T {
  return (iro as T)?.__typename === 'PositiveImpactIro';
}

export function isNegativeImpactIro<T extends NegativeImpactIro>(
  iro: BaseIro | null | undefined,
): iro is T {
  return (iro as T)?.__typename === 'NegativeImpactIro';
}

export function hasScaleCriteria<T extends WithScaleCriteria>(
  iro?: unknown | null | undefined,
): iro is T {
  if (!(iro as WithScaleCriteria).scaleCriteria) {
    return false;
  }
  return true;
}

export function hasScopeCriteria<T extends WithScopeCriteria>(
  iro?: unknown | null | undefined,
): iro is T {
  if (!(iro as WithScopeCriteria).scopeCriteria) {
    return false;
  }
  return true;
}

export function hasIrremediabilityCriteria<
  T extends WithIrremediabilityCriteria,
>(iro?: unknown | null | undefined): iro is T {
  if (!(iro as WithIrremediabilityCriteria).irremediabilityCriteria) {
    return false;
  }
  return true;
}

export function hasLikelihoodCriteria<T extends WithLikelihoodCriteria>(
  iro?: unknown | null | undefined,
): iro is T {
  if (!(iro as WithLikelihoodCriteria).likelihoodCriteria) {
    return false;
  }
  return true;
}

export function hasHasPotentialNegativeHumanRightsImpactCriteria<
  T extends WithPotentialNegativeHumanRightsImpactCriteria,
>(iro?: unknown | null | undefined): iro is T {
  return isNegativeImpactIro(iro as NegativeImpactIro);
}

export enum CriteriaType {
  SCALE = 'scale',
  SCOPE = 'scope',
  IRREMEDIABILITY = 'irremediability',
  LIKELIHOOD = 'likelihood',
  TIME_HORIZON = 'timeHorizon',
}

export const CriteriasByIroType = {
  [IroType.NegativeImpact]: [
    CriteriaType.SCALE,
    CriteriaType.SCOPE,
    CriteriaType.LIKELIHOOD,
    CriteriaType.IRREMEDIABILITY,
    CriteriaType.TIME_HORIZON,
  ],
  [IroType.PositiveImpact]: [
    CriteriaType.SCALE,
    CriteriaType.SCOPE,
    CriteriaType.LIKELIHOOD,
    CriteriaType.TIME_HORIZON,
  ],
  [IroType.Risk]: [
    CriteriaType.SCALE,
    CriteriaType.LIKELIHOOD,
    CriteriaType.TIME_HORIZON,
  ],
  [IroType.Opportunity]: [
    CriteriaType.SCALE,
    CriteriaType.LIKELIHOOD,
    CriteriaType.TIME_HORIZON,
  ],
};

export type CriteriaValues =
  | ScopeValue
  | ScaleValue
  | LikelihoodValue
  | IrremediabilityValue
  | TimeHorizon;
