import React, { ReactNode } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';
import {
  EnterpriseRoleEnum,
  FeatureEnum,
  PermissionEnum,
  useEnterpriseUserAccess_GiveUserAccessMutation,
  UserAccessFragment,
} from '../../../../graphql/generated';
import { SkeletonLoader } from '../../../generic/loader/SkeletonLoader';
import { UserRoleDropdown } from '../roles/UserRoleDropdown';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { CompanyLogo } from '../CompanyLogo';
import { Tag, TagColor } from '../../../badges/Tag';
import { useToast } from '../../../layout/Toast';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { DeleteUserAccessButton } from './DeleteUserAccessButton';

export type UserAccessTableRow = {
  id: string;
  name: string;
  email?: string;
  role: {
    role: EnterpriseRoleEnum;
  };
  type: {
    external: boolean;
    baseCompany: UserAccessFragment['user']['company'];
  };
  actions?: ReactNode[];
};

export const useUserAccessTableColumns = (): Array<
  ColumnDef<UserAccessTableRow>
> => {
  const { t } = useTranslation();
  const toast = useToast();
  const columnHelper = createColumnHelper<UserAccessTableRow>();
  const projectContext = useProjectContext();
  if (!projectContext?.enterprise?.id) {
    return [];
  }

  const [giveUserAccess] = useEnterpriseUserAccess_GiveUserAccessMutation();
  const { isGranted } = usePermissionChecker();
  const randomWidths = [
    'w-1/2',
    'w-7/12',
    'w-3/4',
    'w-9/12',
    'w-10/12',
    'w-11/12',
    'w-12/12',
  ];

  return [
    columnHelper.accessor('name', {
      cell: (data) => {
        const name = data.getValue();
        return (
          (name && (
            <div className={`flex gap-4 items-center font-bold`}>
              {name}
              {data.row.original.type.external &&
                data.row.original.type.baseCompany && (
                  <Tag color={TagColor.gray}>
                    <CompanyLogo
                      companyId={data.row.original.type.baseCompany?.id}
                      size={'tiny'}
                    />
                    {data.row.original.type.baseCompany.name}
                  </Tag>
                )}
            </div>
          )) || (
            <SkeletonLoader
              size={{ height: 'h-5' }}
              randomSizes={{
                width: randomWidths,
              }}
            />
          )
        );
      },
      header: () => t('project.users.table.header.name'),
      meta: {
        th: {
          className: 'w-2/3',
        },
      },
    }),
    columnHelper.accessor('email', {
      cell: (data) => {
        const email = data.getValue();
        return (
          (email && <div className={`font-bold`}>{email}</div>) || (
            <SkeletonLoader
              size={{ height: 'h-5' }}
              randomSizes={{
                width: randomWidths,
              }}
            />
          )
        );
      },
      header: () => t('project.users.table.header.email'),
      meta: {
        th: {
          className: 'w-2/3',
        },
      },
    }),
    columnHelper.accessor('role', {
      cell: (data) => {
        const role = data.getValue().role;

        return (
          (role && (
            <UserRoleDropdown
              item={{
                id: role,
                label: t(`enum:enterpriseRole.${role}`),
              }}
              disabled={
                !isGranted(FeatureEnum.ProjectUsers, PermissionEnum.Write)
              }
              setItem={(item) => {
                if (!item?.id) {
                  return;
                }
                giveUserAccess({
                  variables: {
                    input: {
                      user: {
                        id: data.row.original.id,
                      },
                      enterprise: {
                        id: projectContext?.enterprise?.id!,
                      },
                      role: item.id as EnterpriseRoleEnum,
                    },
                  },
                  onCompleted: (item) => {
                    toast.openToastWithMessage(
                      t('toast:project.users.updateRole.success'),
                    );
                  },
                });
              }}
            />
          )) || (
            <SkeletonLoader
              size={{ height: 'h-5' }}
              randomSizes={{
                width: randomWidths,
              }}
            />
          )
        );
      },
      header: () => t('project.users.table.header.role'),
      meta: {
        th: {
          className: 'w-2/3',
        },
      },
    }),
    columnHelper.accessor('actions', {
      cell: (data) => {
        return (
          <div className={'flex items-center gap-2'}>{data.getValue()}</div>
        );
      },
      enableSorting: false,
      header: () => '',
      meta: {
        th: {
          className: 'w-2/3',
        },
      },
    }),
  ] as Array<ColumnDef<UserAccessTableRow>>;
};

export const prepareData = (
  userAccess: UserAccessFragment[],
): Array<UserAccessTableRow> => {
  const data: Array<UserAccessTableRow> = [];
  const projectContext = useProjectContext();

  userAccess.forEach((ua) => {
    const role = ua.role;
    const user = ua.user;
    const name = `${user.firstName} ${user.lastName}`;
    const email = user.auth?.email;

    data.push({
      id: ua.user.id,
      name,
      email,
      role,
      type: {
        external: ua.user.company?.id !== projectContext?.enterprise?.id,
        baseCompany: ua.user.company!,
      },
      actions: [<DeleteUserAccessButton userAccess={ua} />],
    });
  });

  return data;
};
