import React, { useEffect, useState } from 'react';
import { useDisclosure } from '../DisclosureContext';
import { Toggle } from '../../../../generic/form/Toggle';
import { AccordionItem } from '../../../../generic/accordion/AccordionItem';
import clsx from 'clsx';
import { useSaveDataPoint } from './useSaveDataPoint';
import { DatapointGapAnalysisStatusEditor } from './DatapointGapAnalysisStatusEditor';
import {
  DatapointAccordionItem_ReportDatapointFragment,
  DatapointAccordionItem_ReportDisclosureRequirementFragment,
} from '../../../../../graphql/generated';
import { DatapointAccordionItem_DatapointFragment } from '../../../../../graphql/cms/generated';

export function DatapointAccordionItem({
  datapoint,
  reportDatapoint,
  disclosureRequirement,
  readOnly = false,
}: {
  datapoint: DatapointAccordionItem_DatapointFragment;
  reportDatapoint?: DatapointAccordionItem_ReportDatapointFragment;
  disclosureRequirement: DatapointAccordionItem_ReportDisclosureRequirementFragment;
  readOnly?: boolean;
}) {
  const {
    selectedDatapointSlug,
    selectDatapointSlug,
    highlightedDatapointsSlugs,
    setHighlightedDatapointsSlugs,
  } = useDisclosure();
  const [disabled, setDisabled] = useState(reportDatapoint?.disabled ?? true);

  const { saveDataPoint } = useSaveDataPoint();

  const isExpanded = selectedDatapointSlug === datapoint.slug;
  const toggleExpanded = () => {
    const newValue =
      selectedDatapointSlug === datapoint.slug ? null : datapoint.slug;
    selectDatapointSlug(newValue ? newValue : null);
  };

  useEffect(() => {
    if (!readOnly && datapoint.slug) {
      saveDataPoint(datapoint.slug, disclosureRequirement, { disabled });
    }
  }, [disabled, readOnly]);

  return (
    <div
      onMouseOver={() => setHighlightedDatapointsSlugs([datapoint.slug || ''])}
      onMouseOut={() => setHighlightedDatapointsSlugs([])}
    >
      <AccordionItem
        title={
          <div className="flex items-center justify-between gap-2">
            <div
              className="flex items-center gap-2 cursor-pointer grow"
              onClick={() => toggleExpanded()}
            >
              <div className="text-base font-bold">{datapoint.identifier}</div>
              <div className="flex items-center gap-2">
                {!datapoint.voluntary && (
                  <div className="text-red-500 font-bold">*</div>
                )}
              </div>
            </div>
            <Toggle
              state={!disabled}
              setState={() => {
                setDisabled(!disabled);
              }}
              disabled={readOnly}
            />
          </div>
        }
        isExpanded={isExpanded}
        onToggle={() => toggleExpanded()}
        headerProps={{ onClick: () => undefined }}
        className={clsx(
          highlightedDatapointsSlugs.includes(datapoint.slug || '') &&
            'border-gray-900',
        )}
      >
        {isExpanded && (
          <DatapointGapAnalysisStatusEditor
            autoSave={true}
            datapoint={datapoint}
            reportDatapoint={reportDatapoint}
            reportDisclosureRequirement={disclosureRequirement}
            readOnly={readOnly}
          />
        )}
      </AccordionItem>
    </div>
  );
}
