import React from 'react';
import { StakeMaturityProgressBar } from '../StakeMaturityProgressBar';
import StakeMaturitySelect from '../../project/stakes/StakeMaturitySelect';
import { MaturityEvaluationAnswers } from '../../project/maturity/MaturityEvaluationAnswers';
import { LoaderFullscreen } from '../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import {
  FeatureEnum,
  PermissionEnum,
  StakeMaturityScore_StakeFragment,
  useStakeMaturityDetailQuery,
} from '../../../graphql/generated';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export function StakeMaturityDetail({ stakeId }: { stakeId: string }) {
  const { isGranted } = usePermissionChecker();
  const readOnly = isGranted(
    FeatureEnum.DeliverableMaturityScore,
    PermissionEnum.Read,
    true,
  );
  const query = useStakeMaturityDetailQuery({
    variables: {
      id: stakeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data?.getStakeWithComputedFields) {
    return null;
  }

  const stake = query.data?.getStakeWithComputedFields;

  return (
    <div className="p-4 space-y-4 divide-y divide-gray-100">
      <StakeMaturityScore stake={stake} />
      <div className="space-y-4 pt-4">
        <h6>Détail des réponses</h6>
        <MaturityEvaluationAnswers stakeId={stake.id} readOnly={readOnly} />
      </div>
    </div>
  );
}

function StakeMaturityScore({
  stake,
}: {
  stake: StakeMaturityScore_StakeFragment;
}) {
  const { isGranted } = usePermissionChecker();
  const readOnly = isGranted(
    FeatureEnum.DeliverableMaturityScore,
    PermissionEnum.Read,
    true,
  );
  
  return (
    <div className="space-y-4">
      <h6>Score de maturité</h6>
      <div className="flex items-center gap-4">
        <div className="w-2/3">
          <StakeMaturityProgressBar stake={stake} />
        </div>
        <div className="w-1/3 flex justify-end">
          <StakeMaturitySelect
            stake={stake}
            disabled={readOnly}
          />
        </div>
      </div>
    </div>
  );
}
