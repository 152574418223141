import React, { useRef } from 'react';
import {
  CompanySector,
  CreateOrganizationalUnit_EnterpriseFragment,
  EnterpriseStructureType,
  OrganizationalUnitCard_OrganizationalUnitFragment,
  ProjectCompanyScreenDocument,
  useCreateOrganizationalUnitMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { EnterpriseInformationForm } from './EnterpriseForm';
import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown.types';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';

export function CreateOrganizationalUnit({
  onSave,
  enterprise,
  orgUnitParent,
}: {
  onSave?: () => void;
  enterprise: CreateOrganizationalUnit_EnterpriseFragment;
  orgUnitParent?: OrganizationalUnitCard_OrganizationalUnitFragment;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const projectContext = useProjectContext();
  const formRef = useRef<{ submit: () => void } | null>(null);
  const [createOrganizationalUnit, { loading }] =
    useCreateOrganizationalUnitMutation();
  const handleSave = (data: {
    name: string;
    sector?: CompanySector;
    size?: number;
    annualTurnover?: string;
    identificationNumber?: string;
    country?: string;
    structureType?: StringDropdownItem | null;
  }) => {
    createOrganizationalUnit({
      variables: {
        input: {
          enterprise: {
            id: enterprise.id,
          },
          parent:
            (orgUnitParent && {
              id: orgUnitParent.id,
            }) ||
            undefined,
          name: data.name,
          structureType: data.structureType?.id as EnterpriseStructureType,
          country: data.country,
          metadata: {
            sector: data.sector,
            size: data.size,
            annualTurnover: data.annualTurnover,
            identificationNumber: data.identificationNumber,
          },
        },
      },
      refetchQueries: [
        {
          query: ProjectCompanyScreenDocument,
          variables: { companyId: projectContext?.enterprise?.id || '' },
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:company.settings.updated.success'));
      })
      .catch((err: { message: string }) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        if (onSave) onSave();
      });
  };

  return (
    <div className={'flex flex-col gap-5'}>
      <EnterpriseInformationForm
        onSave={handleSave}
        ref={formRef}
        isLoading={loading}
        initialData={{
          name: '',
          country: orgUnitParent?.country || enterprise.country!,
          sector:
            orgUnitParent?.metadata?.sector || enterprise.metadata?.sector!,
        }}
      />
      <button className="primary" onClick={() => formRef.current?.submit()}>
        {t('global:save')}
      </button>
    </div>
  );
}
