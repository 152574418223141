import React, { Fragment } from 'react';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { MenuIcon } from '../icons';
import { Float } from '@headlessui-float/react';
import clsx from 'clsx';

export function ContextualMenu({
  children,
  button,
  preventFromClosingAutomatically,
  color,
}: {
  children: React.ReactNode;
  button?: React.ReactNode;
  preventFromClosingAutomatically?: boolean;
  color?: string;
}) {
  return (
    <Popover className="relative">
      {({ close }) => (
        <Float portal autoPlacement>
          <PopoverButton className="unstyled focus:outline-hidden ">
            {button ?? (
              <MenuIcon
                className={clsx(
                  'shrink-0 h-3.5 w-3.5',
                  color ?? 'text-gray-500',
                )}
              />
            )}
          </PopoverButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="z-50 bg-white rounded-lg">
              <div className="rounded-lg shadow-lg w-full">
                <div
                  className="flex flex-col items-start p-1 w-full"
                  onClick={() => {
                    if (!preventFromClosingAutomatically) {
                      close();
                    }
                  }}
                >
                  {children}
                </div>
              </div>
            </PopoverPanel>
          </Transition>
        </Float>
      )}
    </Popover>
  );
}
