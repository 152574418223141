import React, { useState } from 'react';
import {
  StandardEnum,
  UpdateTaskModal_SubTaskFragment,
  UpdateTaskModal_TaskFragment,
  useUpdateTaskModal_DeleteMutation,
  useUpdateTaskModal_SurveysQuery,
  useUpdateTaskModal_UpdateMutation,
} from '../../graphql/generated';
import { Loader } from '../generic/Loader';
import { useModal } from '../layout/Modal';
import { useToast } from '../layout/Toast';
import { NumberCircle } from '../generic/NumberCircle';
import { StringDropdownItem } from '../generic/dropdown/StringDropdown.types';
import StringDropdown from '../generic/dropdown/StringDropdown';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { DeleteIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../../screens/AppRoutes';
import GroupedStringDropdown, {
  GroupedStringDropdownItem,
} from '../generic/dropdown/GroupedStringDropdown';
import { useReportDrCMSDataAggregator } from '@hooks/useReportDrCMSDataAggregator';

export function UpdateTaskModal({
  task,
}: {
  task: UpdateTaskModal_TaskFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();
  const projectContext = useProjectContext();

  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description || '');
  const [assignedTo, setAssignedTo] = useState(task.assignedTo || '');
  const [deliverable, setDeliverable] = useState(task.deliverable || '');
  const [startedAt, setStartedAt] = useState(
    task.startedAt?.split('T')[0] || '',
  );
  const [finishedAt, setFinishedAt] = useState(
    task.finishedAt?.split('T')[0] || '',
  );
  const [subtasks, setSubtasks] = useState<UpdateTaskModal_SubTaskFragment[]>(
    task.subtasks || [],
  );
  const [linkToPage, setLinkToPage] = useState<string>(task.linkToPage || '');
  const [linkToSurveyId, setLinkToSurveyId] = useState<string>(
    task.linkToSurvey?.id || '',
  );
  const [
    linkToReportDisclosureRequirementId,
    setLinkToReportDisclosureRequirementId,
  ] = useState<string>(task.linkToReportDisclosureRequirement?.id || '');

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [updateTaskMutation] = useUpdateTaskModal_UpdateMutation();
  const [deleteTaskMutation] = useUpdateTaskModal_DeleteMutation();
  const deleteTask = () => {
    setIsDeleting(true);
    deleteTaskMutation({ variables: { id: task.id } })
      .then(() => {
        toast.openToastWithMessage(
          t('gap_analysis.checklist.update_task_modal.task_deleted'),
        );
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsDeleting(false));
  };

  const updateTask = () => {
    setIsUpdating(true);
    updateTaskMutation({
      variables: {
        input: {
          id: task.id,
          title: title,
          description,
          assignedTo,
          deliverable,
          startedAt: startedAt || null,
          finishedAt: finishedAt || null,
          subtasks,
          linkToPage,
          linkToSurvey: linkToSurveyId ? { id: linkToSurveyId } : null,
          linkToReportDisclosureRequirement: linkToReportDisclosureRequirementId
            ? { id: linkToReportDisclosureRequirementId }
            : null,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(
          t('gap_analysis.checklist.update_task_modal.task_updated'),
        );
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsUpdating(false));
  };

  const addSubtask = () => {
    setSubtasks([
      ...subtasks,
      {
        __typename: 'Subtask',
        title: '',
        done: false,
      },
    ]);
  };

  const availablePages: StringDropdownItem[] = [
    { id: '', label: t('global:none') },
    { id: AppRoutes.Stakes, label: t('nav:page.referential') },
    {
      id: AppRoutes.StakeholderMapping,
      label: t('nav:page.stakeholder_mapping'),
    },
    { id: AppRoutes.Iro, label: t('nav:page.iro_rating') },
    { id: AppRoutes.Survey, label: t('nav:page.surveys') },
    { id: AppRoutes.GapAnalysis, label: t('nav:page.gap_analysis.summary') },
    { id: AppRoutes.PublicationMaturity, label: t('nav:page.maturity_score') },
    {
      id: AppRoutes.PublicationPrioritizationMatrix,
      label: t('nav:page.prioritization_matrix'),
    },
    {
      id: AppRoutes.PublicationMaterialityMatrix,
      label: t('nav:page.materiality_matrix'),
    },
    {
      id: AppRoutes.PublicationDoubleMaterialityMatrixIro,
      label: t('nav:page.double_materiality_matrix'),
    },
    { id: AppRoutes.Roadmap, label: t('nav:page.roadmap') },
    {
      id: AppRoutes.MaturityEvaluations,
      label: t('nav:page.maturity_evaluation'),
    },
    { id: AppRoutes.ProjectUsers, label: 'Paramètres -> Utilisateurs' },
    { id: AppRoutes.ProjectCompany, label: 'Paramètres -> Entreprise' },
  ];

  const surveysDocumentQuery = useUpdateTaskModal_SurveysQuery({
    variables: {
      companyId: projectContext?.enterprise?.id ?? '',
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });
  const availableSurveys: StringDropdownItem[] = (
    surveysDocumentQuery.data?.surveys || []
  )
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .map((survey) => ({
      id: survey.id,
      label: survey.name,
    }));
  availableSurveys.unshift({ id: 'none', label: '-' });

  return (
    <div className="flex flex-col gap-4 max-w-5xl min-w-[600px]">
      <div className="flex flex-col gap-1">
        <label htmlFor="title" className="form-input-label">
          {t('gap_analysis.checklist.update_task_modal.name')}
        </label>
        <input
          type="text"
          id="title"
          className="form-input-text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1">
        <label htmlFor="description" className="form-input-label">
          {t('gap_analysis.checklist.update_task_modal.description')}
        </label>
        <textarea
          id="description"
          className="form-input-text h-24"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <hr />
      <div className="flex gap-4 items-start">
        <div className="w-1/2 flex flex-col gap-4">
          <label htmlFor="description" className="form-input-label">
            {t('gap_analysis.checklist.update_task_modal.link_to_page')}
          </label>
          <StringDropdown
            item={availablePages.find((page) => page.id === linkToPage) || null}
            availableItems={availablePages}
            setItem={(item) => {
              if (item?.id) {
                setLinkToPage(item.id);
              } else {
                setLinkToPage('');
              }
            }}
          />
        </div>
        <div className="w-1/2 flex flex-col gap-4">
          <label htmlFor="description" className="form-input-label">
            {t('gap_analysis.checklist.update_task_modal.link_to_survey')}
          </label>
          <StringDropdown
            item={
              availableSurveys.find((survey) => survey.id === linkToSurveyId) ||
              null
            }
            availableItems={availableSurveys}
            setItem={(item) => {
              if (item?.id) {
                setLinkToSurveyId(item.id);
              }
            }}
          />
        </div>
      </div>
      {task.taskGroup.checklist.report?.standard === StandardEnum.Csrd && (
        <div className="flex gap-4 items-start">
          <div className="w-full flex flex-col gap-4">
            <label htmlFor="description" className="form-input-label">
              {t(
                'gap_analysis.checklist.update_task_modal.link_to_disclosure_requirement',
              )}
            </label>
            <DisclosureRequirementReportDropDown
              selectedItem={linkToReportDisclosureRequirementId}
              handleChange={(items) => {
                setLinkToReportDisclosureRequirementId(items[0]?.id);
              }}
              reportId={task.taskGroup.checklist.report.id}
            />
          </div>
        </div>
      )}
      <hr />
      <div className="space-y-2">
        <label className="form-input-label">
          {t('gap_analysis.checklist.update_task_modal.steps')}
        </label>
        <Subtasks subtasks={subtasks} setSubtasks={setSubtasks} />
        <button className="primary purple small" onClick={addSubtask}>
          {t('gap_analysis.checklist.update_task_modal.add_step')}
        </button>
      </div>
      <hr />
      <div className="flex gap-4 items-start">
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="assignedTo" className="form-input-label">
              {t('gap_analysis.checklist.update_task_modal.responsible')}
            </label>
            <input
              type="text"
              id="assignedTo"
              className="form-input-text"
              value={assignedTo}
              onChange={(e) => setAssignedTo(e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="deliverable" className="form-input-label">
              {t('gap_analysis.checklist.update_task_modal.deliverable')}
            </label>
            <input
              type="text"
              id="deliverable"
              className="form-input-text"
              value={deliverable}
              onChange={(e) => setDeliverable(e.target.value)}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="flex gap-4 items-start">
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="startedAt" className="form-input-label">
              {t('gap_analysis.checklist.update_task_modal.start_date')}
            </label>
            <input
              className="form-input-text"
              type="date"
              id="startedAt"
              value={startedAt}
              onChange={(e) => setStartedAt(e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="finishedAt" className="form-input-label">
              {t('gap_analysis.checklist.update_task_modal.end_date')}
            </label>
            <input
              className="form-input-text"
              type="date"
              id="finishedAt"
              value={finishedAt}
              onChange={(e) => setFinishedAt(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 justify-between">
        <button
          className="secondary danger"
          onClick={() => deleteTask()}
          disabled={isDeleting}
        >
          {isDeleting ? (
            <Loader />
          ) : (
            t('gap_analysis.checklist.update_task_modal.delete')
          )}
        </button>
        <button
          className="primary purple"
          onClick={() => updateTask()}
          disabled={isUpdating || !title}
        >
          {isUpdating ? (
            <Loader />
          ) : (
            t('gap_analysis.checklist.update_task_modal.update')
          )}
        </button>
      </div>
    </div>
  );
}

function Subtasks({
  subtasks,
  setSubtasks,
}: {
  subtasks: UpdateTaskModal_SubTaskFragment[];
  setSubtasks: React.Dispatch<
    React.SetStateAction<UpdateTaskModal_SubTaskFragment[]>
  >;
}) {
  return (
    <div className="flex flex-col gap-2">
      {subtasks.map((subtask, index) => (
        <div key={`subtask_${index}`} className="flex items-center gap-2">
          <NumberCircle number={index + 1} size={8} />
          <input
            type="text"
            className="form-input-text"
            value={subtask.title}
            onChange={(e) => {
              setSubtasks(
                subtasks.map((st, i) =>
                  i === index ? { ...st, title: e.target.value } : st,
                ),
              );
            }}
          />
          <button
            className="tertiary"
            onClick={() => {
              setSubtasks(subtasks.filter((_, i) => i !== index));
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}
    </div>
  );
}

function DisclosureRequirementReportDropDown({
  handleChange,
  reportId,
  selectedItem,
}: {
  selectedItem: string;
  handleChange: (items: GroupedStringDropdownItem[]) => void;
  reportId: string;
}) {
  const { t } = useTranslation();
  const drAggregates = useReportDrCMSDataAggregator({ reportId });

  const availableDisclosureRequirement: GroupedStringDropdownItem[] = (
    drAggregates || []
  ).map((item) => ({
    group: {
      name: item.cmsTopic?.name || '',
    },
    id: item.reportDr?.id || '',
    label: item.cmsDr?.code + ' ' + item.cmsDr?.title || '',
  }));
  availableDisclosureRequirement.unshift({
    group: {
      name: '',
    },
    id: '',
    label: t('global:none'),
  });

  const initialSelectedItems = availableDisclosureRequirement.filter(
    (item) => selectedItem == item.id,
  );

  const [selectedDR, setSelectedDR] =
    useState<GroupedStringDropdownItem[]>(initialSelectedItems);

  return (
    <GroupedStringDropdown
      items={selectedDR}
      availableItems={availableDisclosureRequirement}
      setItem={(item) => {
        setSelectedDR(item);
        handleChange(item);
      }}
    />
  );
}
