import React from 'react';
import {
  IndicatorItem_IndicatorFragment,
  IndicatorValueForm_LatestIndicatorValueFragment,
  useIndicatorValueForm_LatestIndicatorValuesQuery,
} from '../../../../../../../graphql/generated';
import { TableSkeletonLoader } from '../../../../../../generic/loader/TableSkeletonLoader';
import {
  isNarrativeType,
  isNumberType,
} from '../../../../../../../types/indicatorValue.types';
import {
  NarrativeIndicatorValueForm,
  NarrativeIndicatorValueType,
} from './type/narrative/NarrativeIndicatorValueForm';
import {
  NumberIndicatorValueForm,
  NumberIndicatorValueType,
} from './type/number/NumberIndicatorValueForm';

type Props = {
  indicator: IndicatorItem_IndicatorFragment;
  organizationalUnitId?: string;
  readOnly?: boolean;
};

export const IndicatorValueForm = ({
  indicator,
  organizationalUnitId,
  readOnly = false
}: Props) => {
  const { data, loading } =
    useIndicatorValueForm_LatestIndicatorValuesQuery({
      variables: {
        indicatorId: indicator.id,
        organizationalUnitId: organizationalUnitId ?? undefined,
      },
    });

  if (loading) {
    return <TableSkeletonLoader columns={1} rows={1} />;
  }
  const indicatorValue = data?.latestIndicatorValues[0] ?? {
    __typename: indicator.type,
    organizationalUnit: organizationalUnitId ? { id: organizationalUnitId } : undefined,
    indicator: { id: indicator.id },
  } as IndicatorValueForm_LatestIndicatorValueFragment

  return <TypeIndicatorValueForm {...{indicatorValue, indicator, readOnly }} />;
};

export const TypeIndicatorValueForm = ({indicatorValue, indicator, readOnly = false}: {indicatorValue: IndicatorValueForm_LatestIndicatorValueFragment, indicator: IndicatorItem_IndicatorFragment, readOnly?: boolean}) => {
  return (isNarrativeType<NarrativeIndicatorValueType>(indicatorValue) && (
      <NarrativeIndicatorValueForm indicatorValue={{ narrative_value: indicatorValue.narrative_value, ...prepareInput(indicatorValue, indicator) }} readOnly={readOnly} />
    )) ||
    (isNumberType<NumberIndicatorValueType>(indicatorValue) && (
      <NumberIndicatorValueForm indicator={indicator} indicatorValue={{ number_value: indicatorValue.number_value, standardUnitItemLink: indicatorValue.standardUnitItemLink, ...prepareInput(indicatorValue, indicator) }}  readOnly={readOnly}/>
    )) || <div>Unknown indicatorValue type</div>
}

export const prepareInput = (indicatorValue: IndicatorValueForm_LatestIndicatorValueFragment, indicator: IndicatorItem_IndicatorFragment)=> {
  return {
    indicator: { id: indicator.id },
    dimensionItems: indicatorValue.dimensionItems?.flatMap((item) => {
      if (!item.id) {
        return []
      }

      return {
        id: item.id
      }}) || [],
    organizationalUnit: indicatorValue.organizationalUnit ? { id: indicatorValue.organizationalUnit.id } : undefined,
  }
}
