import {
  HighlightableSurveyAnswer_AnalysisThemeFragment,
  SurveyTextAnswers_AnswerFragment,
  SurveyTextAnswers_QuestionFragment,
  TextAnswer_AnswerFragment,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import needsIllustration from '../../../assets/images/illustrations/survey/needs.png';
import AnswerThemeTag from './AnswerThemeTag';
import { HighlightableSurveyAnswer } from './HighlightableSurveyAnswer';
import { useModal } from '../../layout/Modal';
import { SurveyResponseModal } from '../survey/SurveyResponseModal';

export function SurveyTextAnswers({
  question,
  answers,
}: {
  question: SurveyTextAnswers_QuestionFragment;
  answers?: SurveyTextAnswers_AnswerFragment[];
}) {
  return (
    <div className="relative min-h-80">
      {answers?.map((answer) => <TextAnswer answer={answer} key={answer.id} />)}
      <div className="pl-12 pr-4 absolute w-1/4 top-0 right-0">
        {question.themes?.length === 0 && (
          <div className="flex flex-col items-center gap-4 text-center text-sm">
            <img src={needsIllustration} alt="" className="w-32" />
            <p className="font-bold">
              Sélectionnez des bribes de réponses pour leur associer un thème !
            </p>
            <p className="text-gray-500">
              Vous pourrez ensuite voir les citations par thème.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function TextAnswer({ answer }: { answer: TextAnswer_AnswerFragment }) {
  const [highlightedThemeInRightBar, setHighlightedThemeInRightBar] =
    useState<HighlightableSurveyAnswer_AnalysisThemeFragment | null>(null);

  const modal = useModal();
  const openResponseAnswersModal = () => {
    modal.openModalWithComponent(
      <SurveyResponseModal responseId={answer.response.id} />,
      null,
      true,
      false,
      'w-8/12',
    );
  };

  // const answerThemeQuery = useSurveyTextAnswers_TextAnswerQuery({
  //   variables: { id: answer.id },
  //   skip: true,
  // });

  return (
    <div className="flex items-stretch justify-start">
      <div className="w-3/4 border-r border-gray-100 pr-4 py-4">
        <button className="unstyled small" onClick={openResponseAnswersModal}>
          <div className="text-purple-500 font-semibold text-sm mb-2">
            Réponse #{answer.id.substring(0, 5)}
          </div>
        </button>
        <HighlightableSurveyAnswer
          answer={answer}
          highlightedThemeInParentComponent={highlightedThemeInRightBar}
        />
      </div>
      <div className="w-1/4 pl-4 py-4 flex justify-start items-center">
        <div className="flex flex-wrap gap-2 ">
          {answer.themes
            ?.filter(
              // Deduplicate themes
              (analysisSurveyTheme, index, self) =>
                self.findIndex(
                  (t) =>
                    t.analysisTheme.id === analysisSurveyTheme.analysisTheme.id,
                ) === index,
            )
            .map((answerTheme) => (
              <div
                key={answerTheme.analysisTheme.id}
                onMouseOver={() =>
                  setHighlightedThemeInRightBar(answerTheme.analysisTheme)
                }
                onMouseLeave={() => setHighlightedThemeInRightBar(null)}
              >
                <AnswerThemeTag
                  key={answerTheme.analysisTheme.id}
                  answerTheme={answerTheme}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
