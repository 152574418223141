import React, { createContext, ReactNode, useContext } from 'react';
import { useCurrentAuth } from './CurrentAuthProvider';
import {
  CurrentUserFieldsFragment,
  FeatureEnum,
  PermissionEnum,
  useCurrentUserQuery,
  UserAccessFieldsFragment,
} from 'graphql/generated';
import { MessageBox, MessageBoxType } from '../components/layout/MessageBox';
import { identifyUserToMixpanel } from '../services/MixpanelService';
import { useTranslation } from '@hooks/useTranslation';
import Cookies from 'js-cookie';

export type CurrentUser = CurrentUserFieldsFragment | null | undefined;
export type UserPermissions = {
  enterpriseId: string;
  permissions: Record<FeatureEnum, PermissionEnum>[];
}[];

export type UserContextType = {
  currentUser: CurrentUser;
  permissions: UserPermissions;
};

const UserContext = createContext<UserContextType>({
  currentUser: undefined,
  permissions: [],
});

export const useCurrentUser = () => useContext(UserContext);

function extractUserPermissions(
  userAccessArray: UserAccessFieldsFragment[],
): UserPermissions {
  return userAccessArray.map((userAccess) => {
    const filteredRoles = userAccess.role.roleFeaturePermissions
      .filter((permission) => permission.permission !== PermissionEnum.None)
      .reduce(
        (acc: Record<FeatureEnum, PermissionEnum>, permission) => {
          acc[permission.feature] = permission.permission;
          return acc;
        },
        {} as Record<FeatureEnum, PermissionEnum>,
      );

    if (userAccess.role.role === 'administrator') {
      Object.values(FeatureEnum).forEach((feature) => {
        if (!filteredRoles[feature]) {
          filteredRoles[feature] = PermissionEnum.Write;
        }
      });
    }

    return {
      enterpriseId: userAccess.enterprise!.id,
      permissions: [filteredRoles],
    };
  });
}

export function CurrentUserProvider({ children }: { children: ReactNode }) {
  const auth = useCurrentAuth();

  const currentUserQuery = useCurrentUserQuery({
    variables: {},
    skip: !auth,
    fetchPolicy: 'cache-and-network',
  });

  if (currentUserQuery.error) {
    console.error(currentUserQuery.error);
    return (
      <div className="h-screen w-full p-96">
        <MessageBox type={MessageBoxType.Error}>
          <div className="space-y-4">
            <h1>Oups, une erreur est survenue !</h1>
            <h6>Pour nous aider, contactez-nous avec ce message d'erreur : </h6>
            <p>{currentUserQuery.error.message}</p>
          </div>
        </MessageBox>
      </div>
    );
  }

  const currentUser = currentUserQuery?.data?.currentUser;

  const permissions = currentUser?.userAccesses
    ? extractUserPermissions(
        currentUser.userAccesses.filter((ua) => ua.enterprise !== null),
      )
    : [];

  return currentUser ? (
    <CurrentUserProviderInner
      currentUser={currentUser}
      permissions={permissions}
    >
      {children}
    </CurrentUserProviderInner>
  ) : (
    <>{children}</>
  );
}

function CurrentUserProviderInner({
  currentUser,
  permissions,
  children,
}: {
  currentUser: CurrentUser;
  permissions: UserPermissions;
  children: ReactNode;
}) {

  const { i18n } = useTranslation();
  const cookieLanguage = Cookies.get('language');
  if (cookieLanguage !== i18n.language && ['fr','en'].includes(cookieLanguage ?? '')) {
    i18n.changeLanguage(cookieLanguage);
  }
  identifyUserToMixpanel(currentUser);
  return (
    <UserContext.Provider value={{ currentUser, permissions }}>
      {children}
    </UserContext.Provider>
  );
}
