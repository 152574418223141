import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { ApiDownloadRoutes, useDownloadFile } from '@hooks/useDownloadFile';
import { generatePath } from 'react-router-dom';
import { TooltipWrapper } from '../../../generic/TooltipWrapper';
import { Loader } from '../../../generic/Loader';
import { ExportIcon } from '../../../icons';

export function ExportGapAnalysisButton({ reportId }: { reportId: string }) {
  const { t } = useTranslation();
  const { downloadFile, loading } = useDownloadFile({
    errorToastMessage: t('toast:survey.export.error'),
    fileName: `${t('nav:page.gap_analysis.summary')}.xlsx`,
    path: generatePath(ApiDownloadRoutes.GapAnalysisExport, {
      reportId: reportId,
    }),
  });

  return (
    <TooltipWrapper label={t('global:export')}>
      <button className="tertiary" onClick={downloadFile} disabled={loading}>
        {loading ? <Loader /> : <ExportIcon />}
      </button>
    </TooltipWrapper>
  );
}
