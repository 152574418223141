import {
  AdminCompaniesScreen_EmployeeFragment,
  AdminUserDocument,
  AdminUserFieldsFragment,
  CoachsSettingsScreenDocument,
  ConsultingCompanyRoleEnum,
  GiveUserAccess_EnterpriseFragment,
  useAdminUserQuery,
  useAuthUpdateMutation,
  useGiveConsultingCompanyRoleMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import clsx from 'clsx';
import { Loader } from '../../../components/generic/Loader';
import { Toggle } from '../../../components/generic/form/Toggle';
import { TooltipWrapper } from '../../../components/generic/TooltipWrapper';
import { useTranslation } from '@hooks/useTranslation';
import { generatePath, Link } from 'react-router-dom';
import { AppRoutes } from '../../AppRoutes';
import {
  getDateFromISOString,
  getTimeFromISOString,
} from '../../../services/DateService';
import {
  isConsultingCompany,
  isEnterprise,
} from '../../../types/companies.types';
import { UserConsultingCompanyRoleDropdown } from '../../../components/project/project/roles/UserConsultingCompanyRoleDropdown';
import { findRole } from '../../../services/CompanyService';
import { useToast } from '../../../components/layout/Toast';
import { ConsultingCompanyUserRoleDropdown } from '../../../components/project/project/users/consultingCompany/SetUserProjectAccesses';

export function EmployeeItem({
  employee,
}: {
  employee: AdminCompaniesScreen_EmployeeFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const adminUserQuery = useAdminUserQuery({
    variables: {
      id: employee.id,
    },
  });
  const [giveConsultingCompanyRole] = useGiveConsultingCompanyRoleMutation();

  if (adminUserQuery.loading) {
    return <Loader />;
  }
  if (!adminUserQuery?.data?.user.auth) {
    return null;
  }
  const role = findRole(adminUserQuery?.data?.user);
  const user = adminUserQuery?.data.user;

  return (
    <li key={employee.id} className="flex items-center gap-2">
      {adminUserQuery?.data?.user.auth && (
        <EmployeeEnabledToggle employee={user} />
      )}
      {user.auth ? (
        <Link
          to={generatePath(AppRoutes.AdminUser, {
            id: user.id,
          })}
          className={user.auth?.disabled ? 'line-through' : ''}
          rel="noreferrer"
        >
          {employee.firstName} {employee.lastName}
        </Link>
      ) : (
        <div>
          {employee.firstName} {employee.lastName}
        </div>
      )}
      {user.auth ? (
        <UserLastSignInDate user={user} />
      ) : (
        <div className="tag red small">
          {t('admin:companies.list.item.unknown')}
        </div>
      )}
      {isConsultingCompany(user.company) && (
        <UserConsultingCompanyRoleDropdown
          item={{
            id: role,
            label: t(`enum:consultingCompanyRole.${role}`),
          }}
          // disabled={!isGranted(FeatureEnum.ProjectUsers, PermissionEnum.Write)}
          setItem={(item) => {
            if (!item?.id) {
              return;
            }
            giveConsultingCompanyRole({
              variables: {
                input: {
                  user: {
                    id: user.id,
                  },
                  consultingCompany: {
                    id: user?.company!.id,
                  },
                  role: item.id as ConsultingCompanyRoleEnum,
                },
              },
              refetchQueries: [
                {
                  query: CoachsSettingsScreenDocument,
                  variables: {
                    id: user?.company!.id,
                  },
                },
              ],
              onCompleted: (item) => {
                toast.openToastWithMessage(
                  t('toast:project.users.updateRole.success'),
                );
              },
            });
          }}
        />
      )}
      {isEnterprise<GiveUserAccess_EnterpriseFragment>(user.company) && (
        <ConsultingCompanyUserRoleDropdown
          enterprise={user.company}
          user={user}
        />
      )}
    </li>
  );
}

function EmployeeEnabledToggle({
  employee,
}: {
  employee: AdminUserFieldsFragment;
}) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState<boolean>(
    !!employee.auth?.disabled,
  );
  const [disableUserMutation] = useAuthUpdateMutation({
    refetchQueries: () => [
      { query: AdminUserDocument, variables: { id: employee.id } },
    ],
  });
  const setNewState = (newState: boolean) => {
    setIsDisabled(newState);
    disableUserMutation({
      variables: {
        input: {
          id: employee.auth?.id || '',
          disabled: newState,
        },
      },
    })
      .then(() => {
        console.log('User disabled');
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <TooltipWrapper
      label={
        isDisabled
          ? t('admin:companies.list.item.enable')
          : t('admin:companies.list.item.disable')
      }
    >
      <Toggle state={!isDisabled} setState={() => setNewState(!isDisabled)} />
    </TooltipWrapper>
  );
}

function UserLastSignInDate({ user }: { user: AdminUserFieldsFragment }) {
  const { t, i18n } = useTranslation();
  const lastLoginDate = user.auth?.lastSignInDate;
  if (!lastLoginDate) {
    return (
      <span className="text-xs text-gray-500 italic">
        {t('admin:companies.list.item.no_login')}
      </span>
    );
  }

  const isMoreThan30DaysAgo =
    new Date(lastLoginDate) < new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  const loginDate = getDateFromISOString(lastLoginDate, i18n.language);
  const loginTime = getTimeFromISOString(lastLoginDate, i18n.language);

  return (
    <div
      className={clsx(
        'text-xs',
        isMoreThan30DaysAgo ? 'text-red-700' : 'text-gray-500',
      )}
    >
      {loginDate} {loginTime}
    </div>
  );
}
