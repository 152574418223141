import {
  DeleteUserAccessButtonFragment,
  EnterpriseUserAccessDocument,
  useDeleteUserAccessButtonMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import React from 'react';
import { Loader } from '../../../generic/Loader';
import { DeleteIcon } from '../../../icons';

export function DeleteUserAccessButton({
  userAccess,
}: {
  userAccess: DeleteUserAccessButtonFragment;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const enterpriseId = userAccess.enterprise?.id || ''; // enterprise can be null???

  const [deleteUserAccessMutation, { loading }] =
    useDeleteUserAccessButtonMutation({
      refetchQueries: () => [
        {
          query: EnterpriseUserAccessDocument,
          variables: {
            enterpriseId,
          },
        },
      ],
    });

  const deleteUserAccess = () => {
    deleteUserAccessMutation({
      variables: {
        input: {
          id: userAccess.id,
          enterprise: {
            id: enterpriseId,
          },
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:project.users.removeRole.success'));
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <button className="tertiary" disabled={loading} onClick={deleteUserAccess}>
      {loading ? <Loader /> : <DeleteIcon />}
    </button>
  );
}
