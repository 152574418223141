import { useTranslation } from '@hooks/useTranslation';
import { useToast } from '../../layout/Toast';
import { useModal } from '../../layout/Modal';
import React, { useState } from 'react';
import {
  AdminCompaniesScreenDocument,
  AdminEnterprisesWithLicenseScreenDocument,
  AdminEnterprisesWithoutLicenseScreenDocument,
  useAdminCompaniesScreen_DeleteProCompanyMutation,
} from '../../../graphql/generated';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { Loader } from '../../generic/Loader';

export function DeleteCompanyButton({ companyId }: { companyId: string }) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();
  const [isDeleting, setIsDeleting] = useState(false);

  const [deleteCompanyMutation] =
    useAdminCompaniesScreen_DeleteProCompanyMutation();

  const deleteCompany = () => {
    setIsDeleting(true);
    deleteCompanyMutation({
      variables: {
        companyId: companyId,
      },
      refetchQueries: [
        AdminCompaniesScreenDocument,
        AdminEnterprisesWithLicenseScreenDocument,
        AdminEnterprisesWithoutLicenseScreenDocument,
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('admin:companies.toast.company_deleted'));
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <button
      className="primary danger small"
      disabled={isDeleting}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.delete.content')}
            callbackIfConfirmed={deleteCompany}
          />,
          t('global:delete'),
        )
      }
    >
      {isDeleting && <Loader />}
      <div>{t('global:delete')}</div>
    </button>
  );
}
