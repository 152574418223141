import {
  CoachsSettingsScreenDocument,
  CurrentUserDocument,
  EnterpriseRoleEnum,
  GiveUserAccess_EnterpriseFragment,
  useEnterpriseUserAccess_GiveUserAccessMutation,
  useRemoveUserAccessMutation,
  UserRowFragment,
} from '../../../../../graphql/generated';
import { UserRoleDropdown } from '../../roles/UserRoleDropdown';
import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useToast } from '../../../../layout/Toast';
import { StringDropdownItem } from '../../../../generic/dropdown/StringDropdown.types';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';

export const ConsultingCompanyUserRoleDropdown = ({
  enterprise,
  user,
  disabled = false,
}: {
  enterprise: GiveUserAccess_EnterpriseFragment;
  user: UserRowFragment;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [giveUserAccess] = useEnterpriseUserAccess_GiveUserAccessMutation();
  const [removeUserAccess] = useRemoveUserAccessMutation();
  const toast = useToast();
  const currentEnterpriseUserAccess = user.userAccesses?.find(
    (item) => item.enterprise?.id === enterprise.id,
  );
  const currentUserAccess = user.userAccesses
    ?.filter((ua) => ua.enterprise !== null)
    .find((item) => item.enterprise!.id === enterprise.id);
  const [item, setItem] = useState<StringDropdownItem | null>(
    currentEnterpriseUserAccess
      ? {
          id: '',
          label: t(
            `enum:enterpriseRole.${currentEnterpriseUserAccess.role.role}`,
          ),
        }
      : null,
  );
  const refetchQueries = [
    {
      query: CoachsSettingsScreenDocument,
      variables: {
        id: user.company?.id,
      },
    },
    {
      query: CurrentUserDocument,
    },
  ];

  return (
    <UserRoleDropdown
      allowEmpty={true}
      item={item}
      disabled={disabled}
      setItem={(choosenItem) => {
        if (!choosenItem?.id && currentUserAccess) {
          removeUserAccess({
            variables: {
              input: {
                id: currentUserAccess.id,
                enterprise: {
                  id: enterprise?.id!,
                },
              },
            },
            refetchQueries,
            onCompleted: () => {
              setItem(null);
              toast.openToastWithMessage(
                t('toast:project.users.removeRole.success'),
              );
            },
          });
          return;
        }
        giveUserAccess({
          variables: {
            input: {
              user: {
                id: user.id,
              },
              enterprise: {
                id: enterprise?.id!,
              },
              role:
                (choosenItem?.id as EnterpriseRoleEnum) ||
                (item?.id as EnterpriseRoleEnum),
            },
          },
          refetchQueries,
          onCompleted: () => {
            setItem(choosenItem);
            toast.openToastWithMessage(
              t('toast:project.users.updateRole.success'),
            );
          },
        });
      }}
    />
  );
};

export const SetUserProjectAccesses = ({
    user,
    enterprises,
    readOnly = false,
  }: {
    user: UserRowFragment;
    enterprises: GiveUserAccess_EnterpriseFragment[];
    readOnly?: boolean;
  }) => {
  const { t } = useTranslation();
  return (<div className={'m-4'}>
      <MessageBox type={MessageBoxType.Info}>
        {t('consultingCompany.users.giveProjectAccess.description', {
          name: `${user.firstName} ${user.lastName}`,
        })}
      </MessageBox>
      <div className={'flex flex-col gap-4 mt-10'}>
        {enterprises.map((enterprise) => {
          return (
            <div key={enterprise.id}>
              <strong>{enterprise.name}</strong>
              <ConsultingCompanyUserRoleDropdown
                key={enterprise.id}
                enterprise={enterprise}
                user={user}
                disabled={readOnly}
              />
            </div>
          );
          })}
      </div>
    </div>
  );
};

