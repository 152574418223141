import { CriteriaValues } from '../../../../../types/iro.types';

export const definitionByCriteria = <
  V extends CriteriaValues,
  T extends { value: V; definition?: string | null },
>(
  definitions: T[] | null | undefined,
  criteria: V,
): T | undefined => {
  if (!definitions) return undefined;
  return definitions.find((d) => d.value === criteria);
};
