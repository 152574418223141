import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  CreateStakeholderGroupInput,
  StakeholderGroupForm_StakeholderGroupFragment,
  SurveyLanguage,
  ThemeIcon,
  UpdateStakeholderGroupInput,
} from '../../../../../graphql/generated';
import { SegmentInput } from './SegmentInput';
import { FormHeader } from './FormHeader';
import { FormFooter } from './FormFooter';
import { ThemeIconPicker } from '../../../../generic/form/ThemeIconPicker';
import { PlusIcon } from '../../../../icons';

type Props<TInput> = {
  companyId?: string;
  initialData?: StakeholderGroupForm_StakeholderGroupFragment;
  onSubmit: (input: TInput) => Promise<any>;
  onClose: () => void;
};

export const StakeholderGroupForm = <
  TInput extends CreateStakeholderGroupInput | UpdateStakeholderGroupInput,
>({
  companyId,
  initialData,
  onSubmit,
  onClose,
}: Props<TInput>) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState<SurveyLanguage>(SurveyLanguage.Fr);
  const [icon, setIcon] = useState<ThemeIcon>(
    initialData?.icon || ThemeIcon.Collaborators,
  );
  const [i18nValues, setI18nValues] = useState(
    initialData?.i18n || [
      { language: SurveyLanguage.Fr, name: '' },
      { language: SurveyLanguage.En, name: '' },
    ],
  );
  const [segments, setSegments] = useState<
    {
      id?: string;
      i18n: { language: SurveyLanguage; name: string }[];
      weight: number;
    }[]
  >(
    initialData?.segments?.map((segment) => ({
      id: segment.id,
      i18n:
        segment.i18n?.map(({ language, name }) => ({
          language,
          name: name || '',
        })) ?? [],
      weight: segment.weight,
    })) || [
      {
        id: '',
        i18n: [
          { language: SurveyLanguage.Fr, name: '' },
          { language: SurveyLanguage.En, name: '' },
        ],
        weight: 1,
      },
    ],
  );

  useEffect(() => {
    if (initialData) {
      setIcon(initialData.icon);
      setI18nValues(initialData.i18n ?? []);
      setSegments(
        initialData.segments?.map((segment) => ({
          id: segment.id,
          i18n:
            segment.i18n?.map(({ language, name }) => ({
              language,
              name: name || '',
            })) ?? [],
          weight: segment.weight,
        })) || [
          {
            id: '',
            i18n: [
              { language: SurveyLanguage.Fr, name: '' },
              { language: SurveyLanguage.En, name: '' },
            ],
            weight: 1,
          },
        ],
      );
    }
  }, [initialData]);

  const translation = i18nValues.find((i18n) => i18n.language === language);

  const pickLanguage = useCallback(
    (newLanguage: SurveyLanguage) => {
      setLanguage(newLanguage);
      if (!i18nValues.find((i18n) => i18n.language === newLanguage)) {
        setI18nValues((prev) => [...prev, { language: newLanguage, name: '' }]);
      }
    },
    [i18nValues],
  );

  const addSegment = useCallback(() => {
    setSegments((prev) => [
      ...prev,
      {
        id: '',
        i18n: [
          { language: SurveyLanguage.Fr, name: '' },
          { language: SurveyLanguage.En, name: '' },
        ],
        weight: 1,
      },
    ]);
  }, []);

  const removeSegment = useCallback((index: number) => {
    setSegments((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const editSegment = useCallback(
    (index: number, field: string, value: string) => {
      setSegments((prev) =>
        prev.map((segment, i) => {
          if (i === index) {
            const updatedI18n = segment.i18n.map((i18n) => {
              if (i18n.language === language) {
                return { ...i18n, [field]: value };
              }
              return i18n;
            });
            return { ...segment, i18n: updatedI18n };
          }
          return segment;
        }),
      );
    },
    [language],
  );

  const editSegmentWeight = useCallback((index: number, weight: number) => {
    setSegments((prev) =>
      prev.map((segment, i) => {
        if (i === index) {
          return { ...segment, weight };
        }
        return segment;
      }),
    );
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    const input: TInput = {
      ...(initialData && { id: initialData.id }),
      ...(companyId && { company: { id: companyId } }),
      icon,
      i18n: i18nValues,
      segments: segments.map(({ id, i18n, weight }) => ({
        id: id || undefined,
        i18n,
        weight,
      })),
    } as TInput;

    onSubmit(input)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  }, [initialData, companyId, icon, i18nValues, segments, onSubmit, onClose]);

  const editField = useCallback(
    (field: string, value: string) => {
      setI18nValues((prev) =>
        prev.map((i18n) => {
          if (i18n.language === language) {
            return { ...i18n, [field]: value };
          }
          return i18n;
        }),
      );
    },
    [language],
  );

  return (
    <div className="flex flex-col max-w-2xl h-full">
      <FormHeader
        initialData={Boolean(initialData)}
        pickLanguage={pickLanguage}
        onClose={onClose}
        t={t}
      />
      <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
        <div className="space-y-6">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="form-input-label">
              {t('stakeholder.group.form.name.label')}
            </label>
            <input
              type="text"
              id="title"
              className="form-input-text"
              placeholder={t('stakeholder.group.form.name.placeholder', {
                context: language,
              })}
              value={translation?.name || ''}
              onChange={(e) => editField('name', e.target.value)}
              aria-label={t('stakeholder.group.form.name.label')}
            />
          </div>

          <ThemeIconPicker currentIcon={icon} setCurrentIcon={setIcon} />

          <div className="space-y-2">
            <div className="flex items-center">
              <label className="form-input-label w-full">
                {t('stakeholder.group.form.segment.header')}
              </label>
              <label className="form-input-label w-72 mr-12">
                {t('stakeholder.group.form.weight.header')}
              </label>
            </div>
            {segments.map((segment, index) => (
              <SegmentInput
                key={index + (segment?.id || '')}
                segment={segment}
                index={index}
                language={language}
                editSegment={editSegment}
                editSegmentWeight={editSegmentWeight}
                removeSegment={removeSegment}
                t={t}
              />
            ))}
            <button className="secondary purple small" onClick={addSegment}>
              <PlusIcon className="w-4 h-4" />
              {t('stakeholder.group.form.segment.add.button')}
            </button>
          </div>
        </div>
      </div>

      <FormFooter
        initialData={initialData}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        t={t}
      />
    </div>
  );
};
