import { PermissionEnum } from '../../../../graphql/generated';
import { EditIcon, EyeIcon, NoEyeIcon } from '../../../icons';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';

export const usePermissionDropdownItem = (
  permission: PermissionEnum | null,
) => {
  const { t } = useTranslation();
  switch (permission) {
    case PermissionEnum.Read:
      return {
        id: PermissionEnum.Read,
        icon: <EyeIcon />,
        label: t(`translation:project.roles.permissions.read`),
      };
    case PermissionEnum.Write:
      return {
        id: PermissionEnum.Write,
        icon: <EditIcon />,
        label: t(`translation:project.roles.permissions.write`),
      };
    default:
      return {
        id: PermissionEnum.None,
        icon: <NoEyeIcon />,
        label: t(`translation:project.roles.permissions.none`),
      };
  }
};
