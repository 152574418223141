import {
  IroType,
  IrremediabilityValue,
  IrremediabilityValueDefinition,
  QuestionIroAssessment_IroFragment,
} from '../../../../../graphql/generated';
import React from 'react';
import { IroAssessmentOptionRadio } from './radioButton/IroAssessmentOptionRadio';
import { IroAssessmentButtonGroup } from './IroAssessmentButtonGroup';
import { useTranslation } from '@hooks/useTranslation';
import { CriteriaType } from '../../../../../types/iro.types';
import { IroAssessmentOptionRadioTooltip } from './radioButton/IroAssessmentOptionRadioTooltip';
import { useFormContext } from 'react-hook-form';
import { IroAssessmentFormValues } from '../IroAssessmentForm.types';

export const IroAssessmentIrremediabilityButtonGroup = ({
  iro,
  irremediabilityValuesDefinitions,
}: {
  iro: QuestionIroAssessment_IroFragment;
  irremediabilityValuesDefinitions: IrremediabilityValueDefinition[];
}) => {
  const { t } = useTranslation();
  const name =
    `iroAssessments.${iro.id}.irremediabilityCriteria.value` as const;
  const { watch } = useFormContext<IroAssessmentFormValues>();
  const value = watch(name);
  return (
    <IroAssessmentButtonGroup
      criteria={CriteriaType.IRREMEDIABILITY}
      label={t('iro.criteria.irremediability.title')}
      iroType={iro.__typename as IroType}
    >
      <div className="w-56 flex items-center justify-evenly gap-2">
        <IroAssessmentOptionRadio<IrremediabilityValue>
          {...{ name, value }}
          level={IrremediabilityValue.EasilyRemediable}
          placeholder={'1'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              IrremediabilityValue,
              IrremediabilityValueDefinition
            >
              title={t('enum:iroCriteria.irremediability.EasilyRemediable')}
              definitions={irremediabilityValuesDefinitions}
              value={IrremediabilityValue.EasilyRemediable}
            />
          }
        />
        <IroAssessmentOptionRadio<IrremediabilityValue>
          {...{ name, value }}
          level={IrremediabilityValue.ModeratelyRemediable}
          placeholder={'2'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              IrremediabilityValue,
              IrremediabilityValueDefinition
            >
              title={t('enum:iroCriteria.irremediability.ModeratelyRemediable')}
              definitions={irremediabilityValuesDefinitions}
              value={IrremediabilityValue.ModeratelyRemediable}
            />
          }
        />
        <IroAssessmentOptionRadio<IrremediabilityValue>
          {...{ name, value }}
          level={IrremediabilityValue.HardlyRemediable}
          placeholder={'3'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              IrremediabilityValue,
              IrremediabilityValueDefinition
            >
              title={t('enum:iroCriteria.irremediability.HardlyRemediable')}
              definitions={irremediabilityValuesDefinitions}
              value={IrremediabilityValue.HardlyRemediable}
            />
          }
        />
        <IroAssessmentOptionRadio<IrremediabilityValue>
          {...{ name, value }}
          level={IrremediabilityValue.Irremediable}
          placeholder={'4'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              IrremediabilityValue,
              IrremediabilityValueDefinition
            >
              title={t('enum:iroCriteria.irremediability.Irremediable')}
              definitions={irremediabilityValuesDefinitions}
              value={IrremediabilityValue.Irremediable}
            />
          }
        />
      </div>
    </IroAssessmentButtonGroup>
  );
};
