import clsx from 'clsx';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export enum TabsStyles {
  PILLS = 'PILLS',
  UNDERLINED = 'UNDERLINED',
}

export type TabItem = {
  key: string;
  name: string | React.ReactElement;
  tag?: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  path?: string;
};

export type TabItemManager = {
  tabs: TabItem[];
  currentTab: TabItem;
  setCurrentTab: (tab: TabItem) => void;
};

export default function Tabs({
  tabsManager,
  style,
}: {
  tabsManager: TabItemManager;
  style: TabsStyles;
}) {
  const tabs = tabsManager.tabs;
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-900 focus:outline-hidden focus:ring-gray-900 sm:text-sm"
          onChange={(event) => {
            const tab = tabs.find((tab) => tab.name === event.target.value);
            if (tab) {
              tabsManager.setCurrentTab(tab);
            }
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.key}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className={clsx(
            'flex',
            style === TabsStyles.PILLS && 'space-x-2',
            style === TabsStyles.UNDERLINED &&
              'flex items-center justify-start px-0 pt-4 space-x-8',
          )}
          aria-label="Tabs"
        >
          {tabs.map((tab) =>
            style === TabsStyles.UNDERLINED ? (
              <UnderlineTab key={tab.key} tabsManager={tabsManager} tab={tab} />
            ) : (
              <PillsTab key={tab.key} tabsManager={tabsManager} tab={tab} />
            ),
          )}
        </nav>
      </div>
    </div>
  );
}

function PillsTab({
  tabsManager,
  tab,
}: {
  tabsManager: TabItemManager;
  tab: TabItem;
}) {
  const ref = useRef<HTMLDivElement>(null);

  function chooseTab(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    tabsManager.setCurrentTab(tab);
    if (ref) {
      // Scroll to the selected tab
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const isSelected = tabsManager.currentTab.key === tab.key;

  return (
    <div
      ref={ref}
      key={tab.key}
      onClick={(event) => chooseTab(event)}
      className={clsx(
        'cursor-pointer flex items-center gap-1.5 whitespace-nowrap p-2 text-sm',
        isSelected
          ? 'rounded-lg bg-gray-900 text-white font-bold hover:text-white'
          : 'text-gray-500',
      )}
      aria-current={tabsManager.currentTab.key === tab.key ? 'page' : undefined}
    >
      {tab.icon && <div className="">{tab.icon}</div>}
      {tab.name}
      {tab.tag && (
        <span
          className={clsx(
            tabsManager.currentTab.key === tab.key
              ? 'bg-white text-gray-900'
              : 'bg-purple-100 text-gray-900',
            'hidden rounded-full py-0.5 px-2 text-xs font-medium md:inline-block',
          )}
        >
          {tab.tag}
        </span>
      )}
    </div>
  );
}

function UnderlineTab({
  tabsManager,
  tab,
}: {
  tabsManager: TabItemManager;
  tab: TabItem;
}) {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  function chooseTab(event: React.MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    if (tab.disabled) {
      return;
    }
    if (tab.path) {
      navigate(tab.path);
    }
    tabsManager.setCurrentTab(tab);
    if (ref) {
      // Scroll to the selected tab
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const isSelected = tabsManager.currentTab.key === tab.key;

  return (
    <div
      ref={ref}
      key={tab.key}
      onClick={(event) => chooseTab(event)}
      className={clsx(
        'flex items-center gap-1.5 whitespace-nowrap py-2 text-sm border-b-2',
        !tab.disabled && "cursor-pointer hover:text-gray-900",
        isSelected
          ? 'text-gray-900 font-bold border-gray-900'
          : 'text-gray-500 border-transparent',
      )}
      aria-current={tabsManager.currentTab.key === tab.key ? 'page' : undefined}
    >
      {tab.icon && <div className="">{tab.icon}</div>}
      {tab.name}
      {tab.tag && (
        <span
          className={clsx(
            'hidden rounded-full py-0.5 px-2 text-xs font-medium md:inline-block',
            isSelected
              ? 'bg-purple-100 text-gray-900'
              : 'bg-purple-100 text-gray-900',
            tab.disabled && 'opacity-50',
          )}
        >
          {tab.tag}
        </span>
      )}
    </div>
  );
}
