import { TMaterialityMatrixChartDatapoint } from './types';
import { readCssVar } from '../../../../services/TailwindService';

export const getChartJsDataset = (
  datapoints: TMaterialityMatrixChartDatapoint[],
  filteredDatapointIds: string[],
) => {
  return datapoints.map((datapoint, index) => {
    const isDatapointSelected =
      filteredDatapointIds?.length === 0 ||
      filteredDatapointIds?.includes(datapoint.id);
    return {
      label: datapoint.name,
      data: [
        {
          x: datapoint.position.x,
          y: datapoint.position.y,
          r: index + 1,
        },
      ],
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      animation: false,
      datalabels: {
        display: true,
        label: datapoint.name,
        backgroundColor: isDatapointSelected
          ? readCssVar(`--color-${datapoint.color}-500`)
          : '#CCC',
      },
    };
  });
};
