import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { FeatureEnum, PermissionEnum } from '../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';

export const enum SURVEY_TABS {
  SURVEY_CONTENT = 'SURVEY_CONTENT',
  SURVEY_SHARE = 'SURVEY_SHARE',
  SURVEY_TRACKING_RESPONSES = 'SURVEY_TRACKING_RESPONSES',
  SURVEY_TRACKING_WAITING_STAKEHOLDER = 'SURVEY_TRACKING_WAITING_STAKEHOLDER',
  SURVEY_RESULTS = 'SURVEY_RESULTS',
  SETTINGS = 'SETTINGS',
}
export const useSurveyTabs = () => {
  const { t } = useTranslation();
  const tabs = [];
  const { isGranted } = usePermissionChecker();

  if (isGranted(FeatureEnum.ConsultationManagement, PermissionEnum.Write)) {
    tabs.push({
      key: SURVEY_TABS.SURVEY_CONTENT,
      name: t('survey.tabs.content'),
    });
  }
  tabs.push({
    key: SURVEY_TABS.SURVEY_SHARE,
    name: t('survey.tabs.share'),
  });

  if (isGranted(FeatureEnum.ConsultationManagement) || isGranted(FeatureEnum.ConsultationResults)) {
    tabs.push({
      key: SURVEY_TABS.SURVEY_TRACKING_RESPONSES,
      name: t('survey.tabs.tracking'),
    });
  }

  if (isGranted(FeatureEnum.ConsultationResults)) {
    tabs.push({
      key: SURVEY_TABS.SURVEY_RESULTS,
      name: t('survey.tabs.results'),
    });
  }

  if (isGranted(FeatureEnum.ConsultationManagement, PermissionEnum.Write)) {
    tabs.push({
      key: SURVEY_TABS.SETTINGS,
      name: t('survey.tabs.settings'),
    });
  }

  return tabs;
};
