import {
  PillarCreateTranslationInput,
  SurveyLanguage,
  ThemeColor,
  ThemeIcon,
  useCreatePillarModalMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { useModal } from '../../layout/Modal';
import { Loader } from '../../generic/Loader';
import { ThemeIconPicker } from '../../generic/form/ThemeIconPicker';
import { ThemeColorPicker } from '../../form/ThemeColorPicker';
import { useTranslation } from '@hooks/useTranslation';
import i18n from 'i18next';
import { XIcon } from '../../icons';
import LanguageDropdown from '../../form/LanguageDropdown';

export function CreatePillarModal({
  referentialId,
}: {
  referentialId: string;
}) {
  const { t } = useTranslation();
  const modal = useModal();

  const [isCreating, setIsCreating] = useState(false);

  const [language, setLanguage] = useState<SurveyLanguage>(SurveyLanguage.Fr);
  const [i18nValues, setI18nValues] = useState<PillarCreateTranslationInput[]>([
    {
      language: SurveyLanguage.Fr,
      name: '',
    },
  ]);
  const translation = i18nValues?.find((i18n) => i18n.language === language);

  i18n.on('languageChanged', () => {
    pickLanguage(i18n.language as SurveyLanguage);
  });

  const pickLanguage = (newLanguage: SurveyLanguage) => {
    setLanguage(newLanguage);
    // Inject language if not present
    if (!i18nValues.find((i18n) => i18n.language === newLanguage)) {
      const newI18nValues = [...i18nValues];
      newI18nValues.push({
        language: newLanguage,
        name: '',
      });
      setI18nValues(newI18nValues);
    }
  };

  const [icon, setIcon] = useState(ThemeIcon.Environment);
  const [color, setColor] = useState(ThemeColor.Blue);

  const [createPillarMutation] = useCreatePillarModalMutation();
  const createPillar = () => {
    setIsCreating(true);
    createPillarMutation({
      variables: {
        input: {
          referential: {
            id: referentialId,
          },
          icon: icon,
          color: color,
          i18n: i18nValues,
        },
      },
    })
      .then(() => {
        modal.closeModal();
      })
      .catch((err) => console.error(err))
      .finally(() => setIsCreating(false));
  };

  const editField = (field: string, value: string) => {
    const newI18nValues = i18nValues.map((i18n) => {
      if (i18n.language === language) {
        return {
          ...i18n,
          [field]: value.trimStart(),
        };
      }
      return i18n;
    });
    setI18nValues(newI18nValues);
  };

  // Form is valid when at least one translation has a name (not empty string)
  const isValid = i18nValues.some((i18n) => i18n.name !== '');

  return (
    <div className="flex flex-col max-w-5xl h-full">
      <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-400"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <h4 className="grow">{t('referential.form.pillar.add_pillar')}</h4>
        <LanguageDropdown onUpdate={(language) => pickLanguage(language)} />
      </div>
      <div className="p-6 space-y-6 grow overflow-y-scroll">
        <div className="flex flex-col gap-2">
          <label htmlFor="title" className="form-input-label">
            {t('referential.form.pillar.name')}
          </label>
          <input
            type="text"
            id="title"
            className="form-input-text"
            placeholder={t('stake.form.name', {
              context: language,
            })}
            value={translation?.name || ''}
            onChange={(e) => editField('name', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="icon" className="form-input-label">
            {t('referential.form.pillar.icon')}
          </label>
          <ThemeIconPicker currentIcon={icon} setCurrentIcon={setIcon} />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="color" className="form-input-label">
            {t('referential.form.pillar.color')}
          </label>
          <ThemeColorPicker currentColor={color} setCurrentColor={setColor} />
        </div>

        <div className="flex gap-2 justify-between">
          <button
            className="secondary"
            onClick={() => modal.closeModal()}
            disabled={isCreating}
          >
            {t('global:cancel')}
          </button>
          <button
            className="primary"
            onClick={() => createPillar()}
            disabled={isCreating || !isValid}
          >
            {isCreating ? <Loader /> : t('global:add')}
          </button>
        </div>
      </div>
    </div>
  );
}
