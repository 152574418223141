import {
  DatapointGapAnalysisStatusEditor_DisclosureRequirementFragment,
  DatapointGapAnalysisStatusEditor_ReportDatapointFragment,
} from '../../../../../graphql/generated';
import { useModal } from '../../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import { useSaveDataPoint } from './useSaveDataPoint';
import { Toggle, ToggleColors } from '../../../../generic/form/Toggle';
import { CheckIcon, XIcon } from '../../../../icons';
import clsx from 'clsx';
import { DatapointTags } from '../../../datapoint/DatapointTags';
import { DatapointGapAnalysisStatusEditor_DatapointFragment } from '../../../../../graphql/cms/generated';
import { JustifyReportDatapointButton } from '../../../audit/justification/type/JustifyReportDatapointButton';

export function DatapointGapAnalysisStatusEditor({
  autoSave,
  datapoint,
  reportDatapoint,
  reportDisclosureRequirement,
  readOnly = false,
}: {
  autoSave?: boolean;
  datapoint: DatapointGapAnalysisStatusEditor_DatapointFragment;
  reportDatapoint?: DatapointGapAnalysisStatusEditor_ReportDatapointFragment;
  reportDisclosureRequirement: DatapointGapAnalysisStatusEditor_DisclosureRequirementFragment;
  readOnly?: boolean;
}) {
  const [hasChanged, setHasChanged] = useState(false);
  const modal = useModal();
  const { t, translateStrapiProperty } = useTranslation();
  const [isAvailable, setIsAvailable] = useState(
    reportDatapoint?.isAvailable ?? false,
  );
  const [availabilityNotice, setAvailabilityNotice] = useState(
    reportDatapoint?.availabilityNotice ?? '',
  );

  useEffect(() => {
    if (!autoSave || !hasChanged) {
      return;
    }

    const handler = setTimeout(() => {
      saveItem();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [availabilityNotice, isAvailable]);

  const { saveDataPoint, loading } = useSaveDataPoint();

  function saveItem() {
    if (!readOnly && datapoint.slug) {
      saveDataPoint(datapoint.slug, reportDisclosureRequirement, {
        isAvailable,
        availabilityNotice,
      });
    }
  }

  return (
    <div className="space-y-2">
      <div className="font-bold">
        {translateStrapiProperty(datapoint.translation, 'name')}
      </div>
      <div className="flex gap-2 text-sm">
        <DatapointTags datapoint={datapoint} />
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="flex flex-col gap-2 w-full">
          <div className="border-t-gray-100 border-t my-3" />
          {(!reportDatapoint?.disabled && (
            <div className="flex flex-col gap-2">
              <label className="flex gap-2 text-sm font-bold">
                {t('gap_analysis.disclosure_requirement.datapoint.available')}
                <Toggle
                  state={isAvailable}
                  setState={() => {
                    setIsAvailable(!isAvailable);
                    setHasChanged(true);
                  }}
                  color={ToggleColors.Green}
                  offIcon={<XIcon />}
                  onIcon={<CheckIcon />}
                  disabled={readOnly}
                />
              </label>
              <label
                className={clsx('flex flex-col gap-2  text-sm', {
                  'text-gray-300': !isAvailable,
                })}
              >
                {t(
                  'gap_analysis.disclosure_requirement.datapoint.availabilityNotice.label',
                )}{' '}
                :
                <input
                  disabled={!isAvailable || readOnly}
                  type="text"
                  className="form-input-text"
                  value={availabilityNotice}
                  onChange={(e) => {
                    setAvailabilityNotice(e.target.value);
                    setHasChanged(true);
                  }}
                />
              </label>
            </div>
          )) || (
            <div className={'flex justify-center items-center gap-4'}>
              <span className={'text-gray-500 text-xs'}>
                {t(
                  'gap_analysis.disclosure_requirement.datapoint.disabled.justify',
                )}
              </span>
              {reportDatapoint?.id && (
                <JustifyReportDatapointButton
                  reportDatapointId={reportDatapoint.id}
                  readOnly={readOnly}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {!autoSave && !readOnly && (
        <div className={'pt-4 flex justify-center'}>
          <button
            className={'primary'}
            onClick={() => {
              saveItem();
              modal.closeModal();
            }}
          >
            {t('global:save')}
          </button>
        </div>
      )}
    </div>
  );
}
