import React from 'react';
import { IllustrationByType, MessageBoxType } from '../layout/MessageBox';
import clsx from 'clsx';

const TypeToColor = {
  [MessageBoxType.Info]: 'bg-blue-50 text-blue-900',
  [MessageBoxType.Feature]: 'bg-purple-50 text-purple-900',
  [MessageBoxType.Success]: 'bg-green-50 text-green-900',
  [MessageBoxType.Warning]: 'bg-yellow-50 text-yellow-900',
  [MessageBoxType.Error]: 'bg-red-50 text-red-900',
};

export function IllustratedExplanationMessage({
  title,
  description,
  illustration,
  children,
  type,
}: {
  title: string;
  description: string;
  type?: MessageBoxType;
  illustration?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <div
      className={clsx(
        'bg-purple-50 text-gray-900 rounded-xl flex items-stretch gap-8 p-4 w-full',
        type !== undefined && TypeToColor[type],
      )}
    >
      {(illustration && (
        <div className="max-w-20 flex items-center justify-center">
          {illustration}
        </div>
      )) ||
        (type && IllustrationByType[type])}
      <div className="grow flex justify-between items-center gap-8">
        <div className="space-y-2">
          <h4>{title}</h4>
          <div className={'max-w-xl text-sm text-gray-500'}>{description}</div>
        </div>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
}
