import React from 'react';
import {
  AnswerThemesList_AnalysisThemeFragment,
  StakeholderStats_StakeholderAnalysisThemeStatsFragment,
  SurveyQuestionType,
  SurveyThemeQualiQuestion_AnswerThemeFragment,
  useAnalysisThemeModalQuery,
} from '../../../graphql/generated';
import clsx from 'clsx';
import { getThemeBgColor } from './AnalysisThemeTag';
import { LoaderFullscreen } from '../../layout/Loader';
import {
  getSurveyTargetLabel,
  sanitizeTypeformString,
} from '../../../services/SurveyService';
import { PieChart } from 'react-minimal-pie-chart';
import { EditableAnalysisThemeTitle } from './EditableAnalysisThemeTitle';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { ExportableToImage } from '../../generic/ExportableToImage';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { QuoteWhiteIcon } from '../../icons';

export function AnalysisThemeModal({
  analysisThemeId,
}: {
  analysisThemeId: string;
}) {
  const query = useAnalysisThemeModalQuery({
    variables: {
      id: analysisThemeId,
    },
    fetchPolicy: 'network-only',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data?.analysisTheme) {
    return null;
  }

  const analysisTheme = query.data.analysisTheme;
  const answerThemeList = analysisTheme.answers || [];

  return (
    <div className="flex flex-col w-full">
      <div
        className={clsx(
          'py-8 px-16 border-y border-gray-100 flex flex-col gap-4',
          getThemeBgColor(analysisTheme.color, false, null),
        )}
      >
        <EditableAnalysisThemeTitle theme={analysisTheme} />

        <div className="grid grid-cols-3 gap-4">
          <StakeholderStats
            stakeholderAnalysisThemeStats={
              analysisTheme.ceoStakeholderRepartition
            }
          />
          <StakeholderStats
            stakeholderAnalysisThemeStats={
              analysisTheme.internalStakeholderRepartition
            }
          />
          <StakeholderStats
            stakeholderAnalysisThemeStats={
              analysisTheme.externalStakeholderRepartition
            }
          />
        </div>
      </div>
      <div className="w-full grow overflow-scroll p-16">
        <h2 className="title-h6 flex items-center gap-2 mb-4">
          <span>Contenus associés</span>
          <span className="tag gray">{answerThemeList.length}</span>
        </h2>
        <AnswerThemesList analysisTheme={analysisTheme} />
      </div>
    </div>
  );
}

function StakeholderStats({
  stakeholderAnalysisThemeStats,
}: {
  stakeholderAnalysisThemeStats: StakeholderStats_StakeholderAnalysisThemeStatsFragment;
}) {
  return (
    <div className="bg-white rounded-xl p-4 shadow-sm flex items-center gap-2 justify-between text-sm">
      <div className="flex flex-col items-start gap-0">
        <span className="text-gray-500 font-bold">
          {getSurveyTargetLabel(stakeholderAnalysisThemeStats?.surveyTarget)}
        </span>
        <span className="text-gray-500 mt-1">
          {stakeholderAnalysisThemeStats.themeUniqueMentions} /{' '}
          {stakeholderAnalysisThemeStats.totalRespondents} répondant
          {stakeholderAnalysisThemeStats.totalRespondents > 1 ? 's' : ''}
        </span>
        <span className="text-gray-500">
          {stakeholderAnalysisThemeStats.themeMentions} mention
          {stakeholderAnalysisThemeStats.themeMentions > 1 ? 's' : ''}
        </span>
      </div>
      <div className="flex flex-col items-center">
        <div className="w-6 h-6">
          <PieChart
            data={[
              {
                value: stakeholderAnalysisThemeStats.themeUniqueMentions,
                color: '#282a2c',
              },
              {
                value:
                  stakeholderAnalysisThemeStats.totalRespondents -
                  stakeholderAnalysisThemeStats.themeUniqueMentions,
                color: '#E4E6F2',
              },
            ]}
            startAngle={270}
            lineWidth={43}
          />
        </div>
        {stakeholderAnalysisThemeStats.totalRespondents > 0 ? (
          <span className="font-semibold">
            {Math.round(
              (100 * stakeholderAnalysisThemeStats.themeUniqueMentions) /
                stakeholderAnalysisThemeStats.totalRespondents,
            )}
            %
          </span>
        ) : (
          <span className="font-semibold">-%</span>
        )}
      </div>
    </div>
  );
}

function AnswerThemesList({
  analysisTheme,
}: {
  analysisTheme: AnswerThemesList_AnalysisThemeFragment;
}) {
  return (
    <div className="columns-2 h-full">
      {analysisTheme.answers?.map((answerTheme) => {
        if (answerTheme?.question) {
          const isQualiQuestion = [
            SurveyQuestionType.LongText,
            SurveyQuestionType.ShortText,
            SurveyQuestionType.Email,
          ].includes(answerTheme.question.type);
          if (isQualiQuestion) {
            return (
              <SurveyThemeQualiQuestion
                key={answerTheme.id}
                answerTheme={answerTheme}
              />
            );
          } else {
            // Not supported anymore
            return null;
          }
        }

        return null;
      })}
    </div>
  );
}

function SurveyThemeQualiQuestion({
  answerTheme,
}: {
  answerTheme: SurveyThemeQualiQuestion_AnswerThemeFragment;
}) {
  const projectContext = useProjectContext();
  const { currentUser } = useCurrentUser();

  const title = answerTheme.question?.title || 'Question';
  return (
    <ExportableToImage name="Verbatim">
      <div className="border border-gray-100 rounded-xl mb-4 break-inside-avoid max-w-xl">
        <div className="p-4 text-xs font-semibold uppercase text-gray-500">
          {sanitizeTypeformString(title, projectContext, currentUser)}
        </div>
        <div className="p-4 bg-yellow-50 rounded-b-xl font-title">
          <div className="">
            <QuoteWhiteIcon className="w-5 h-5" />
          </div>
          <div className="py-4 flex items-center justify-center text-center">
            {answerTheme.chunk?.content}
          </div>
        </div>
      </div>
    </ExportableToImage>
  );
}
