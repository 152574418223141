import React, { useState } from 'react';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import importIllustration from '../../../assets/images/import.png';
import {
  ImportStakeholdersModal_StakeholderFragment,
  ManageStakeholderListDocument,
  StakeholderSurveyCreateInput,
  useImportStakeholdersModal_StakeholderImportMutation,
} from '../../../graphql/generated';
import { Loader } from '../../generic/Loader';
import { useModal } from '../../layout/Modal';
import { useToast } from '../../layout/Toast';
import { StakeholderTable } from './StakeholdersTable';
import { useTranslation } from '@hooks/useTranslation';

export function ImportStakeholdersModal({ surveyId }: { surveyId: string }) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();

  const [importStakeholder] =
    useImportStakeholdersModal_StakeholderImportMutation();
  const [isLoading, setIsLoading] = useState(false);

  const [unparsedFromClipboard, setUnparsedFromClipboard] = useState('');
  const [stakeholderList, setStakeholderList] = useState<
    ImportStakeholdersModal_StakeholderFragment[]
  >([]);
  const [isPasted, setIsPasted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fromClipboardToListOfStakeholder = (unparsedFromClipboard: string) => {
    const stakeholderList: ImportStakeholdersModal_StakeholderFragment[] = [];
    const lines = unparsedFromClipboard.split('\n');
    lines.forEach((line) => {
      if (line) {
        const splits = line.split('\t');
        let firstName, lastName, email;
        if (splits.length === 3) {
          [firstName, lastName, email] = splits;
        } else if (splits.length === 1) {
          email = splits[0];
        }

        if (email) {
          stakeholderList.push({
            id: '',
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            email,
          });
        }
      }
    });
    return stakeholderList;
  };

  const paste = () => {
    const stakeholderList = fromClipboardToListOfStakeholder(
      unparsedFromClipboard,
    );
    if (
      stakeholderList.length > 0 &&
      stakeholderList.every((stakeholder) => {
        return (
          stakeholder.email !== '' &&
          stakeholder.email !== null &&
          stakeholder.email !== undefined
        );
      })
    ) {
      setErrorMessage('');
      setStakeholderList(stakeholderList);
      setIsPasted(true);
    } else {
      setErrorMessage(
        'Aucun contact trouvé. Vérifiez que chaque contact a une adresse email.',
      );
      setStakeholderList([]);
      setIsPasted(false);
    }
  };

  const unpaste = () => {
    setIsPasted(false);
    setUnparsedFromClipboard('');
    setStakeholderList([]);
  };

  const getCreateStakeholderInput = (
    stakeholder: ImportStakeholdersModal_StakeholderFragment,
  ): StakeholderSurveyCreateInput => {
    return {
      survey: {
        id: surveyId,
      },
      email: stakeholder.email,
      firstName: stakeholder.firstName ?? '',
      lastName: stakeholder.lastName ?? '',
    };
  };

  const submit = () => {
    setIsLoading(true);
    void importStakeholder({
      variables: {
        input: stakeholderList.map((stakeholder) => {
          return getCreateStakeholderInput(stakeholder);
        }),
      },
      refetchQueries: [
        {
          query: ManageStakeholderListDocument,
          variables: {
            id: surveyId,
          },
        },
      ],
    })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        modal.closeModal();
        toast.openToastWithMessage(
          `${stakeholderList.length} contacts importés avec succès`,
        );
      })
      .finally(() => setIsLoading(false));
  };

  if (!isPasted) {
    return (
      <div className="flex flex-col gap-4">
        <div
          dangerouslySetInnerHTML={{
            __html: t('survey.stakeholderLists.importContacts.description'),
          }}
        />
        <MessageBox type={MessageBoxType.Info}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('survey.stakeholderLists.importContacts.help'),
            }}
          />
          <p className="mt-2">
            <img src={importIllustration} alt="" className="" />
          </p>
        </MessageBox>
        <textarea
          className="form-input-text h-48"
          value={unparsedFromClipboard}
          onChange={(event) => {
            setUnparsedFromClipboard(event.target.value);
          }}
        />
        {errorMessage && (
          <MessageBox type={MessageBoxType.Error}>
            <p>{errorMessage}</p>
          </MessageBox>
        )}
        <button
          className="self-end primary"
          onClick={paste}
          disabled={unparsedFromClipboard === ''}
        >
          {t('global:import.action')}
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-4">
        <p>{stakeholderList.length} contacts prêts à être importés :</p>
        <StakeholderTable stakeholders={stakeholderList} />
        <div className="flex items-center gap-2 justify-between mt-4">
          <button onClick={unpaste}>Retour</button>
          {isLoading ? (
            <Loader className="w-8 h-8" />
          ) : (
            <button className="primary" onClick={submit}>
              Importer
            </button>
          )}
        </div>
      </div>
    );
  }
}
