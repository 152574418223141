import { useJoinEnterpriseListQuery } from '../../../../graphql/generated';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../../../layout/Loader';
import React from 'react';
import { EnterprisesList } from './EnterprisesList';

export function ArchivedProjectList() {
  const { currentUser } = useCurrentUser();

  const companyProjectsQuery = useJoinEnterpriseListQuery({
    variables: { companyId: currentUser?.company?.id || '' },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (companyProjectsQuery.loading) {
    return <LoaderFullscreen />;
  }

  const archivedProjects =
    currentUser?.userAccesses
      ?.filter((userAccess) => userAccess.enterprise !== null)
      ?.map((userAccess) => userAccess.enterprise!)
      .filter((enterprise) => enterprise.archivedDate) || [];

  return (
    <div className="max-w-6xl mt-16 pb-16">
      {archivedProjects && archivedProjects.length > 0 ? (
        <EnterprisesList enterprises={archivedProjects} />
      ) : null}
    </div>
  );
}
