import { useTranslation } from '@hooks/useTranslation';
import { useToast } from '../../layout/Toast';
import { useModal } from '../../layout/Modal';
import React from 'react';
import {
  AdminCompaniesScreenDocument,
  AdminEnterprisesWithLicenseScreenDocument,
  AdminEnterprisesWithoutLicenseScreenDocument,
  useDeleteEnterpriseButtonMutation,
} from '../../../graphql/generated';
import { ConfirmationModal } from '../../modals/ConfirmationModal';
import { Loader } from '../../generic/Loader';

export function DeleteEnterpriseButton({
  enterpriseId,
}: {
  enterpriseId: string;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();

  const [deleteMutation, { loading }] = useDeleteEnterpriseButtonMutation();

  const deleteEnterprise = () => {
    deleteMutation({
      variables: {
        input: {
          id: enterpriseId,
        },
      },
      refetchQueries: [
        AdminCompaniesScreenDocument,
        AdminEnterprisesWithLicenseScreenDocument,
        AdminEnterprisesWithoutLicenseScreenDocument,
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('admin:companies.toast.company_deleted'));
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <button
      className="primary danger small"
      disabled={loading}
      onClick={() =>
        modal.openModalWithComponent(
          <ConfirmationModal
            message={t('project.delete.content')}
            callbackIfConfirmed={deleteEnterprise}
          />,
          t('global:delete'),
        )
      }
    >
      {loading && <Loader />}
      <div>{t('global:delete')}</div>
    </button>
  );
}
