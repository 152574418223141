import React from 'react';
import { AdminUserList_UserFragment } from '../../../../graphql/generated';
import { NumberCircle } from '../../../../components/generic/NumberCircle';
import { generatePath, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { AppRoutes } from '../../../AppRoutes';

export const AdminUserListItem = ({
  user,
  index,
}: {
  user: AdminUserList_UserFragment;
  index: number;
}) => {
  return (
    <li className="flex items-center gap-2">
      <NumberCircle number={index + 1} size={6} />
      <span>
        <NavLink
          to={
            (!user.auth?.disabled &&
              generatePath(AppRoutes.AdminUser, {
                id: user.id,
              })) ||
            ''
          }
          className={clsx('flex items-center gap-2')}
        >
          <div className={clsx(user.auth?.disabled && 'text-gray-400')}>
            {user.firstName} {user.lastName}
          </div>
          <div className="flex items-center gap-2">
            {(user.auth?.roles || []).map((role) => (
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              <div key={`${user.id} ${role}`} className="tag purple">
                {role}
              </div>
            ))}
          </div>
        </NavLink>
      </span>
    </li>
  );
};
