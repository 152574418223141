import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../../AppRoutes';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { FeatureEnum, PermissionEnum } from '../../../graphql/generated';

export const enum IRO_TABS {
  BY_STAKES = 'BY_STAKES',
  BY_IRO = 'BY_IRO',
  MATERIALITY_THRESHOLD = 'MATERIALITY_THRESHOLD',
  SETTINGS = 'SETTINGS',
}

export const useIroTabs = () => {
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker();
  const canManage = isGranted(FeatureEnum.IroCotation, PermissionEnum.Write);
  const tabs = [
    {
      key: IRO_TABS.BY_STAKES,
      name: t('nav:page.iro.byStakes'),
      path: AppRoutes.Iro,
    },
    {
      key: IRO_TABS.BY_IRO,
      name: t('nav:page.iro.byIro'),
      path: AppRoutes.IroList,
    },
    {
      key: IRO_TABS.MATERIALITY_THRESHOLD,
      name: t('nav:page.iro.materiality_thresholds'),
      path: AppRoutes.IroMaterialityThresholds,
    },
  ];

  if (canManage) {
    tabs.push({
      key: IRO_TABS.SETTINGS,
      name: t('nav:page.iro.settings'),
      path: AppRoutes.IroSettings,
    });
  }

  return tabs
};
