import React, { ReactNode } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { GradientScore } from '../../../generic/GradientScore';
import { Getter } from '@tanstack/table-core/src/utils';
import { useTranslation } from '@hooks/useTranslation';
import { ChevronDownIcon, ChevronUpIcon, ZapThinIcon } from '../../../icons';
import { SummaryDetailIroRowFragment } from '../../../../graphql/generated';
import clsx from 'clsx';
import { StandardTopicTag_TopicFragment } from '../../../../graphql/cms/generated';
import { StandardTopicTag } from '../../stakes/stakesStandardTopics/StandardTopicTag';
import { SkeletonLoader } from '../../../generic/loader/SkeletonLoader';

export type IroSummaryStakeRow = {
  stake: {
    id: string;
    name: string;
    icon: ReactNode;
    isDisabled: boolean;
  };
  topics?: StandardTopicTag_TopicFragment[];
  isLoadingTopics: boolean;
  financialMateriality?: {
    score?: number | null;
    isMaterial: boolean;
  } | null;
  impactMateriality?: {
    score?: number | null;
    isMaterial: boolean;
  } | null;
  maxMateriality?: {
    score?: number | null;
    isMaterial: boolean;
  } | null;
  isMaterial?: boolean;
  actions: ReactNode[];
  iros: SummaryDetailIroRowFragment[];
};

export const scoreFormatter = (data: {
  getValue: Getter<{ score?: number | null; isMaterial: boolean } | undefined>;
}) => {
  const scoreElement = data.getValue();
  return (
    <div className="w-full flex items-center justify-center">
      {scoreElement?.isMaterial && (
        <GradientScore score={scoreElement.score} iconRight={<ZapThinIcon />} />
      )}
      {!scoreElement?.isMaterial && (
        <GradientScore score={scoreElement?.score} />
      )}
    </div>
  );
};

export const useIroSummaryColumns = (
  readOnly: boolean = false,
): Array<ColumnDef<IroSummaryStakeRow>> => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<IroSummaryStakeRow>();

  return [
    columnHelper.accessor('stake', {
      cell: (data) => {
        const stake = data.getValue();
        const expandProps = {
          onClick: () => data.row.toggleExpanded(),
          style: { cursor: 'pointer' },
        };
        return (
          <div className="flex items-center justify-between">
            <div
              className={clsx(
                'flex items-center gap-3',
                stake.isDisabled ? 'text-gray-500' : 'text-gray-900 font-bold',
              )}
            >
              {stake.icon} <span {...expandProps}>{stake.name}</span>
            </div>
          </div>
        );
      },
      sortDescFirst: false,
      header: () => <span>{t('iro.summary.table.header.stake.label')}</span>,
      meta: {
        th: {
          className: 'text-left',
        },
        td: {
          className: 'text-left',
        },
      },
    }),
    columnHelper.accessor('topics', {
      cell: (data) => {
        const topics = data.getValue();
        if (data.row.original.isLoadingTopics) {
          return (
            <SkeletonLoader
              size={{ height: 'h-5' }}
              randomSizes={{ width: ['w-1/2', 'w-7/12', 'w-3/4'] }}
            />
          );
        }
        return (
          <div className="flex items-center gap-0.5 flex-wrap">
            {topics?.map((topic) => (
              <StandardTopicTag topic={topic} key={topic.documentId} />
            ))}
          </div>
        );
      },
      header: () => (
        <span>{t('iro.summary.table.header.subTopics.label')}</span>
      ),
      meta: {
        th: {
          className: 'text-left',
        },
        td: {
          className: 'text-left',
        },
      },
    }),
    columnHelper.accessor('financialMateriality', {
      cell: scoreFormatter,
      sortUndefined: 'last',
      header: () => t('iro.summary.table.header.financialMateriality.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
      sortingFn: (a, b): number => {
        const aScore = a.getValue('financialMateriality') as unknown as {
          score: number;
        };
        const bScore = b.getValue('financialMateriality') as unknown as {
          score: number;
        };
        return aScore.score - bScore.score;
      },
    }),
    columnHelper.accessor('impactMateriality', {
      cell: scoreFormatter,
      sortUndefined: 'last',
      header: () => t('iro.summary.table.header.impactMateriality.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'text-center w-36',
        },
      },
      sortingFn: (a, b) => {
        const aScore = a.getValue('impactMateriality') as unknown as {
          score: number;
        };
        const bScore = b.getValue('impactMateriality') as unknown as {
          score: number;
        };
        return aScore.score - bScore.score;
      },
    }),
    columnHelper.accessor('maxMateriality', {
      cell: scoreFormatter,
      sortUndefined: 'last',
      header: () => t('iro.summary.table.header.maxMateriality.label'),
      meta: {
        th: {
          className: 'text-center w-36',
        },
        td: {
          className: 'border-r-0 text-center w-36',
        },
      },
      sortingFn: (a, b) => {
        const aScore = a.getValue('maxMateriality') as unknown as {
          score: number;
        };
        const bScore = b.getValue('maxMateriality') as unknown as {
          score: number;
        };
        return aScore.score - bScore.score;
      },
    }),
    columnHelper.accessor('actions', {
      cell: (data) => {
        const expandButton = (data.row.getIsExpanded() && (
          <ChevronUpIcon />
        )) || <ChevronDownIcon />;
        return (
          <div className={'flex items-center gap-3'}>
            <a href={'#'} onClick={() => data.row.toggleExpanded()}>
              {expandButton}
            </a>
            {data.getValue()}
          </div>
        );
      },
      meta: {
        th: {
          className: 'text-center w-20',
        },
        td: {
          className: 'text-center w-20 pl-0',
        },
      },
      enableSorting: false,
      header: () => '',
    }),
  ] as Array<ColumnDef<IroSummaryStakeRow>>;
};
