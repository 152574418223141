import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DiagnosticStakeFieldsFragment,
  ImpactLevel,
  MaterialityTypes,
  PriorityLevel,
  ProbabilityLevel,
  QualityLevel,
  SurveyAnswerType,
  SurveyQuestionSummaryFieldsFragment,
  SurveySubmitAnswerInput,
  SurveySubmitAnswerStakeInput,
  useSurveyWithCompanyReferentialQuery,
} from '../../../../graphql/generated';
import { QuestionDescription } from '../QuestionDescription';
import { useParams } from 'react-router-dom';
import { TooltipWrapper } from '../../../generic/TooltipWrapper';
import clsx from 'clsx';
import { PillarIcon } from '../../../stake/PillarIcon';
import { LoaderFullscreen } from '../../../layout/Loader';
import { HelpModal } from '../../../generic/HelpModal';
import { useTranslation } from '@hooks/useTranslation';
import {
  CheckBoxProps,
  StakeOptionCheckBox,
} from '../atoms/StakeOptionCheckBox';
import { UtopiesQualityLevelLabel } from './UtopiesQualityLabel';
import { ArrowRightIcon, CheckIcon, MinusIcon, PlusIcon } from '../../../icons';
import { InnerHtml } from '../../../generic/InnerHtml';

export function QuestionUtopiesStakesDoubleMateriality({
  question,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { translateProperty } = useTranslation();
  const { surveyId } = useParams();
  const surveyWithCompanyReferentialQuery =
    useSurveyWithCompanyReferentialQuery({
      variables: { id: surveyId || '' },
      skip: !surveyId,
      fetchPolicy: 'network-only',
    });

  // Fetch stakes
  const referential =
    surveyWithCompanyReferentialQuery.data?.survey?.company?.referential;
  // All stakes or only the ones from the pillar
  const stakes = useMemo(() => {
    if (question.pillar?.id) {
      return (
        (referential?.pillars || [])
          .filter((pillar) => pillar.id === question.pillar?.id)
          .flatMap((pillar) => pillar.stakes)
          .toSorted((a, b) =>
            translateProperty(a, 'name').localeCompare(
              translateProperty(b, 'name'),
            ),
          ) || []
      );
    } else {
      return (
        (referential?.pillars || [])
          .flatMap((pillar) => pillar.stakes)
          .toSorted((a, b) =>
            translateProperty(a, 'name').localeCompare(
              translateProperty(b, 'name'),
            ),
          ) || []
      );
    }
  }, [question.pillar?.id, referential?.pillars, translateProperty]);

  if (surveyWithCompanyReferentialQuery.loading) {
    return <LoaderFullscreen />;
  }

  return (
    <QuestionStakesMaterialityInner
      question={question}
      stakes={stakes}
      answer={answer}
      setAnswer={setAnswer}
      setIsAnswerValid={setIsAnswerValid}
    />
  );
}

function QuestionStakesMaterialityInner({
  question,
  stakes,
  answer,
  setAnswer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  stakes: DiagnosticStakeFieldsFragment[];
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) {
  const { t, translateProperty } = useTranslation();

  const materialityType =
    question.stakeOptions?.materialityType || MaterialityTypes.Financial;

  const [currentStake, setCurrentStake] =
    useState<DiagnosticStakeFieldsFragment | null>(
      // First stake by default
      stakes[0] || null,
    );

  const [validStakesIds, setValidStakesIds] = useState<string[]>([]);

  const chooseStake = (stake: DiagnosticStakeFieldsFragment) => {
    setCurrentStake(stake);
  };

  const validateStakeAnswer = useCallback(
    (stakeId: string) => {
      const answerStakes = answer?.stakes || [];
      const stakeAnswer = answerStakes.find((s) => s.stakeId === stakeId);
      const isValid =
        stakeAnswer?.quality &&
        (stakeAnswer?.priority || stakeAnswer?.impact) &&
        stakeAnswer?.probability;
      const isStakeValid = !!isValid;

      if (isStakeValid) {
        if (!validStakesIds.includes(stakeId)) {
          const newValidStakesIds = [...validStakesIds, stakeId];
          setValidStakesIds(newValidStakesIds);

          // If all stakes are valid, then the answer is valid
          if (newValidStakesIds.length >= stakes.length) {
            setIsAnswerValid(true);
          }
        } else {
          if (validStakesIds.length >= stakes.length) {
            setIsAnswerValid(true);
          }
        }
      } else {
        if (validStakesIds.includes(stakeId)) {
          const newValidStakesIds = validStakesIds.filter(
            (id) => id !== stakeId,
          );
          setValidStakesIds(newValidStakesIds);
          setIsAnswerValid(false);
        }
      }
    },
    [answer, validStakesIds, stakes.length, setIsAnswerValid],
  );

  const validateAnswer = useCallback(() => {
    // Validate each stake
    stakes.forEach((stake) => {
      validateStakeAnswer(stake.id);
    });
  }, [stakes, validateStakeAnswer]);

  const mergeAnswerStakeInput = (
    surveySubmitAnswerStakeInput: SurveySubmitAnswerStakeInput,
  ) => {
    const answerStakes = [...(answer?.stakes || [])];
    const stakeIndex = answerStakes.findIndex(
      (s) => s.stakeId === surveySubmitAnswerStakeInput.stakeId,
    );
    if (stakeIndex === -1) {
      // Add new stake
      answerStakes.push(surveySubmitAnswerStakeInput);
    } else {
      // Update stake
      answerStakes[stakeIndex] = {
        ...answerStakes[stakeIndex],
        ...surveySubmitAnswerStakeInput,
      };
    }
    setAnswer({
      question: {
        id: question.id,
      },
      type: SurveyAnswerType.Stakes,
      stakes: answerStakes,
    });
  };

  const toggleQualityLevel = (level: QualityLevel) => {
    // If already checked, uncheck. Else check.
    const currentStakeAnswer = answer?.stakes?.find(
      (s) => s.stakeId === currentStake?.id,
    );
    if (currentStakeAnswer?.quality === level) {
      mergeAnswerStakeInput({
        stakeId: currentStake?.id || '',
        quality: undefined,
      });
    } else {
      mergeAnswerStakeInput({
        stakeId: currentStake?.id || '',
        quality: level,
      });
    }
  };

  // Validate answer
  useEffect(() => {
    validateAnswer();
  }, [validateAnswer, answer]);

  const mainContentRef = useRef<HTMLDivElement>(null);

  const checkboxProps: CheckBoxProps = {
    stake: currentStake,
    stakeOptions: question.stakeOptions,
    stakeAnswer: answer?.stakes?.find((s) => s.stakeId === currentStake?.id),
    mergeAnswerStakeInput: mergeAnswerStakeInput,
  };

  return (
    <div className="bg-yellow-50 p-8 lg:px-16" ref={mainContentRef}>
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {question.description && (
          <QuestionDescription description={question.description} />
        )}
        <div className="mt-4 w-full flex justify-center items-center">
          <div className="w-full max-w-5xl flex flex-col sm:flex-row items-start gap-8">
            <div className={clsx('w-full sm:w-1/3')}>
              <StakesNav
                stakes={stakes}
                currentStake={currentStake}
                chooseStake={chooseStake}
                answeredStakesIds={validStakesIds}
              />
            </div>
            <div className="space-y-4 w-full sm:w-2/3">
              <div className="space-y-8 bg-yellow-100 p-4 rounded-xl border-2 border-yellow-500 w-full">
                <div className="space-y-4">
                  <h1 className="text-center w-full">
                    {translateProperty(currentStake, 'name')}
                  </h1>
                  {question.stakeOptions?.materialityType ===
                    MaterialityTypes.Financial && (
                    <InnerHtml
                      html={translateProperty(
                        currentStake,
                        'financialMaterialityDescription',
                      )}
                      className="text-gray-700 text-sm"
                    />
                  )}
                  {question.stakeOptions?.materialityType ===
                    MaterialityTypes.Impact && (
                    <InnerHtml
                      html={translateProperty(
                        currentStake,
                        'impactMaterialityDescription',
                      )}
                      className="text-gray-700 text-sm"
                    />
                  )}
                </div>
                <div className="divide-y-2 divide-gray-900 space-y-8">
                  <div className="py-4 space-y-4">
                    <h6 className="w-full text-center">
                      {question.stakeOptions?.materialityOptions?.qualityLabel}
                    </h6>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <TooltipWrapper
                        label={
                          question.stakeOptions?.materialityOptions
                            ?.qualityNegativeChoiceLabel
                        }
                        place="bottom"
                      >
                        <div
                          className={clsx(
                            'form-input-multiple-choice-item',
                            answer?.stakes?.find(
                              (s) =>
                                s.stakeId === currentStake?.id &&
                                s.quality === QualityLevel.Negative,
                            ) && 'selected',
                          )}
                          onClick={() =>
                            toggleQualityLevel(QualityLevel.Negative)
                          }
                        >
                          <UtopiesQualityLevelLabel
                            qualityLevel={QualityLevel.Negative}
                            materialityType={materialityType}
                          />
                        </div>
                        <div className="sm:hidden">
                          <HelpModal modalTitle="Info">
                            {
                              question.stakeOptions?.materialityOptions
                                ?.qualityNegativeChoiceLabel
                            }
                          </HelpModal>
                        </div>
                      </TooltipWrapper>
                      <TooltipWrapper
                        label={
                          question.stakeOptions?.materialityOptions
                            ?.qualityNeutralChoiceLabel
                        }
                        place="bottom"
                      >
                        <div
                          className={clsx(
                            'form-input-multiple-choice-item',
                            answer?.stakes?.find(
                              (s) =>
                                s.stakeId === currentStake?.id &&
                                s.quality === QualityLevel.Neutral,
                            ) && 'selected',
                          )}
                          onClick={() =>
                            toggleQualityLevel(QualityLevel.Neutral)
                          }
                        >
                          <UtopiesQualityLevelLabel
                            qualityLevel={QualityLevel.Neutral}
                            materialityType={materialityType}
                          />
                        </div>
                        <div className="sm:hidden">
                          <HelpModal modalTitle="Info">
                            {
                              question.stakeOptions?.materialityOptions
                                ?.qualityNeutralChoiceLabel
                            }
                          </HelpModal>
                        </div>
                      </TooltipWrapper>
                      <TooltipWrapper
                        label={
                          question.stakeOptions?.materialityOptions
                            ?.qualityPositiveChoiceLabel
                        }
                        place="bottom"
                      >
                        <div
                          className={clsx(
                            'form-input-multiple-choice-item',
                            answer?.stakes?.find(
                              (s) =>
                                s.stakeId === currentStake?.id &&
                                s.quality === QualityLevel.Positive,
                            ) && 'selected',
                          )}
                          onClick={() =>
                            toggleQualityLevel(QualityLevel.Positive)
                          }
                        >
                          <UtopiesQualityLevelLabel
                            qualityLevel={QualityLevel.Positive}
                            materialityType={materialityType}
                          />
                        </div>
                        <div className="sm:hidden">
                          <HelpModal modalTitle="Info">
                            {
                              question.stakeOptions?.materialityOptions
                                ?.qualityPositiveChoiceLabel
                            }
                          </HelpModal>
                        </div>
                      </TooltipWrapper>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center sm:items-end gap-4 py-4">
                    <h6 className="grow">
                      {question.stakeOptions?.materialityOptions?.impactLabel}
                    </h6>
                    <div>
                      <div className="flex items-center justify-end gap-4 w-full">
                        <div className="w-56 flex items-center justify-evenly gap-2 py-4 pr-4 pl-2">
                          <div className="rounded-full border-2 border-gray-900">
                            <MinusIcon />
                          </div>
                          <hr className="border border-gray-900 w-8" />
                          <div className="font-semibold">
                            {materialityType === MaterialityTypes.Financial
                              ? t(
                                  'survey.question.utopies_stakes_double_materiality.severity',
                                )
                              : t(
                                  'survey.question.utopies_stakes_double_materiality.impact',
                                )}
                          </div>
                          <hr className="border border-gray-900 w-8" />
                          <div className="rounded-full border-2 border-gray-900">
                            <PlusIcon />
                          </div>
                        </div>
                      </div>
                      {materialityType === MaterialityTypes.Financial && (
                        <div className="w-56 flex items-center justify-evenly gap-2">
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'priority'}
                            level={PriorityLevel.None}
                            placeholder={'1'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactNoneLabel
                            }
                          />
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'priority'}
                            level={PriorityLevel.Low}
                            placeholder={'2'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactLowLabel
                            }
                          />
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'priority'}
                            level={PriorityLevel.Medium}
                            placeholder={'3'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactMediumLabel
                            }
                          />
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'priority'}
                            level={PriorityLevel.High}
                            placeholder={'4'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactHighLabel
                            }
                          />
                        </div>
                      )}
                      {materialityType === MaterialityTypes.Impact && (
                        <div className="w-56 flex items-center justify-evenly gap-2">
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'impact'}
                            level={ImpactLevel.None}
                            placeholder={'1'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactNoneLabel
                            }
                          />
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'impact'}
                            level={ImpactLevel.Low}
                            placeholder={'2'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactLowLabel
                            }
                          />
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'impact'}
                            level={ImpactLevel.Medium}
                            placeholder={'3'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactMediumLabel
                            }
                          />
                          <StakeOptionCheckBox
                            checkboxProps={checkboxProps}
                            levelType={'impact'}
                            level={ImpactLevel.High}
                            placeholder={'4'}
                            tooltip={
                              question.stakeOptions?.materialityOptions
                                ?.impactHighLabel
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center sm:items-end gap-4 py-4">
                    <h6 className="grow">
                      {
                        question.stakeOptions?.materialityOptions
                          ?.probabilityLabel
                      }
                    </h6>
                    <div>
                      <div className="flex items-center justify-end gap-4 w-full">
                        <div className="w-56 flex items-center justify-evenly gap-2 py-4 pr-4 pl-2">
                          <div className="rounded-full border-2 border-gray-900">
                            <MinusIcon />
                          </div>
                          <hr className="border border-gray-900 w-8" />
                          <div className="font-semibold">
                            {t(
                              'survey.question.utopies_stakes_double_materiality.likehood',
                            )}
                          </div>
                          <hr className="border border-gray-900 w-8" />
                          <div className="rounded-full border-2 border-gray-900">
                            <PlusIcon />
                          </div>
                        </div>
                      </div>
                      <div className="w-56 flex items-center justify-evenly gap-2">
                        <StakeOptionCheckBox
                          checkboxProps={checkboxProps}
                          levelType={'probability'}
                          level={ProbabilityLevel.Low}
                          placeholder={'1'}
                          tooltip={
                            question.stakeOptions?.materialityOptions
                              ?.probabilityLowLabel
                          }
                        />
                        <StakeOptionCheckBox
                          checkboxProps={checkboxProps}
                          levelType={'probability'}
                          level={ProbabilityLevel.Medium}
                          placeholder={'2'}
                          tooltip={
                            question.stakeOptions?.materialityOptions
                              ?.probabilityMediumLabel
                          }
                        />
                        <StakeOptionCheckBox
                          checkboxProps={checkboxProps}
                          levelType={'probability'}
                          level={ProbabilityLevel.High}
                          placeholder={'3'}
                          tooltip={
                            question.stakeOptions?.materialityOptions
                              ?.probabilityHighLabel
                          }
                        />
                        <StakeOptionCheckBox
                          checkboxProps={checkboxProps}
                          levelType={'probability'}
                          level={ProbabilityLevel.Certain}
                          placeholder={'4'}
                          tooltip={
                            question.stakeOptions?.materialityOptions
                              ?.probabilityCertainLabel
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {question.canAddOtherChoice && (
                    <div className="space-y-2 pt-4">
                      <h6 className="font-semibold">
                        {t(
                          'survey.question.utopies_stakes_double_materiality.comment',
                        )}
                      </h6>
                      <textarea
                        className="form-input-text-survey"
                        placeholder={t(
                          'survey.question.utopies_stakes_double_materiality.comment_placeholder',
                        )}
                        value={
                          answer?.stakes?.find(
                            (s) => s.stakeId === currentStake?.id,
                          )?.comment || ''
                        }
                        onChange={(e) => {
                          mergeAnswerStakeInput({
                            stakeId: currentStake?.id || '',
                            comment: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {stakes[stakes.length - 1] &&
                stakes[stakes.length - 1].id !== currentStake?.id && (
                  <div className="w-full max-w-5xl flex justify-end">
                    <button
                      className="primary"
                      disabled={
                        !validStakesIds.includes(currentStake?.id || '')
                      }
                      onClick={() => {
                        const currentStakeIndex = stakes.findIndex(
                          (s) => s.id === currentStake?.id,
                        );
                        chooseStake(stakes[currentStakeIndex + 1]);
                        mainContentRef.current &&
                          mainContentRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                          });
                      }}
                    >
                      <div>
                        {t(
                          'survey.question.utopies_stakes_double_materiality.next_stake',
                        )}
                      </div>
                      <ArrowRightIcon />
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StakesNav({
  stakes,
  currentStake,
  chooseStake,
  answeredStakesIds,
}: {
  stakes: DiagnosticStakeFieldsFragment[];
  currentStake: DiagnosticStakeFieldsFragment | null;
  chooseStake: (stake: DiagnosticStakeFieldsFragment) => void;
  answeredStakesIds: string[];
}) {
  const { t, translateProperty } = useTranslation();
  return (
    <div>
      <div className="sm:hidden space-y-1">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <h6>
          {t(
            'survey.question.utopies_stakes_double_materiality.stakes_to_evaluate',
          )}
        </h6>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-900 focus:outline-hidden focus:ring-gray-900 sm:text-sm"
          value={currentStake?.id || ''}
          onChange={(event) => {
            const stake = stakes.find((s) => s.id === event.target.value);
            if (stake) {
              chooseStake(stake);
            }
          }}
        >
          {stakes.map((stake) => (
            <option key={stake.id} value={stake.id}>
              {translateProperty(stake, 'name')}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block space-y-1">
        <h6>
          {t(
            'survey.question.utopies_stakes_double_materiality.stakes_to_evaluate',
          )}
        </h6>
        {stakes.map((stake) => (
          <div
            key={stake.id}
            className={clsx(
              'rounded-lg h-10 border-2 border-gray-900 flex items-center gap-2 p-2 cursor-pointer hover:bg-gray-900 hover:text-white',
              currentStake?.id === stake.id
                ? 'bg-gray-900 text-white'
                : 'bg-white text-gray-900',
            )}
            onClick={() => chooseStake(stake)}
          >
            {stake.pillar && <PillarIcon pillar={stake.pillar} />}
            <div className="font-bold text-sm grow truncate text-ellipsis max-w-[20rem]">
              {translateProperty(stake, 'name')}
            </div>
            {answeredStakesIds.includes(stake.id) && (
              <CheckIcon className="shrink-0 w-4 h-4 bg-green-500 text-white rounded-full p-0.5" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
