import {
  AuthRolesAndExperimentalFeatures,
  ProjectContextualMenu_CompanyFragment,
} from '../../../../graphql/generated';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { EyeIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { ContextualMenu } from '../../../generic/ContextualMenu';
import { DeleteCompanyButton } from './DeleteCompanyButton';
import { ArchiveEnterpriseButton } from './ArchiveEnterpriseButton';
import { DuplicateCompanyButton } from './DuplicateCompanyButton';
import { CreateModelCompanyButton } from './CreateModelCompanyButton';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { AppRoutes } from '../../../../screens/AppRoutes';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export function ProjectContextualMenu({
  company,
}: {
  company: ProjectContextualMenu_CompanyFragment;
}) {
  const projectContext = useProjectContext();
  const { t } = useTranslation();
  const { hasRole } = usePermissionChecker();
  const goToProjectHome = () => {
    if (projectContext) {
      projectContext.updateEnterprise(company.id, true);
    }
  };

  return (
    <ContextualMenu preventFromClosingAutomatically={true}>
      <NavLink
        to={AppRoutes.Project}
        onClick={goToProjectHome}
        className="w-full"
      >
        <button className="contextual-menu-item">
          <EyeIcon />
          <div>{t('global:open')}</div>
        </button>
      </NavLink>
      {hasRole(AuthRolesAndExperimentalFeatures.Administrator, false) && (
        <>
          <CreateModelCompanyButton company={company} />
          <DuplicateCompanyButton company={company} />
          <DeleteCompanyButton company={company} />
          <ArchiveEnterpriseButton company={company} />
        </>
      )}
    </ContextualMenu>
  );
}
