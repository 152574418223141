import React, { Fragment, ReactNode } from 'react';
import {
  Field,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon, ChevronDownIcon } from '../../icons';
import { Float } from '@headlessui-float/react';
import { Checkbox, CheckboxColors } from '../../form/Checkbox';
import { defaultLabelFn } from './GroupedStringDropdown.utils';

export type GroupedStringDropdownItem = {
  id: string;
  label: string;
  group: {
    name: string;
    icon?: ReactNode;
  };
};

type GroupedStringDropdownProps = {
  availableItems: GroupedStringDropdownItem[];
  items: GroupedStringDropdownItem[];
  setItem: (items: GroupedStringDropdownItem[]) => void;
  extraPositionClassName?: string;
  readonly?: boolean;
  multiple?: boolean;
  placeholder?: string;
  labelFn?: (multiple: boolean, items: GroupedStringDropdownItem[]) => ReactNode;
};

export default function GroupedStringDropdown({
  availableItems,
  items,
  setItem,
  extraPositionClassName,
  placeholder,
  readonly = false,
  multiple = false,
  labelFn,
}: GroupedStringDropdownProps) {
  const chooseItem = (
    item: GroupedStringDropdownItem | GroupedStringDropdownItem[],
  ) => {
    if (readonly) return;

    if (Array.isArray(item)) {
      setItem(item);
      return;
    }

    if (multiple) {
      const isSelected = items.some(
        (selectedItem) => selectedItem.id === item.id,
      );
      let newSelectedItems;

      if (isSelected) {
        newSelectedItems = items.filter(
          (selectedItem) => selectedItem.id !== item.id,
        );
      } else {
        newSelectedItems = [...items, item];
      }

      setItem(newSelectedItems);
    } else {
      setItem([item]);
    }
  };

  const groupedItems = availableItems.reduce<{
    [key: string]: GroupedStringDropdownItem[];
  }>((groups, item) => {
    const groupName = item.group.name || 'default';
    if (!groups[groupName]) {
      groups[groupName] = [];
    }
    groups[groupName].push(item);

    return groups;
  }, {});

  return (
    <Field disabled={readonly}>
      <Listbox value={items} onChange={chooseItem} multiple={multiple}>
        {({ open }) => (
          <Float portal adaptiveWidth>
            <ListboxButton className="form-input-select">
              <span className="flex items-center w-full min-h-6">
                {items[0]?.group.icon && (
                  <span className="mr-2">{items[0].group.icon}</span>
                )}
                {(labelFn && labelFn(multiple, items)) ||
                  defaultLabelFn(multiple, items)}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions
                className={clsx(
                  'absolute z-10 mt-1 w-full min-w-fit overflow-auto rounded-md bg-white text-base shadow-lg',
                  'ring-1 ring-black/5 focus:outline-hidden sm:text-sm max-h-60 py-1',
                  extraPositionClassName,
                )}
              >
                {Object.keys(groupedItems).map((groupName) => (
                  <Fragment key={groupName}>
                    {groupName !== 'default' && (
                      <div className="px-3 py-1.5 text-gray-500 font-bold flex items-center gap-2">
                        {groupedItems[groupName][0]?.group.icon}
                        <span>{groupName}</span>
                      </div>
                    )}
                    {groupedItems[groupName].map((item) => (
                      <ListboxOption
                        key={item.id}
                        className={clsx(
                          'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                          'data-[selected]:active data-focus:text-white data-focus:bg-blue-600',
                        )}
                        value={item}
                      >
                        {({ selected }) => (
                          <div className="flex items-center gap-1">
                            {multiple && (
                              <Checkbox
                                checked={selected}
                                color={CheckboxColors.Gray}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  chooseItem(item);
                                }}
                              />
                            )}
                            <div
                              className={clsx(
                                selected ? 'font-semibold' : 'font-normal',
                                'ml-1 shrink-0',
                              )}
                            >
                              {item.label}
                            </div>

                            {selected && !multiple && (
                              <div
                                className={clsx(
                                  'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5 shrink-0"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </ListboxOption>
                    ))}
                  </Fragment>
                ))}
              </ListboxOptions>
            </Transition>
          </Float>
        )}
      </Listbox>
    </Field>
  );
}
