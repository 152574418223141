import React from 'react';
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { Td } from './Td';
import { Th } from './Th';
import { TableOptions } from '@tanstack/table-core';
import { SortDownIcon, SortUpDownIcon, SortUpIcon } from '../../icons';
import clsx from 'clsx';

type TableProps<T extends RowData> = {
  columns: ColumnDef<T>[];
  data: T[];
  expandedRowRenderFn?: (data: T, columnLength: number) => React.ReactNode;
  tableClassName?: string;
  getRowClassName?: (row: T) => string; // Propriété pour les classes conditionnelles des lignes
  getCellClassName?: (row: T, columnId: string) => string; // Propriété pour les classes conditionnelles des cellules
  emptyView?: React.ReactNode;
} & Omit<TableOptions<T>, 'data' | 'columns' | 'getCoreRowModel'>;

export enum TableSpecialColumn {
  Actions = 'actions',
}

export const Table = <T,>({
  columns,
  data,
  expandedRowRenderFn,
  tableClassName,
  getRowClassName,
  getCellClassName,
  emptyView,
  ...options
}: TableProps<T>) => {
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    ...options,
  });

  function setSortingIcon(column: Column<any, any>) {
    if (!column.getCanSort()) {
      return;
    }

    return (
      {
        asc: (
          <a href={'#'}>
            <SortUpIcon className="h-3 w-3 text-gray-400" aria-hidden="true" />
          </a>
        ),
        desc: (
          <a href={'#'}>
            <SortDownIcon
              className="h-3 w-3 text-gray-400"
              aria-hidden="true"
            />
          </a>
        ),
        false: (
          <a href={'#'}>
            {column.getIsSorted() as string}
            <SortUpDownIcon
              className="h-3 w-3 text-gray-400"
              aria-hidden="true"
            />
          </a>
        ),
      }[column.getIsSorted() as string] ?? null
    );
  }

  return (
    <table className={clsx(
      'bg-white rounded-2xl',
      tableClassName
    )}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                colSpan={header.colSpan}
                className={header.column.columnDef.meta?.th?.className ?? ''}
              >
                <div
                  className={clsx(
                    'flex items-center justify-between gap-2',
                    header.column.getCanSort()
                      ? 'cursor-pointer select-none'
                      : '',
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === 'asc'
                        ? 'Sort ascending'
                        : header.column.getNextSortingOrder() === 'desc'
                          ? 'Sort descending'
                          : 'Clear sort'
                      : undefined
                  }
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  {setSortingIcon(header.column)}
                </div>
              </Th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {emptyView && table.getRowModel().rows.length == 0 && (
          <tr>
            <td
              colSpan={table.getHeaderGroups().reduce((acc, headerGroup) => {
                return acc + headerGroup.headers.length;
              }, 0)}
            >
              {emptyView}
            </td>
          </tr>
        )}
        {(table.getRowModel().rows.length > 0 || !emptyView) &&
          table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <tr
                className={getRowClassName ? getRowClassName(row.original) : ''}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    className={clsx(
                      cell.column.columnDef.meta?.td?.className ?? '',
                      getCellClassName
                        ? getCellClassName(row.original, cell.column.id)
                        : '',
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </tr>
              {row.getIsExpanded() &&
                expandedRowRenderFn &&
                expandedRowRenderFn(row.original, columns.length)}
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};
