import React, { useEffect } from 'react';
import { useIroDropdownListIroByStakesQuery } from '../../graphql/generated';
import StringDropdown from '../generic/dropdown/StringDropdown';
import { useTranslation } from '@hooks/useTranslation';
import { StringDropdownItem } from '../generic/dropdown/StringDropdown.types';
import { IroIcon } from '../project/iro/IroIcon';
import { useToast } from '../layout/Toast';

export default function IroDropdown({
  currentStakeId,
  setIroIds,
  iroIds,
  selectAllByDefault = false,
  requiresAtLeastOneIro = false,
}: {
  currentStakeId: string | null | undefined;
  iroIds: (string | null)[];
  setIroIds: (item: (string | null)[]) => void;
  selectAllByDefault?: boolean;
  requiresAtLeastOneIro?: boolean;
}) {
  const { t, translateProperty } = useTranslation();
  const toast = useToast();
  if (!currentStakeId) return null;
  const { data, loading } = useIroDropdownListIroByStakesQuery({
    variables: { stakeIds: [currentStakeId] },
    fetchPolicy: 'cache-and-network',
  });
  const irosItem: StringDropdownItem[] =
    data?.listIrosByStakeIds?.map((iro) => ({
      id: iro.id,
      label: translateProperty(iro, 'name'),
      icon: <IroIcon iroTypename={iro.dtype} />,
    })) || [];

  useEffect(() => {
    if (selectAllByDefault && iroIds.length === 0 && irosItem.length > 0) {
      const iroIdsOfStake = irosItem.map((item) => item.id);
      if (
        !(
          iroIds.every((id) => iroIdsOfStake.includes(id)) &&
          iroIds.length === iroIdsOfStake.length
        )
      ) {
        setIroIds(iroIdsOfStake);
      }
    }
  }, [currentStakeId]);

  useEffect(() => {
    if (!loading && irosItem.length > 0) {
      const validSelection = iroIds.every((id) =>
        irosItem.some((item) => item.id === id),
      );
      if (!validSelection) {
        setIroIds([]);
      }
    }
  }, [loading, irosItem, iroIds, setIroIds]);

  return (
    <StringDropdown
      availableItems={irosItem}
      filterable={true}
      multiple={true}
      item={irosItem.filter((item) => iroIds.find((id) => id == item.id)) || []}
      setItem={(item) => {
        if (requiresAtLeastOneIro && item.length === 0) {
          toast.openToastWithError(t('toast:iro.dropdown.oneIroRequired'));
        } else {
          setIroIds(item.map((iro) => iro.id));
        }
      }}
    />
  );
}
