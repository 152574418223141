import React from 'react';
import { AdminLayout } from '../../components/layout/AdminLayout';
import { AdminUsersListScreen } from '../admin/users/list/AdminUserList';
import { AdminUserScreen } from '../admin/users/[id]/AdminUserScreen';
import { AdminCompaniesScreen } from '../admin/company/AdminCompaniesScreen';
import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../AppRoutes';
import { AdminEnterprisesScreen } from '../admin/enterprise/AdminEnterprisesScreen';

export const useAdminRouteConfig = () => {
  const { t } = useTranslation();
  return [
    {
      path: AppRoutes.Admin,
      breadcrumb: t('admin:breadcrumb.admin'),
      element: <AdminLayout />,
      children: [
        {
          index: true,
          element: <AdminCompaniesScreen />,
        },
        {
          path: AppRoutes.AdminUsers,
          breadcrumb: t('admin:breadcrumb.users.list'),
          element: <AdminUsersListScreen />,
        },
        {
          path: AppRoutes.AdminUser,
          breadcrumb: t('admin:breadcrumb.users.form'),
          element: <AdminUserScreen />,
        },
        {
          path: AppRoutes.AdminConsultingCompanies,
          breadcrumb: t('admin:breadcrumb.consulting_companies'),
          element: <AdminCompaniesScreen />,
        },
        {
          path: AppRoutes.AdminEnterprises,
          breadcrumb: t('admin:breadcrumb.enterprises'),
          element: <AdminEnterprisesScreen />,
        },
      ],
    },
  ];
};
