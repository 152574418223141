import {
  DiagnosticStakeFieldsFragment,
  FeatureEnum,
  PermissionEnum,
  ProPrioritizationMatrixScreen_DiagnosticStakeFragment,
} from '../../../../graphql/generated';
import StakePrioritySelect from '../../stakes/StakePrioritySelect';
import { Tooltip } from 'react-tooltip';
import React, { useState } from 'react';
import clsx from 'clsx';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../../../nav/Tabs';
import StakeMaturitySelect from '../../stakes/StakeMaturitySelect';
import PillarDropdown from '../../../form/PillarDropdown';
import { StakeTag } from '../../../stake/StakeTag';
import StakeMaterialityToggle from '../../stakes/StakeMaterialityToggle';
import { ChevronDownIcon } from '../../../icons';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

enum TableColumns {
  InternalStakeholder = 'InternalStakeholder',
  ExternalStakeHolder = 'ExternalStakeHolder',
  Ceo = 'Ceo',
  TotalAverage = 'TotalAverage',
}

const priorityBgColor = 'bg-gray-50';

export const enum VIEW_TABS {
  STAKES = 'STAKES',
  PRIORITY = 'PRIORITY',
}

type Weights = {
  internalStakeholderWeight: number;
  externalStakeholderWeight: number;
  ceoStakeholderWeight: number;
};

type StakeScore = {
  count: number;
  sum: number;
};

export function PrioritizationMatrixEditForm({
  stakes,
}: {
  stakes: ProPrioritizationMatrixScreen_DiagnosticStakeFragment[];
}) {
  const { isGranted } = usePermissionChecker();
  const canManagePrioritizationMatrix = isGranted(
    FeatureEnum.DeliverablePrioritizationMatrix,
    PermissionEnum.Write,
  );
  const tabs: TabItem[] = [
    { key: VIEW_TABS.STAKES, name: 'Importance et maturité' },
    {
      key: VIEW_TABS.PRIORITY,
      name: 'Détail importance selon les parties prenantes',
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  // Sorting
  const [sortTableByColumn, setTableColumnSorting] = useState(
    TableColumns.TotalAverage,
  );

  // Filtering
  const [filterByPillar, setFilterByPillar] = useState<string | null>(null);

  // Hide disabled stakes
  const [areDisabledStakeHidden, setAreDisabledStakeHidden] = useState(false);

  // Weighting
  const [internalStakeholderWeight, setInternalStakeholderWeight] = useState(1);
  const [externalStakeholderWeight, setExternalStakeholderWeight] = useState(1);
  const [ceoStakeholderWeight, setCeoStakeholderWeight] = useState(1);
  const weights = {
    internalStakeholderWeight: internalStakeholderWeight,
    externalStakeholderWeight: externalStakeholderWeight,
    ceoStakeholderWeight: ceoStakeholderWeight,
  };

  const filteredStakes: ProPrioritizationMatrixScreen_DiagnosticStakeFragment[] =
    stakes
      .filter((stake) => {
        if (filterByPillar) {
          return stake.pillar?.id === filterByPillar;
        }
        return true;
      })
      .filter((stake) => {
        if (
          areDisabledStakeHidden &&
          stake.isDisabled !== null &&
          stake.isDisabled !== undefined
        ) {
          return !stake.isDisabled;
        }
        return true;
      })
      .toSorted((a, b) => sortByAverage(a, b, sortTableByColumn, weights)) ||
    [];

  return (
    <>
      <div className="flex items-center justify-between gap-2 mb-4">
        <h2>Définition des enjeux</h2>
      </div>

      <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />

      <div className="flex items-center justify-between gap-2 my-4">
        <div className="flex gap-2 items-center">
          <PillarDropdown
            currentPillarId={filterByPillar}
            setPillarId={setFilterByPillar}
            isFilter={true}
          />
          <HideDisabledStakesButton
            areDisabledStakeHidden={areDisabledStakeHidden}
            setAreDisabledStakeHidden={setAreDisabledStakeHidden}
          />
        </div>
      </div>

      <table className="condensed">
        <thead>
          <tr>
            <th className="bg-white text-center font-bold text-sm">Enjeux</th>
            <th
              colSpan={currentTab.key === VIEW_TABS.PRIORITY ? 5 : 1}
              className={clsx('text-center font-bold text-sm', priorityBgColor)}
            >
              Importance
            </th>
            {currentTab.key !== VIEW_TABS.PRIORITY && (
              <th
                className={clsx(
                  'text-center font-bold text-sm',
                  priorityBgColor,
                )}
              >
                Maturité
              </th>
            )}
          </tr>

          {currentTab.key !== VIEW_TABS.STAKES && (
            <tr>
              <td className="border-l"></td>
              <td
                className={clsx(priorityBgColor)}
                data-tooltip-content="Parties prenantes internes"
                data-tooltip-id="internal-stakeholder-tooltip"
              >
                <Tooltip
                  id="internal-stakeholder-tooltip"
                  className="tooltip"
                />
                <HeaderCell
                  column={TableColumns.InternalStakeholder}
                  label="PPI"
                  sortTableByColumn={sortTableByColumn}
                  setTableColumnSorting={setTableColumnSorting}
                  weight={internalStakeholderWeight}
                  setWeight={setInternalStakeholderWeight}
                />
              </td>
              <td
                className={clsx(priorityBgColor)}
                data-tooltip-content="Parties prenantes externes"
                data-tooltip-id="external-stakeholder-tooltip"
              >
                <Tooltip
                  id="external-stakeholder-tooltip"
                  className="tooltip"
                />
                <HeaderCell
                  column={TableColumns.ExternalStakeHolder}
                  label="PPE"
                  sortTableByColumn={sortTableByColumn}
                  setTableColumnSorting={setTableColumnSorting}
                  weight={externalStakeholderWeight}
                  setWeight={setExternalStakeholderWeight}
                />
              </td>
              <td
                className={clsx(priorityBgColor)}
                data-tooltip-content="Dirigeant.e / Responsable RSE"
                data-tooltip-id="ceo-tooltip"
              >
                <Tooltip id="ceo-tooltip" className="tooltip" />
                <HeaderCell
                  label="CEO"
                  column={TableColumns.Ceo}
                  sortTableByColumn={sortTableByColumn}
                  setTableColumnSorting={setTableColumnSorting}
                  weight={ceoStakeholderWeight}
                  setWeight={setCeoStakeholderWeight}
                />
              </td>
              <td className={clsx('align-top', priorityBgColor)}>
                <div>
                  <HeaderCell
                    column={TableColumns.TotalAverage}
                    label="Moyenne"
                    sortTableByColumn={sortTableByColumn}
                    setTableColumnSorting={setTableColumnSorting}
                  />
                </div>
              </td>

              <td
                className={clsx(
                  priorityBgColor,
                  'border-r text-center text-sm',
                )}
              >
                Niveau d'importance
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {filteredStakes.length > 0 ? (
            filteredStakes.map((diagnosticStake, index) => (
              <StakeRow
                key={diagnosticStake.id}
                stake={diagnosticStake}
                weights={weights}
                sortTableByColumn={sortTableByColumn}
                index={index}
                currentTab={currentTab.key}
                readOnly={!canManagePrioritizationMatrix}
              />
            ))
          ) : (
            <tr>
              <td colSpan={6} className="text-center">
                <div className="text-gray-700 p-8 italic">Aucun enjeu</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

function HeaderCell({
  column,
  label,
  sortTableByColumn,
  setTableColumnSorting,
  weight,
  setWeight,
}: {
  column: TableColumns;
  label: string;
  sortTableByColumn: TableColumns;
  setTableColumnSorting: (sortTableByColumn: TableColumns) => void;
  weight?: number;
  setWeight?: (weight: number) => void;
}) {
  if (sortTableByColumn === column) {
    return (
      <div className="flex flex-col items-center gap-2 font-bold text-xs px-1">
        <div className="flex items-center">
          <div>{label}</div>
          <ChevronDownIcon />
        </div>
        {setWeight && <WeightHandle weight={weight} setWeight={setWeight} />}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-2 items-center text-xs px-1">
        <div
          onClick={() => setTableColumnSorting(column)}
          className="cursor-pointer"
        >
          {label}
        </div>
        {setWeight && <WeightHandle weight={weight} setWeight={setWeight} />}
      </div>
    );
  }
}

function StakeRow({
  stake,
  weights,
  sortTableByColumn,
  index,
  currentTab,
  readOnly = false,
}: {
  stake: ProPrioritizationMatrixScreen_DiagnosticStakeFragment;
  weights: Weights;
  sortTableByColumn: TableColumns;
  index: number;
  currentTab: string;
  readOnly?: boolean;
}) {
  const disabled = stake.isDisabled || false;

  return (
    <tr>
      <td className="w-full">
        <div className="flex items-center gap-2">
          <div className="w-4 text-gray-500">{index + 1}</div>
          <StakeMaterialityToggle stake={stake} />
          <StakeTag stake={stake} />
        </div>
      </td>

      {currentTab === VIEW_TABS.STAKES && (
        <>
          <td className={clsx('w-full', priorityBgColor)}>
            <StakePrioritySelect stake={stake} disabled={disabled || readOnly} />
          </td>
          <td className={clsx('w-full', priorityBgColor)}>
            <StakeMaturitySelect stake={stake} disabled={disabled || readOnly} />
          </td>
        </>
      )}

      {currentTab === VIEW_TABS.PRIORITY && (
        <>
          <ScoreCells
            diagnosticStake={stake}
            isDisabled={disabled}
            sortTableByColumn={sortTableByColumn}
            weights={weights}
          />
          <td className={clsx('w-full', priorityBgColor)}>
            <StakePrioritySelect stake={stake} disabled={disabled} />
          </td>
        </>
      )}
    </tr>
  );
}

function ScoreCells({
  diagnosticStake,
  weights,
  sortTableByColumn,
  isDisabled,
}: {
  diagnosticStake: DiagnosticStakeFieldsFragment;
  weights: Weights;
  sortTableByColumn: TableColumns;
  isDisabled: boolean;
}) {
  return (
    <>
      <td className={clsx('w-full', priorityBgColor)}>
        <Cell
          diagnosticStake={diagnosticStake}
          column={TableColumns.InternalStakeholder}
          isSorted={sortTableByColumn === TableColumns.InternalStakeholder}
          isDisabled={isDisabled}
        />
      </td>
      <td className={clsx('w-full', priorityBgColor)}>
        <Cell
          diagnosticStake={diagnosticStake}
          column={TableColumns.ExternalStakeHolder}
          isSorted={sortTableByColumn === TableColumns.ExternalStakeHolder}
          isDisabled={isDisabled}
        />
      </td>
      <td className={clsx('w-full', priorityBgColor)}>
        <Cell
          diagnosticStake={diagnosticStake}
          column={TableColumns.Ceo}
          isSorted={sortTableByColumn === TableColumns.Ceo}
          isDisabled={isDisabled}
        />
      </td>
      <td className={clsx('w-full', priorityBgColor)}>
        <WeightedAverageCell
          diagnosticStake={diagnosticStake}
          weights={weights}
          isSorted={sortTableByColumn === TableColumns.TotalAverage}
          isDisabled={isDisabled}
        />
      </td>
    </>
  );
}

function WeightHandle({
  weight = 1,
  setWeight,
}: {
  weight?: number;
  setWeight: (weight: number) => void;
}) {
  return (
    <div className="flex items-center gap-0.5">
      <div
        className={clsx(
          'rounded-full border border-gray-900 w-2 h-2 hover:bg-gray-500 cursor-pointer',
          weight >= 1 && 'bg-gray-900',
        )}
        onClick={() => {
          if (weight === 1) {
            setWeight(0);
          } else {
            setWeight(1);
          }
        }}
      ></div>
      <div
        className={clsx(
          'rounded-full border border-gray-900 w-2 h-2 hover:bg-gray-500 cursor-pointer',
          weight >= 2 && 'bg-gray-900',
        )}
        onClick={() => setWeight(2)}
      ></div>
      <div
        className={clsx(
          'rounded-full border border-gray-900 w-2 h-2 hover:bg-gray-500 cursor-pointer',
          weight >= 3 && 'bg-gray-900',
        )}
        onClick={() => setWeight(3)}
      ></div>
    </div>
  );
}

function Cell({
  diagnosticStake,
  column,
  isSorted,
  isDisabled,
}: {
  diagnosticStake: DiagnosticStakeFieldsFragment;
  column: TableColumns;
  isSorted: boolean;
  isDisabled: boolean;
}) {
  const diagnosticStakeColumnScore = getCountsAndSumsPerColumn(
    diagnosticStake,
    column,
  );
  return (
    <div
      className={clsx(
        'text-center text-sm',
        isSorted && 'font-bold',
        isDisabled && 'text-gray-300',
      )}
    >
      {getRoundedAverage(
        diagnosticStakeColumnScore.count,
        diagnosticStakeColumnScore.sum,
      )}
    </div>
  );
}

function WeightedAverageCell({
  diagnosticStake,
  weights,
  isSorted,
  isDisabled,
}: {
  diagnosticStake: DiagnosticStakeFieldsFragment;
  weights: Weights;
  isSorted: boolean;
  isDisabled: boolean;
}) {
  const diagnosticStakeTotalScore = getWeightedAverage(
    diagnosticStake,
    weights,
  );
  return (
    <div
      className={clsx(
        'text-center text-sm',
        isSorted && 'font-bold',
        isDisabled && 'text-gray-300',
      )}
    >
      {getRoundedAverage(
        diagnosticStakeTotalScore.count,
        diagnosticStakeTotalScore.sum,
      )}
    </div>
  );
}

function sortByAverage(
  diagnosticStake1: DiagnosticStakeFieldsFragment,
  diagnosticStake2: DiagnosticStakeFieldsFragment,
  sortTableByColumn: TableColumns,
  weights: Weights,
): number {
  if (sortTableByColumn === TableColumns.TotalAverage) {
    const diagnosticStake1Score = getWeightedAverage(diagnosticStake1, weights);
    const avg1 = getRoundedAverage(
      diagnosticStake1Score.count,
      diagnosticStake1Score.sum,
    );
    const diagnosticStake2Score = getWeightedAverage(diagnosticStake2, weights);
    const avg2 = getRoundedAverage(
      diagnosticStake2Score.count,
      diagnosticStake2Score.sum,
    );
    return compareScores(avg1, avg2);
  } else {
    const diagnosticStake1Score = getCountsAndSumsPerColumn(
      diagnosticStake1,
      sortTableByColumn,
    );
    const avg1 = getRoundedAverage(
      diagnosticStake1Score.count,
      diagnosticStake1Score.sum,
    );
    const diagnosticStake2Score = getCountsAndSumsPerColumn(
      diagnosticStake2,
      sortTableByColumn,
    );
    const avg2 = getRoundedAverage(
      diagnosticStake2Score.count,
      diagnosticStake2Score.sum,
    );
    return compareScores(avg1, avg2);
  }
}

function compareScores(avg1: number, avg2: number) {
  if (avg2 > avg1) return 1;
  if (avg2 < avg1) return -1;
  return 0;
}

function getCountsAndSumsPerColumn(
  diagnosticStake: DiagnosticStakeFieldsFragment,
  column: TableColumns,
): StakeScore {
  const scores = diagnosticStake.stakePriorityScore;
  if (scores) {
    switch (column) {
      case TableColumns.InternalStakeholder:
        return {
          count: scores.internalCount || 0,
          sum: scores.internalSum || 0,
        };
      case TableColumns.ExternalStakeHolder:
        return {
          count: scores.externalCount || 0,
          sum: scores.externalSum || 0,
        };
      case TableColumns.Ceo:
        return {
          count: scores.ceoCount || 0,
          sum: scores.ceoSum || 0,
        };
    }
  }
  return {
    count: 0,
    sum: 0,
  };
}

function HideDisabledStakesButton({
  areDisabledStakeHidden,
  setAreDisabledStakeHidden,
}: {
  areDisabledStakeHidden: boolean;
  setAreDisabledStakeHidden: (areDisabledStakeHidden: boolean) => void;
}) {
  return (
    <div className="font-bold text-sm p-2 flex items-center gap-2 text-gray-900 cursor-pointer hover:bg-gray-50">
      <input
        type="checkbox"
        checked={areDisabledStakeHidden}
        onChange={() => setAreDisabledStakeHidden(!areDisabledStakeHidden)}
        className="border-gray-300 rounded-xs checked:bg-gray-900"
      />
      <span onClick={() => setAreDisabledStakeHidden(!areDisabledStakeHidden)}>
        Cacher les enjeux non matériels
      </span>
    </div>
  );
}

function getWeightedAverage(
  diagnosticStake: DiagnosticStakeFieldsFragment,
  weights: Weights,
): StakeScore {
  const scores = diagnosticStake.stakePriorityScore;

  if (scores) {
    const count =
      (scores.internalCount || 0) * weights.internalStakeholderWeight +
      (scores.externalCount || 0) * weights.externalStakeholderWeight +
      (scores.ceoCount || 0) * weights.ceoStakeholderWeight;
    const sum =
      (scores.internalSum || 0) * weights.internalStakeholderWeight +
      (scores.externalSum || 0) * weights.externalStakeholderWeight +
      (scores.ceoSum || 0) * weights.ceoStakeholderWeight;
    return {
      count,
      sum,
    };
  }
  return {
    count: 0,
    sum: 0,
  };
}

function getRoundedAverage(counts: number, sums: number): number {
  const numerator = sums;
  const denominator = counts;
  if (denominator && denominator > 0) {
    if (numerator) {
      return Math.round(100 * (numerator / denominator)) / 100;
    }
  }
  return 0;
}
