import React from 'react';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import { IRO_TABS, useIroTabs } from '../useIroTabs';
import { IroHeader } from '../IroHeader';
import { IroList as IroListContent } from '../../../../components/project/iro/list/IroList';
import {
  FeatureEnum,
  PermissionEnum,
  useIroListScreenQuery,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

function IroList() {
  const iroTabs = useIroTabs();
  const { isGranted } = usePermissionChecker();
  const readOnly = !isGranted(FeatureEnum.IroCotation, PermissionEnum.Write);

  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const { data, loading, error } = useIroListScreenQuery({
    variables: {
      referentialId: projectContext?.enterprise?.referential?.id || '',
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (error) {
    return <MessageBox type={MessageBoxType.Error}>{error.message}</MessageBox>;
  }

  if (projectContext?.enterprise) {
    return (
      <>
        <PageTitle title={t('screen.iro.iro_list')} />
        <Breadcrumb />

        <TabNavigationLayout<IRO_TABS>
          tabs={iroTabs}
          activeTab={IRO_TABS.BY_IRO}
          renderTabs={(tabsContent) => (
            <div>
              <IroHeader />
              {tabsContent}
            </div>
          )}
        >
          <div className="w-full p-8">
            <IroListContent
              iros={data?.listIrosByReferentialId || []}
              company={projectContext?.enterprise}
              readOnly={readOnly}
            />
          </div>
        </TabNavigationLayout>
      </>
    );
  }

  return null;
}

export const IroListScreen = withProjectContext(IroList, {
  requiredCompany: true,
});
