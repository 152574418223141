import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { SurveySubmitAnswerInput } from '../../../graphql/generated';

interface SurveyResponseContextProps {
  answers: { [key: string]: SurveySubmitAnswerInput };
  setAnswer: (
    questionId: string,
    answer: SurveySubmitAnswerInput | null,
  ) => void;
  setAnswers: Dispatch<
    SetStateAction<{ [key: string]: SurveySubmitAnswerInput }>
  >;
  setIsAnswerValid: (isValid: boolean) => void;
  isAnswerValid: boolean;
}

const SurveyResponseContext = createContext<SurveyResponseContextProps | undefined>(undefined);

export const SurveyResponseProvider: React.FC<{ children: React.ReactNode, surveyId: string; }> = ({ children, surveyId }) => {
  const [answers, setAnswers] = useState<{ [key: string]: SurveySubmitAnswerInput }>({});
  const [isAnswerValid, setIsAnswerValid] = useState(false);

  const setAnswer = useCallback((questionId: string, answer: SurveySubmitAnswerInput | null) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers };
      if (answer === null) {
        delete newAnswers[questionId];
      } else {
        newAnswers[questionId] = answer;
      }
      return newAnswers;
    });
  }, [surveyId]);

  return (
    <SurveyResponseContext.Provider value={{ answers, setAnswer, setAnswers, setIsAnswerValid, isAnswerValid }}>
      {children}
    </SurveyResponseContext.Provider>
  );
};

export const useSurveyResponse = () => {
  const context = useContext(SurveyResponseContext);
  if (!context) {
    throw new Error('useSurveyResponse must be used within a SurveyResponseProvider');
  }
  return context;
};
