import React from 'react';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { PageTitle } from '../../../components/nav/PageTitle';
import { Referential } from '../../../components/project/stakes/Referential';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { withProjectContext } from '../../../providers/withProjectContext';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { Hero } from '../../../components/generic/Hero';
import { useTranslation } from '@hooks/useTranslation';
import {
  ChooseProjectTemplateModal,
  chooseProjectTemplateModalConfig,
} from '../../../components/project/project/ChooseProjectTemplate/ChooseProjectTemplateModal';
import { useModal } from '../../../components/layout/Modal';
import { PlusIcon } from '../../../components/icons';
import resultIllusatration from '../../../assets/images/illustrations/result.png';
import { IllustratedExplanationMessage } from '../../../components/generic/IllustratedExplanationMessage';
import { FeatureEnum, PermissionEnum } from '../../../graphql/generated';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export const ReferentialScreen = withProjectContext(
  () => {
    const projectContext = useProjectContext();
    const { t } = useTranslation();
    const modal = useModal();
    const { isGranted } = usePermissionChecker();
    const readOnly = isGranted(
      FeatureEnum.ReferentialManagement,
      PermissionEnum.Read,
      true,
    );
    const openReferentialCreateModal = () => {
      if (projectContext?.enterprise?.id) {
        modal.openModal(
          <ChooseProjectTemplateModal
            applyTemplateToCompanyId={projectContext.enterprise.id}
          />,
          {
            ...chooseProjectTemplateModalConfig,
          },
        );
      }
    };

    return (
      <>
        <PageTitle title="Enjeux" />
        <Breadcrumb />
        {projectContext?.enterprise?.referential && (
          <Hero
            title={t('referential.header.title')}
            subtitle={t('referential.header.description')}
          />
        )}
        <div className="p-8 w-full">
          {(projectContext?.isLoadingEnterprise && <LoaderFullscreen />) ||
            (projectContext?.enterprise?.referential && (
              <Referential
                referentialId={projectContext.enterprise.referential?.id}
                readonly={readOnly}
              />
            )) || (
              <>
                <IllustratedExplanationMessage
                  illustration={<img src={resultIllusatration} alt="" />}
                  title={t('referential.emptyState.title')}
                  description={t('referential.emptyState.description')}
                >
                  <button
                    className="primary purple"
                    onClick={openReferentialCreateModal}
                  >
                    <PlusIcon className="w-4 h-4" />
                    {t('referential.emptyState.cta')}
                  </button>
                </IllustratedExplanationMessage>
              </>
            )}
        </div>
      </>
    );
  },
  { requiredCompany: true },
);
