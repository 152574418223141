import React, { useState } from 'react';
import {
  CompanySector,
  CurrentUserDocument,
  useConsultingCompanyMaxProjectsCountProgressBarQuery,
  useRegisterNewProjectModalMutation,
} from '../../graphql/generated';
import { Loader } from '../generic/Loader';
import { ModalConfig, useModal } from '../layout/Modal';
import { useToast } from '../layout/Toast';
import { MessageBox, MessageBoxType } from '../layout/MessageBox';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { CompanySectorDropdown } from './project/CompanySectorDropdown';
import {
  ChooseProjectTemplateModal,
  chooseProjectTemplateModalConfig,
} from './project/ChooseProjectTemplate/ChooseProjectTemplateModal';
import { LogoUploadForm } from '../generic/LogoUploadForm';
import { NumberInput } from '../generic/form/NumberEditor';

export const registerNewProjectModalConfig: ModalConfig = {
  title: undefined,
  isFullscreen: false,
  displayTitleBar: true,
};

export function RegisterNewProjectModal() {
  const { currentUser } = useCurrentUser();
  const toast = useToast();
  const modal = useModal();
  const projectContext = useProjectContext();

  const [companyName, setCompanyName] = useState('');
  const [logo, setLogo] = useState<string | null | undefined>(null);
  const [sector, setSector] = useState<CompanySector | null | undefined>(
    undefined,
  );
  const [size, setSize] = useState<number | null | undefined>(undefined);
  const [annualTurnover, setAnnualTurnover] = useState('');

  const [createEnterprise] = useRegisterNewProjectModalMutation({
    refetchQueries: () => [CurrentUserDocument],
  });

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const companyLicenseUsage =
    useConsultingCompanyMaxProjectsCountProgressBarQuery({
      variables: { companyId: currentUser?.company?.id || '' },
      skip: !currentUser?.company?.id,
      fetchPolicy: 'network-only',
    });

  const license = companyLicenseUsage.data?.consultingCompany.license;
  const maxProjectsCount = license?.licensesCount || null;
  const projectsCount =
    companyLicenseUsage.data?.consultingCompany.projectsCount || 0;
  const isUnlimited = maxProjectsCount === 0 || maxProjectsCount === null;
  const isMaxProjectsReached =
    (maxProjectsCount && projectsCount >= maxProjectsCount && !isUnlimited) ||
    false;

  const create = () => {
    setIsCreating(true);
    createEnterprise({
      variables: {
        input: {
          company: {
            name: companyName,
            logo,
            metadata: {
              sector,
              size,
              annualTurnover,
              customQuestionTypes:
                currentUser?.company?.metadata?.customQuestionTypes || [],
            },
          },
          ownedBy: {
            id: currentUser?.company?.id || '',
          },
        },
      },
    })
      .then((result) => {
        const createdEnterpriseId = result.data?.createEnterprise?.id;
        if (createdEnterpriseId) {
          toast.openToastWithMessage('Projet créé');
          projectContext?.updateEnterprise(createdEnterpriseId, true);
          modal.openModal(
            <ChooseProjectTemplateModal
              applyTemplateToCompanyId={createdEnterpriseId}
            />,
            {
              ...chooseProjectTemplateModalConfig,
            },
          );
        } else {
          setError('Erreur lors de la création du projet');
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <div className="flex flex-col gap-4 max-w-lg">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <label htmlFor="companyName" className="form-input-label">
            Nom de l'entreprise
          </label>
          <input
            type="text"
            id="companyName"
            className="form-input-text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="logo" className="form-input-label">
            Logo de l'entreprise
          </label>

          <div className="flex gap-4 items-start">
            <div className="shrink-0 w-24 h-24 border border-gray-100 rounded-xl flex items-center justify-center p-2">
              {logo ? (
                <img src={logo} className="rounded-xl w-56" alt="logo" />
              ) : (
                <div className="italic text-sm text-gray-500">Aucun logo</div>
              )}
            </div>

            <LogoUploadForm logo={logo} setLogo={setLogo} />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="sector" className="form-input-label">
              Effectif
            </label>
            <NumberInput value={size} setValue={setSize} />
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="annualTurnOver" className="form-input-label">
              Chiffre d'affaires annuel
            </label>
            <input
              type="text"
              name="annualTurnOver"
              id="annualTurnOver"
              className="form-input-text"
              placeholder={'250k€ - 5M€'}
              value={annualTurnover}
              onChange={(event) => setAnnualTurnover(event.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="sector" className="form-input-label">
            Secteur d'activité
          </label>
          <CompanySectorDropdown sector={sector} setSector={setSector} />
        </div>
      </div>

      {error && <MessageBox type={MessageBoxType.Error}>{error}</MessageBox>}
      {isMaxProjectsReached && (
        <MessageBox type={MessageBoxType.Warning}>
          Vous avez atteint la limite de nombre de projets pour votre compte.
        </MessageBox>
      )}

      <div className="flex gap-2 justify-between pt-4">
        <button
          className="secondary"
          onClick={() => modal.closeModal()}
          disabled={isCreating}
        >
          {isCreating ? <Loader /> : 'Annuler'}
        </button>
        <button
          className="primary purple"
          onClick={create}
          disabled={isCreating || !companyName || isMaxProjectsReached}
        >
          {isCreating ? <Loader /> : 'Créer'}
        </button>
      </div>
    </div>
  );
}
