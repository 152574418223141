import { FetchResult } from '@apollo/client';
import {
  CreateIndicatorValueNarrativeInput,
  CreateIndicatorValueNumberInput,
  IndicatorItem_IndicatorFragment,
  IndicatorValueForm_LatestIndicatorValuesDocument,
  IndicatorValueType,
  useNarrativeIndicatorValueFormMutation,
  useNumberIndicatorValueFormMutation,
} from '../../../../../../../../graphql/generated';
import { NumberIndicatorValueFormMutationTypes } from './number/NumberIndicatorValueForm';
import { NarrativeIndicatorValueFormMutationTypes } from './narrative/NarrativeIndicatorValueForm';

type IndicatorTypeToMutationMap = {
  [IndicatorValueType.NumberIndicatorValue]: NumberIndicatorValueFormMutationTypes;
  [IndicatorValueType.NarrativeIndicatorValue]: NarrativeIndicatorValueFormMutationTypes;
};

type IndicatorTypeToItem<T extends IndicatorValueType> =
  IndicatorTypeToMutationMap[T]['item'];

type IndicatorTypeToMutation<T extends IndicatorValueType> =
  IndicatorTypeToMutationMap[T]['mutation'];

export const useIndicatorValueTypeMutation = <
  T extends IndicatorValueType,
>(
  indicator: IndicatorItem_IndicatorFragment & { type: T },
  organizationalUnitId?: string
): {
  mutation: (
    values: IndicatorTypeToItem<T>[],
  ) => Promise<FetchResult<IndicatorTypeToMutation<T>>>;
  loading: boolean;
} => {
  let mutation:
    | ((values: IndicatorTypeToItem<T>[]) => Promise<FetchResult<IndicatorTypeToMutation<T>>>)
    | undefined;
  let loading: boolean | undefined;
  const refetchQueries= [
    {
      query: IndicatorValueForm_LatestIndicatorValuesDocument,
      variables: {
        indicatorId: indicator.id,
        organizationalUnitId,
      },
    },
  ]
  switch (indicator.type) {
    case IndicatorValueType.NumberIndicatorValue: {
      const [numberMutation, { loading: numberLoading }] = useNumberIndicatorValueFormMutation();
      mutation = ((values: CreateIndicatorValueNumberInput[]) =>
        numberMutation({
          variables: {
            input: {
              numberIndicatorValues: values,
            },
          },
          refetchQueries

        })) as typeof mutation;
      loading = numberLoading;
      break;
    }
    case IndicatorValueType.NarrativeIndicatorValue: {
      const [narrativeMutation, { loading: narrativeLoading }] = useNarrativeIndicatorValueFormMutation();
      mutation = ((values: CreateIndicatorValueNarrativeInput[]) =>
        narrativeMutation({
          variables: {
            input: {
              narrativeIndicatorValues: values,
            },
          },
          refetchQueries
        })) as typeof mutation;
      loading = narrativeLoading;
      break;
    }
    default:
      throw new Error(`The ${indicator.type} type is not managed yet`);
  }

  if (!mutation || loading === undefined) {
    throw new Error(`The ${indicator.type} type is not managed yet`);
  }

  return { mutation, loading };
};
