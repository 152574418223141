import { CriteriaValues } from '../../../../../../types/iro.types';
import { InnerHtml } from '../../../../../generic/InnerHtml';
import { definitionByCriteria } from '../definitionByCriteria';
import React from 'react';

export const IroAssessmentOptionRadioTooltip = <
  T extends CriteriaValues,
  U extends { value: T; definition?: string | null },
>({
  title,
  definitions,
  value,
}: {
  title: string;
  definitions: U[] | null | undefined;
  value: T;
}) => {
  return (
    <div className="flex flex-col">
      <div className={'font-bold'}>{title}</div>
      {
        <InnerHtml
          html={
            definitionByCriteria<T, U>(definitions, value)?.definition || ''
          }
        />
      }
    </div>
  );
};
