import {
  IroType,
  QuestionIroAssessment_IroFragment,
  ScaleValue,
  ScaleValueDefinition,
} from '../../../../../graphql/generated';
import React from 'react';
import { IroAssessmentOptionRadio } from './radioButton/IroAssessmentOptionRadio';
import { IroAssessmentButtonGroup } from './IroAssessmentButtonGroup';
import { useTranslation } from '@hooks/useTranslation';
import { CriteriaType } from '../../../../../types/iro.types';
import { IroAssessmentOptionRadioTooltip } from './radioButton/IroAssessmentOptionRadioTooltip';
import { IroAssessmentFormValues } from '../IroAssessmentForm.types';
import { useFormContext } from 'react-hook-form';

export const IroAssessmentScaleButtonGroup = ({
  iro,
  scaleValuesDefinitions,
}: {
  iro: QuestionIroAssessment_IroFragment;
  scaleValuesDefinitions: ScaleValueDefinition[];
}) => {
  const { t } = useTranslation();
  const name = `iroAssessments.${iro.id}.scaleCriteria.value` as const;
  const { watch } = useFormContext<IroAssessmentFormValues>();
  const value = watch(name);

  return (
    <IroAssessmentButtonGroup
      criteria={CriteriaType.SCALE}
      label={t('iro.criteria.scale.title')}
      iroType={iro.__typename as IroType}
    >
      <div className="w-56 flex items-center justify-evenly gap-2">
        <IroAssessmentOptionRadio<ScaleValue>
          {...{ name, value }}
          level={ScaleValue.Low}
          placeholder={'1'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScaleValue, ScaleValueDefinition>
              title={t('enum:iroCriteria.scale.Low')}
              definitions={scaleValuesDefinitions}
              value={ScaleValue.Low}
            />
          }
        />
        <IroAssessmentOptionRadio<ScaleValue>
          {...{ name, value }}
          level={ScaleValue.Medium}
          placeholder={'2'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScaleValue, ScaleValueDefinition>
              title={t('enum:iroCriteria.scale.Medium')}
              definitions={scaleValuesDefinitions}
              value={ScaleValue.Medium}
            />
          }
        />
        <IroAssessmentOptionRadio<ScaleValue>
          {...{ name, value }}
          level={ScaleValue.High}
          placeholder={'3'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScaleValue, ScaleValueDefinition>
              title={t('enum:iroCriteria.scale.High')}
              definitions={scaleValuesDefinitions}
              value={ScaleValue.High}
            />
          }
        />
        <IroAssessmentOptionRadio<ScaleValue>
          {...{ name, value }}
          level={ScaleValue.VeryHigh}
          placeholder={'4'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScaleValue, ScaleValueDefinition>
              title={t('enum:iroCriteria.scale.VeryHigh')}
              definitions={scaleValuesDefinitions}
              value={ScaleValue.VeryHigh}
            />
          }
        />
      </div>
    </IroAssessmentButtonGroup>
  );
};
