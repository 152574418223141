import React, { ReactNode } from 'react';
import {
  ActionFieldsFragment,
  Resource,
  useActionQuery,
} from '../../../graphql/generated';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { PageTitle } from '../../nav/PageTitle';
import { ComplexityBadge } from '../../badges/ComplexityBadge';
import { PriceBadge } from '../../badges/PriceBadge';
import { InnerHtml } from '../../generic/InnerHtml';
import { StakeTag } from '../../stake/StakeTag';
import { LoaderFullscreen } from '../../layout/Loader';
import { ChecklistPreview } from '../../task/ChecklistPreview';
import { CheckCircleIcon, LinkIcon, MetricIcon } from '../../icons';

export function ActionPreview({
  actionSummary,
}: {
  actionSummary: ActionFieldsFragment;
}) {
  const actionDocumentQuery = useActionQuery({
    variables: {
      id: actionSummary.id || '',
    },
    fetchPolicy: 'network-only',
  });

  if (actionDocumentQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (actionDocumentQuery.data?.action) {
    const action = actionDocumentQuery.data?.action;
    return (
      <>
        <PageTitle title={`${action.title}`} />
        <div className="flex grow">
          {/* Left column */}
          <div className="grow">
            <div
              className="flex-none bg-cover bg-center h-60"
              style={{
                backgroundImage: action.illustration
                  ? `url(${action.illustration})`
                  : `url(/actions/riccardo-annandale-7e2pe9wjL9M-unsplash.jpg)`,
              }}
            >
              <div className="flex flex-col place-content-end h-full bg-linear-to-b from-gray-900/[.2] to-gray-900/[.5]"></div>
            </div>
            <div className="2xl:max-w-5xl 2xl:mx-auto p-8 space-y-6">
              <div className="flex items-start gap-2">
                <ThemeIcon
                  theme={action.theme}
                  className="bg-gray-900 text-white rounded-lg p-1 w-8 h-8"
                />
                <h1 className="title-h2">{action.title}</h1>
              </div>
              {action.contextualData &&
                (action.contextualData.title ||
                  action.contextualData.description) && (
                  <div className="rounded-xl bg-blue-50 p-8 flex items-center gap-8">
                    <div className="font-title font-extrabold text-5xl shrink-0">
                      {action.contextualData.title}
                    </div>
                    <div className="text-gray-500">
                      {action.contextualData.description}
                    </div>
                  </div>
                )}
              <div className="flex flex-col gap-2">
                {action.description && <InnerHtml html={action.description} />}
              </div>
              {action.solutions && action.solutions.length > 0 && (
                <Section title="Suggestions">
                  <div className="flex flex-col gap-4">
                    {action.solutions.map((solution) => (
                      <div className="card gap-2">
                        <h6>{solution.title}</h6>
                        {solution.description && (
                          <p className="text-gray-500">
                            {solution.description}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </Section>
              )}
              {action?.checklist && (
                <ChecklistPreview checklistId={action.checklist.id} />
              )}
              {action.diagnosticStakes &&
                action.diagnosticStakes.length > 0 && (
                  <div className="border-t border-gray-100">
                    <Section title="Enjeux liés">
                      <div className="flex gap-2 flex-wrap">
                        {action.diagnosticStakes.map((diagnosticStake) => (
                          <StakeTag
                            stake={diagnosticStake}
                            key={diagnosticStake.id}
                            disableDetailModalOpening={true}
                          />
                        ))}
                      </div>
                    </Section>
                  </div>
                )}
            </div>
          </div>

          {/* Right column */}
          <div className="max-w-xl shrink-0 px-6 bg-yellow-50 divide-y divide-yellow-300 h-full grow">
            <AboutSidebarSection action={action} />
            {action.benefits && action.benefits?.length > 0 && (
              <ActionBenefitsSection action={action} />
            )}
            {action.metrics && action.metrics?.length > 0 ? (
              <ActionMetricSection action={action} />
            ) : (
              ''
            )}
            {action.resources && action.resources.length > 0 && (
              <Section title="Ressources">
                <ActionResources resources={action.resources} />
              </Section>
            )}
          </div>
        </div>
      </>
    );
  }

  return null;
}

function Section({ title, children }: { title?: string; children: ReactNode }) {
  return (
    <section className="py-6">
      {title && <h5 className="mb-2">{title}</h5>}
      {children}
    </section>
  );
}

function AboutSidebarSection({ action }: { action: ActionFieldsFragment }) {
  return (
    <Section title="A propos">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row bg-white shadow-sm p-3 rounded-lg justify-between items-center">
          <div className="font-bold text-sm">Complexité</div>
          <ComplexityBadge complexityLevel={action.complexityLevel} />
        </div>
        <div className="flex flex-row bg-white shadow-sm p-3 rounded-lg justify-between items-center">
          <div className="font-bold text-sm">Coût</div>
          <PriceBadge priceLevel={action.priceLevel} />
        </div>
      </div>
    </Section>
  );
}

function ActionMetricSection({ action }: { action: ActionFieldsFragment }) {
  return (
    <Section title="Métrique à suivre">
      <div className="flex flex-col gap-2">
        {action.metrics &&
          action.metrics.map((metric) => (
            <div className="flex gap-4 bg-white shadow-sm p-3 rounded-lg justify-start items-center">
              <MetricIcon />
              <div>
                <p className="font-bold text-sm text-gray-900">
                  {metric.title}
                </p>
                {metric.description && (
                  <p className="text-sm text-gray-500">{metric.description}</p>
                )}
                {metric.quote && (
                  <p className="text-sm text-gray-500">"{metric.quote}"</p>
                )}
              </div>
            </div>
          ))}
      </div>
    </Section>
  );
}

function ActionBenefitsSection({ action }: { action: ActionFieldsFragment }) {
  return (
    <Section title="Bénéfices">
      <ul className="flex flex-col gap-2 mt-4">
        {action.benefits?.map((benefit) => (
          <li className="flex gap-2 items-start text-sm" key={benefit}>
            <CheckCircleIcon className="h-6 w-6 shrink-0" />
            {benefit}
          </li>
        ))}
      </ul>
    </Section>
  );
}

function ActionResources({ resources }: { resources: Array<Resource> }) {
  return (
    <ul className="space-y-1 text-sm font-bold">
      {resources.map((resource) => (
        <li className="flex gap-1 items-center" key={resource.url}>
          {resource.url ? (
            <>
              <LinkIcon className="shrink-0" />
              <a
                href={resource.url}
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                {resource.title}
              </a>
            </>
          ) : (
            <p>{resource.title}</p>
          )}
        </li>
      ))}
    </ul>
  );
}
