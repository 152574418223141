import React from 'react';
import { CompanyLogo } from '../CompanyLogo';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  SingleBuildingIcon,
  UserGroupIcon,
} from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { OrganizationalUnitCard_OrganizationalUnitFragment } from '../../../../graphql/generated';
import { UserProfileInitials } from '../../../user/UserProfileInitials';

export function OrganizationalUnitCard({
  showExpandButton,
  organizationalUnit,
  children,
  expandedState,
  setExpandedState,
}: {
  showExpandButton?: boolean;
  organizationalUnit:
    | OrganizationalUnitCard_OrganizationalUnitFragment
    | null
    | undefined;
  children: React.ReactNode;
  expandedState?: string[] | undefined;
  setExpandedState?: (
    value: ((prevState: string[]) => string[]) | string[],
  ) => void;
}) {
  const MAX_AVATARS = 3;

  function toggleEnterpriseIdToExpanded(id: string) {
    if (expandedState?.includes(id)) {
      setExpandedState?.(expandedState.filter((item) => item !== id));
    } else {
      setExpandedState?.([...(expandedState || []), id]);
    }
  }

  return (
    organizationalUnit && (
      <div className="w-full bg-white card px-4 py-4 space-y-4 min-w-96 ">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {showExpandButton &&
              organizationalUnit.subsidiaries &&
              organizationalUnit.subsidiaries.length > 0 && (
                <button
                  className="tertiary"
                  onClick={() =>
                    toggleEnterpriseIdToExpanded(organizationalUnit?.id)
                  }
                >
                  {!expandedState?.find(
                    (id) => id === organizationalUnit.id,
                  ) && <ChevronDownIcon className="w-4 h-4" />}
                  {expandedState?.find(
                    (id) => id === organizationalUnit.id,
                  ) && <ChevronUpIcon className="w-4 h-4" />}
                </button>
              )}

            <CompanyLogo
              companyId={organizationalUnit.id}
              size={'medium'}
              fallbackBgColor="bg-yellow-100"
            />
            <div className="ml-3 space-y-2">
              <span className="font-bold">{organizationalUnit.name}</span>
              <OrganizationalUnitMetadata
                organizationalUnit={organizationalUnit}
              />
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div className="flex items-center">
              {organizationalUnit.userAccesses
                ?.slice(0, MAX_AVATARS)
                .map((userAccess) => (
                  <div className="-ml-2 border border-white rounded-full">
                    <UserProfileInitials user={userAccess.user} size={'mini'} />
                  </div>
                ))}
              {organizationalUnit.userAccesses &&
                organizationalUnit.userAccesses?.length > MAX_AVATARS && (
                  <div className="-ml-2 rounded-full bg-gray-900 w-8 h-8 flex items-center justify-center text-white font-title font-extrabold text-base ">
                    +{organizationalUnit.userAccesses?.length - MAX_AVATARS}
                  </div>
                )}
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  );
}

export function OrganizationalUnitMetadata({
  organizationalUnit,
}: {
  organizationalUnit: OrganizationalUnitCard_OrganizationalUnitFragment;
}) {
  const { t } = useTranslation();
  const hasMetadata = organizationalUnit?.metadata?.size || organizationalUnit?.structureType;

  if (!hasMetadata) {
    return null;
  }

  return (
    <div className="flex gap-4">
      {organizationalUnit.structureType && (
        <div className="text-gray-500 font-light flex gap-1">
          <SingleBuildingIcon />
          <span>
            {t(
              `company.organizationalUnit.structureType.${organizationalUnit.structureType}`,
            )}
          </span>
        </div>
      )}
      {organizationalUnit.metadata?.size && (
        <div className="text-gray-500 font-light flex gap-1">
          <UserGroupIcon />
          <span>
            {t(`company.organizationalUnit.size`, {
              count: organizationalUnit.metadata?.size,
            })}
          </span>
        </div>
      )}
    </div>
  );
}
