import { CompanyFieldsFragment } from 'graphql/generated';
import React from 'react';
import { UserAccess } from './UserAccess/UserAccess';

export function Users({ enterprise }: { enterprise: CompanyFieldsFragment }) {
  return (
    <div className="space-y-8 divide-y divide-gray-100">
      {!enterprise.isTemplate && <UserAccess company={enterprise} />}
    </div>
  );
}
