import React, { useRef } from 'react';
import { Radar } from 'react-chartjs-2';
import plugin from 'chartjs-plugin-datalabels';
import { PillarIcon } from '../../../stake/PillarIcon';
import {
  MaturityLevel,
  MaturityScreen_DiagnosticStakeFragment,
  ThemeColor,
} from '../../../../graphql/generated';
import {
  getLevelFromValue,
  ProgressBarLevel,
  ProgressBarWithScale,
} from '../../../generic/ProgressBarWithScale';
import { readCssVar } from '../../../../services/TailwindService';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { ChartOptions } from 'chart.js';
import { wrapLongLabel } from '../../../../services/ChartJsService';
import { ExportChartJsToImageButton } from '../../../generic/ExportChartJsToImageButton';
import Chart from 'chart.js/auto';
import { DownloadIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function MaturityRadarChart({
  stakes,
  filterByPillar,
  setPillarId,
}: {
  stakes: MaturityScreen_DiagnosticStakeFragment[];
  filterByPillar?: string | null;
  setPillarId?: (pillarId: string | null) => void;
}) {
  const filteredStakes = stakes.filter((stake) =>
    filterByPillar ? stake.pillar.id === filterByPillar : true,
  );

  const pillars = Array.from(
    new Set(stakes.map((stake) => stake.pillar)),
  ).filter((pillar) => (filterByPillar ? pillar.id === filterByPillar : true));

  return (
    <div className="flex flex-col xl:flex-row items-start justify-center gap-4 w-full">
      <div className="w-full xl:w-2/3 border border-gray-100 rounded-xl p-4 bg-white">
        <ThemesMaturityRadarChart
          stakes={filteredStakes}
          filterByPillar={filterByPillar}
        />
      </div>
      <div className="w-full xl:w-1/3">
        <ThemesMaturityRadarLegend
          pillars={pillars}
          stakes={filteredStakes}
          setPillarId={setPillarId}
        />
      </div>
    </div>
  );
}

function ThemesMaturityRadarLegend({
  pillars,
  stakes,
  setPillarId,
}: {
  pillars: MaturityScreen_DiagnosticStakeFragment['pillar'][];
  stakes: MaturityScreen_DiagnosticStakeFragment[];
  setPillarId?: (pillarId: string | null) => void;
}) {
  const { translateProperty } = useTranslation();

  // Ensure pillars are unique
  const uniquePillars = Array.from(
    new Set(pillars.map((pillar) => pillar.id)),
  ).map((id) => pillars.find((pillar) => pillar.id === id)!);

  return (
    <div className="grid grid-cols-2 xl:grid-cols-1 gap-2">
      {uniquePillars.map((pillar) => {
        const pillarStakes = stakes.filter(
          (stake) => stake.pillar.id === pillar.id,
        );
        const pillarScore = calculatePillarScore(pillarStakes);

        return (
          <div
            className="p-4 shadow-sm rounded-xl space-y-2 border border-gray-100 bg-white cursor-pointer hover:border-gray-900"
            key={pillar.id}
            onClick={() => setPillarId?.(pillar.id)}
          >
            <div className="flex items-center gap-3">
              <div className="rounded-full shrink-0 bg-gray-900 p-1 text-white w-8 h-8 flex items-center justify-center">
                <PillarIcon pillar={pillar} />
              </div>
              <div className="w-full">
                <h6 className="font-bold">
                  {translateProperty(pillar, 'name')}
                </h6>
                <ProgressBarWithScale
                  percentageValue={pillarScore / 4}
                  displayLevel={true}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function ThemesMaturityRadarChart({
  stakes,
  filterByPillar,
}: {
  stakes: MaturityScreen_DiagnosticStakeFragment[];
  filterByPillar?: string | null;
}) {
  const { translateProperty } = useTranslation();

  // ChartJS init
  Chart.register(plugin);

  // Prepare chart data
  const chartData: {
    data: number[];
    labels: string[][];
    backgroundColor: string[];
    borderColor: string[];
  } = {
    data: [],
    labels: [],
    backgroundColor: [],
    borderColor: [],
  };

  if (filterByPillar) {
    // If a pillar is filtered, show stakes within that pillar
    stakes
      .filter((stake) => stake.maturity?.score && stake.maturity.score > 0) // Exclude stakes with null or zero score
      .forEach((stake) => {
        const score = getStakeMaturityComputedValue(stake);
        chartData.data.push(score);
        chartData.labels.push(wrapLongLabel(translateProperty(stake, 'name')));
        chartData.backgroundColor.push(
          mapThemeColorToHexColorWithTransparency(stake.pillar.color, 500, 50),
        );
        const level = getLevelFromValue(score / 4);
        const color = mapProgressBarLevelHexColor(level);
        chartData.borderColor.push(color);
      });
  } else {
    // If no pillar is filtered, aggregate stakes by pillar
    const stakesByPillar = stakes.reduce(
      (acc, stake) => {
        if (stake.maturity?.score && stake.maturity.score > 0) {
          // Exclude stakes with null or zero score
          const { pillar } = stake;
          if (!acc[pillar.id]) {
            acc[pillar.id] = { pillar, stakes: [] };
          }
          acc[pillar.id].stakes.push(stake);
        }
        return acc;
      },
      {} as Record<
        string,
        {
          pillar: MaturityScreen_DiagnosticStakeFragment['pillar'];
          stakes: MaturityScreen_DiagnosticStakeFragment[];
        }
      >,
    );

    Object.values(stakesByPillar).forEach(({ pillar, stakes }) => {
      const aggregatedScore = calculatePillarScore(stakes);
      chartData.data.push(aggregatedScore);
      chartData.labels.push(wrapLongLabel(translateProperty(pillar, 'name')));
      chartData.backgroundColor.push(
        mapThemeColorToHexColorWithTransparency(pillar.color, 500, 50),
      );
      const level = getLevelFromValue(aggregatedScore / 4);
      const color = mapProgressBarLevelHexColor(level);
      chartData.borderColor.push(color);
    });
  }

  const chartRef =
    useRef<ChartJSOrUndefined<'radar', number[], string[]>>(null);

  const options: ChartOptions<'radar'> = {
    aspectRatio: 1.5,
    layout: {
      autoPadding: true,
    },
    elements: {
      line: {
        borderWidth: 0,
      },
    },
    scales: {
      r: {
        grid: {
          circular: true,
        },
        angleLines: {
          display: true,
        },
        ticks: {
          display: true,
          precision: 0,
        },
        suggestedMin: 0,
        suggestedMax: 4,
        pointLabels: {
          font: {
            weight: 'bold',
            size: 12,
          },
          color: '#282A2C',
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        backgroundColor: (context) => {
          return context.dataset.borderColor as string;
        },
        color: readCssVar('--color-gray-900'),
        borderRadius: 50,
        font: {
          weight: 'bolder',
          size: 12,
          lineHeight: 1.3,
        },
        formatter: (value) => {
          return (Math.round((value as number) * 10) / 10).toFixed(1);
        },
        padding: 8,
      },
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Scores de maturité',
        data: chartData.data,
        fill: 'origin',
        backgroundColor: chartData.backgroundColor,
        borderColor: chartData.borderColor,
      },
    ],
  };

  return (
    <div className="relative w-full flex items-center justify-center">
      <ExportChartJsToImageButton
        chartRef={chartRef}
        className="tertiary absolute top-0 right-0"
      >
        <DownloadIcon className="w-4 h-4" />
      </ExportChartJsToImageButton>
      <div className="h-[500px] w-[700px]">
        <Radar ref={chartRef} data={data} options={options} />
      </div>
    </div>
  );
}

function mapProgressBarLevelHexColor(level: ProgressBarLevel | null): string {
  switch (level) {
    case ProgressBarLevel.High:
      return readCssVar('--color-green-300');
    case ProgressBarLevel.Medium:
      return readCssVar('--color-green2-300');
    case ProgressBarLevel.Low:
      return readCssVar('--color-yellow-300');
    case ProgressBarLevel.None:
    case null:
      return readCssVar('--color-red-300');
    default:
      return readCssVar('--color-gray-100');
  }
}

function mapThemeColorToHexColorWithTransparency(
  themeColor: ThemeColor,
  shade: number,
  opacity: number,
): string {
  const hexColor = readCssVar(`--color-${themeColor}-${shade}`);
  // Map transparency from 0 to 100 to 00 to FF
  const hexTransparency = Math.round((opacity / 100) * 255).toString(16);
  return hexColor + hexTransparency;
}

function getStakeMaturityComputedValue(
  stake: MaturityScreen_DiagnosticStakeFragment,
): number {
  if (stake.maturityLevel === MaturityLevel.Computed) {
    return stake.maturity?.score || 0;
  } else {
    // Maturity has been overridden by the coach, so we use the provided value
    return getMaturityScoreFromMaturityLevel(stake.maturityLevel) || 0;
  }
}

function getMaturityScoreFromMaturityLevel(
  stakeMaturityLevel: MaturityLevel | undefined | null,
): number | null {
  switch (stakeMaturityLevel) {
    case MaturityLevel.High:
      return 4;
    case MaturityLevel.Medium:
      return 3;
    case MaturityLevel.Low:
      return 2;
    case MaturityLevel.None:
      return 1;
    case null:
    case undefined:
    default:
      return null;
  }
}

function calculatePillarScore(
  stakes: MaturityScreen_DiagnosticStakeFragment[],
): number {
  const validStakes = stakes.filter(
    (stake) => stake.maturity?.score && stake.maturity.score > 0,
  );
  const totalScore = validStakes.reduce(
    (acc, stake) => acc + (stake.maturity?.score || 0),
    0,
  );
  return validStakes.length > 0 ? totalScore / validStakes.length : 0;
}
