import React, { Fragment, ReactElement, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import {
  TaskStatus,
  TaskStatusSelect_TaskFragment,
  TaskUpdateInput,
  useTaskStatusSelect_UpdateMutation,
} from '../../graphql/generated';
import { CheckIcon, DoingIcon, DropIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';

type TaskStatusListItem = {
  id: TaskStatus | null;
  name: string;
  icon: ReactElement;
};

const todoStatus = (): TaskStatusListItem => {
  const { t } = useTranslation();
  return {
    id: TaskStatus.Todo,
    name: t('task.todo.status.todo'),
    icon: (
      <div className="border-2 border-gray-300 rounded-lg w-6 h-6 bg-white"></div>
    ),
  };
};

const taskStatusListItems = (): TaskStatusListItem[] => {
  const { t } = useTranslation();

  return [
    todoStatus(),
    {
      id: TaskStatus.Doing,
      name: t('task.todo.status.doing'),
      icon: (
        <div className="border-2 border-yellow-700 rounded-lg w-6 h-6 flex items-center justify-center">
          <DoingIcon className="text-yellow-700" />
        </div>
      ),
    },
    {
      id: TaskStatus.Done,
      name: t('task.todo.status.done'),
      icon: (
        <div className="bg-purple-500 border-2 border-purple-500 rounded-lg w-6 h-6 flex items-center justify-center text-white">
          <CheckIcon />
        </div>
      ),
    },
  ];
};

export default function TaskStatusSelect({
  task,
  readOnly
}: {
  task: TaskStatusSelect_TaskFragment;
  readOnly: boolean
}) {
  const initialTaskStatusListItem = defineTaskStatusListItem(
    task.status || TaskStatus.Todo,
  );
  const [selected, setSelected] = useState(initialTaskStatusListItem);
  const [updateTaskStatus] = useTaskStatusSelect_UpdateMutation();

  const editTaskStatus = (input: TaskUpdateInput) => {
    updateTaskStatus({
      variables: {
        input,
      },
    }).catch((err) => {
      console.error(err);
    });
  };

  const chooseItem = (taskStatusListItem: TaskStatusListItem) => {
    setSelected(taskStatusListItem);
    editTaskStatus({
      id: task.id,
      status: taskStatusListItem.id || TaskStatus.Todo,
    });
  };
  return (
    <Listbox value={selected} onChange={chooseItem}>
      {({ open }) => (
        <div className="relative">
          <ListboxButton className="relative w-full p-2 font-normal border-0 group tertiary" disabled={readOnly}>
            <span className="flex items-center w-full">{selected.icon}</span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-32 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm">
              {taskStatusListItems().map((taskStatusListItem) => (
                <ListboxOption
                  key={taskStatusListItem.id}
                  className={clsx(
                    'listbox-option relative cursor-default select-none py-2 pl-3 pr-3 text-gray-900',
                    'data-focus:text-white data-focus:bg-blue-600',
                  )}
                  value={taskStatusListItem}
                >
                  {({ selected }) => (
                    <>
                      {selected ? (
                        <span
                          className={
                            'absolute inset-y-0 left-0 flex items-center pr-4 text-white'
                          }
                        >
                          <DropIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}

                      <div className="flex items-center pl-2">
                        {taskStatusListItem.icon}
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block truncate',
                          )}
                        >
                          {taskStatusListItem.name}
                        </span>
                      </div>
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

function defineTaskStatusListItem(taskStatus: TaskStatus): TaskStatusListItem {
  const found = taskStatusListItems().find(
    (taskStatusListItem) => taskStatusListItem.id === taskStatus,
  );
  if (found) {
    return found;
  } else {
    return todoStatus();
  }
}
