import {
  IroType,
  IrremediabilityValue,
  LikelihoodValue,
  QuestionIro_CriteriaFragment,
  ScaleValue,
  ScopeValue,
  SurveyQuestionSummaryFieldsFragment,
  SurveyQuestionUpdateInput,
  TimeHorizon,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { AccordionItem } from '../../../../generic/accordion/AccordionItem';
import clsx from 'clsx';
import { IroAssessmentCriteriaInputs } from './atoms/IroAssessmentCriteriaForm';
import { iroTypeToTypename } from '../../../iro/evaluation/row/IroRow';
import { IroIcon } from '../../../iro/IroIcon';
import {
  hasIrremediabilityCriteriaConfig,
  hasLikelihoodCriteriaConfig,
  hasScaleCriteriaConfig,
  hasScopeCriteriaConfig,
} from '../../../../../types/iroAssessments.guards';

export const QuestionIroAssessmentByTypeCriterias = ({
  question,
  iroType,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  iroType: IroType;
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const toast = useToast();
  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });
  useEffect(() => {
    if (question) {
      setQuestionDraft({ ...question });
    }
  }, [question]);

  let iroAssessmentCriteriaByType: any | undefined | null;

  switch (iroType) {
    case IroType.Risk:
      iroAssessmentCriteriaByType = questionDraft.iroAssessment?.risk;
      break;
    case IroType.Opportunity:
      iroAssessmentCriteriaByType = questionDraft.iroAssessment?.opportunity;
      break;
    case IroType.NegativeImpact:
      iroAssessmentCriteriaByType = questionDraft.iroAssessment?.negativeImpact;
      break;
    case IroType.PositiveImpact:
      iroAssessmentCriteriaByType = questionDraft.iroAssessment?.positiveImpact;
  }

  const updateQuestion = (question: SurveyQuestionUpdateInput) => {
    updateQuestionMutation({
      variables: {
        input: question,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const buildPayloadByIroTypeAndUpdate = (
    target:
      | typeof ScaleValue
      | typeof ScopeValue
      | typeof LikelihoodValue
      | typeof IrremediabilityValue
      | typeof TimeHorizon,
    input: QuestionIro_CriteriaFragment,
  ) => {
    const payload: SurveyQuestionUpdateInput = {
      id: question.id,
      iroAssessment: {
        id: question.iroAssessment?.id,
      },
    };

    const targetValue: any = {};

    switch (iroType) {
      case IroType.Risk:
        payload.iroAssessment!.risk = targetValue;
        break;
      case IroType.Opportunity:
        payload.iroAssessment!.opportunity = targetValue;
        break;
      case IroType.NegativeImpact:
        payload.iroAssessment!.negativeImpact = targetValue;
        break;
      case IroType.PositiveImpact:
        payload.iroAssessment!.positiveImpact = targetValue;
        break;
      default:
        break;
    }
    switch (target) {
      case LikelihoodValue:
        targetValue.likelihoodCriteria = input;
        break;
      case ScaleValue:
        targetValue.scaleCriteria = input;
        break;
      case ScopeValue:
        targetValue.scopeCriteria = input;
        break;
      case IrremediabilityValue:
        targetValue.irremediabilityCriteria = input;
        break;
      case TimeHorizon:
        targetValue.timeHorizon = input;
        break;
    }

    updateQuestion(payload);
  };

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  return (
    <AccordionItem
      title={
        <div className="flex items-center justify-between gap-2">
          <div
            className="flex items-center gap-2 cursor-pointer grow"
            onClick={() => toggleExpanded()}
          >
            <div className="text-base font-bold flex items-center gap-2">
              <IroIcon iroTypename={iroTypeToTypename(iroType)} />
              {t(`survey.question.iroAssessment.${iroType}.title`)}
            </div>
          </div>
        </div>
      }
      isExpanded={isExpanded}
      onToggle={() => toggleExpanded()}
      headerProps={{ onClick: () => undefined }}
      className={clsx('border-t-0 border-l-0 border-r-0 rounded-b-none')}
    >
      {isExpanded && (
        <div className="flex flex-col gap-2">
          <div>
            <div className={'font-semibold text-base mb-2'}>
              {t(
                `translation:survey.question.iroAssessment.${iroType}.scale.definition`,
              )}
            </div>

            {hasScaleCriteriaConfig(iroAssessmentCriteriaByType) && (
              <IroAssessmentCriteriaInputs
                title={t('iro.criteria.scale.title')}
                descriptionPlaceholder={t(
                  `survey.question.iroAssessment.${iroType}.scale.definition`,
                )}
                scaleType={ScaleValue}
                iroType={iroType}
                iroAssessmentCriteria={
                  iroAssessmentCriteriaByType?.scaleCriteria
                }
                setIroAssessmentCriteria={(criteria) => {
                  buildPayloadByIroTypeAndUpdate(ScaleValue, criteria);
                }}
              />
            )}
          </div>
          <div>
            {hasScopeCriteriaConfig(iroAssessmentCriteriaByType) && (
              <IroAssessmentCriteriaInputs
                title={t('iro.criteria.scope.title')}
                descriptionPlaceholder={t(
                  `survey.question.iroAssessment.${iroType}.scope.definition`,
                )}
                scaleType={ScopeValue}
                iroType={iroType}
                iroAssessmentCriteria={
                  iroAssessmentCriteriaByType!.scopeCriteria
                }
                setIroAssessmentCriteria={(criteria) => {
                  buildPayloadByIroTypeAndUpdate(ScopeValue, criteria);
                }}
              />
            )}
          </div>
          <div>
            {hasLikelihoodCriteriaConfig(iroAssessmentCriteriaByType) && (
              <IroAssessmentCriteriaInputs
                title={t('iro.criteria.likelihood.title')}
                descriptionPlaceholder={t(
                  `survey.question.iroAssessment.${iroType}.likelihood.definition`,
                )}
                scaleType={LikelihoodValue}
                iroType={iroType}
                iroAssessmentCriteria={
                  iroAssessmentCriteriaByType!.likelihoodCriteria
                }
                setIroAssessmentCriteria={(criteria) => {
                  buildPayloadByIroTypeAndUpdate(LikelihoodValue, criteria);
                }}
              />
            )}
          </div>
          <div>
            {hasIrremediabilityCriteriaConfig(iroAssessmentCriteriaByType) && (
              <IroAssessmentCriteriaInputs
                title={t('iro.criteria.irremediability.title')}
                descriptionPlaceholder={t(
                  `survey.question.iroAssessment.${iroType}.irremediability.definition`,
                )}
                scaleType={IrremediabilityValue}
                iroType={iroType}
                iroAssessmentCriteria={
                  iroAssessmentCriteriaByType.irremediabilityCriteria
                }
                setIroAssessmentCriteria={(criteria) => {
                  buildPayloadByIroTypeAndUpdate(
                    IrremediabilityValue,
                    criteria,
                  );
                }}
              />
            )}
          </div>
          <div>
            <IroAssessmentCriteriaInputs
              title={t('iro.criteria.timeHorizon.title')}
              descriptionPlaceholder={t(
                `survey.question.iroAssessment.${iroType}.timeHorizon.definition`,
              )}
              scaleType={TimeHorizon}
              iroType={iroType}
              iroAssessmentCriteria={iroAssessmentCriteriaByType?.timeHorizon}
              setIroAssessmentCriteria={(criteria) => {
                buildPayloadByIroTypeAndUpdate(TimeHorizon, criteria);
              }}
            />
          </div>
        </div>
      )}
    </AccordionItem>
  );
};
