import {
  IroType,
  SurveyQuestionSummaryFieldsFragment,
  SurveyQuestionUpdateInput,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useEffect, useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';
import { QuestionPillarStake } from './atoms/QuestionPillarStake';
import { iroTypenameToIroType } from '../../../iro/iroTypenameToIroType';
import { QuestionIroAssessmentByTypeCriterias } from './QuestionIroAssessmentCriteriasForIroType';
import { useTranslation } from '@hooks/useTranslation';

export const QuestionIroAssessmentForm = ({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>(question);

  const [iroTypesUsed, setIroTypesUsed] = useState<IroType[]>([]);

  function listIroTypesFound() {
    return (
      question.iroAssessment?.iros?.reduce((acc: IroType[], iro) => {
        const iroType = iroTypenameToIroType(iro.__typename);
        if (!acc.find((type) => type === iroType)) acc.push(iroType);
        return acc;
      }, []) || []
    );
  }

  useEffect(() => {
    if (question) {
      setQuestionDraft({ ...question });
      const iroTypesFound = listIroTypesFound();
      setIroTypesUsed(iroTypesFound);
    }
  }, [question]);

  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();

  const updateQuestion = (question: SurveyQuestionUpdateInput) => {
    updateQuestionMutation({
      variables: {
        input: question,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
      })
      .catch((error) => {
        toast.openToastWithError(t('toast:survey.question.update.error'));
      });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
      </div>
      <div className="pt-8">
        <QuestionPillarStake
          question={questionDraft}
          enableStakePicker={true}
          enablePillarPicker={true}
          enableIrosPicker={true}
        />
      </div>
      <div className="pt-4 space-y-2">
        <span className={'font-semibold text-base'}>
          {t('translation:survey.question.iroAssessment.title')}
        </span>
        <div>
          <label htmlFor="title" className="form-input-label">
            {t('translation:survey.question.iroAssessment.questionLabel')}
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({ ...questionDraft, title: e.target.value });
            }}
            onBlur={() =>
              updateQuestion({
                id: questionDraft.id,
                title: questionDraft.title,
              })
            }
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
          {question.stake && iroTypesUsed.length > 0 && (
            <>
              <label htmlFor="title" className="form-input-label my-2">
                {t('survey.question.iroAssessment.criteria.label')}
              </label>
              {iroTypesUsed.includes(IroType.Risk) && (
                <QuestionIroAssessmentByTypeCriterias
                  question={questionDraft}
                  iroType={IroType.Risk}
                />
              )}
              {iroTypesUsed.includes(IroType.Opportunity) && (
                <QuestionIroAssessmentByTypeCriterias
                  question={questionDraft}
                  iroType={IroType.Opportunity}
                />
              )}
              {iroTypesUsed.includes(IroType.NegativeImpact) && (
                <QuestionIroAssessmentByTypeCriterias
                  question={questionDraft}
                  iroType={IroType.NegativeImpact}
                />
              )}
              {iroTypesUsed.includes(IroType.PositiveImpact) && (
                <QuestionIroAssessmentByTypeCriterias
                  question={questionDraft}
                  iroType={IroType.PositiveImpact}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
