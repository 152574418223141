import { Users } from '../Users';
import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { IconTitle } from '../../../heading/iconTitle';
import { useTranslation } from '@hooks/useTranslation';
import { InvitationModalButton } from './InvitationModal';

export const ProjectUsersContent = () => {
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  return (
    projectContext?.enterprise && (
      <div className="w-full py-8 px-16 max-w-7xl">
        <div className="main-content w-full py-8 space-y-8">
          {projectContext?.enterprise && (
            <div className="flex justify-between items-center gap-2">
              <IconTitle>{t('project.users.title')}</IconTitle>
              <div className="flex gap-2">
                <InvitationModalButton
                  enterpriseId={projectContext.enterprise.id}
                />
              </div>
            </div>
          )}
          <Users enterprise={projectContext?.enterprise} />
        </div>
      </div>
    )
  );
};
