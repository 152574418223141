import React, { useEffect, useRef, useState } from 'react';
import { EnterpriseCard } from './EnterpriseCard';
import { EditEnterpriseButton } from './EditEnterpriseButton';
import { AddOrganizationalUnitButton } from './AddOrganizationalUnitButton';
import { ContextualMenu } from '../../../generic/ContextualMenu';
import { MenuIcon, UserPlusIcon } from '../../../icons';
import { useRightDrawer } from '../../../layout/RightDrawer';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { EditSubsidiaryButton } from './EditSubsidiaryButton';
import {
  FeatureEnum,
  OrganizationalUnitContent_EnterpriseFragment,
  OrganizationalUnitContent_OrganizationalUnitFragment,
  PermissionEnum,
} from '../../../../graphql/generated';
import { OrganizationalUnitCard } from './OrganizationalUnitCard';
import { AssociateEmployeesToOrganizationalUnitList } from './AssociateEmployeesToOrganizationalUnitList';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

const MAX_DEPTH = 3;

export function OrganizationalUnitContent({
  enterprise,
}: {
  enterprise: OrganizationalUnitContent_EnterpriseFragment;
}) {
  const { t } = useTranslation();
  const [expandedEnterprise, setExpandedEnterprise] = useState<string[]>([]);
  const newlyAddedRef = useRef<HTMLDivElement | null>(null);

  const rightDrawer = useRightDrawer();
  const openRightDrawer = ({
    organizationalUnit,
  }: {
    organizationalUnit: Omit<
      OrganizationalUnitContent_OrganizationalUnitFragment,
      '__typename'
    >;
  }) => {
    rightDrawer.openDrawerWithComponent(
      <>
        <div className="px-8 py-4 bg-gray-50 border-b border-gray-100">
          <h3>
            {t('company.organizationalUnit.associateCollaborator.title', {
              companyName: organizationalUnit.name,
            })}
          </h3>
        </div>
        {/* FIXME */}
        <AssociateEmployeesToOrganizationalUnitList enterprise={enterprise} />
      </>,
    );
  };

  function isExpanded(id: string) {
    return expandedEnterprise.includes(id);
  }

  function handleAddSubsidiary(parentId: string) {
    setExpandedEnterprise((prev) => [...prev, parentId]);
    newlyAddedRef.current = document.getElementById(
      `company-${parentId}`,
    ) as HTMLDivElement;
  }

  useEffect(() => {
    if (newlyAddedRef.current) {
      newlyAddedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      newlyAddedRef.current = null;
    }
  }, [expandedEnterprise]);
  const { isGranted } = usePermissionChecker();

  function renderSubsidiaries(
    subsidiaries: (OrganizationalUnitContent_OrganizationalUnitFragment & {
      subsidiaries?: Array<OrganizationalUnitContent_OrganizationalUnitFragment>;
    })[],
    depth: number,
  ) {
    if (depth > MAX_DEPTH || !subsidiaries || subsidiaries.length === 0) {
      return null;
    }

    return subsidiaries.map((subsidiary, index) => {
      const isLastItem = index === subsidiaries.length - 1;

      return (
        <div
          id={`company-${subsidiary.id}`}
          key={subsidiary.id}
          className={clsx(depth === 1 ? 'mt-5' : 'ml-10')}
        >
          <div className={clsx('flex items-stretch')}>
            {depth > 1 && (
              <div
                className={clsx(!isLastItem && 'border-l-2 border-gray-200')}
              >
                <div
                  className={clsx(
                    'w-full border-gray-200',
                    isLastItem && 'border-l-2',
                  )}
                ></div>
              </div>
            )}
            {depth > 1 && (
              <div
                className={clsx(
                  'border-b-2 border-l-2 border-gray-200 h-[70px] w-[30px] -ml-[2px]',
                )}
              />
            )}
            <div className={clsx('w-full ', depth > 1 && 'my-4')}>
              <OrganizationalUnitCard
                showExpandButton={depth < MAX_DEPTH}
                expandedState={expandedEnterprise}
                setExpandedState={setExpandedEnterprise}
                organizationalUnit={subsidiary}
              >
                {isGranted(
                  FeatureEnum.ProjectCompany,
                  PermissionEnum.Write,
                ) && (
                  <ContextualMenu button={<MenuIcon className="w-4 h-4" />}>
                    <EditSubsidiaryButton companyId={subsidiary.id} />
                    <AssociateCollaboratorsButton
                      onClick={() =>
                        openRightDrawer({ organizationalUnit: subsidiary })
                      }
                    />
                    {depth < MAX_DEPTH && (
                      <AddOrganizationalUnitButton
                        parent={subsidiary}
                        onAdd={() => handleAddSubsidiary(subsidiary.id)}
                      />
                    )}
                  </ContextualMenu>
                )}
              </OrganizationalUnitCard>
              {isExpanded(subsidiary.id) &&
                renderSubsidiaries(subsidiary.subsidiaries ?? [], depth + 1)}
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    enterprise && (
      <div className="grow bg-gray-50 w-full py-8 px-16">
        <div className="max-w-6xl space-y-16" id={`company-${enterprise.id}`}>
          <EnterpriseCard
            expandedState={expandedEnterprise}
            setExpandedState={setExpandedEnterprise}
            enterprise={enterprise}
          >
            {isGranted(FeatureEnum.ProjectCompany, PermissionEnum.Write) && (
              <ContextualMenu button={<MenuIcon className="w-4 h-4" />}>
                <EditEnterpriseButton companyId={enterprise.id} />
                <AddOrganizationalUnitButton
                  onAdd={() => handleAddSubsidiary(enterprise.id)}
                />
              </ContextualMenu>
            )}
          </EnterpriseCard>

          {enterprise.organizationalUnits &&
            enterprise.organizationalUnits.length > 0 && (
              <div className={'mt-5'}>
                <h2>
                  {t('screen.project.settings.organizationalUnits.title')}
                </h2>
                {/** @ts-ignore fixme */}
                {renderSubsidiaries(enterprise.organizationalUnits, 1)}
              </div>
            )}
        </div>
      </div>
    )
  );
}

function AssociateCollaboratorsButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  return (
    <button className="contextual-menu-item" onClick={onClick}>
      <UserPlusIcon />
      {t('company.organizationalUnit.associateCollaborator.action')}
    </button>
  );
}
