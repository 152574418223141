import React from 'react';
import { CheckIcon } from '../icons';

export type StepIndicatorItem = {
  id: number;
  name: React.ReactNode;
  status: 'upcoming' | 'current' | 'complete';
};

export function StepsIndicator({ steps }: { steps: StepIndicatorItem[] }) {
  return (
    <ol className="divide-y divide-gray-300 rounded-lg border border-gray-300 lg:flex lg:divide-y-0 bg-white">
      {steps.map((step, stepIdx) => (
        <li key={step.id} className="relative lg:flex lg:flex-1">
          {step.status === 'complete' ? (
            <div className="flex w-full items-center">
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-green-500">
                  <CheckIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <span className="ml-4 text-sm font-medium text-gray-900">
                  {step.name}
                </span>
              </span>
            </div>
          ) : step.status === 'current' ? (
            <div
              className="flex items-center px-6 py-4 text-sm font-medium"
              aria-current="step"
            >
              <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-900">
                <span className="text-white title-h6">{step.id}</span>
              </span>
              <span className="ml-4 text-sm font-medium text-gray-900">
                {step.name}
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-300">
                  <span className="text-white title-h6">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-500">
                  {step.name}
                </span>
              </span>
            </div>
          )}

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div
                className="absolute right-0 top-0 hidden h-full w-5 lg:block"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </>
          ) : null}
        </li>
      ))}
    </ol>
  );
}
