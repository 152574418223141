import React, { useState } from 'react';
import {
  StakeRankingDetail_AnswerFragment,
  StakeRankingDetail_StakeFragment,
  StakesRankingDataviz_AnswerFragment,
  StakesRankingDataviz_QuestionFragment,
  useStakesRankingDataviz_ReferentialQuery,
} from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { NumberCircle } from '../../../generic/NumberCircle';
import { StakeTag } from '../../../stake/StakeTag';
import clsx from 'clsx';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { ChevronDownIcon } from '../../../icons';
import { CommentList } from './CommentList';
import { RankedStakes, StakesRankingChart } from './StakesRankingChart';
import { sanitizeTypeformString } from '../../../../services/SurveyService';

export function StakesRankingDataviz({
  question,
  answers,
  isThumbnail,
}: {
  question: StakesRankingDataviz_QuestionFragment;
  answers: StakesRankingDataviz_AnswerFragment[];
  isThumbnail?: boolean;
}) {
  const [displayDetails, setDisplayDetails] = useState(false);

  const projectContext = useProjectContext();
  const { currentUser } = useCurrentUser();

  const companyReferentialDocumentQuery =
    useStakesRankingDataviz_ReferentialQuery({
      variables: { id: projectContext?.enterprise?.referential?.id || '' },
      skip: !projectContext?.enterprise?.id,
      fetchPolicy: 'cache-and-network',
    });

  const referential = companyReferentialDocumentQuery.data?.referential;
  const stakes =
    referential?.pillars
      .filter((pillar) =>
        question.pillar?.id ? pillar.id === question.pillar?.id : true,
      )
      .flatMap((pillar) => pillar.stakes) || [];

  // TODO: fix preview when in isThumbnail mode (theme modal)
  if (isThumbnail) {
    return (
      <div className="p-4">
        <MessageBox type={MessageBoxType.Info}>
          Fonctionnalité en cours de développement
        </MessageBox>
      </div>
    );
  }

  if (answers.length === 0) {
    return <MessageBox type={MessageBoxType.Info}>Aucune réponse</MessageBox>;
  }

  const topStakes = getTopStakesWithSimpleAlgorithm(
    stakes,
    answers,
    question.valueMax || 5,
  );

  return (
    <div className="p-4 rounded-2xl shadow-sm bg-white m-auto relative">
      <StakesRankingChart
        rankedStakes={topStakes}
        title={sanitizeTypeformString(
          question.title,
          projectContext,
          currentUser,
        )}
      />
      {displayDetails ? (
        <div className="space-y-2">
          <div
            className="flex items-center justify-center gap-2 text-gray-500 font-bold text-xs cursor-pointer"
            onClick={() => setDisplayDetails(false)}
          >
            <span>Cacher le détail</span>
            <ChevronDownIcon className="w-3 h-3 rotate-180" />
          </div>
          <h6>Détail des résultats</h6>
          <div className="space-y-4 divide-y divide-gray-100">
            {stakes.map((stake) => (
              <StakeRankingDetail
                key={stake.id}
                top={question.valueMax || 5}
                stake={stake}
                answers={answers || []}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          className="flex items-center justify-center gap-2 text-gray-500 font-bold text-xs cursor-pointer"
          onClick={() => setDisplayDetails(true)}
        >
          <span>Voir le détail</span>
          <ChevronDownIcon className="w-3 h-3" />
        </div>
      )}
      <CommentList answers={answers} />
    </div>
  );
}

export function StakeRankingDetail({
  top,
  stake,
  answers,
}: {
  top: number;
  stake: StakeRankingDetail_StakeFragment;
  answers: StakeRankingDetail_AnswerFragment[];
}) {
  // For each rank, count the number of times it was ranked
  // Initialize rankCounts with 0 for each rank
  const rankCounts = new Map<number, number>(
    Array.from(Array(top || 5).keys()).map((rank) => [rank, 0]),
  );
  answers.forEach((answer) => {
    (answer.stakes || []).forEach((answerStake) => {
      if (answerStake.stakeId === stake.id) {
        const rank = answerStake.rank || 0;
        rankCounts.set(rank, (rankCounts.get(rank) || 0) + 1);
      }
    });
  });

  return (
    <div className="flex flex-col gap-4 w-full pt-4">
      <div className="flex">
        <StakeTag stake={stake} disableDetailModalOpening={true} />
      </div>
      <div className="flex flex-col items-stretch gap-1 justify-evenly">
        {Array.from(rankCounts.entries())
          .toSorted((a, b) => a[0] - b[0])
          .map(([rank, count], index) => (
            <div className="flex items-end gap-4 w-full" key={index}>
              <NumberCircle number={rank + 1} size={6} />
              <div className="w-full">
                <RankBar
                  score={count}
                  maxOfAllCounts={answers.length}
                  label={''}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

function RankBar({
  label,
  score,
  maxOfAllCounts,
}: {
  label: string;
  score: number;
  maxOfAllCounts: number;
}) {
  const percent = score / maxOfAllCounts;
  return (
    <div className="flex gap-2">
      <div className="w-4/5 bg-gray-100 rounded-lg flex items-end h-6">
        <div
          className="bg-purple-500 h-6 rounded-lg"
          style={{ width: `${Math.min(100, percent * 100)}%` }}
        ></div>
      </div>
      <div className="flex gap-4 justify-between items-center text-sm">
        <div className="text-gray-900">{label}</div>
        <div className="flex gap-2 items-center">
          <div className={clsx(percent > 0 && 'font-bold')}>
            {Math.round(percent * 100)}%
          </div>
          <div className="text-gray-500">{score}</div>
        </div>
      </div>
    </div>
  );
}

// TODO: top stakes should be computed in the backend (see https://github.com/Good-Steps/gs/issues/977)
export function getTopStakesWithSimpleAlgorithm(
  stakes: StakeRankingDetail_StakeFragment[],
  answers: StakesRankingDataviz_AnswerFragment[],
  top: number,
): RankedStakes[] {
  // Each answer contains a list of stakes, ranked by the user
  // Compute the average score for each stake, thus giving the ranking
  const stakesAverageScores = stakes.map((stake) => {
    const stakeAnswers = answers.flatMap((answer) =>
      (answer.stakes || []).filter(
        (answerStake) => answerStake.stakeId === stake.id,
      ),
    );
    // Score is the multiplication of each rank by the number of times it was ranked
    // Average score is the sum of scores divided by the number of answers
    const scoreAndCount: { score: number; count: number } = stakeAnswers.reduce(
      (acc: { score: number; count: number }, answerStake) => {
        // acc + (answerStake.rank || 0)
        // Test if answerStake.rank is undefined or null
        if (answerStake.rank === undefined || answerStake.rank === null) {
          return acc;
        }

        // Convert rank to 1-based, where the top rank is 1
        const rank = top - answerStake.rank;

        return { score: acc.score + rank, count: acc.count + 1 };
      },
      { score: 0, count: 0 },
    );

    return { stake, score: scoreAndCount.score };
  });

  // Sort stakes by average score
  return stakesAverageScores
    .filter((stakeAverageScore) => stakeAverageScore.score > 0)
    .toSorted((a, b) => (b.score || 0) - (a.score || 0));
}
