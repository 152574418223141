import { useCurrentUser } from '../providers/CurrentUserProvider';
import {
  AuthRolesAndExperimentalFeatures,
  EnterpriseRoleEnum,
  FeatureEnum,
  PermissionEnum,
} from '../graphql/generated';
import { useProjectContext } from '../providers/ProjectContextProvider';

export function usePermissionChecker() {
  const { currentUser, permissions } = useCurrentUser();

  const permissionHierarchy: Record<PermissionEnum, number> = {
    [PermissionEnum.None]: 0,
    [PermissionEnum.Read]: 1,
    [PermissionEnum.Write]: 2,
  };
  const projectContext = useProjectContext();

  const isGranted = (
    feature: FeatureEnum,
    requiredPermission: PermissionEnum = PermissionEnum.Read,
    strictComparison = false,
  ): boolean => {
    const enterpriseId = projectContext?.enterprise?.id;
    if (projectContext?.enterprise?.isTemplate) {
      return (
        (hasRole(AuthRolesAndExperimentalFeatures.Administrator) ||
          hasRole(AuthRolesAndExperimentalFeatures.Coach)) &&
        projectContext?.enterprise.ownedBy?.id === currentUser?.company?.id
      );
    }
    const isAdmin = currentUser?.userAccesses?.some((userAccess) => {
      return (
        userAccess.enterprise?.id === enterpriseId &&
        userAccess.role.role === EnterpriseRoleEnum.Administrator
      );
    });
    const enterprisePermissions = permissions.filter(
      (permission) => permission.enterpriseId === enterpriseId,
    )[0] || { permissions: [] };

    for (const permission of enterprisePermissions.permissions) {
      const userPermission = permission[feature];
      if (userPermission) {
        if (strictComparison) {
          return userPermission === requiredPermission;
        } else {
          return (
            permissionHierarchy[userPermission] >=
            permissionHierarchy[requiredPermission]
          );
        }
      }
    }

    return isAdmin || false;
  };

  const hasRole = (
    role: AuthRolesAndExperimentalFeatures,
    allowSuperAdminByPass = true,
  ) => {
    if (!currentUser?.auth) {
      return false;
    }
    return (
      currentUser.auth.roles.includes(role) ||
      (allowSuperAdminByPass &&
        currentUser.auth.roles.includes(
          AuthRolesAndExperimentalFeatures.Superadmin,
        ))
    );
  };

  return { isGranted, hasRole, loading: !currentUser };
}
