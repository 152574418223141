import React from 'react';
import { IndicatorList_Datapoint_StrapiFragment } from '../../../../../graphql/cms/generated';
import {
  IndicatorList_DatapointFragment,
  useIndicatorListQuery,
} from '../../../../../graphql/generated';
import { IndicatorItem } from './IndicatorItem';
import { useTranslation } from '@hooks/useTranslation';
import { DatapointTags } from '../../../datapoint/DatapointTags';
import { AddIndicatorButton } from './add/AddIndicatorButton';
import { NoData } from '../../../../generic/NoData';
import { TableSkeletonLoader } from '../../../../generic/loader/TableSkeletonLoader';
import { SkeletonLoader } from '../../../../generic/loader/SkeletonLoader';

export const IndicatorList = ({
  reportDatapoint,
  cmsDatapoint,
  readOnly,
}: {
  reportDatapoint: IndicatorList_DatapointFragment;
  cmsDatapoint: IndicatorList_Datapoint_StrapiFragment;
  readOnly?: boolean;
}) => {
  const { t, translateStrapiProperty } = useTranslation();
  const { data, loading } = useIndicatorListQuery({
    variables: {
      reportDataPointId: reportDatapoint.id,
    },
  });
  const indicators = data?.indicatorsByDataPoint || [];
  if (loading) {
    return (
      <div className={'flex flex-col space-y-3'}>
        <SkeletonLoader size={{ width: 'w-2/3', height: 'h-6' }} />
        <SkeletonLoader size={{ width: 'w-1/2', height: 'h-6' }} />
        <SkeletonLoader size={{ height: 'h-6' }} />
        <TableSkeletonLoader rows={5} columns={3} />
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-100 space-y-4">
      <div className="space-y-2">
        <div className="uppercase font-bold">{cmsDatapoint.identifier}</div>
        <h4>{translateStrapiProperty(cmsDatapoint.translation, 'name')}</h4>
        <DatapointTags datapoint={cmsDatapoint} />
      </div>

      {indicators.length > 0 ? (
        <div className="pt-4 space-y-4">
          <div className="flex items-center justify-end">
            {!readOnly && (
              <AddIndicatorButton
                reportDatapoint={reportDatapoint}
                cmsDatapoint={cmsDatapoint}
              />
            )}
          </div>
          <div className="space-y-4">
            {indicators.map((indicator) => (
              <IndicatorItem
                key={indicator.id}
                indicator={indicator}
                readOnly={readOnly}
              />
            ))}
          </div>
        </div>
      ) : (
        <NoData
          text={t(
            'indicator_collect.disclosure_requirement.indicator.create.description',
            { context: readOnly ? 'readOnly' : '' },
          )}
          actions={
            (!readOnly && [
              {
                key: 'addIndicator',
                component: (
                  <AddIndicatorButton
                    reportDatapoint={reportDatapoint}
                    cmsDatapoint={cmsDatapoint}
                  />
                ),
              },
            ]) ||
            []
          }
        />
      )}
    </div>
  );
};
