import {
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionStatementForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const updateQuestion = () => {
    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          title: questionDraft.title,
          description: questionDraft.description,
          required: false, // Forced to false
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <QuestionTypeInput question={questionDraft} />

      <div className="pt-8 space-y-2">
        <h3>{t('survey.edit.questions.content')}</h3>
        <div>
          <label htmlFor="title" className="form-input-label mb-2">
            {t('survey.edit.questions.type.common.title')}
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({ ...questionDraft, title: e.target.value });
            }}
            onBlur={() => updateQuestion()}
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>
      </div>
    </div>
  );
}
