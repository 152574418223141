import React, { useState } from 'react';
import {
  GradientDropdown,
  GradientDropdownStrength,
} from '../../../../generic/dropdown/GradientDropdown';
import {
  IroIrremediabilityPicker_NegativeImpactFragment,
  IrremediabilityValue,
  useUpdateIroIrremediabilityMutation,
} from '../../../../../graphql/generated';
import { useToast } from '../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { TFunction } from 'i18next';
import { useRefetchMaterialities } from './useRefetchMaterialities';

export type IroIrremediabilityItem = {
  id: IrremediabilityValue | null;
  name: string;
  strength: GradientDropdownStrength;
};

export const irremediabilityCriteriaChoices = (
  t: TFunction,
): IroIrremediabilityItem[] => {
  return [
    {
      id: IrremediabilityValue.Irremediable,
      name: t(
        `enum:iroCriteria.irremediability.${IrremediabilityValue.Irremediable}`,
      ),
      strength: GradientDropdownStrength.VeryStrong,
    },
    {
      id: IrremediabilityValue.HardlyRemediable,
      name: t(
        `enum:iroCriteria.irremediability.${IrremediabilityValue.HardlyRemediable}`,
      ),
      strength: GradientDropdownStrength.Strong,
    },
    {
      id: IrremediabilityValue.ModeratelyRemediable,
      name: t(
        `enum:iroCriteria.irremediability.${IrremediabilityValue.ModeratelyRemediable}`,
      ),
      strength: GradientDropdownStrength.Medium,
    },
    {
      id: IrremediabilityValue.EasilyRemediable,
      name: t(
        `enum:iroCriteria.irremediability.${IrremediabilityValue.EasilyRemediable}`,
      ),
      strength: GradientDropdownStrength.Light,
    },
    {
      id: null,
      name: '-',
      strength: GradientDropdownStrength.VeryLight,
    },
  ];
};

export function IroIrremediabilityPicker({
  iro,
  extraPositionClassName,
  readOnly = false,
}: {
  iro: IroIrremediabilityPicker_NegativeImpactFragment;
  extraPositionClassName?: string;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();
  const items = irremediabilityCriteriaChoices(t);
  const initialIrremediabilityItem = items.find(
    (item) => item.id === iro.irremediabilityCriteria?.value || null,
  );
  const [selected, setSelected] = useState(initialIrremediabilityItem || null);
  const [update] = useUpdateIroIrremediabilityMutation();
  const toast = useToast();
  const refetchQueries = useRefetchMaterialities();

  const chooseItemId = (itemId: string | null) => {
    const item = items.find((item) => item.id === itemId) || null;
    setSelected(item);

    update({
      variables: {
        input: {
          id: iro.id,
          irremediability: item?.id ?? null,
        },
      },
      refetchQueries,
    })
      .then(() => {
        toast.openToastWithMessage(
          t('toast:iro.criteria.update.success', {
            criteriaName: t('iro.criteria.irremediability.title'),
          }),
        );
      })
      .catch(() => {
        toast.openToastWithError(
          t('toast:iro.criteria.irremediability.update.error'),
        );
      });
  };
  return (
    <GradientDropdown
      {...{
        items,
        selected,
        chooseItemId,
        extraPositionClassName,
        disabled: readOnly,
      }}
    />
  );
}
