import {
  SurveyLanguage,
  UpdateStakeModal_StakeFragment,
  useUpdateStakeModal_StakeUpdateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { useModal } from '../../layout/Modal';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import RichTextEditorTextarea from '../../generic/form/RichTextEditorTextarea';
import i18n from 'i18next';
import { MaterialityIcon, XIcon } from '../../icons';
import LanguageDropdown from '../../form/LanguageDropdown';
import { useTranslation } from '@hooks/useTranslation';
import { DeleteStakeButton } from './DeleteStakeButton';

export function UpdateStakeModal({
  stake,
  readOnly = false,
}: {
  stake: UpdateStakeModal_StakeFragment;
  readOnly?: boolean;
}) {
  const toast = useToast();
  const modal = useModal();
  const { t, translateProperty } = useTranslation();

  const [language, setLanguage] = useState<SurveyLanguage>(SurveyLanguage.Fr);
  const initializeI18nValues = () => {
    // Get i18n values from stake
    const i18nValues = stake.i18n || [];
    // Inject FR if not present
    if (!i18nValues.find((i18n) => i18n.language === SurveyLanguage.Fr)) {
      i18nValues.push({
        language: SurveyLanguage.Fr,
        name: translateProperty(stake, 'name'),
        description: translateProperty(stake, 'description'),
        financialMaterialityDescription: translateProperty(
          stake,
          'financialMaterialityDescription',
        ),
        impactMaterialityDescription: translateProperty(
          stake,
          'impactMaterialityDescription',
        ),
      });
    }
    return i18nValues;
  };
  const [i18nValues, setI18nValues] = useState(initializeI18nValues());
  const translation = i18nValues?.find((i18n) => i18n.language === language);

  i18n.on('languageChanged', () => {
    pickLanguage(i18n.language as SurveyLanguage);
  });

  const pickLanguage = (newLanguage: SurveyLanguage) => {
    setLanguage(newLanguage);
    // Inject language if not present
    if (!i18nValues.find((i18n) => i18n.language === newLanguage)) {
      const newI18nValues = [...i18nValues];
      newI18nValues.push({
        language: newLanguage,
        name: '',
        description: '',
        financialMaterialityDescription: '',
        impactMaterialityDescription: '',
      });
      setI18nValues(newI18nValues);
    }
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateDiagnosticStake] = useUpdateStakeModal_StakeUpdateMutation();
  const editStake = () => {
    setIsUpdating(true);
    updateDiagnosticStake({
      variables: {
        input: {
          id: stake.id,
          i18n: i18nValues,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:stake.form.update.success'));
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
        toast.openToastWithError(t('toast:stake.form.update.error'));
      })
      .finally(() => setIsUpdating(false));
  };

  const editField = (field: string, value: string) => {
    const newI18nValues = i18nValues.map((i18n) => {
      if (i18n.language === language) {
        return {
          ...i18n,
          [field]: value.trimStart(),
        };
      }
      return i18n;
    });
    setI18nValues(newI18nValues);
  };

  // Form is valid when at least one translation has a name (not empty string)
  const isValid = i18nValues.some((i18n) => i18n.name !== '');

  return (
    <div className="flex flex-col max-w-2xl h-full">
      <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-400"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <h4 className="grow">
          {t('referential.form.stake.update_stake', {
            context: readOnly ? 'readOnly' : undefined,
          })}
        </h4>
        <LanguageDropdown onUpdate={(language) => pickLanguage(language)} />
      </div>

      <div className="p-6 space-y-6 divide-y divide-gray-100 grow overflow-y-scroll">
        <div className="space-y-6">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="form-input-label">
              {t('stake.form.name')}
            </label>
            <input
              type="text"
              id="title"
              className="form-input-text"
              placeholder={t('stake.form.name', {
                context: language,
              })}
              value={translation?.name || ''}
              onChange={(e) => editField('name', e.target.value)}
              disabled={readOnly}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="description" className="form-input-label">
              {t('stake.form.description')}
            </label>
            <div className="p-0">
              <RichTextEditorTextarea
                value={translation?.description || ''}
                placeholder={t('stake.form.description', {
                  context: language,
                })}
                onChange={(value) => editField('description', value)}
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
        <div className="space-y-6 pt-6">
          <h4 className="flex items-center gap-3">
            <MaterialityIcon />
            <div>{t('stake.form.materiality')}</div>
          </h4>
          <div className="flex flex-col gap-1">
            <label htmlFor="description" className="form-input-label">
              {t('stake.form.materiality_financial')}
            </label>
            <p className="text-gray-500 text-sm">
              {t('stake.form.materiality_financial_description')}
            </p>
            <div className="p-0">
              <RichTextEditorTextarea
                value={translation?.financialMaterialityDescription || ''}
                placeholder={t('stake.form.description', {
                  context: language,
                })}
                onChange={(value) =>
                  editField('financialMaterialityDescription', value)
                }
                disabled={readOnly}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="description" className="form-input-label">
              {t('stake.form.materiality_impact')}
            </label>
            <p className="text-gray-500 text-sm">
              {t('stake.form.materiality_impact_description')}
            </p>
            <div className="p-0">
              <RichTextEditorTextarea
                value={translation?.impactMaterialityDescription || ''}
                placeholder={t('stake.form.description', {
                  context: language,
                })}
                onChange={(value) =>
                  editField('impactMaterialityDescription', value)
                }
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
      </div>
      {!readOnly && (
        <div className="flex gap-2 justify-between px-6 py-4 border-t border-gray-100">
          <DeleteStakeButton stake={stake} />
          <button
            className="primary"
            onClick={() => editStake()}
            disabled={isUpdating || !isValid}
          >
            {isUpdating ? <Loader /> : t('global:save')}
          </button>
        </div>
      )}
    </div>
  );
}
