import { CriteriaIro } from '../graphql/generated';

interface WithScaleCriteriaConfig {
  scaleCriteria: CriteriaIro;
}
interface WithScopeCriteriaConfig {
  scopeCriteria: CriteriaIro;
}
interface WithIrremediabilityCriteriaConfig {
  irremediabilityCriteria: CriteriaIro;
}
interface WithLikelihoodCriteriaConfig {
  likelihoodCriteria: CriteriaIro;
}

export function hasScaleCriteriaConfig<T extends WithScaleCriteriaConfig>(
  iroAssessment?: unknown | null | undefined,
): iroAssessment is T {
  if (!(iroAssessment as WithScaleCriteriaConfig).scaleCriteria) {
    return false;
  }
  return true;
}

export function hasScopeCriteriaConfig<T extends WithScopeCriteriaConfig>(
  iroAssessment?: unknown | null | undefined,
): iroAssessment is T {
  if (!(iroAssessment as WithScopeCriteriaConfig).scopeCriteria) {
    return false;
  }
  return true;
}

export function hasIrremediabilityCriteriaConfig<
  T extends WithIrremediabilityCriteriaConfig,
>(iroAssessment?: unknown | null | undefined): iroAssessment is T {
  if (
    !(iroAssessment as WithIrremediabilityCriteriaConfig)
      .irremediabilityCriteria
  ) {
    return false;
  }
  return true;
}

export function hasLikelihoodCriteriaConfig<
  T extends WithLikelihoodCriteriaConfig,
>(iroAssessment?: unknown | null | undefined): iroAssessment is T {
  if (!(iroAssessment as WithLikelihoodCriteriaConfig).likelihoodCriteria) {
    return false;
  }
  return true;
}
