import React from 'react';
import { FeatureEnum, PermissionEnum } from '../graphql/generated';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { LoaderFullscreen } from '../components/layout/Loader';
import { NotFoundScreen } from '../screens/public/NotFoundScreen';
import { useProjectContext } from '../providers/ProjectContextProvider';

interface FeatureGuardProps {
  feature: FeatureEnum;
  requiredPermission?: PermissionEnum;
  strictComparison?: boolean;
  children: React.ReactNode;
}

export const FeatureGuard: React.FC<FeatureGuardProps> = ({
    feature,
    requiredPermission,
    strictComparison = false,
    children,
  }) => {
  const { isGranted, loading } = usePermissionChecker();
  const projectContext = useProjectContext();
  if (loading || !projectContext?.enterprise) {
    return <LoaderFullscreen />;
  }
  if (!isGranted(feature, requiredPermission, strictComparison)) {
    return <NotFoundScreen />;
  }

  return <>{children}</>;
};
