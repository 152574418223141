import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import React from 'react';
import loginIllustration from '../../../../../assets/images/illustrations/login.png';
import { IllustratedExplanationMessage } from '../../../../generic/IllustratedExplanationMessage';
import { UserRow } from './UserRow';
import {
  AuthRolesAndExperimentalFeatures,
  ConsultingCompanyEmployeesList_UserFragment,
  GiveUserAccess_EnterpriseFragment,
} from '../../../../../graphql/generated';
import { InvitationModalButton } from './InvitationModal';
import { useTranslation } from '@hooks/useTranslation';

export function ConsultingCompanyEmployeesList({
  users,
  companyId,
  enterprises,
}: {
  users: ConsultingCompanyEmployeesList_UserFragment[];
  companyId: string;
  enterprises: GiveUserAccess_EnterpriseFragment[];
}) {
  const { t } = useTranslation();
  if (users.length > 0) {
    const admins = users.filter((user) =>
      user.auth?.roles.includes(AuthRolesAndExperimentalFeatures.Administrator),
    );

    return (
      <div className="main-content w-full max-w-5xl p-8 space-y-8">
        <IllustratedExplanationMessage
          illustration={<img src={loginIllustration} alt="" />}
          title="Invitez vos collègues"
          description="Utilisez ce lien pour inviter vos collaborateurs à rejoindre votre espace sur la plateforme Good Steps et ainsi partager tous vos projets !"
        >
          <InvitationModalButton consultingCompanyId={companyId} />
        </IllustratedExplanationMessage>

        <table>
          <thead>
            <tr>
              <th>{t('consultingCompany.users.table.name')}</th>
              <th>{t('consultingCompany.users.table.email')}</th>
              <th>{t('consultingCompany.users.table.lastLogin')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users
              .filter((user) => !user.auth?.disabled)
              .map((user, index) => (
                <UserRow
                  user={user}
                  key={index}
                  companyId={companyId}
                  enterprises={enterprises}
                  isLastAdmin={
                    (admins.length === 1 &&
                      user.auth?.roles.includes(
                        AuthRolesAndExperimentalFeatures.Administrator,
                      )) ||
                    false
                  }
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Info}>{t('global:noResult')}</MessageBox>
    );
  }
}
