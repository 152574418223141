import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../../AppRoutes';
import { useFeatureEnabled } from '@hooks/useFeatureEnabled';
import { ExperimentalFeatures, FeatureEnum } from '../../../graphql/generated';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { useProjectContext } from '../../../providers/ProjectContextProvider';

export const enum PROJECT_HOME_TABS {
  METHODOLOGY = 'METHODOLOGY',
  NOTEBOOK = 'NOTEBOOK',
  COMPANY = 'COMPANY',
  USERS = 'USERS',
  SETTINGS = 'SETTINGS',
  ROLES = 'ROLES',
}

export const useProjectTabs = () => {
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker();
  const projectContext = useProjectContext()
  const isTemplate = projectContext?.enterprise?.isTemplate

  const tabs = []

  if (isGranted(FeatureEnum.ProjectMethodology)) {
    tabs.push(
      {
        key: PROJECT_HOME_TABS.METHODOLOGY,
        name: t('nav:page.project.methodology'),
        path: AppRoutes.Project,
      })
  }
  if (isGranted(FeatureEnum.ProjectNotes)) {
    tabs.push(
      {
        key: PROJECT_HOME_TABS.NOTEBOOK,
        name: t('nav:page.project.notes'),
        path: AppRoutes.ProjectNotebook,
      })
  }
  if (isGranted(FeatureEnum.ProjectCompany)) {
    tabs.push(
      {
        key: PROJECT_HOME_TABS.COMPANY,
        name: t('nav:page.project.company'),
        path: AppRoutes.ProjectCompany,
      })
  }
  if (isGranted(FeatureEnum.ProjectUsers)) {
    tabs.push(
      {
        key: PROJECT_HOME_TABS.USERS,
        name: t('nav:page.project.users'),
        path: AppRoutes.ProjectUsers,
        disabled: isTemplate,
      })
    }
  if (isGranted(FeatureEnum.ProjectUsers)) {
    tabs.push(
      {
        key: PROJECT_HOME_TABS.ROLES,
        name: t('nav:page.project.roles'),
        path: AppRoutes.ProjectRoles,
      });
  }
  const isSettingsEnabled = useFeatureEnabled(ExperimentalFeatures.CompanySettings);
  if (isSettingsEnabled) {
    tabs.push({
      key: PROJECT_HOME_TABS.SETTINGS,
      name: t('nav:page.project.settings'),
      path: AppRoutes.ProjectSettings,
    });
  }

  return tabs;
};
