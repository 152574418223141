import {
  MarkActionAsTemplateButtonFragment,
  useActionDuplicateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { Loader } from '../../generic/Loader';
import { useToast } from '../../layout/Toast';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { ResultsIcon } from '../../icons';

export function MarkActionAsTemplateButton({
  action,
}: {
  action: MarkActionAsTemplateButtonFragment;
}) {
  const { currentUser } = useCurrentUser();
  const toast = useToast();
  const [updateActionMutation] = useActionDuplicateMutation();
  const [isUpdating, setIsUpdating] = useState(false);
  const markAsTemplate = () => {
    setIsUpdating(true);
    updateActionMutation({
      variables: {
        input: {
          actionId: action.id,
          companyId: currentUser?.company?.id || '',
          isTemplate: true,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(
          "L'action a bien été importée dans vos modèles",
        );
      })
      .catch((err) => console.error(err))
      .finally(() => setIsUpdating(false));
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isUpdating}
      onClick={markAsTemplate}
    >
      {isUpdating ? <Loader /> : <ResultsIcon />}
      <span>Ajouter à vos modèles</span>
    </button>
  );
}
