import { useForm } from 'react-hook-form';
import { useTranslation } from '@hooks/useTranslation';

import React, { useState } from 'react';
import {
  CriteriaIro,
  CriteriaIroInput,
  IroType,
  IrremediabilityValue,
  LikelihoodValue,
  ScaleValue,
  ScopeValue,
  TimeHorizon,
} from '../../../../../../graphql/generated';
import { useIroCriteriaDefinitionDrawer } from '../../../../iro/evaluation/iroCriteriaDefinition/OpenCriteriaDefinitionDrawer';
import { InfoIcon } from '../../../../../icons';
import { ToggleCriteriaRequired } from './ToggleCriteriaRequired';

export const IroAssessmentCriteriaInputs = ({
  iroAssessmentCriteria,
  setIroAssessmentCriteria,
  iroType,
  scaleType,
  title,
  descriptionPlaceholder,
}: {
  iroAssessmentCriteria: CriteriaIroInput | null | undefined;
  setIroAssessmentCriteria: (criteria: CriteriaIro) => void;
  iroType: IroType;
  scaleType:
    | typeof ScaleValue
    | typeof ScopeValue
    | typeof LikelihoodValue
    | typeof IrremediabilityValue
    | typeof TimeHorizon;
  title: string;
  descriptionPlaceholder: string;
}) => {
  const { register, handleSubmit, getValues } = useForm();
  const [required, setRequired] = useState<boolean>(
    iroAssessmentCriteria?.required || false,
  );
  const { t } = useTranslation();
  const onUpdateRequired = (isRequired: boolean) => {
    const iroAssessmentCriteriaUpdate = {
      ...iroAssessmentCriteria,
      required: isRequired,
      description: getValues('description')?.toString() || '',
    };
    setIroAssessmentCriteria(iroAssessmentCriteriaUpdate);
  };
  const onUpdate = () => {
    onUpdateRequired(required);
  };

  const { openCriteriaDefinitionDrawer } =
    useIroCriteriaDefinitionDrawer(scaleType);

  return (
    <div className="w-full flex flex-col gap-2 bg-blue-50 p-4 rounded-lg">
      <div className="w-full flex justify-between items-center">
        <div className="font-semibold mr-2">{title}</div>
        <button
          className="unstyled"
          onClick={() => openCriteriaDefinitionDrawer(iroType)}
        >
          <InfoIcon className="w-4 h-4" />
        </button>
        <div className="grow"></div>
        <ToggleCriteriaRequired
          criteria={iroAssessmentCriteria}
          setCriteria={(criteria) => {
            setRequired(criteria.required);
            onUpdateRequired(criteria.required);
          }}
        />
      </div>

      <div className="w-full">
        <input
          type="text"
          className="w-full form-input-text"
          placeholder={descriptionPlaceholder}
          {...register(`description`)}
          onBlur={handleSubmit(onUpdate)}
          defaultValue={iroAssessmentCriteria?.description ?? ''}
        />
      </div>
    </div>
  );
};
