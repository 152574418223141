import React from 'react';
import {
  QuestionAnswersDataviz_AnswerFragment,
  QuestionAnswersDataviz_QuestionFragment,
  SurveyQuestionType,
} from '../../../graphql/generated';
import { SurveyTextAnswers } from '../analysisThemes/SurveyTextAnswers';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { MultipleChoiceChart } from './questionsDataviz/MultipleChoiceChart';
import { EnpsCharts } from './questionsDataviz/EnpsCharts';
import { ScoringChart } from './questionsDataviz/ScoringChart';
import { StakesRankingDataviz } from './questionsDataviz/StakesRankingDataviz';
import { UtopiesDoubleMaterialityArrays } from './questionsDataviz/custom/UtopiesDoubleMaterialityArrays';
import { SurveyIdentityAnswers } from './questionsDataviz/SurveyIdentityAnswers';
import { DoubleMaterialityArrays } from './questionsDataviz/materiality/DoubleMaterialityArrays';
import { SimpleMaterialityArray } from './questionsDataviz/materiality/SimpleMaterialityArray';
import { useTranslation } from '@hooks/useTranslation';
import { IroAssessmentsDataviz } from './questionsDataviz/iroAssessment/IroAssessmentsDataviz';

export function QuestionAnswersDataviz({
  question,
  answers,
  isThumbnail,
}: {
  question: QuestionAnswersDataviz_QuestionFragment;
  answers: QuestionAnswersDataviz_AnswerFragment[];
  isThumbnail: boolean;
}) {
  const { t } = useTranslation();
  switch (question.type) {
    case SurveyQuestionType.LongText:
    case SurveyQuestionType.ShortText:
    case SurveyQuestionType.Number:
      return <SurveyTextAnswers question={question} answers={answers} />;
    case SurveyQuestionType.Email:
      return <SurveyIdentityAnswers answers={answers} />;
    case SurveyQuestionType.MultipleChoice:
      return (
        <div className="max-w-xl m-auto">
          <MultipleChoiceChart
            question={question}
            answers={answers}
            isThumbnail={isThumbnail}
          />
        </div>
      );
    case SurveyQuestionType.Enps:
      return (
        <div className="max-w-5xl m-auto">
          <EnpsCharts answers={answers} isThumbnail={isThumbnail} />
        </div>
      );
    case SurveyQuestionType.Matrix:
      return (
        <div className="max-w-5xl m-auto">
          <SimpleMaterialityArray question={question} answers={answers} />
        </div>
      );
    case SurveyQuestionType.Rating:
      return (
        <div className="max-w-5xl m-auto">
          <ScoringChart
            question={question}
            answers={answers}
            isThumbnail={isThumbnail}
          />
        </div>
      );
    case SurveyQuestionType.StakesRanking:
      return (
        <div className="max-w-xl m-auto">
          <StakesRankingDataviz
            question={question}
            answers={answers}
            isThumbnail={isThumbnail}
          />
        </div>
      );
    case SurveyQuestionType.StakeDoubleMateriality:
      return (
        <div className="max-w-5xl m-auto">
          <DoubleMaterialityArrays
            question={question}
            answers={answers}
            isThumbnail={isThumbnail}
          />
        </div>
      );
    case SurveyQuestionType.IroAssessment:
      return (
        <div className="m-auto">
          <IroAssessmentsDataviz answers={answers} />
        </div>
      );
    case SurveyQuestionType.UtopiesStakeDoubleMateriality:
      return (
        <div className="max-w-5xl m-auto">
          <UtopiesDoubleMaterialityArrays
            question={question}
            answers={answers}
            isThumbnail={isThumbnail}
          />
        </div>
      );
    default:
      return (
        <MessageBox type={MessageBoxType.Warning}>
          {t('global:featureNotImplemented')}
        </MessageBox>
      );
  }
}
