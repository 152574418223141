import { SurveyQuestionType, SurveyTarget } from '../graphql/generated';
import { ProjectContextType } from '../providers/ProjectContextProvider';
import { CurrentUser } from '../providers/CurrentUserProvider';
import { useTranslation } from '@hooks/useTranslation';

// Local storage management
export function getSurveyAnswersFromLocalStorage(surveyId: string) {
  return localStorage.getItem(`survey-${surveyId}-answers`);
}
export function storeSurveyAnswersToLocalStorage(
  surveyId: string,
  stringifiedAnswers: string,
) {
  localStorage.setItem(`survey-${surveyId}-answers`, stringifiedAnswers);
}
export function deleteSurveyAnswersToLocalStorage(surveyId: string) {
  localStorage.removeItem(`survey-${surveyId}-answers`);
}

export function sanitizeTypeformString(
  text: string,
  projectContext: ProjectContextType | null,
  currentUser: CurrentUser,
) {
  const getCompanyName = () => {
    if (projectContext?.enterprise) {
      return projectContext.enterprise.name;
    } else if (currentUser?.company?.name) {
      return currentUser.company.name;
    }
    return 'entreprise';
  };

  return text
    .replace(/\*/g, '')
    .replace(/_/g, '')
    .replace(/({{hidden:company}})/g, getCompanyName())
    .replace(/({{ENTREPRISE}})/g, getCompanyName());
}

export function getSurveyTargetLabel(type: SurveyTarget): string {
  const { t } = useTranslation();
  switch (type) {
    case SurveyTarget.Collaborators:
      return t('survey.edit.targetType.internal');
    case SurveyTarget.External:
      return t('survey.edit.targetType.internal');
    case SurveyTarget.Ceo:
      return t('survey.edit.targetType.ceo');
    default:
      return t('survey.edit.targetType.default');
  }
}

export function getQuestionTypeLabel(type: SurveyQuestionType): string {
  const { t } = useTranslation();
  switch (type) {
    case SurveyQuestionType.Group:
      return t('survey.edit.questions.type.group.name');
    case SurveyQuestionType.MultipleChoice:
      return t('survey.edit.questions.type.multipleChoice.name');
    case SurveyQuestionType.Dropdown:
      return t('survey.edit.questions.type.dropdown.name');
    case SurveyQuestionType.Matrix:
      return t('survey.edit.questions.type.matrix.name');
    case SurveyQuestionType.MaturityEvaluation:
      return t('survey.edit.questions.type.maturityEvaluation.name');
    case SurveyQuestionType.Statement:
      return t('survey.edit.questions.type.statement.name');
    case SurveyQuestionType.LongText:
    case SurveyQuestionType.ShortText:
      return t('survey.edit.questions.type.shortText.name');
    case SurveyQuestionType.Number:
      return t('survey.edit.questions.type.number.name');
    case SurveyQuestionType.Email:
      return t('survey.edit.questions.type.identity.name');
    case SurveyQuestionType.Enps:
      return t('survey.edit.questions.type.enps.name');
    case SurveyQuestionType.Rating:
      return t('survey.edit.questions.type.rating.name');
    case SurveyQuestionType.StakesRanking:
      return t('survey.edit.questions.type.stakesRanking.name');
    case SurveyQuestionType.StakeDoubleMateriality:
    case SurveyQuestionType.UtopiesStakeDoubleMateriality:
      return t('survey.edit.questions.type.stakeDoubleMateriality.name');
    case SurveyQuestionType.IroAssessment:
      return t('survey.edit.questions.type.iroAssessment.name');
  }
  return '';
}

export function getPublicLinkToSurvey(surveyId: string): string {
  return `${window.location.origin}/survey/${surveyId}/answer`;
}
