import { SurveyQuestionIcon } from '../../../survey/SurveyQuestionIcon';
import {
  SurveyQuestionsMenuItems_QuestionFragment,
  SurveyQuestionsMenuItems_QuestionsFragment,
  SurveyQuestionType,
  useSurveyQuestionsMenuItems_MoveUnderQuestionMutation,
} from '../../../../graphql/generated';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { EditQuestionMenu } from './EditQuestionMenu';
import { Loader } from '../../../generic/Loader';
import { useToast } from '../../../layout/Toast';
import { AddQuestionButton, AddQuestionButtonStyle } from './AddQuestionButton';
import { StakeTag, StakeTagStyle } from '../../../stake/StakeTag';
import { Toggle } from '../../../generic/form/Toggle';
import { DropIcon, WarningIcon } from '../../../icons';
import { EditSurvey_QuestionableSurveyFragment } from './EditSurvey';
import { useTranslation } from '@hooks/useTranslation';

type DraggableQuestion = {
  dragFrom: string | null;
  dragTo: string | null;
  dragStart: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  dragEnter: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  drop: () => void;
  isDragging: boolean;
};

export function SurveyQuestionsMenuItems({
  survey,
  selectedQuestion,
  setSelectedQuestionId,
  isEditable,
  readOnly = false
}: {
  survey: EditSurvey_QuestionableSurveyFragment;
  selectedQuestion: SurveyQuestionsMenuItems_QuestionFragment | null;
  setSelectedQuestionId: (questionId: string) => void;
  isEditable: boolean;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();
  const maxQuestionPosition: number =
    survey.questions?.reduce((acc, question) => {
      if (question.position > acc) {
        return question.position;
      }
      return acc;
    }, 0) || 0;

  const [displayReferential, setDisplayReferential] = useState<boolean>(false);

  const sortedQuestions = (survey.questions || []).toSorted(
    (a, b) => a.position - b.position,
  );

  useEffect(() => {
    if (sortedQuestions.length > 0) {
      setSelectedQuestionId(sortedQuestions[0]?.id || '');
    }
    /* eslint-disable-next-line */
  }, []);

  const [dragFrom, setDragFrom] = useState<string | null>(null);
  const [dragTo, setDragTo] = useState<string | null>(null);
  const [isSavingDrag, setIsSavingDrag] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const dragStart = (e: React.DragEvent<HTMLDivElement>, id: string) => {
    setIsDragging(true);
    setDragFrom(id);
  };

  const toast = useToast();
  const [moveQuestionMutation] =
    useSurveyQuestionsMenuItems_MoveUnderQuestionMutation();

  const dragEnter = (e: React.DragEvent<HTMLDivElement>, id: string) => {
    setDragTo(id);
  };

  const drop = () => {
    setIsDragging(false);
    if (dragFrom) {
      if (dragTo) {
        if (dragFrom === dragTo) {
          // Cancel: we cannot move a question to itself
          setDragFrom(null);
          setDragTo(null);
          return;
        }
        // Perform move
        setIsSavingDrag(true);

        moveQuestionMutation({
          variables: {
            questionId: dragFrom,
            moveUnderQuestionId: dragTo,
          },
        })
          .catch((error) => {
            toast.openToastWithError(error.message);
          })
          .finally(() => {
            setIsSavingDrag(false);
            setDragFrom(null);
            setDragTo(null);
          });

        return;
      }
    }
    // Cancel
    setDragFrom(null);
  };

  const draggableQuestion: DraggableQuestion | null = isEditable
    ? {
        dragFrom,
        dragTo,
        dragStart,
        dragEnter,
        drop,
        isDragging,
      }
    : null;

  return (
    <div className="flex flex-col gap-4 py-4 grow justify-between relative">
      {isSavingDrag && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-500 opacity-50 z-50 flex items-start justify-center pt-60">
          <Loader className="text-white w-12 h-12 z-50" />
        </div>
      )}
      <div className="flex items-center gap-2 px-4">
        <Toggle
          state={displayReferential}
          setState={setDisplayReferential}
        ></Toggle>
        <label className="form-input-label">{t('survey.edit.questions.displayStakes')}</label>
      </div>
      <div className="px-4 grow space-y-1">
        {sortedQuestions.map((question, index) => (
          <QuestionItem
            survey={survey}
            key={question.id}
            question={question}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestionId}
            index={index}
            preview={!isEditable}
            draggableQuestion={draggableQuestion}
            displayReferential={displayReferential}
          />
        ))}
      </div>
      {!readOnly && isEditable && (
        <div className="p-4 bg-white border-t border-gray-100 sticky bottom-0">
          <AddQuestionButton
            survey={survey}
            position={maxQuestionPosition + 1} // TODO: put new question just after the selected question
            style={AddQuestionButtonStyle.BUTTON}
            callback={(newQuestionId) => {
              setSelectedQuestionId(newQuestionId);
            }}
          />
        </div>
      )}
    </div>
  );
}

function QuestionItem({
  survey,
  question,
  selectedQuestion,
  setSelectedQuestion,
  index,
  preview,
  draggableQuestion,
  displayReferential,
}: {
  survey: EditSurvey_QuestionableSurveyFragment;
  question: SurveyQuestionsMenuItems_QuestionsFragment;
  selectedQuestion: SurveyQuestionsMenuItems_QuestionFragment | null;
  setSelectedQuestion: (questionId: string) => void;
  index: number;
  preview: boolean;
  draggableQuestion: DraggableQuestion | null;
  displayReferential: boolean;
}) {
  const { isFirst, isLast } = isFirstOrLastQuestionOfArray(
    question,
    survey.questions || [],
  );

  if (question.type === SurveyQuestionType.Group) {
    return (
      <GroupQuestionItem
        survey={survey}
        question={question}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        isFirst={isFirst}
        isLast={isLast}
        index={index + 1}
        preview={preview}
        draggableQuestion={draggableQuestion}
        displayReferential={displayReferential}
      />
    );
  } else {
    return (
      <SimpleQuestionItem
        question={question}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        isFirst={isFirst}
        isLast={isLast}
        index={(index + 1).toString()}
        preview={preview}
        draggableQuestion={draggableQuestion}
        displayReferential={displayReferential}
      />
    );
  }
}

function GroupQuestionItem({
  survey,
  question,
  selectedQuestion,
  setSelectedQuestion,
  isFirst,
  isLast,
  index,
  preview,
  draggableQuestion,
  displayReferential,
}: {
  survey: EditSurvey_QuestionableSurveyFragment;
  question: SurveyQuestionsMenuItems_QuestionsFragment;
  selectedQuestion: SurveyQuestionsMenuItems_QuestionsFragment | null;
  setSelectedQuestion: (questionId: string) => void;
  isFirst: boolean;
  isLast: boolean;
  index: number;
  preview: boolean;
  draggableQuestion: DraggableQuestion | null;
  displayReferential?: boolean;
}) {
  const [hideChildren, setHideChildren] = useState(false);

  const maxQuestionPosition: number =
    question.children?.reduce((acc, child) => {
      if (child.position > acc) {
        return child.position;
      }
      return acc;
    }, 0) || 0;

  const sortedChildrenQuestions = (question.children || []).toSorted(
    (a, b) => a.position - b.position,
  );

  return (
    <div className="py-3">
      <div className="flex justify-between gap-2 pr-1">
        <div className="flex items-center gap-1">
          <div
            className={clsx(
              'hover:text-gray-500 text-sm font-bold cursor-pointer',
              selectedQuestion?.id === question.id ||
                selectedQuestion?.parent?.id === question.id
                ? 'text-gray-500'
                : 'text-gray-300',
            )}
            onClick={() => setSelectedQuestion(question.id)}
          >
            {index}. {question.title}
          </div>
          {hideChildren && (
            <div className="text-gray-500 text-sm font-bold">
              ({question.children?.length})
            </div>
          )}
          <button className="unstyled">
            <DropIcon
              className={clsx(
                'text-gray-500 w-4 h-4',
                hideChildren && '-rotate-90',
              )}
              onClick={() => setHideChildren(!hideChildren)}
            />
          </button>
        </div>
        <div className="flex items-center gap-2">
          {!preview && (
            <div className="flex items-center gap-1">
              <AddQuestionButton
                survey={survey}
                position={maxQuestionPosition + 1}
                style={AddQuestionButtonStyle.PLUS_ICON}
                callback={(newQuestionId) => {
                  setSelectedQuestion(newQuestionId);
                }}
                parentId={question.id}
              />
              <EditQuestionMenu
                question={question}
                isFirst={isFirst}
                isLast={isLast}
              />
            </div>
          )}
        </div>
      </div>
      {!hideChildren && (
        <div className="space-y-1 ml-2 mt-2">
          {sortedChildrenQuestions.map((question, childrenIndex) => {
            const { isFirst, isLast } = isFirstOrLastQuestionOfArray(
              question,
              sortedChildrenQuestions || [],
            );
            return (
              <SimpleQuestionItem
                key={question.id}
                question={question}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                isFirst={isFirst}
                isLast={isLast}
                index={index
                  .toString()
                  .concat('.')
                  .concat((childrenIndex + 1).toString())}
                preview={preview}
                draggableQuestion={draggableQuestion}
                displayReferential={displayReferential}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

function SimpleQuestionItem({
  question,
  selectedQuestion,
  setSelectedQuestion,
  isFirst,
  isLast,
  index,
  preview,
  draggableQuestion,
  displayReferential,
}: {
  question: SurveyQuestionsMenuItems_QuestionFragment;
  selectedQuestion: SurveyQuestionsMenuItems_QuestionFragment | null;
  setSelectedQuestion: (questionId: string) => void;
  isFirst: boolean;
  isLast: boolean;
  index: string;
  preview: boolean;
  draggableQuestion: DraggableQuestion | null;
  displayReferential?: boolean;
}) {
  const { t } = useTranslation();
  const isMissingStakeOrPillar = !question.stake && !question.pillar;
  const isMissingStakeAndPillarAndIro =
    !question.stake ||
    !question.pillar ||
    question.iroAssessment?.iros?.length === 0;
  const needsStakeOrPillar = [
    SurveyQuestionType.StakesRanking,
    SurveyQuestionType.Matrix,
    SurveyQuestionType.MaturityEvaluation,
    SurveyQuestionType.UtopiesStakeDoubleMateriality,
    SurveyQuestionType.StakeDoubleMateriality,
  ].includes(question.type);

  const needsStakeAndPillarAndIro = [SurveyQuestionType.IroAssessment].includes(
    question.type,
  );

  return (
    <div>
      <div
        id={`drag-from-${question.id}`}
        draggable={draggableQuestion !== null}
        onDragStart={(e) => draggableQuestion?.dragStart(e, question.id)}
        onDragEnd={() => draggableQuestion?.drop()}
        className={clsx(
          'group border-2 rounded-xl p-4 flex items-center gap-3 text-sm shadow-sm cursor-pointer hover:border-gray-900 hover:text-gray-900',
          selectedQuestion?.id === question.id
            ? 'border-gray-900 text-gray-900'
            : 'border-gray-100 text-gray-500',
        )}
        onClick={() => {
          setSelectedQuestion(question.id);
        }}
      >
        <div
          className={clsx(
            'font-semibold my-2 group-hover:text-gray-500',
            selectedQuestion?.id === question.id
              ? 'text-gray-500'
              : 'text-gray-300',
          )}
        >
          {index}
        </div>
        <SurveyQuestionIcon questionType={question.type} />
        <div className="grow space-y-1">
          <div className="font-semibold">
            {question.title.substring(0, 100)}
            {question.title.length > 100 && '...'}
            {question.required && (
              <span className="text-red-300 text-xs ml-1">*</span>
            )}
          </div>
          {needsStakeOrPillar && isMissingStakeOrPillar && (
            <div className="font-semibold rounded-3xl py-1 px-2 flex items-center gap-2 shrink-0 w-fit text-xs border bg-red-50 border-red-100 text-red-500">
              <WarningIcon className="w-5 h-5" />
              {t('translation:survey.question.menu.noPillarOrStakeSelected')}
            </div>
          )}
          {needsStakeAndPillarAndIro && isMissingStakeAndPillarAndIro && (
            <div className="font-semibold rounded-3xl py-1 px-2 flex items-center gap-2 shrink-0 w-fit text-xs border bg-red-50 border-red-100 text-red-500">
              <WarningIcon className="w-5 h-5" />
              {t('translation:survey.question.menu.stakeWithIROIsRequired')}
            </div>
          )}
          {displayReferential && (question.stake || question.pillar) && (
            <StakeTag
              stake={question.stake}
              pillar={question.pillar}
              style={StakeTagStyle.GRAYED}
            />
          )}
        </div>
        {!preview && (
          <div className="opacity-0 group-hover:opacity-100">
            <EditQuestionMenu
              question={question}
              isFirst={isFirst}
              isLast={isLast}
            />
          </div>
        )}
      </div>
      {draggableQuestion && draggableQuestion?.dragFrom !== question.id && (
        <div
          className={clsx(
            'w-full border-dashed text-center italic font-semibold text-xs rounded-lg flex items-center justify-center transition-all',
            draggableQuestion?.isDragging
              ? draggableQuestion.dragTo === question.id
                ? 'mt-2 h-12 border-2 border-gray-500 text-gray-900'
                : 'mt-2 h-1 border-t-2 border-gray-300 text-transparent'
              : 'mt-0 h-0 border-0 text-transparent',
          )}
          onDragOver={(e) => draggableQuestion?.dragEnter(e, question.id)}
        >
          {t('translation:survey.question.menu.dropHere')}
        </div>
      )}
    </div>
  );
}

function isFirstOrLastQuestionOfArray(
  question: SurveyQuestionsMenuItems_QuestionFragment,
  questions: SurveyQuestionsMenuItems_QuestionFragment[],
): { isFirst: boolean; isLast: boolean } {
  const writableQuestionArray = [...(questions || [])];
  // Detect if question is first or last
  // First, extract all questions at the same level
  const questionsAtTheSameLevel = question.parent
    ? writableQuestionArray.filter((q) => q.parent?.id === question.parent?.id)
    : writableQuestionArray.filter((q) => !q.parent);
  // Sort them
  const sortedQuestionsAtTheSameLevel = questionsAtTheSameLevel.toSorted(
    (a, b) => a.position - b.position,
  );
  // Detect if question is first or last
  const isFirst =
    sortedQuestionsAtTheSameLevel &&
    sortedQuestionsAtTheSameLevel.length > 0 &&
    sortedQuestionsAtTheSameLevel?.[0].id === question.id;
  const isLast =
    sortedQuestionsAtTheSameLevel &&
    sortedQuestionsAtTheSameLevel.length > 0 &&
    sortedQuestionsAtTheSameLevel?.[sortedQuestionsAtTheSameLevel.length - 1]
      .id === question.id;
  return { isFirst, isLast };
}
