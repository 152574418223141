import { useParams } from 'react-router-dom';
import {
  SurveyLanguage,
  useSurveyResponseScreen_ResponseQuery,
  useSurveyResponseScreen_SurveyQuery,
} from '../../graphql/generated';
import { LoaderFullscreen } from '../../components/layout/Loader';
import { MessageBox, MessageBoxType } from '../../components/layout/MessageBox';
import React from 'react';
import {
  SURVEY_RESPONSE_MODE,
  SurveyResponse,
} from '../../components/survey/response/SurveyResponse';
import { getSurveyAnswersFromLocalStorage } from '../../services/SurveyService';
import { useTranslation } from '@hooks/useTranslation';
import { SurveyResponseProvider } from '../../components/survey/response/SurveyResponseContext';

export function SurveyResponseScreen() {
  const { surveyId } = useParams();
  const { responseId } = useParams(); // We may pass a responseId in the URL to edit an existing response

  if (surveyId) {
    return (
      <SurveyResponseProvider surveyId={surveyId}>
        <SurveyResponseScreenInner
          surveyId={surveyId}
          responseId={responseId}
        />
      </SurveyResponseProvider>
    );
  }

  return (
    <MessageBox type={MessageBoxType.Error}>
      Questionnaire non trouvé
    </MessageBox>
  );
}

function SurveyResponseScreenInner({
  surveyId,
  responseId,
}: {
  surveyId: string;
  responseId?: string;
}) {
  const { i18n } = useTranslation();

  // TODO FIXME: this query is fired 2 times, because of auth/currentUser loading
  const surveyQuery = useSurveyResponseScreen_SurveyQuery({
    variables: { id: surveyId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // Switch to survey language
      i18n
        .changeLanguage(data.questionableSurvey.language || SurveyLanguage.Fr)
        .catch((e) => console.error(e));
    },
  });

  // When editing an existing response, fetch it
  const getResponseQuery = useSurveyResponseScreen_ResponseQuery({
    variables: { id: responseId || '' },
    skip: !responseId,
  });

  if (surveyQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {surveyQuery.error.message}
      </MessageBox>
    );
  }

  const localStorageContent = getSurveyAnswersFromLocalStorage(surveyId || '');
  const responseMode: SURVEY_RESPONSE_MODE = responseId
    ? SURVEY_RESPONSE_MODE.EDIT_EXISTING_RESPONSE
    : localStorageContent
      ? SURVEY_RESPONSE_MODE.CONTINUE_STARTED_RESPONSE
      : SURVEY_RESPONSE_MODE.NEW_RESPONSE;

  if (surveyQuery.data?.questionableSurvey) {
    return (
      <SurveyResponseProvider surveyId={surveyId ?? ''}>
        <SurveyResponse
          survey={surveyQuery.data.questionableSurvey}
          initialResponseMode={responseMode}
          response={getResponseQuery.data?.surveyResponse}
        />
      </SurveyResponseProvider>
    );
  } else {
    if (surveyQuery.loading) {
      return (
        <div className="h-screen w-full">
          <LoaderFullscreen />
        </div>
      );
    } else {
      return (
        <MessageBox type={MessageBoxType.Error}>
          Consultation non trouvé
        </MessageBox>
      );
    }
  }
}
