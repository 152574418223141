import React, { ReactNode } from 'react';
import clsx from 'clsx';

export enum TagColor {
  purple = 'purple',
  green = 'green',
  blue = 'blue',
  yellow = 'yellow',
  red = 'red',
  gray = 'gray',
}

interface Props {
  children: ReactNode;
  color?: 'purple' | 'blue' | 'green' | 'yellow' | 'red' | 'gray' | TagColor;
  small?: boolean;
}

export const Tag = ({ children, color = 'purple', small = false }: Props) => {
  return (
    <div className="inline-block shrink-0">
      <div className={clsx('tag', color, small && 'small')}>{children}</div>
    </div>
  );
};
