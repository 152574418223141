import {
  AuthRoles,
  AuthRolesAndExperimentalFeatures,
  CompanySummaryFieldsFragment,
  EnterpriseSummaryFieldsFragment,
  RoadmapSummaryFieldsFragment,
  UserRowFragment,
} from '../graphql/generated';
import { isEnterprise } from '../types/companies.types';
import { CurrentUser } from '../providers/CurrentUserProvider';

export function isCoachUser(currentUser: CurrentUser): boolean {
  return currentUser?.company?.__typename === 'ConsultingCompany';
}

export function isSuperadminUser(currentUser: CurrentUser): boolean {
  return (
    (currentUser?.auth?.roles || []).filter((role) =>
      [AuthRoles.Superadmin.valueOf()].includes(role),
    ).length > 0
  );
}

export const findRole = (
  user: UserRowFragment,
): AuthRolesAndExperimentalFeatures => {
  if (
    user.auth?.roles.includes(AuthRolesAndExperimentalFeatures.Administrator)
  ) {
    return AuthRolesAndExperimentalFeatures.Administrator;
  }
  return AuthRolesAndExperimentalFeatures.Coach;
};

export function getLatestCompanyRoadmapSummary(
  company: CompanySummaryFieldsFragment | undefined | null,
): RoadmapSummaryFieldsFragment | undefined {
  if (
    isEnterprise<
      CompanySummaryFieldsFragment & EnterpriseSummaryFieldsFragment
    >(company) &&
    company?.roadmaps &&
    company.roadmaps.length > 0
  ) {
    return company.roadmaps[company.roadmaps.length - 1];
  }
}
