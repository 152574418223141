import React, { useEffect, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  IroAssessmentDataviz_Answer_IroAssessmentFragment,
  IroConsultationResultsModal_IroFragment,
  useIroConsultationResultsModalQuery,
} from '../../../../../graphql/generated';
import { IroAssessmentsTable } from '../../../survey/questionsDataviz/iroAssessment/IroAssessmentsTable';
import Cookies from 'js-cookie';
import { ITEMS_PER_PAGE_COOKIE } from '../../../../survey/surveyTrackingResponses/responsesList/SurveyResponsesTable';
import { IroConsultationResultsPager } from './IroConsultationResultsPager';
import { TableSkeletonLoader } from '../../../../generic/loader/TableSkeletonLoader';
import { iroTypenameToIroType } from '../../iroTypenameToIroType';

export const IroConsultationResultsModal = ({
  iro,
}: {
  iro: IroConsultationResultsModal_IroFragment;
}) => {
  const { translateProperty } = useTranslation();
  const { t } = useTranslation();
  const initialItemsPerPage = parseInt(
    Cookies.get(ITEMS_PER_PAGE_COOKIE) || '20',
    10,
  );
  const [numberPerPage, setNumberPerPage] = useState(initialItemsPerPage);
  const [cursor, setCursor] = useState<{
    before: string | undefined;
    after: string | undefined;
  }>({ before: undefined, after: undefined });
  const paging = {
    first: cursor.after || !cursor.before ? numberPerPage : undefined,
    last: cursor.before ? numberPerPage : undefined,
    after: cursor.after,
    before: cursor.before,
  };

  useEffect(() => {
    Cookies.set(ITEMS_PER_PAGE_COOKIE, numberPerPage.toString());
  }, [numberPerPage]);
  const { data } = useIroConsultationResultsModalQuery({
    variables: {
      iroId: iro.id,
      paging,
    },
    fetchPolicy: 'network-only',
  });
  const rows: IroAssessmentDataviz_Answer_IroAssessmentFragment[] = [];

  data?.surveyAnswerIroAssessments.edges.forEach((item) => {
    rows.push(item.node);
  });
  const next = () => {
    setCursor({
      before: undefined,
      after: data?.surveyAnswerIroAssessments?.pageInfo.endCursor,
    });
  };

  const previous = () => {
    setCursor({
      before: data?.surveyAnswerIroAssessments?.pageInfo.startCursor,
      after: undefined,
    });
  };

  return (
    <div className="flex flex-col space-y-10">
      {(!data?.surveyAnswerIroAssessments && (
        <TableSkeletonLoader columns={6} rows={6} />
      )) || (
        <>
          {((data?.surveyAnswerIroAssessments?.edges?.length ?? 0) > 0 && (
            <>
              <h4>{translateProperty(iro, 'name')}</h4>
              <IroAssessmentsTable
                data={rows}
                iroType={iroTypenameToIroType(iro.__typename)}
              />
              <IroConsultationResultsPager
                {...{
                  numberPerPage,
                  setNumberPerPage,
                  pageInfo: data!.surveyAnswerIroAssessments.pageInfo,
                  previous,
                  next,
                }}
              />
            </>
          )) || (
            <>
              <div className="flex justify-center items-center my-24">
                <div className="text-gray-600 text-xl">
                  {t('global:noResult')}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
