import React from 'react';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { IndicatorCollectSummary } from '../../../../components/project/indicatorCollect/summary/IndicatorCollectSummary';
import { Hero } from '../../../../components/generic/Hero';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { FeatureEnum, PermissionEnum } from '../../../../graphql/generated';

function IndicatorCollectSummaryScreenInner() {
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker();
  const readOnly = isGranted(
    FeatureEnum.IndicatorsCollect,
    PermissionEnum.Read,
    true,
  );

  return (
    <>
      <Breadcrumb />
      <PageTitle title={t('screen.indicator_collect.summary.title')} />
      <Hero
        title={t('screen.indicator_collect.summary.title')}
        subtitle={t('screen.indicator_collect.summary.subtitle')}
      />
      <div className="w-full">
        <IndicatorCollectSummary readOnly={readOnly} />
      </div>
    </>
  );
}

export const IndicatorCollectSummaryScreen = withProjectContext(
  IndicatorCollectSummaryScreenInner,
  {
    requiredCompany: true,
  },
);
