import React from 'react';
import { LoaderFullscreen } from '../components/layout/Loader';
import { HomeScreen } from '../screens/project/home/HomeScreen';
import { useProjectContext } from './ProjectContextProvider';
import { useCurrentUser } from './CurrentUserProvider';
import { isCoachUser } from '../services/CompanyService';
import { useToast } from '../components/layout/Toast';

type ProjectContextRequiredProps = {
  requiredCompany: boolean;
};

export const withProjectContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  options?: ProjectContextRequiredProps,
) => {
  return function WithProContext(props: P) {
    const projectContext = useProjectContext();
    const { currentUser } = useCurrentUser();
    const isCoach = isCoachUser(currentUser);
    const toast = useToast()

    if (projectContext?.isLoadingEnterprise) {
      return (
        <div className="w-full h-screen bg-white">
          {projectContext?.isLoadingEnterprise}
          <LoaderFullscreen />
        </div>
      );
    }

    if (options?.requiredCompany) {
      if (!projectContext?.isLoadingEnterprise) {
        if (((isCoach && !projectContext?.enterprise))) {
          return <HomeScreen />;
        }
        if (currentUser && !currentUser?.company) {
          toast.openToastWithError('Need a company')
          return <HomeScreen />;
        }
      }
    }

    return <WrappedComponent {...props} />;
  };
};
