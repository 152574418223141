import React from 'react';
import { useNotebookQuery } from '../../../graphql/generated';
import { LoaderFullscreen } from '../../layout/Loader';
import { Note } from './Note';
import { CreateNoteButton } from './CreateNoteButton';

export function Notebook({
  notebookId,
  readOnly = false,
}: {
  notebookId: string;
  readOnly?: boolean;
}) {
  const notebookQuery = useNotebookQuery({
    variables: {
      id: notebookId,
    },
    fetchPolicy: 'network-only',
  });

  if (notebookQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (!notebookQuery.data?.getNotebook) {
    return null;
  }

  const notes = notebookQuery.data?.getNotebook?.notes || [];
  const sortedNotes = notes.toSorted((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });

  return (
    <div className="space-y-4">
      {sortedNotes.map((note) => (
        <Note key={note.id} note={note} readOnly={readOnly} />
      ))}
      <CreateNoteButton
        notebook={notebookQuery.data?.getNotebook}
        className="primary purple"
        disabled={readOnly}
      />
    </div>
  );
}
