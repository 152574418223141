import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { LoaderFullscreen } from '../../layout/Loader';
import clsx from 'clsx';
import { ChevronUpIcon } from '../../icons';

export enum RichTextEditorTextareaTheme {
  SNOW = 'snow',
  BUBBLE = 'bubble',
}

export default function RichTextEditorTextarea({
  value,
  onChange,
  onBlur,
  placeholder,
  inlined = false,
  disabled = false,
  theme = RichTextEditorTextareaTheme.SNOW,
}: {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  inlined?: boolean;
  disabled?: boolean;
  theme?: RichTextEditorTextareaTheme;
}) {
  const [loading, setLoading] = useState(true);

  const [currentTheme, setCurrentTheme] =
    useState<RichTextEditorTextareaTheme>(theme);

  const sanitizeAndChange = (html: string) => {
    // Clear string if it only contains <p><br></p> or <p><br/></p> or <p></p>
    if (
      html === '<p><br></p>' ||
      html === '<p><br/></p>' ||
      html === '<p></p>'
    ) {
      onChange('');
    } else {
      // FIXME: DOMPurify is not working with ReactQuill
      // const purified = DOMPurify.sanitize(html.trim());
      // onChange(purified);
      onChange(html.trim());
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'p',
    'br',
    'align',
  ];

  if (loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div
      id="editorContainer"
      className="relative"
      onBlur={() => onBlur && onBlur()}
    >
      {!disabled && (
        <div className="absolute top-3 right-3 z-50">
          <button
            className="unstyled"
            onClick={() => {
              setCurrentTheme(
                currentTheme === RichTextEditorTextareaTheme.BUBBLE
                  ? RichTextEditorTextareaTheme.SNOW
                  : RichTextEditorTextareaTheme.BUBBLE,
              );
              setLoading(true);
            }}
          >
            <ChevronUpIcon
              className={clsx(
                'w-4 h-4 text-gray-500 transition-transform transform duration-200',
                currentTheme === RichTextEditorTextareaTheme.BUBBLE &&
                  'rotate-180',
              )}
            />
          </button>
        </div>
      )}
      <ReactQuill
        theme={currentTheme}
        className={clsx(
          'focus:border-0 p-0 text-base',
          inlined ? 'form-input-text-inline' : 'form-input-text',
        )}
        readOnly={disabled}
        modules={modules}
        formats={formats}
        value={value}
        onChange={(
          text: string,
          delta: never,
          source: string,
          editor: never,
        ) => {
          if (source === 'user') {
            // Hacky way to handle only user changes
            // See https://github.com/zenoamaro/react-quill/issues/259
            sanitizeAndChange(text);
          }
        }}
        placeholder={placeholder}
        bounds="#editorContainer"
      />
    </div>
  );
}
