import {
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { ToggleRequired } from './atoms/ToggleRequired';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionRatingForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const updateQuestion = (
    valueMin: number | null | undefined,
    valueMax: number | null | undefined,
  ) => {
    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          title: questionDraft.title,
          valueMin,
          valueMax,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
        <ToggleRequired question={questionDraft} />
      </div>

      <div className="pt-8 space-y-2">
        <h3>{t('survey.edit.questions.content')}</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="valueMin" className="form-input-label mb-2">
              {t('survey.edit.questions.type.number.valueMin')}
            </label>
            <input
              type="number"
              name="valueMin"
              min={0}
              max={(questionDraft.valueMax || 10) - 1}
              id="valueMin"
              className="form-input-text"
              value={questionDraft.valueMin || 0}
              onChange={(e) => {
                const newQuestionDraft = { ...questionDraft };
                newQuestionDraft.valueMin = parseInt(e.target.value);
                setQuestionDraft(newQuestionDraft);
                updateQuestion(
                  parseInt(e.target.value),
                  questionDraft.valueMax,
                );
              }}
            />
          </div>
          <div>
            <label htmlFor="valueMax" className="form-input-label mb-2">
              {t('survey.edit.questions.type.number.valueMax')}
            </label>
            <input
              type="number"
              name="valueMax"
              min={(questionDraft.valueMin || 0) + 1}
              max={10}
              id="valueMax"
              className="form-input-text"
              value={questionDraft.valueMax || 0}
              onChange={(e) => {
                const newQuestionDraft = { ...questionDraft };
                newQuestionDraft.valueMax = parseInt(e.target.value);
                setQuestionDraft(newQuestionDraft);
                updateQuestion(
                  questionDraft.valueMin,
                  parseInt(e.target.value),
                );
              }}
            />
          </div>
        </div>
        <div>
          <label htmlFor="title" className="form-input-label mb-2">
            {t('survey.edit.questions.type.common.title')}
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({ ...questionDraft, title: e.target.value });
            }}
            onBlur={() =>
              updateQuestion(questionDraft.valueMin, questionDraft.valueMax)
            }
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>
      </div>
    </div>
  );
}
