import React from 'react';
import { Loader } from '../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { ApiDownloadRoutes, useDownloadFile } from '@hooks/useDownloadFile';
import { TooltipWrapper } from '../../generic/TooltipWrapper';
import { generatePath } from 'react-router-dom';
import { ExportIcon } from '../../icons';
import { SurveyLanguage } from '../../../graphql/generated';

export function ExportReferentialButton({
  referentialId,
  language,
}: {
  referentialId: string;
  language: SurveyLanguage;
}) {
  const { t } = useTranslation();
  const { downloadFile, loading } = useDownloadFile({
    errorToastMessage: t('toast:survey.export.error'),
    fileName: `${t('nav:page.referential')}.xlsx`,
    path: generatePath(ApiDownloadRoutes.ReferentialExport, {
      referentialId: referentialId,
    }),
    exportToLanguage: language,
  });

  return (
    <TooltipWrapper label={t('referential.export.help')}>
      <button className="tertiary" onClick={downloadFile} disabled={loading}>
        {loading ? <Loader /> : <ExportIcon />}
      </button>
    </TooltipWrapper>
  );
}
