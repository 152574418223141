import React, { useState } from 'react';
import { Toggle } from '../../../../../generic/form/Toggle';
import { QuestionIro_CriteriaFragment } from '../../../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';

export function ToggleCriteriaRequired({
  criteria,
  setCriteria,
}: {
  criteria: QuestionIro_CriteriaFragment | null | undefined;
  setCriteria: (criteria: QuestionIro_CriteriaFragment) => void;
}) {
  const { t } = useTranslation();
  const [isRequired, setIsRequired] = useState<boolean>(
    criteria?.required || false,
  );
  const updateRequiredState = (newState: boolean) => {
    setCriteria({ ...criteria, required: newState });
    setIsRequired(newState);
  };

  return (
    <div className="flex items-center gap-2">
      <Toggle state={isRequired} setState={updateRequiredState} />
      <div className="font-bold text-sm">
        {t('survey.edit.questions.type.common.requiredResponse')}
      </div>
    </div>
  );
}
