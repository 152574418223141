import {
  FeatureEnum,
  PermissionEnum,
  ProSurvey_SurveyFragment,
} from '../../../graphql/generated';
import React, { useEffect, useState } from 'react';
import Tabs, { TabItem, TabItemManager, TabsStyles } from '../../nav/Tabs';
import { SurveySettings } from './SurveySettings';
import { AnalysisThemesList } from '../analysisThemes/AnalysisThemesList';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ChangeSurveyStatusButtons } from './ChangeSurveyStatusButtons';
import { SurveyQuestions } from './SurveyQuestions';
import { ManageStakeholderList } from '../../survey/manageStakeholderList/ManageStakeholderList';
import { EditSurvey } from './editSurvey/EditSurvey';
import { OpenIcon, XIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { SurveyTrackingResponsesTab } from '../../survey/surveyTrackingResponses/SurveyTrackingResponses';
import { useNavContext } from '../../../providers/NavContextProvider';
import { AppRoutes } from '../../../screens/AppRoutes';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { SURVEY_TABS, useSurveyTabs } from './useSurveyTabs';

const enum SURVEY_RESULTS_TABS {
  SURVEY_RESULTS_RESPONSES = 'SURVEY_RESULTS_RESPONSES',
  SURVEY_RESULTS_THEMES = 'SURVEY_RESULTS_THEMES',
}

export function Survey({ survey }: { survey: ProSurvey_SurveyFragment }) {
  const { t } = useTranslation();
  const navContext = useNavContext();
  const navigate = useNavigate();
  const { isGranted } = usePermissionChecker();
  const readOnly = !isGranted(
    FeatureEnum.ConsultationManagement,
    PermissionEnum.Write,
  );

  useEffect(() => {
    navContext?.setIsNavCollapsed(true);
    /* eslint-disable-next-line */
  }, []);

  const { tab } = useParams();
  const tabs: TabItem[] = useSurveyTabs();

  const [currentTab, setCurrentTab] = useState(
    tabs.find((t) => t.key === tab) || tabs[0],
  );
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="px-8 py-4 border-b border-gray-100 shadow-sm flex items-center justify-between">
        <div className="flex items-center gap-1 w-1/3">
          {navContext?.isNavCollapsed && (
            <button
              className="tertiary"
              onClick={() => {
                navigate(AppRoutes.Survey);
                navContext?.setIsNavCollapsed(false);
              }}
            >
              <XIcon />
            </button>
          )}
          <div className="flex gap-1 title-h5 items-center flex-wrap">
            <span>{survey.name}</span>
          </div>
        </div>
        <div className="w-1/3 flex justify-center">
          <Tabs tabsManager={tabsManager} style={TabsStyles.PILLS} />
        </div>
        <div className="w-1/3 flex items-center justify-end gap-2">
          <NavLink to={`/survey/${survey.id}/preview`} target={'_blank'}>
            <button className="secondary small purple">
              <OpenIcon />
              <div>{t('survey.preview.button')}</div>
            </button>
          </NavLink>
          <ChangeSurveyStatusButtons survey={survey} readOnly={readOnly} />
        </div>
      </div>
      <div className="grow overflow-scroll flex">
        <SurveyInnerTab currentTab={currentTab} survey={survey} readOnly={readOnly}/>
      </div>
    </div>
  );
}

function SurveyInnerTab({
  currentTab,
  survey,
  readOnly = false
}: {
  currentTab: TabItem;
  survey: ProSurvey_SurveyFragment;
  readOnly?: boolean;
}) {
  switch (currentTab.key) {
    case SURVEY_TABS.SURVEY_CONTENT:
      return <EditSurvey survey={survey} isPreview={false}/>;
    case SURVEY_TABS.SURVEY_TRACKING_RESPONSES:
      return (
        <div className="main-content py-8 w-full flex flex-col gap-8">
          <SurveyTrackingResponsesTab surveyId={survey.id} />
        </div>
      );
    case SURVEY_TABS.SURVEY_SHARE:
      return (
        <div className="w-full">
          <ManageStakeholderList surveyId={survey.id} readOnly={readOnly} />
        </div>
      );
    case SURVEY_TABS.SURVEY_RESULTS:
      return <SurveyResultsTab surveyId={survey.id} />;
    case SURVEY_TABS.SETTINGS:
      return (
        <div className="w-full">
          <SurveySettings surveyId={survey.id} />
        </div>
      );
    default:
      return null;
  }
}

function SurveyResultsTab({ surveyId }: { surveyId: string }) {
  const { t } = useTranslation();
  const tabs: TabItem[] = [
    {
      key: SURVEY_RESULTS_TABS.SURVEY_RESULTS_RESPONSES,
      name: t('survey.tabs.resultsResponses'),
    },
    {
      key: SURVEY_RESULTS_TABS.SURVEY_RESULTS_THEMES,
      name: t('survey.tabs.resultsThemes'),
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabsManager: TabItemManager = {
    tabs,
    currentTab,
    setCurrentTab,
  };

  switch (currentTab.key) {
    case SURVEY_RESULTS_TABS.SURVEY_RESULTS_RESPONSES:
    default:
      return (
        <div className="w-full">
          <div className="border-b border-gray-100 px-8">
            <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
          </div>
          <div className="w-full p-8">
            <SurveyQuestions surveyId={surveyId} />
          </div>
        </div>
      );
    case SURVEY_RESULTS_TABS.SURVEY_RESULTS_THEMES:
      // Themes are not linked to survey, they are company-wide
      return (
        <div className="w-full">
          <div className="border-b border-gray-100 px-8">
            <Tabs tabsManager={tabsManager} style={TabsStyles.UNDERLINED} />
          </div>
          <div className="w-full p-8">
            <AnalysisThemesList />
          </div>
        </div>
      );
  }
}
