import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebaseAuth } from '../../providers/FirebaseProvider';
import { signInWithEmailAndPassword } from 'firebase/auth';

import logo from '../../assets/images/logo-with-name.svg';
import { PageTitle } from '../../components/nav/PageTitle';
import { useCurrentAuth } from '../../providers/CurrentAuthProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import {
  useCompanyNameQuery,
  useRegisterConsultingCompanyAccountMutation,
} from '../../graphql/generated';
import { LoaderFullscreen } from '../../components/layout/Loader';

import { AppRoutes } from '../AppRoutes';
import { useTranslation } from '@hooks/useTranslation';
import { withRouter } from '../withRouter';
import { useForm } from 'react-hook-form';
import { EyeIcon, EyeStrikeThroughIcon } from '../../components/icons';
import { useToast } from '../../components/layout/Toast';

type RegisterConsultingCompanyScreenProps = {
  params?: {
    companyId: string;
    token: string;
  };
};

type RegisterConsultingCompanyFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};
export const passwordMinLength = 6;

export const RegisterConsultingCompanyScreen = withRouter<RegisterConsultingCompanyScreenProps>(({ params: { companyId, token } }) => {
  const navigate = useNavigate();
  const firebaseAuth = useFirebaseAuth();
  const currentAuth = useCurrentAuth();
  const { currentUser } = useCurrentUser();
  const { register, handleSubmit, formState: { isLoading: isFormLoading, errors } } = useForm<RegisterConsultingCompanyFormData>();
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();
  const toast = useToast()
  const [registerAccount] = useRegisterConsultingCompanyAccountMutation();

  useEffect(() => {
    if (currentAuth && currentUser) {
      navigate(AppRoutes.Home, {
        replace: true,
      });
    }
  }, [currentAuth, currentUser]);

  const { data, loading } = useCompanyNameQuery({
    variables: {
      id: companyId!,
    },
    skip: !companyId,
  });

  // Loader
  if (loading) {
    return (
      <div className="w-full h-screen">
        <LoaderFullscreen />
      </div>
    );
  }

  const submit = (formData: RegisterConsultingCompanyFormData) => {
    registerAccount({
      variables: {
        input: {
          token,
          ...formData
        },
      },
      onCompleted: (response) => {
        const { firstName, email, password } = formData;
        toast.openToastWithMessage(t('toast:registerConsultingCompany.success', {
          consultingCompanyName: data?.companyName,
          firstName
        })); 
        if (response.registerConsultingCompanyAccount.authId) {
          signInWithEmailAndPassword(firebaseAuth, email, password)
        }
      }
    })
  };

  return (
    <>
      <PageTitle
        title={t('nav:register.invitation_title', {
          name: data?.companyName.name,
        })}
      />

      <div className="w-full h-screen overflow-scroll flex flex-col p-8 items-stretch">
        <div className="flex items-center gap-4">
          <img className="h-12 w-auto" src={logo} alt="Good Steps logo" />
          {data?.companyName?.logo && (
            <div className="flex items-center gap-4">
              <div className="font-title font-extrabold text-gray-500 text-xl">
                &
              </div>
              <div className="shrink-0 h-12 flex items-center justify-center">
                <img
                  src={data?.companyName?.logo}
                  className="shrink-0 h-full rounded-md"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="grow flex gap-8 justify-between items-center self-center max-w-6xl">
          <div className="">
            <h1>
              {t('nav:register.invitation_title', {
                name: data?.companyName.name,
              })}
            </h1>

            <>
              <p className="text-gray-500 mt-4">
                {t('nav:register.invitation_message', {
                  name: data?.companyName.name,
                })}
              </p>

              <div className="mt-8">
                <form
                  name="loginForm"
                  className="space-y-6"
                  onSubmit={handleSubmit(submit)}
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <label htmlFor="firstName">
                        {t('registerConsultingCompany.form.firstName')}
                      </label>
                      <input
                        id="firstName"
                        type="text"
                        {...register('firstName', {
                          required: true,
                        })}
                      />
                    </div>

                    <div className="space-y-1">
                      <label htmlFor="lastName">
                        {t(
                          'translation:registerConsultingCompany.form.lastName',
                        )}
                      </label>
                      <input
                        id="lastName"
                        type="text"
                        {...register('lastName', {
                          required: true,
                        })}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="email">
                      {t('registerConsultingCompany.form.email')}
                    </label>
                    <input
                      id="email"
                      type="email"
                      autoComplete="email"
                      {...register('email', {
                        required: true,
                      })}
                    />
                  </div>

                  <div className="space-y-1 relative">
                    <label htmlFor="password">
                      {t(
                        'registerConsultingCompany.form.password.label',
                      )}
                    </label>
                    <input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      {...register('password', {
                        required: true,
                        minLength: {
                          value: passwordMinLength,
                          message: t(
                            'registerConsultingCompany.form.password.validation.minLength',
                            { minLength: passwordMinLength },
                          ),
                        },
                      })}
                    />
                    <button
                      type="button"
                      className="absolute top-2/3 bottom-1/2 right-4 unstyled"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <EyeStrikeThroughIcon className="text-gray-500 hover:text-gray-900" />
                      ) : (
                        <EyeIcon className="text-gray-500 hover:text-gray-900" />
                      )}
                    </button>
                  </div>

                  <div className="flex justify-center">
                    <input
                      className="primary purple w-full"
                      type="submit"
                      value={t('registerConsultingCompany.form.submit')}
                      disabled={isFormLoading}
                    />
                  </div>
                </form>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
});
