import React, { useState } from 'react';
import {
  GradientDropdown,
  GradientDropdownStrength,
} from '../../../../generic/dropdown/GradientDropdown';
import {
  IroScopePicker_NegativeImpactFragment,
  IroScopePicker_PositiveImpactFragment,
  ScopeValue,
  useUpdateIroScopeMutation,
} from '../../../../../graphql/generated';
import { useToast } from '../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { TFunction } from 'i18next';
import { useRefetchMaterialities } from './useRefetchMaterialities';

export type IroScopeItem = {
  id: ScopeValue | null;
  name: string;
  strength: GradientDropdownStrength;
};

export const scopeCriteriaChoices = (t: TFunction): IroScopeItem[] => {
  return [
    {
      id: ScopeValue.Widespread,
      name: t(`enum:iroCriteria.scope.${ScopeValue.Widespread}`),
      strength: GradientDropdownStrength.VeryStrong,
    },
    {
      id: ScopeValue.Large,
      name: t(`enum:iroCriteria.scope.${ScopeValue.Large}`),
      strength: GradientDropdownStrength.Strong,
    },
    {
      id: ScopeValue.Limited,
      name: t(`enum:iroCriteria.scope.${ScopeValue.Limited}`),
      strength: GradientDropdownStrength.Medium,
    },
    {
      id: ScopeValue.Local,
      name: t(`enum:iroCriteria.scope.${ScopeValue.Local}`),
      strength: GradientDropdownStrength.Light,
    },
    {
      id: null,
      name: '-',
      strength: GradientDropdownStrength.VeryLight,
    },
  ];
};

export default function IroScopePicker({
  iro,
  extraPositionClassName,
  readOnly = false,
}: {
  iro:
    | IroScopePicker_PositiveImpactFragment
    | IroScopePicker_NegativeImpactFragment;
  extraPositionClassName?: string;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();
  const items = scopeCriteriaChoices(t);
  const initialScopeItem = items.find(
    (item) => item.id === iro.scopeCriteria?.value || null,
  );
  const [selected, setSelected] = useState(initialScopeItem || null);
  const [update] = useUpdateIroScopeMutation();
  const toast = useToast();
  const refetchQueries = useRefetchMaterialities();

  const chooseItemId = (itemId: string | null) => {
    const item = items.find((item) => item.id === itemId) || null;
    setSelected(item);

    update({
      variables: {
        input: {
          id: iro.id,
          scope: item?.id ?? null,
        },
      },
      refetchQueries,
    })
      .then(() => {
        toast.openToastWithMessage(
          t('toast:iro.criteria.update.success', {
            criteriaName: t('iro.criteria.scope.title'),
          }),
        );
      })
      .catch(() => {
        toast.openToastWithError(t('toast:iro.criteria.scope.update.success'));
      });
  };
  return (
    <GradientDropdown
      {...{
        items,
        selected,
        chooseItemId,
        extraPositionClassName,
        disabled: readOnly,
      }}
    />
  );
}
