import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import {
  PillarFieldsFragment,
  PillarSummaryFieldsFragment,
  useReferentialQuery,
} from '../../graphql/generated';
import { PillarIcon } from '../stake/PillarIcon';
import { Loader } from '../generic/Loader';
import { CheckIcon, ChevronDownIcon, XIcon } from '../icons';
import { Float } from '@headlessui-float/react';
import { useTranslation } from '@hooks/useTranslation';

type PillarDropdownItem = {
  id: string | null;
  label: string;
  pillar: PillarFieldsFragment | PillarSummaryFieldsFragment | null;
};

export default function PillarDropdown({
  currentPillarId,
  setPillarId,
  isFilter = false,
}: {
  currentPillarId: string | null | undefined;
  setPillarId: (item: string | null) => void;
  isFilter?: boolean;
}) {
  const { t, translateProperty } = useTranslation();
  // Fetch pillars
  const projectContext = useProjectContext();
  const companyReferentialDocumentQuery = useReferentialQuery({
    variables: { id: projectContext?.enterprise?.referential?.id || '' },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });
  const referential = companyReferentialDocumentQuery.data?.referential;
  const pillars =
    referential?.pillars.toSorted((a, b) =>
      translateProperty(a, 'name').localeCompare(translateProperty(b, 'name')),
    ) || [];

  const pillarItems = pillars.map((pillar) => ({
    id: pillar.id,
    label: translateProperty(pillar, 'name'),
    pillar: pillar,
  }));

  const allItems = isFilter
    ? [
        { id: null, label: t('pillar.dropdown.all_pillar'), pillar: null },
        ...pillarItems,
      ]
    : pillarItems;

  const chooseItem = (item: PillarDropdownItem) => {
    setPillarId(item.id);
  };
  const currentItem: PillarDropdownItem = allItems.find(
    (availableItem) => availableItem.id === currentPillarId,
  ) || {
    id: '',
    label: '',
    pillar: null,
  };

  return (
    <div className="flex items-center gap-1 shrink-0">
      <Listbox value={currentItem} onChange={chooseItem}>
        {({ open }) => (
          <Float portal adaptiveWidth>
            <ListboxButton className="form-input-select">
              {companyReferentialDocumentQuery.loading ? (
                <Loader />
              ) : (
                <div className="flex items-center gap-2 w-full min-w-[100px] h-5">
                  {currentItem.pillar && (
                    <PillarIcon pillar={currentItem.pillar} />
                  )}
                  {currentItem?.label || ''}
                </div>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm">
                {allItems.map((item: PillarDropdownItem) => (
                  <ListboxOption
                    key={item.id}
                    className={clsx(
                      'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                      'data-[selected]:active data-focus:text-white data-focus:bg-blue-600',
                    )}
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        <div
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'flex items-center gap-2',
                          )}
                        >
                          {item.pillar && <PillarIcon pillar={item.pillar} />}
                          <div>{item.label}</div>
                        </div>

                        {selected ? (
                          <span
                            className={clsx(
                              'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </Float>
        )}
      </Listbox>
      {!isFilter && currentPillarId && (
        <button
          className="unstyled"
          onClick={() => {
            setPillarId(null);
          }}
        >
          <XIcon />
        </button>
      )}
    </div>
  );
}
