import React from 'react';
import { useSignOut } from 'providers/FirebaseProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { MenuIcon, UserIcon } from '../icons';
import { isSuperadminUser } from '../../services/CompanyService';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from '@hooks/useTranslation';
import { ContextualMenu } from '../generic/ContextualMenu';
import ChangeLanguageDropdown from '../form/ChangeLanguageDropdown';
import { AppRoutes } from '../../screens/AppRoutes';
import { useProjectContext } from '../../providers/ProjectContextProvider';

export function UserProfileButton() {
  const { currentUser } = useCurrentUser();
  const projectContext = useProjectContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const signOut = useSignOut();
  const signUserOut = () => {
    signOut()
      .then(() => {
        // Reset URL to home
        navigate('/');

        // Unload current project
        projectContext?.updateEnterprise(null, false);
      })
      .catch((error) => {
        console.error('Failed to sign out', error);
      });
  };

  return (
    <ContextualMenu button={<Button />}>
      <>
        <div onClick={(e) => e.stopPropagation()}>
          <ChangeLanguageDropdown />
        </div>
        {isSuperadminUser(currentUser) && (
          <Link className="contextual-menu-item" to={AppRoutes.Admin}>
            {t('nav:userContextualMenu.admin')}
          </Link>
        )}
        <button className="contextual-menu-item" onClick={signUserOut}>
          {t('nav:userContextualMenu.logout')}
        </button>
      </>
    </ContextualMenu>
  );
}

function Button() {
  const { currentUser } = useCurrentUser();
  const employeeName = currentUser
    ? `${currentUser.firstName} ${currentUser.lastName}`
    : '';

  return (
    <div className="hover:bg-gray-50 flex items-center rounded-xl p-2 w-full gap-3">
      <div className="shrink-0 text-gray-900 h-9 w-9 rounded-full bg-gray-50 flex items-center justify-center border border-gray-100">
        <UserIcon className="h-5 w-5" />
      </div>
      <div className="text-sm font-bold grow text-left">{employeeName}</div>
      <MenuIcon className="shrink-0 h-3.5 w-3.5 text-gray-500 rotate-90" />
    </div>
  );
}
