import React from 'react';
import { PageTitle } from '../../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { IroSummary as IroSummaryContent } from '../../../../components/project/iro/summary/IroSummary';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { ReferentialProvider } from '../../../../components/project/context/ReferentialContext';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import { IRO_TABS, useIroTabs } from '../useIroTabs';
import {
  FeatureEnum,
  PermissionEnum,
  useIroSummaryScreenQuery,
} from '../../../../graphql/generated';
import { IroHeader } from '../IroHeader';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

function IroSummary() {
  const projectContext = useProjectContext();
  const { isGranted } = usePermissionChecker();
  const readOnly = !isGranted(FeatureEnum.IroCotation, PermissionEnum.Write);
  const { t } = useTranslation();
  const companyReferentialDocumentQuery = useIroSummaryScreenQuery({
    variables: {
      referentialId: projectContext?.enterprise?.referential?.id || '',
      companyId: projectContext?.enterprise?.id || '',
    },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });

  const iroTabs = useIroTabs();

  if (companyReferentialDocumentQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (companyReferentialDocumentQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {companyReferentialDocumentQuery.error.message}
      </MessageBox>
    );
  }

  if (companyReferentialDocumentQuery.data?.referential) {
    return (
      <ReferentialProvider
        initialReferential={
          companyReferentialDocumentQuery.data?.referential.id
        }
      >
        <PageTitle title={t('screen.iro.iro_summary')} />
        <Breadcrumb />

        <TabNavigationLayout<IRO_TABS>
          tabs={iroTabs}
          activeTab={IRO_TABS.BY_STAKES}
          renderTabs={(tabsContent) => (
            <div>
              <IroHeader />
              {tabsContent}
            </div>
          )}
        >
          <div className="w-full">
            <IroSummaryContent
              referential={companyReferentialDocumentQuery.data?.referential}
              scores={
                companyReferentialDocumentQuery.data?.computeDoubleMaterialities
              }
              readOnly={readOnly}
            />
          </div>
        </TabNavigationLayout>
      </ReferentialProvider>
    );
  }

  return null;
}

export const IroSummaryScreen = withProjectContext(IroSummary, {
  requiredCompany: true,
});
