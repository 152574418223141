import {
  CurrentUserDocument,
  ProjectContextualMenu_CompanyFragment,
  useProjectContextualMenu_ChooseTemplateMutation,
  useProjectContextualMenu_CreateCompanyMutation,
} from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import { useToast } from '../../../layout/Toast';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import React, { useState } from 'react';
import { Loader } from '../../../generic/Loader';
import { TemplateIcon } from '../../../icons';

export function CreateModelCompanyButton({
  company,
}: {
  company: ProjectContextualMenu_CompanyFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { currentUser } = useCurrentUser();

  const [isDuplicating, setIsDuplicating] = useState(false);

  const [createEnterpriseMutation] =
    useProjectContextualMenu_CreateCompanyMutation();
  const [chooseTemplateMutation] =
    useProjectContextualMenu_ChooseTemplateMutation();

  const duplicate = () => {
    setIsDuplicating(true);
    createEnterpriseMutation({
      variables: {
        input: {
          company: {
            name: t('company.duplicate.generatedName', {
              companyName: company.name,
            }),
            isTemplate: true,
          },
          ownedBy: {
            id: currentUser?.company?.id || '',
          },
        },
      },
    })
      .then((copy) => {
        if (copy?.data?.createEnterprise) {
          chooseTemplateMutation({
            variables: {
              input: {
                companyId: copy.data.createEnterprise.id,
                templateId: company.id,
              },
            },
            refetchQueries: () => [{ query: CurrentUserDocument }],
          })
            .then(() => {
              toast.openToastWithMessage(
                t('project.contextualMenu.createModelCompany.success'),
              );
              setIsDuplicating(false);
            })
            .catch((err) => {
              toast.openToastWithError(err.message);
            });
        }
      })
      .catch((err) => {
        toast.openToastWithError(err.message);
      });
  };

  return (
    <button
      className="contextual-menu-item"
      disabled={isDuplicating}
      onClick={duplicate}
    >
      {isDuplicating ? <Loader /> : <TemplateIcon />}
      {isDuplicating ? (
        <div>{t('project.contextualMenu.createModelCompany.duplicating')}</div>
      ) : (
        <div>{t('project.contextualMenu.createModelCompany.button')}</div>
      )}
    </button>
  );
}
