import {
  MaturityEvaluationsListScreen_MaturityEvaluationFragment,
  SurveyStatus,
  useMaturityEvaluationWithRecipientsQuery,
} from '../../../../graphql/generated';
import { useModal } from '../../../layout/Modal';
import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { ShareSurveyModal } from '../../../survey/ShareSurveyModal';
import { MaturityEvaluationRespondModal } from '../MaturityEvaluationRespondModal/MaturityEvaluationRespondModal';
import { useTranslation } from '@hooks/useTranslation';
import { Loader } from '../../../generic/Loader';
import { MaturityEvaluationContextualMenu } from '../MaturityEvaluationContextualMenu';
import { generatePath, NavLink } from 'react-router-dom';
import { AppRoutes } from '../../../../screens/AppRoutes';
import { TooltipWrapper } from '../../../generic/TooltipWrapper';
import { UserIcon } from '../../../icons';
import React from 'react';
import { MaturityEvaluationStatusTag } from './MaturityEvaluationStatusTag';
import { getDateFromISOString } from '../../../../services/DateService';

export const MaturityEvaluationRow = ({
  maturityEvaluation,
  readOnly = false,
}: {
  maturityEvaluation: MaturityEvaluationsListScreen_MaturityEvaluationFragment;
  readOnly?: boolean;
}) => {
  const modal = useModal();
  const { currentUser } = useCurrentUser();

  // Fetch Recipients data
  const surveyWithRecipientsQuery = useMaturityEvaluationWithRecipientsQuery({
    variables: { id: maturityEvaluation.id },
    fetchPolicy: 'network-only',
  });

  const latestResponse =
    surveyWithRecipientsQuery.data?.surveyLastResponse || null;

  const amIRecipient =
    surveyWithRecipientsQuery.data?.maturityEvaluation?.maturityEvaluationRecipients?.some(
      (recipient) => recipient.email === currentUser?.auth?.email,
    );

  const openShareModal = () => {
    modal.openModalWithComponent(
      <ShareSurveyModal survey={maturityEvaluation} />,
      null,
      false,
      false,
    );
  };

  const openAnswerModal = () => {
    modal.openModalWithComponent(
      <MaturityEvaluationRespondModal
        maturityEvaluation={maturityEvaluation}
      />,
      null,
      true,
      false,
      'w-3/4',
    );
  };
  const { t, i18n } = useTranslation();

  return (
    <tr>
      <td>
        <div className="font-title font-extrabold">
          {maturityEvaluation.name}
        </div>
      </td>
      <td>
        <div className="flex justify-center">
          <MaturityEvaluationStatusTag
            maturityEvaluation={maturityEvaluation}
            completionRate={latestResponse?.completionRate}
          />
        </div>
      </td>
      <td className="text-center">
        {surveyWithRecipientsQuery.loading ? (
          <Loader />
        ) : (
          latestResponse?.completionRate && (
            <div className="font-bold">
              {Math.round(latestResponse?.completionRate * 100)}%
            </div>
          )
        )}
      </td>
      <td>
        {latestResponse?.submittedAt && (
          <div className="text-gray-500">
            {getDateFromISOString(latestResponse.submittedAt, i18n.language)}
          </div>
        )}
      </td>
      <td>
        <div className="flex items-center justify-end gap-2">
          {!readOnly && (
            <>
              <MaturityEvaluationContextualMenu survey={maturityEvaluation} />
              {maturityEvaluation.status === SurveyStatus.Created && (
                <NavLink
                  to={generatePath(AppRoutes.MaturityEvaluationAssign, {
                    surveyId: maturityEvaluation.id,
                  })}
                >
                  <TooltipWrapper
                    place={'left'}
                    label={t(
                      'maturity.evaluation.answerModal.assign.button.tooltip',
                    )}
                  >
                    <button className="primary purple small shrink-0">
                      <UserIcon />
                      <div>
                        {t(
                          'maturity.evaluation.answerModal.assign.button.label',
                        )}
                      </div>
                    </button>
                  </TooltipWrapper>
                </NavLink>
              )}
            </>
          )}
          {!readOnly &&
            maturityEvaluation.status === SurveyStatus.Ready &&
            amIRecipient && (
              <button
                className="primary purple small"
                onClick={() => openAnswerModal()}
              >
                {t('maturity.evaluation.answerModal.respond.button.label')}
              </button>
            )}
          {!readOnly &&
            maturityEvaluation.status === SurveyStatus.Ready &&
            !amIRecipient && (
              <button
                className="secondary purple small"
                onClick={() => openAnswerModal()}
              >
                {t('maturity.evaluation.answerModal.respond.button.label')}
              </button>
            )}
          {maturityEvaluation.status === SurveyStatus.Ready &&
            !amIRecipient && (
              <button
                className="primary purple small"
                onClick={() => openShareModal()}
              >
                {t('maturity.evaluation.answerModal.share.button.label')}
              </button>
            )}
        </div>
      </td>
    </tr>
  );
};
