import React from 'react';
import { ProjectLayout } from '../../components/layout/ProjectLayout';
import { HomeScreen } from '../project/home/HomeScreen';
import { CoachsSettingsScreen } from '../project/coach/settings/CoachsSettingsScreen';
import { CompanySettingsScreen } from '../project/coach/settings/CompanySettingsScreen';
import { TemplatesSettingsScreen } from '../project/coach/settings/TemplatesSettingsScreen';
import { ReferentialScreen } from '../project/referential/ReferentialScreen';
import { IroScreen } from '../project/iro/evaluation/IroScreen';
import { PrioritizationMatrixScreen } from '../project/publications/prioritizationMatrix/PrioritizationMatrixScreen';
import { MaterialityMatrixScreen } from '../project/publications/MaterialityMatrixScreen';
import { RoadmapScreen } from '../project/publications/roadmap/RoadmapScreen';
import { ActionScreen } from '../project/publications/roadmap/ActionScreen';
import { SurveyListScreen } from '../project/survey/SurveyList/SurveyListScreen';
import { ProSurveyResultsScreen } from '../project/survey/ProSurveyResultsScreen';
import { SurveyScreen } from '../project/survey/SurveyScreen';
import { ProStakeholderScreen } from '../project/stakeholder/ProStakeholderScreen';
import { IroSummaryScreen } from '../project/iro/summary/IroSummaryScreen';
import { useTranslation } from '@hooks/useTranslation';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { DoubleMaterialityMatrixIroScreen } from '../project/publications/doubleMaterialityMatrix/DoubleMaterialityMatrixIroScreen';
import { ProConsultationRawMaterialityScreen } from '../project/survey/ConsultationRawMateriality/ProConsultationRawMaterialityScreen';
import { GapAnalysisSummaryScreen } from '../project/gapAnalysis/summary/GapAnalysisSummaryScreen';
import { GapAnalysisDisclosureRequirementScreen } from '../project/gapAnalysis/disclosureRequirement/[referenceSlug]/GapAnalysisDisclosureRequirementScreen';
import { FocusLayout } from '../../components/layout/FocusLayout';
import { ProGapAnalysisSynthesisScreen } from '../project/gapAnalysis/synthesis/ProGapAnalysisSynthesisScreen';
import { LoggedInOnlyGuard } from '../../guards/LoggedInOnlyGuard';
import { AppRoutes } from '../AppRoutes';
import { ProjectNotebookScreen } from '../project/project/ProjectNotebookScreen';
import { ProjectSettingsScreen } from '../project/project/ProjectSettingsScreen';
import { ProjectCompanyScreen } from '../project/project/ProjectCompanyScreen';
import { ProjectUsersScreen } from '../project/project/ProjectUsersScreen';
import { ProjectMethodologyScreen } from '../project/project/ProjectMethodologyScreen';
import { MaturityScreen } from '../project/publications/MaturityScreen';
import { MaturityEvaluationsListScreen } from '../project/maturity/MaturityEvaluationsListScreen';
import { MaturityEvaluationImportScreen } from '../project/maturity/MaturityEvaluationImportScreen';
import { MaturityEvaluationScreen } from '../project/maturity/MaturityEvaluationScreen';
import { MaturityEvaluationAssignScreen } from '../project/maturity/MaturityEvaluationAssignScreen';
import { DoubleMaterialityMatrixStakeholderScreen } from '../project/publications/doubleMaterialityMatrix/DoubleMaterialityMatrixStakeholderScreen';
import { ProGapAnalysisChecklistScreen } from '../project/gapAnalysis/checklist/ProGapAnalysisChecklistScreen';
import { ActionEditScreen } from '../project/publications/roadmap/ActionEditScreen';
import { IndicatorCollectDisclosureRequirementScreen } from '../project/indicatorCollect/disclosureRequirement/[referenceSlug]/IndicatorCollectDisclosureRequirementScreen';
import { IndicatorCollectSummaryScreen } from '../project/indicatorCollect/summary/IndicatorCollectSummaryScreen';
import { IroSettingsScreen } from '../project/iro/settings/IroSettingsScreen';
import { IroMaterialityThresholdScreen } from '../project/iro/thresholds/IroMaterialityThresholdScreen';
import { IroListScreen } from '../project/iro/list/IroListScreen';
import { ProjectRolesScreen } from '../project/project/ProjectRolesScreen';
import { FeatureGuard } from '../../guards/FeatureGuard';
import { FeatureEnum, PermissionEnum } from '../../graphql/generated';
import { ConsultingCompanyAdministratorOnlyGuard } from '../../guards/ConsultingCompanyAdministratorOnlyGuard';

export const useAppRouteConfig = (): BreadcrumbsRoute[] => {
  const { t } = useTranslation();

  return [
    {
      path: AppRoutes.Home,
      element: (
        <LoggedInOnlyGuard>
          <ProjectLayout />
        </LoggedInOnlyGuard>
      ),
      children: [
        {
          index: true,
          breadcrumb: null,
          element: <HomeScreen />,
        },
        {
          path: AppRoutes.Stakes,
          element: (
            <FeatureGuard feature={FeatureEnum.ReferentialManagement}>
              <ReferentialScreen />
            </FeatureGuard>
          ),
          breadcrumb: t('nav:page.referential'),
        },
        {
          path: AppRoutes.Iro,
          children: [
            {
              index: true,
              element: (
                <FeatureGuard feature={FeatureEnum.IroCotation}>
                  <IroSummaryScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.iro_rating'),
            },
            {
              path: AppRoutes.IroSettings,
              element: (
                <FeatureGuard
                  feature={FeatureEnum.IroCotation}
                  requiredPermission={PermissionEnum.Write}
                >
                  <IroSettingsScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.iro.settings'),
            },
            {
              path: AppRoutes.IroMaterialityThresholds,
              element: (
                <FeatureGuard feature={FeatureEnum.IroCotation}>
                  <IroMaterialityThresholdScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.iro.materiality_thresholds'),
            },
            {
              path: AppRoutes.IroList,
              element: (
                <FeatureGuard feature={FeatureEnum.IroCotation}>
                  <IroListScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.iro.byIro'),
            },
            {
              path: AppRoutes.IroRating,
              breadcrumb: '',
              children: [
                {
                  index: true,
                  element: (
                    <FeatureGuard feature={FeatureEnum.IroCotation}>
                      <IroScreen />
                    </FeatureGuard>
                  ),
                  breadcrumb: t('screen.iro.iro_rating'),
                },
              ],
            },
          ],
        },
        {
          path: AppRoutes.GapAnalysis,
          children: [
            {
              index: true,
              element: (
                <FeatureGuard feature={FeatureEnum.GapAnalysisManagement}>
                  <GapAnalysisSummaryScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.gap_analysis.summary'),
            },
            {
              element: (
                <FeatureGuard feature={FeatureEnum.GapAnalysisManagement}>
                  <ProGapAnalysisSynthesisScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.gap_analysis.synthesis'),
              path: AppRoutes.GapAnalysisSynthesis,
            },
            {
              element: (
                <FeatureGuard feature={FeatureEnum.GapAnalysisManagement}>
                  <ProGapAnalysisChecklistScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.gap_analysis.checklist'),
              path: AppRoutes.GapAnalysisChecklist,
            },
            {
              path: AppRoutes.GapAnalysisDisclosureRequirement,
              element: (
                <FocusLayout>
                  <FeatureGuard feature={FeatureEnum.GapAnalysisManagement}>
                    <GapAnalysisDisclosureRequirementScreen />
                  </FeatureGuard>
                </FocusLayout>
              ),
              breadcrumb: t('nav:page.gap_analysis.summary'),
            },
          ],
        },
        {
          path: AppRoutes.IndicatorCollect,
          children: [
            {
              index: true,
              element: (
                <FeatureGuard feature={FeatureEnum.IndicatorsManagement}>
                  <IndicatorCollectSummaryScreen />
                </FeatureGuard>
              ),
              breadcrumb: t('nav:page.indicator_collect.summary'),
            },
            {
              path: AppRoutes.IndicatorCollectDisclosureRequirement,
              element: (
                <FocusLayout>
                  <FeatureGuard feature={FeatureEnum.IndicatorsCollect}>
                    <IndicatorCollectDisclosureRequirementScreen />
                  </FeatureGuard>
                </FocusLayout>
              ),
              breadcrumb: t('nav:page.indicator_collect.summary'),
            },
          ],
        },
        {
          path: AppRoutes.StakeholderMapping,
          element: (
            <FeatureGuard feature={FeatureEnum.StakeholderManagement}>
              <ProStakeholderScreen />
            </FeatureGuard>
          ),
          breadcrumb: t('nav:page.stakeholder_mapping'),
        },
        {
          path: AppRoutes.Project,
          breadcrumb: null,
          children: [
            {
              index: true,
              element: (
                <FeatureGuard feature={FeatureEnum.ProjectMethodology}>
                  <ProjectMethodologyScreen />
                </FeatureGuard>
              ),
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectNotebook,
              element: (
                <FeatureGuard feature={FeatureEnum.ProjectNotes}>
                  <ProjectNotebookScreen />
                </FeatureGuard>
              ),
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectCompany,
              element: (
                <FeatureGuard feature={FeatureEnum.ProjectCompany}>
                  <ProjectCompanyScreen />
                </FeatureGuard>
              ),
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectUsers,
              element: (
                <FeatureGuard feature={FeatureEnum.ProjectUsers}>
                  <ProjectUsersScreen />
                </FeatureGuard>
              ),
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectRoles,
              element: (
                <FeatureGuard feature={FeatureEnum.ProjectUsers}>
                  <ProjectRolesScreen />
                </FeatureGuard>
              ),
              breadcrumb: null,
            },
            {
              path: AppRoutes.ProjectSettings,
              element: (
                <FeatureGuard feature={FeatureEnum.ProjectCompany}>
                  <ProjectSettingsScreen />
                </FeatureGuard>
              ),
              breadcrumb: null,
            },
          ],
        },
        {
          path: AppRoutes.Publication,
          breadcrumb: null,
          children: [
            {
              path: AppRoutes.PublicationMaturity,
              breadcrumb: t('nav:page.maturity_score'),
              element: (
                <FeatureGuard feature={FeatureEnum.DeliverableMaturityScore}>
                  <MaturityScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.PublicationPrioritizationMatrix,
              breadcrumb: t('nav:page.prioritization_matrix'),
              element: (
                <FeatureGuard
                  feature={FeatureEnum.DeliverablePrioritizationMatrix}
                >
                  <PrioritizationMatrixScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.PublicationMaterialityMatrix,
              breadcrumb: t('nav:page.materiality_matrix'),
              element: (
                <FeatureGuard
                  feature={FeatureEnum.DeliverableMaterialityMatrix}
                >
                  <MaterialityMatrixScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.PublicationDoubleMaterialityMatrixIro,
              breadcrumb: t('nav:page.double_materiality_matrix'),
              element: (
                <FeatureGuard
                  feature={FeatureEnum.DeliverableDoubleMaterialityMatrix}
                >
                  <DoubleMaterialityMatrixIroScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.PublicationDoubleMaterialityMatrixStakeholder,
              breadcrumb: t('nav:page.double_materiality_matrix'),
              element: (
                <FeatureGuard
                  feature={FeatureEnum.DeliverableDoubleMaterialityMatrix}
                >
                  <DoubleMaterialityMatrixStakeholderScreen />
                </FeatureGuard>
              ),
            },
          ],
        },
        {
          path: AppRoutes.Roadmap,
          children: [
            {
              index: true,
              breadcrumb: t('nav:page.roadmap'),
              element: (
                <FeatureGuard feature={FeatureEnum.RoadmapManagement}>
                  <RoadmapScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.RoadmapAction,
              breadcrumb: null,
              element: (
                <FeatureGuard feature={FeatureEnum.RoadmapManagement}>
                  <ActionScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.RoadmapActionEdit,
              breadcrumb: null,
              element: (
                <FeatureGuard feature={FeatureEnum.RoadmapManagement}>
                  <ActionEditScreen />
                </FeatureGuard>
              ),
            },
          ],
        },
        {
          path: AppRoutes.Survey,
          breadcrumb: null,
          children: [
            {
              path: AppRoutes.Survey,
              breadcrumb: null,
              children: [
                {
                  index: true,
                  breadcrumb: t('nav:page.surveys'),
                  element: (
                    <FeatureGuard feature={FeatureEnum.ConsultationManagement}>
                      <SurveyListScreen />
                    </FeatureGuard>
                  ),
                },
                {
                  path: AppRoutes.SurveyResults,
                  children: [
                    {
                      index: true,
                      element: (
                        <FeatureGuard feature={FeatureEnum.ConsultationResults}>
                          <ProSurveyResultsScreen />
                        </FeatureGuard>
                      ),
                      breadcrumb: t('nav:page.surveys'),
                    },
                  ],
                },
                {
                  path: AppRoutes.SurveyItem,
                  children: [
                    {
                      index: true,
                      element: (
                        <FeatureGuard
                          feature={FeatureEnum.ConsultationManagement}
                        >
                          <SurveyScreen />
                        </FeatureGuard>
                      ),
                      breadcrumb: null,
                    },
                  ],
                },
                {
                  path: AppRoutes.SurveyTab,
                  children: [
                    {
                      index: true,
                      element: (
                        <FeatureGuard
                          feature={FeatureEnum.ConsultationManagement}
                        >
                          <SurveyScreen />
                        </FeatureGuard>
                      ),
                      breadcrumb: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: AppRoutes.ConsultationRawMaterialities,
          breadcrumb: null,
          children: [
            {
              index: true,
              breadcrumb: t('nav:page.surveys'),
              element: (
                <FeatureGuard feature={FeatureEnum.ConsultationManagement}>
                  <ProConsultationRawMaterialityScreen />
                </FeatureGuard>
              ),
            },
          ],
        },
        {
          path: AppRoutes.MaturityEvaluations,
          breadcrumb: null,
          children: [
            {
              index: true,
              breadcrumb: t('maturity.evaluation.title'),
              element: (
                <FeatureGuard
                  feature={FeatureEnum.MaturityEvaluationManagement}
                >
                  <MaturityEvaluationsListScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.MaturityEvaluationImport,
              breadcrumb: t('nav:page.maturity_evaluation_import'),
              element: (
                <FeatureGuard
                  feature={FeatureEnum.MaturityEvaluationManagement}
                  requiredPermission={PermissionEnum.Write}
                >
                  <MaturityEvaluationImportScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.MaturityEvaluationItem,
              breadcrumb: null,
              element: (
                <FeatureGuard
                  feature={FeatureEnum.MaturityEvaluationManagement}
                  requiredPermission={PermissionEnum.Write}
                >
                  <MaturityEvaluationScreen />
                </FeatureGuard>
              ),
            },
            {
              path: AppRoutes.MaturityEvaluationItemTab,
              children: [
                {
                  index: true,
                  element: (
                    <FeatureGuard
                      feature={FeatureEnum.MaturityEvaluationManagement}
                      requiredPermission={PermissionEnum.Write}
                    >
                      <MaturityEvaluationScreen />
                    </FeatureGuard>
                  ),
                  breadcrumb: null,
                },
              ],
            },
            {
              path: AppRoutes.MaturityEvaluationAssign,
              breadcrumb: t('nav:page.maturity_evaluation_assign'),
              element: (
                <FeatureGuard
                  feature={FeatureEnum.MaturityEvaluationManagement}
                  requiredPermission={PermissionEnum.Write}
                >
                  <MaturityEvaluationAssignScreen />
                </FeatureGuard>
              ),
            },
          ],
        },
        {
          path: AppRoutes.Settings,
          breadcrumb: null,
          children: [
            {
              path: AppRoutes.SettingsCoach,
              element: (
                <ConsultingCompanyAdministratorOnlyGuard>
                  <CoachsSettingsScreen />
                </ConsultingCompanyAdministratorOnlyGuard>
              ),
              breadcrumb: t('nav:page.coach_management'),
            },
            {
              path: AppRoutes.SettingsCompany,
              element: (
                <ConsultingCompanyAdministratorOnlyGuard>
                  <CompanySettingsScreen />
                </ConsultingCompanyAdministratorOnlyGuard>
              ),
              breadcrumb: t('nav:page.company_management'),
            },
            {
              path: AppRoutes.SettingsTemplates,
              element: (
                <ConsultingCompanyAdministratorOnlyGuard>
                  <TemplatesSettingsScreen />
                </ConsultingCompanyAdministratorOnlyGuard>
              ),
              breadcrumb: t('nav:page.templates_management'),
            },
            {
              path: AppRoutes.ActionTemplateEdit,
              element: (
                <ConsultingCompanyAdministratorOnlyGuard>
                  <ActionEditScreen />
                </ConsultingCompanyAdministratorOnlyGuard>
              ),
            },
          ],
        },
      ],
    },
  ];
};
