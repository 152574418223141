import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ImportStakeholdersModal } from './ImportStakeholdersModal';
import { ImportStakeholderListMenu_SurveyFragment } from '../../../graphql/generated';
import { useModal } from '../../layout/Modal';
import { ChevronDownIcon, DownloadIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function ImportStakeholderListMenu({
  survey,
}: {
  survey: ImportStakeholderListMenu_SurveyFragment;
}) {
  const { t } = useTranslation();
  const modal = useModal();
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="secondary purple small">
            <div className="flex items-center rounded-xl w-full gap-3">
              <div className="shrink-0">
                <DownloadIcon className="h-4 w-4" />
              </div>
              <p className="text-sm font-bold grow text-left">
                {t('global:import.action')}
              </p>
              <ChevronDownIcon className="shrink-0 h-3.5 w-3.5" />
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute bg-white top-0 right-0 transform translate-y-8 mt-3 px-2 sm:px-0 w-40">
              <div className="rounded-lg shadow-lg ring-1 ring-black/5">
                <div className="divide-y divide-gray-100">
                  <div>
                    <button
                      className="border-0 font-normal text-sm text-gray-900 p-2 hover:bg-gray-50 transition ease-in-out duration-150 flex items-center gap-2 w-full justify-start"
                      onClick={(e) => {
                        e.preventDefault();
                        modal.openModalWithComponent(
                          <ImportStakeholdersModal surveyId={survey.id} />,
                          t('survey.stakeholderLists.importContacts.title'),
                        );
                      }}
                    >
                      <div className="shrink-0">
                        <DownloadIcon className="h-4 w-4" />
                      </div>
                      <span>{t('global:import.csvFile')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
