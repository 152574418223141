import { useTranslation } from '@hooks/useTranslation';
import { GradientScore } from '../../../../generic/GradientScore';
import React, { useState } from 'react';
import clsx from 'clsx';
import { FinancialMaterialityScoreFragment } from '../../../../../graphql/generated';
import { FinancialScoreRangeSlider } from './FinancialScoreRangeSlider';
import { ChevronDownIcon } from '../../../../icons';

export function FinancialMaterialityScore({
  stake,
  readOnly = false,
}: {
  stake: FinancialMaterialityScoreFragment;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();
  const [showScoreSlider, setShowScoreSlider] = useState(false);
  return (
    <div className="relative">
      <div className="border border-gray-900 rounded-xl px-4 py-2 bg-white">
        <div className="flex items-center justify-between gap-4">
          <div className="font-bold text-sm">
            {t('screen.iro.computed_financial_materiality_score')}
          </div>
          <GradientScore
            score={
              stake.financialMaterialityScore ||
              stake.persistedFinancialMaterialityScore ||
              stake.computedFinancialMaterialityScore
            }
          />
          {!readOnly && (
            <button
              className="unstyled"
              onClick={() => setShowScoreSlider(!showScoreSlider)}
            >
              <ChevronDownIcon
                className={clsx(showScoreSlider && 'rotate-180')}
              />
            </button>
          )}
        </div>
      </div>
      {!readOnly && showScoreSlider && (
        <div className="absolute top-full right-0 z-50 mt-2">
          <FinancialScoreRangeSlider stake={stake} />
        </div>
      )}
    </div>
  );
}
