import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { TooltipWrapper } from '../TooltipWrapper';
import { CheckIcon, InfoIcon } from '../../icons';

export function CheckboxSurveyInput({
  checked,
  check,
  uncheck,
  tooltip,
  placeholder,
}: {
  checked: boolean | null | undefined;
  check: () => void;
  uncheck: () => void;
  tooltip?: ReactNode | null | undefined;
  placeholder?: string | null | undefined;
}) {
  const performCheck = () => {
    if (checked) {
      uncheck();
    } else {
      check();
    }
  };

  if (tooltip) {
    return (
      <TooltipWrapper label={tooltip}>
        <div
          className={clsx(
            'form-input-checkbox-survey relative',
            checked && 'selected',
          )}
          onClick={performCheck}
        >
          <InfoIcon className="absolute top-0 right-0 w-4 h-4 text-gray-900 bg-white rounded-full" />

          {placeholder ? (
            <div className={checked ? '' : 'text-gray-900'}>{placeholder}</div>
          ) : (
            <CheckIcon className="w-4 h-4" />
          )}
        </div>
      </TooltipWrapper>
    );
  } else {
    return (
      <div
        className={clsx('form-input-checkbox-survey', checked && 'selected')}
        onClick={performCheck}
      >
        {placeholder ? (
          <div className={checked ? '' : 'text-gray-900'}>{placeholder}</div>
        ) : (
          <CheckIcon className="w-4 h-4" />
        )}
      </div>
    );
  }
}
