import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import {
  DiagnosticStakeFieldsFragment,
  useReferentialQuery,
} from '../../graphql/generated';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { PillarIcon } from '../stake/PillarIcon';
import { Loader } from '../generic/Loader';
import { CheckIcon, ChevronDownIcon, WarningIcon, XIcon } from '../icons';
import { Float } from '@headlessui-float/react';
import { useTranslation } from '@hooks/useTranslation';

export default function StakeDropdown({
  currentStakeId,
  setCurrentStakeId,
  isRequired,
  pillarId,
  isFilter,
}: {
  currentStakeId: string | null | undefined;
  setCurrentStakeId: (stakeId: string | null) => void;
  isRequired: boolean;
  pillarId?: string | null;
  isFilter?: boolean;
}) {
  const { translateProperty } = useTranslation();
  // Fetch stakes
  const projectContext = useProjectContext();
  const companyReferentialDocumentQuery = useReferentialQuery({
    variables: { id: projectContext?.enterprise?.referential?.id || '' },
    skip: !projectContext?.enterprise?.id,
    fetchPolicy: 'network-only',
  });
  const referential = companyReferentialDocumentQuery.data?.referential;
  const stakes =
    referential?.pillars
      .filter((pillar) => (pillarId ? pillar.id === pillarId : true))
      .flatMap((pillar) => pillar.stakes) || [];
  const sortedStakes = stakes
    .toSorted((a, b) =>
      translateProperty(a, 'name').localeCompare(translateProperty(b, 'name')),
    )
    .toSorted((a, b) =>
      translateProperty(a.pillar, 'name').localeCompare(
        translateProperty(b.pillar, 'name'),
      ),
    );

  const chooseStake = (stake: DiagnosticStakeFieldsFragment | null) => {
    setCurrentStakeId(stake?.id || null);
  };
  const currentStake =
    sortedStakes.find((stake) => stake.id === currentStakeId) || null;

  return (
    <div className="flex items-center gap-1 shrink-0">
      <Listbox value={currentStake} onChange={chooseStake}>
        {({ open }) => (
          <Float portal adaptiveWidth>
            <ListboxButton
              className={clsx(
                'form-input-select form-input-text py-2 pl-3 pr-10',
              )}
            >
              {companyReferentialDocumentQuery.loading ? (
                <Loader />
              ) : (
                <CurrentStake
                  currentStake={currentStake}
                  isFilter={isFilter}
                  isRequired={isRequired}
                />
              )}
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm">
                {isFilter && (
                  <ListboxOption
                    key={'null'}
                    className={clsx(
                      'group[selected]:text-white group[selected]:bg-purple-600',
                      'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    )}
                    value={null}
                  >
                    {({ selected }) => (
                      <span
                        className={clsx(
                          selected ? 'font-semibold' : 'font-normal',
                          'ml-3 block',
                        )}
                      >
                        Tous les enjeux
                      </span>
                    )}
                  </ListboxOption>
                )}

                {sortedStakes.map((stake) => (
                  <ListboxOption
                    key={stake.id}
                    className={clsx(
                      'listbox-option group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                      'data-[selected]:active data-focus:text-white data-focus:bg-blue-600',
                    )}
                    value={stake}
                  >
                    {({ selected }) => (
                      <>
                        <div className="flex items-center">
                          <PillarIcon pillar={stake?.pillar} />
                          <span
                            className={clsx(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block',
                            )}
                          >
                            {translateProperty(stake, 'name')}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={clsx(
                              'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </Float>
        )}
      </Listbox>
      {!isFilter && currentStake && (
        <button
          className="unstyled"
          onClick={() => {
            setCurrentStakeId(null);
          }}
        >
          <XIcon />
        </button>
      )}
    </div>
  );
}

function CurrentStake({
  currentStake,
  isFilter,
  isRequired,
}: {
  currentStake: DiagnosticStakeFieldsFragment | null | undefined;
  isFilter: boolean | undefined;
  isRequired: boolean;
}) {
  const { translateProperty } = useTranslation();
  if (currentStake) {
    return (
      <div className={clsx('flex items-center gap-1 w-full')}>
        <PillarIcon pillar={currentStake?.pillar} />
        {translateProperty(currentStake, 'name')}
      </div>
    );
  }

  if (isFilter) {
    return (
      <div className={clsx('flex items-center gap-1 w-full')}>
        Tous les enjeux
      </div>
    );
  }

  if (isRequired) {
    return (
      <div className={clsx('flex items-center gap-1 w-full text-red-700')}>
        <WarningIcon />
        <div>Aucun enjeu sélectionné</div>
      </div>
    );
  }

  return <div className={clsx('flex items-center gap-1 w-full h-5')}></div>;
}
