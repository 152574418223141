import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  AdminCompaniesScreenDocument,
  LicenseEditor_CompanyFragment,
  useLicenseEditor_UpdateLicenseMutationMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';

export function LicenseEditor({
  company,
}: {
  company: LicenseEditor_CompanyFragment;
}) {
  const { t } = useTranslation();
  const [maxProjectsCount, setMaxProjectsCount] = useState<number>(
    company.license?.licensesCount || 0,
  );
  const toast = useToast();

  const [updateCompany] = useLicenseEditor_UpdateLicenseMutationMutation();
  const [isLoading, setIsLoading] = useState(false);
  const saveLicenseCount = (newMaxCount: number) => {
    setIsLoading(true);
    setMaxProjectsCount(newMaxCount);
    updateCompany({
      variables: {
        input: {
          id: company.id,
          license: {
            id: company.license?.id || undefined,
            licensesCount: newMaxCount,
          },
        },
      },
      refetchQueries: [
        {
          query: AdminCompaniesScreenDocument,
        },
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('admin:companies.toast.limit_updated'));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const isUnlimited =
    maxProjectsCount === 0 ||
    maxProjectsCount === null ||
    maxProjectsCount === undefined;
  return (
    <div className="flex items-center gap-2">
      <label className="form-input-label">
        {t('admin:companies.list.item.max_projects')}
      </label>
      {isUnlimited && (
        <div className="tag red">{t('admin:companies.list.item.infinite')}</div>
      )}
      {isLoading && <Loader />}
      <input
        type="number"
        className="form-input-text w-20"
        disabled={isLoading}
        min={0}
        value={maxProjectsCount}
        onChange={(e) => saveLicenseCount(parseInt(e.target.value))}
      />
    </div>
  );
}
