import React, { ReactNode, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { IrosNav } from './IrosNav';
import { IroAssessmentScaleButtonGroup } from './buttonGroups/IroAssessmentScaleButtonGroup';
import { IroAssessmentScopeButtonGroup } from './buttonGroups/IroAssessmentScopeButtonGroup';
import { IroAssessmentLikelihoodButtonGroup } from './buttonGroups/IroAssessmentLikelihoodButtonGroup';
import { IroAssessmentIrremediabilityButtonGroup } from './buttonGroups/IroAssessmentIrremediabilityButtonGroup';
import { IroAssessmentTimeHorizonButtonGroup } from './buttonGroups/IroAssessmentTimeHorizonButtonGroup';
import {
  QuestionIroAssessment_IroFragment,
  SurveyAnswerType,
  SurveyQuestionSummaryFieldsFragment,
  SurveySubmitAnswerInput,
} from '../../../../graphql/generated';
import { useIroCriteriaValuesDefinitions } from '../../../project/iro/evaluation/IroCriteriaValuesDefinitionsContext';
import {
  getIroAssessmentPropertyName,
  iroTypenameToIroType,
  iroTypeToTypename,
} from '../../../project/iro/iroTypenameToIroType';
import { IroAssessmentFormValues } from './IroAssessmentForm.types';
import { useSurveyResponse } from '../../response/SurveyResponseContext';
import {
  hasIrremediabilityCriteria,
  hasLikelihoodCriteria,
  hasScaleCriteria,
  hasScopeCriteria,
} from '../../../../types/iro.types';
import {
  hasIrremediabilityCriteriaConfig,
  hasLikelihoodCriteriaConfig,
  hasScaleCriteriaConfig,
  hasScopeCriteriaConfig,
} from '../../../../types/iroAssessments.guards';

type IroFormProps = {
  question: SurveyQuestionSummaryFieldsFragment;
  iros: QuestionIroAssessment_IroFragment[];
  iro: QuestionIroAssessment_IroFragment;
  chooseIro: (index: number) => void;
  bottomNav?: ReactNode;
};

export const IroAssessmentForm = ({
  question,
  iros,
  iro,
  chooseIro,
  bottomNav,
}: IroFormProps) => {
  const { register } = useFormContext<IroAssessmentFormValues>();
  const { translateProperty, t } = useTranslation();
  const iroCriteriaValuesDefinitions = useIroCriteriaValuesDefinitions();
  const { answers, setAnswer, setIsAnswerValid } = useSurveyResponse();
  const [answeredIroIds, setAnsweredIroIds] = useState<string[]>([]);
  const iroDef = iroCriteriaValuesDefinitions.definitions?.find((d) => {
    return iroTypeToTypename(d.iroType) === iro.__typename;
  });

  const questionIroAssessmentNode =
    question.iroAssessment?.[getIroAssessmentPropertyName(iro.__typename)];
  const watchedValues = useWatch();

  useEffect(() => {
    const iroAssessment = watchedValues?.iroAssessments?.[iro.id];
    if (iroAssessment) {
      const prevIroAssessments = answers[question.id]?.iroAssessments || [];
      const updatedIroAssessments = [
        ...prevIroAssessments.filter(
          (assessment) => assessment.iro.id !== iroAssessment.iro.id,
        ),
        iroAssessment,
      ];

      setAnswer(question.id, {
        ...answers[question.id],
        type: SurveyAnswerType.IroAssessment,
        question: { id: question.id },
        iroAssessments: updatedIroAssessments,
      });

      const validatedAnswer = validateAnswer(
        question,
        {
          ...answers[question.id],
          iroAssessments: updatedIroAssessments,
        },
        iros,
        answeredIroIds,
        setAnsweredIroIds,
      );
      setIsAnswerValid(validatedAnswer);
    }
  }, [watchedValues, setAnswer, question, iro]);

  return (
    <div className="mt-4 w-full flex justify-center items-center">
      <div className="w-full max-w-5xl flex flex-col sm:flex-row items-start gap-8">
        <div className={clsx('w-full sm:w-1/3')}>
          <IrosNav
            iros={iros}
            currentIro={iro}
            chooseIro={chooseIro}
            answeredIroIds={answeredIroIds}
          />
        </div>
        <div className="space-y-4 w-full sm:w-2/3">
          <div className="space-y-8 bg-yellow-100 p-4 rounded-xl border-2 border-yellow-500 w-full">
            <div className="space-y-4">
              <h1 className="text-center w-full">
                {translateProperty(iro, 'name')}
              </h1>
              <input
                type="hidden"
                {...register(`iroAssessments.${iro.id}.iro.id`, {
                  value: iro.id,
                })}
              />
            </div>
            <div className="divide-y-2 divide-gray-900 space-y-8 p-4 sm:p-8">
              {hasScaleCriteria(iro) &&
                questionIroAssessmentNode?.scaleCriteria && (
                  <div className="flex flex-col sm:flex-row items-center sm:items-end gap-4 py-4">
                    <h6 className="grow">
                      {questionIroAssessmentNode?.scaleCriteria?.description ||
                        t(
                          'survey.question.iroAssessment.' +
                            iroTypenameToIroType(iro.__typename) +
                            '.scale.definition',
                        )}
                    </h6>
                    <IroAssessmentScaleButtonGroup
                      iro={iro}
                      scaleValuesDefinitions={
                        iroDef?.scaleValuesDefinitions || []
                      }
                    />
                  </div>
                )}

              {hasScopeCriteria(iro) && iro.scopeCriteria && (
                <div className="flex flex-col sm:flex-row items-center sm:items-end gap-4 py-4">
                  <h6 className="grow">
                    {(hasScopeCriteriaConfig(questionIroAssessmentNode) &&
                      questionIroAssessmentNode?.scopeCriteria?.description) ||
                      t(
                        'survey.question.iroAssessment.' +
                          iroTypenameToIroType(iro.__typename) +
                          '.scope.definition',
                      )}
                  </h6>
                  <IroAssessmentScopeButtonGroup
                    iro={iro}
                    scopeValuesDefinitions={
                      iroDef?.scopeValuesDefinitions || []
                    }
                  />
                </div>
              )}

              {hasLikelihoodCriteria(iro) && iro.likelihoodCriteria && (
                <div className="flex flex-col sm:flex-row items-center sm:items-end gap-12">
                  <h6 className="grow">
                    {(hasLikelihoodCriteriaConfig(questionIroAssessmentNode) &&
                      questionIroAssessmentNode?.likelihoodCriteria
                        ?.description) ||
                      t(
                        'survey.question.iroAssessment.' +
                          iroTypenameToIroType(iro.__typename) +
                          '.likelihood.definition',
                      )}
                  </h6>
                  <IroAssessmentLikelihoodButtonGroup
                    iro={iro}
                    likelihoodValuesDefinitions={
                      iroDef?.likelihoodValuesDefinitions || []
                    }
                  />
                </div>
              )}

              {hasIrremediabilityCriteria(iro) &&
                iro?.irremediabilityCriteria && (
                  <div className="flex flex-col sm:flex-row items-center sm:items-end gap-4 py-4">
                    <h6 className="grow">
                      {(hasIrremediabilityCriteriaConfig(
                        questionIroAssessmentNode,
                      ) &&
                        questionIroAssessmentNode?.irremediabilityCriteria
                          ?.description) ||
                        t(
                          'survey.question.iroAssessment.' +
                            iroTypenameToIroType(iro.__typename) +
                            '.irremediability.definition',
                        )}
                    </h6>
                    <IroAssessmentIrremediabilityButtonGroup
                      iro={iro}
                      irremediabilityValuesDefinitions={
                        iroDef?.irremediabilityValuesDefinitions || []
                      }
                    />
                  </div>
                )}

              <div className="flex flex-col sm:flex-row items-center sm:items-end gap-4 py-4">
                <h6 className="grow">
                  {questionIroAssessmentNode?.timeHorizon?.description ||
                    t(
                    'survey.question.iroAssessment.' +
                      iroTypenameToIroType(iro.__typename) +
                      '.timeHorizon.definition',
                  )}
                </h6>
                <IroAssessmentTimeHorizonButtonGroup iro={iro} />
              </div>
            </div>
          </div>
          {bottomNav}
        </div>
      </div>
    </div>
  );
};

const validateAnswer = (
  question: SurveyQuestionSummaryFieldsFragment,
  answer: SurveySubmitAnswerInput | undefined,
  iros: QuestionIroAssessment_IroFragment[],
  answeredIroIds: string[],
  setAnsweredIroIds: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  if (!answer) return false;
  const answerIroAssessments = answer.iroAssessments || [];
  const questionIroAssessmentConfig = question.iroAssessment;
  if (!questionIroAssessmentConfig) {
    throw new Error('Question iro assessment config not found');
  }

  let newAnsweredIroIds = [...answeredIroIds];

  const isValid =
    answerIroAssessments.every((answerIroAssessment) => {
      const iro = iros.find((iro) => iro.id === answerIroAssessment.iro.id);
      if (!iro) {
        return false;
      }

      const iroConfigBlock =
        questionIroAssessmentConfig[
          getIroAssessmentPropertyName(iro.__typename)
        ];

      let isCriteriaValid = true;

      if (
        hasIrremediabilityCriteria(iro) &&
        hasIrremediabilityCriteriaConfig(iroConfigBlock) &&
        iroConfigBlock?.irremediabilityCriteria?.required &&
        !answerIroAssessment?.irremediabilityCriteria?.value
      ) {
        isCriteriaValid = false;
      }
      if (
        hasLikelihoodCriteria(iro) &&
        hasLikelihoodCriteriaConfig(iroConfigBlock) &&
        iroConfigBlock?.likelihoodCriteria?.required &&
        !answerIroAssessment?.likelihoodCriteria?.value
      ) {
        isCriteriaValid = false;
      }
      if (
        hasScaleCriteria(iro) &&
        hasScaleCriteriaConfig(iroConfigBlock) &&
        iroConfigBlock?.scaleCriteria?.required &&
        !answerIroAssessment?.scaleCriteria?.value
      ) {
        isCriteriaValid = false;
      }
      if (
        hasScopeCriteria(iro) &&
        hasScopeCriteriaConfig(iroConfigBlock) &&
        iroConfigBlock?.scopeCriteria?.required &&
        !answerIroAssessment?.scopeCriteria?.value
      ) {
        isCriteriaValid = false;
      }

      if (isCriteriaValid) {
        if (!newAnsweredIroIds.includes(iro.id)) {
          newAnsweredIroIds.push(iro.id);
        }
      } else {
        newAnsweredIroIds = newAnsweredIroIds.filter((id) => id !== iro.id);
      }

      return isCriteriaValid;
    }) && iros.length === answerIroAssessments.length;

  setAnsweredIroIds([...newAnsweredIroIds]);

  return isValid;
};
