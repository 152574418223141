import { useCurrentUser } from '../../../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import { PageTitle } from '../../../../components/nav/PageTitle';
import React from 'react';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { useCompanySettingsScreenQuery } from '../../../../graphql/generated';
import { ConsultingCompany } from '../../../../components/project/project/ConsultingCompany';

export function CompanySettingsScreen() {
  const { currentUser } = useCurrentUser();

  // Get full company data
  const { data, loading } = useCompanySettingsScreenQuery({
    variables: {
      id: currentUser?.company?.id || '',
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.consultingCompany) {
    return (
      <div className="">
        <PageTitle title="Gérer l'entreprise" />
        <Breadcrumb />
        <div className="max-w-5xl main-content">
          <ConsultingCompany company={data.consultingCompany} />
        </div>
      </div>
    );
  } else {
    return <LoaderFullscreen />;
  }
}
