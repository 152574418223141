import { useTranslation } from '@hooks/useTranslation';
import React from 'react';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { CompanySettings_CompanyFragment } from '../../../../graphql/generated';
import { NumberCircle } from '../../../generic/NumberCircle';

export const CompanySettingsCalculators = ({
  company,
}: {
  company: CompanySettings_CompanyFragment;
}) => {
  const { t } = useTranslation();
  type TCalculatorSetting = {
    name: string;
    version: string;
    key: string;
  };
  const calculators: TCalculatorSetting[] = [
    {
      name: company.calculators.simpleMaterialityFinancialByStakeholderSegments
        .name,
      version:
        company.calculators.simpleMaterialityFinancialByStakeholderSegments
          .version,
      key: 'simpleMaterialityFinancialByStakeholderSegments',
    },
    {
      name: company.calculators.doubleMaterialityStatic.name,
      version: company.calculators.doubleMaterialityStatic.version,
      key: 'doubleMaterialityStatic',
    },
    {
      name: company.calculators.doubleMaterialityStakeholder.name,
      version: company.calculators.doubleMaterialityStakeholder.version,
      key: 'doubleMaterialityStakeholder',
    },
    {
      name: company.calculators.simpleMaterialityFinancial.name,
      version: company.calculators.simpleMaterialityFinancial.version,
      key: 'simpleMaterialityFinancial',
    },
    {
      name: company.calculators.simpleMaterialityImpact.name,
      version: company.calculators.simpleMaterialityImpact.version,
      key: 'simpleMaterialityImpact',
    },
    {
      name: company.calculators.iroScore.name,
      version: company.calculators.iroScore.version,
      key: 'iroScore',
    },
  ];

  return (
    <div className="space-y-4">
      <h2 className="title-h4">{t('company.settings.calculators.title')}</h2>
      <div className="space-y-4">
        <MessageBox type={MessageBoxType.Info}>
          {t('company.settings.calculators.info')}
        </MessageBox>
        <dl className="grid grid-cols-2 gap-2">
          {calculators.map((calculator, index) => (
            <React.Fragment key={index}>
              <dt className="flex items-center gap-1">
                <NumberCircle number={index + 1} size={8} />
                <label className="form-input-label">
                  {t(`enum:calculators.${calculator.key}`)}
                </label>
              </dt>
              <dd>
                <input
                  type="text"
                  className="form-input-text disabled"
                  readOnly={true}
                  disabled
                  value={`${calculator.name} ${calculator.version}`}
                />
              </dd>
            </React.Fragment>
          ))}
        </dl>
      </div>
    </div>
  );
};
