import { Loader } from '../../../../generic/Loader';
import { useState } from 'react';
import {
  useRemoveConsultingCompanyEmployeeMutation,
  UserRowFragment,
} from '../../../../../graphql/generated';
import { useToast } from '../../../../layout/Toast';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';
import { DeleteIcon } from '../../../../icons';
import { ConfirmationModal } from '../../../../modals/ConfirmationModal';
import { useModal } from '../../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';

export function RemoveConsultingCompanyUserButton({
  user,
  companyId,
  disabled = false,
}: {
  user: UserRowFragment;
  companyId: string;
  disabled?: boolean;
}) {
  const projectContext = useProjectContext();
  const toast = useToast();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const modal = useModal();

  const [employeeRemoveMutation] = useRemoveConsultingCompanyEmployeeMutation();
  const openDeleteModal = () => {
    modal.openModal(
      <ConfirmationModal
        callbackIfConfirmed={remove}
        message={t('consultingCompany.users.delete.message', {
          name: `${user.firstName} ${user.lastName}`,
        })}
      />,
      {
        title: t('consultingCompany.users.delete.title'),
        isFullscreen: false,
        displayTitleBar: true,
      },
    );
  };

  const remove = () => {
    setIsLoading(true);
    employeeRemoveMutation({ variables: { companyId, userId: user.id } })
      .then((res) => {
        toast.openToastWithMessage(t('toast:user.remove.success'));
        if (projectContext) {
          projectContext.refreshEnterprise();
        }
      })
      .catch((err) => {
        toast.openToastWithMessage(t('toast:user.remove.error'));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <button
      className="unstyled small text-gray-500 hover:text-red-500"
      disabled={isLoading || disabled}
      onClick={() => openDeleteModal()}
    >
      {isLoading ? <Loader /> : <DeleteIcon />}
    </button>
  );
}


