import { useTranslation } from '@hooks/useTranslation';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import {
  StandardEnum,
  useIndicatorCollectSummaryQuery,
} from '../../../../graphql/generated';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../generic/Loader';
import { NeedToCreateReportFirst } from '../../report/NeedToCreateReportFirst';
import { NoData } from '../../../generic/NoData';
import { SkeletonLoader } from '../../../generic/loader/SkeletonLoader';
import { Table } from '../../../molecules/table/Table';
import { getSortedRowModel } from '@tanstack/react-table';
import { StarIcon } from '../../../icons';
import {
  IndicatorCollectDRTableRow,
  prepareData,
  useIndicatorCollectDRTableColumns,
} from './useIndicatorCollectDRTableColumns';
import { useIndicatorCollectSummary_TopicsQuery } from '../../../../graphql/cms/generated';

export function IndicatorCollectSummary({
  readOnly = false,
}: {
  readOnly?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const context = useProjectContext();

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useIndicatorCollectSummaryQuery({
    variables: {
      input: {
        company: { id: context?.enterprise?.id! },
        standard: StandardEnum.Csrd,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: cmsData, loading: cmsLoading } =
    useIndicatorCollectSummary_TopicsQuery({
      client: cmsClient,
      variables: {
        locale: i18n.language,
        standardSlug: StandardEnum.Csrd,
      },
      fetchPolicy: 'cache-and-network',
    });

  const navigate = useNavigate();
  const columns = useIndicatorCollectDRTableColumns();
  if (appError) {
    return <NeedToCreateReportFirst />;
  }
  const appTopics = appData?.companyReport?.topics || [];
  return (
    <div className="main-content space-y-8 py-8">
      {appLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : appTopics.length === 0 ? (
        <NoData
          text={t('indicator_collect.summary.noData', {
            companyName: context?.enterprise?.name ?? '',
          })}
          actions={[
            {
              key: 'chooseDisclosureRequirements',
              component: (
                <div>
                  <StarIcon />
                  TODO EMPTY STATE
                </div>
              ),
            },
          ]}
        />
      ) : (
        appTopics
          .filter((topic) =>
            topic.disclosureRequirements?.some(
              (dr) => dr.dataPoints && dr.dataPoints.length > 0,
            ),
          )
          .map((topic) => {
            const cmsFilteredTopics =
              cmsData?.topics.filter(
                (topic): topic is NonNullable<typeof topic> => topic !== null,
              ) || [];
            const data = prepareData(
              topic.disclosureRequirements.filter(
                (dr) => dr.dataPoints.length > 0,
              ),
              cmsFilteredTopics,
              navigate,
              readOnly,
            );
            const cmsTopic = cmsFilteredTopics.find(
              (item) => item.slug === topic.standardItemLink.slug,
            );

            return (
              <div
                className="flex flex-col gap-4"
                key={topic.standardItemLink.slug}
              >
                <h4>
                  {cmsTopic?.name! || (
                    <SkeletonLoader
                      size={{ height: 'h-8' }}
                      randomSizes={{
                        width: ['w-1/2', 'w-7/12', 'w-3/4'],
                      }}
                    />
                  )}
                </h4>
                <Table<IndicatorCollectDRTableRow>
                  {...{
                    columns,
                    data,
                    getSortedRowModel: getSortedRowModel(),
                    initialState: {
                      sorting: [
                        {
                          id: 'name',
                          desc: false,
                        },
                      ],
                    },
                  }}
                />
              </div>
            );
          })
      )}
    </div>
  );
}
