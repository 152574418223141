import React, { ReactNode } from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';
import { ProgressBar, ProgressBarStyles } from '../../../generic/ProgressBar';
import { IndicatorCollectSummary_DisclosureRequirementFragment } from '../../../../graphql/generated';
import { IndicatorCollectSummary_Topic_TreeFragment } from '../../../../graphql/cms/generated';
import { EditIcon, EyeIcon } from '../../../icons';
import { SkeletonLoader } from '../../../generic/loader/SkeletonLoader';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../screens/AppRoutes';

export type IndicatorCollectDRTableRow = {
  name?: string;
  indicatorProgress: {
    count: number;
    total: number;
  };
  actions: {
    key: string;
    component: ReactNode;
  }[];
};

export const useIndicatorCollectDRTableColumns = (): Array<
  ColumnDef<IndicatorCollectDRTableRow>
> => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<IndicatorCollectDRTableRow>();

  return [
    columnHelper.accessor('name', {
      cell: (data) => {
        const name = data.getValue();
        return (
          (name && <div className={`font-bold`}>{name}</div>) || (
            <SkeletonLoader
              size={{ height: 'h-5' }}
              randomSizes={{
                width: [
                  'w-1/2',
                  'w-7/12',
                  'w-3/4',
                  'w-9/12',
                  'w-10/12',
                  'w-11/12',
                  'w-12/12',
                ],
              }}
            />
          )
        );
      },
      header: () => t('indicator_collect.summary.table.header.name.label'),
      meta: {
        th: {
          className: 'w-2/3',
        },
      },
    }),
    columnHelper.accessor('indicatorProgress', {
      cell: (data) => {
        const indicatorProgress = data.getValue();
        return (
          <div className={'flex items-center gap-2'}>
            <ProgressBar
              value={indicatorProgress.count}
              total={indicatorProgress.total}
              style={ProgressBarStyles.COLORED}
            />
            <div className={'font-bold'}>
              {indicatorProgress.count}/{indicatorProgress.total}
            </div>
          </div>
        );
      },
      sortDescFirst: false,
      header: () => (
        <span>
          {t('indicator_collect.summary.table.header.indicatorProgress.label')}
        </span>
      ),
    }),
    columnHelper.accessor('actions', {
      cell: (data) => {
        const actions = data.getValue();
        return (
          <div className="flex items-center gap-1">
            {actions.map(({ key, component }) => (
              <div key={key}>{component}</div>
            ))}
          </div>
        );
      },
      sortDescFirst: false,
      header: () => <span />,
    }),
  ] as Array<ColumnDef<IndicatorCollectDRTableRow>>;
};

export const prepareData = (
  disclosureRequirements: IndicatorCollectSummary_DisclosureRequirementFragment[],
  cmsTopics: IndicatorCollectSummary_Topic_TreeFragment[],
  navigate: ReturnType<typeof useNavigate>,
  readOnly: boolean
): Array<IndicatorCollectDRTableRow> => {
  const data: Array<IndicatorCollectDRTableRow> = [];

  disclosureRequirements.forEach((dr) => {
    const cmsTopic = cmsTopics.find(
      (topic) => topic.slug === dr.reportTopic.standardItemLink.slug,
    );
    const cmsDisclosureRequirement = cmsTopic?.disclosure_requirements.find(
      (cmsDR) => cmsDR?.slug === dr.standardItemLink.slug,
    );
    const name = cmsDisclosureRequirement?.title
      ? `${cmsDisclosureRequirement?.code} - ${cmsDisclosureRequirement?.title}`
      : undefined;

    const indicators = dr.dataPoints.flatMap((dp) => dp.indicators);
    const indicatorsCount = indicators.length;

    const indicatorsHavingValue = indicators?.filter((indicator) => {
      const countAggregate = indicator.valuesAggregate.find((agg) => agg.count);
      return countAggregate?.count?.id || 0 > 0;
    }).length;
    const allIndicatorsHaveAValue =
      indicators.length > 0 && indicators.length === indicatorsHavingValue;

    let mainAction = (
      <button
        className="tertiary"
        onClick={() => {
          navigate(
            generatePath(AppRoutes.IndicatorCollectDisclosureRequirement, {
              referenceSlug: dr.standardItemLink.slug,
            }),
          );
        }}
      >
        {readOnly && <EyeIcon/> || <EditIcon />}
      </button>
    );

    const actions = [
      {
        key: 'mainAction',
        component: mainAction,
      },
    ];

    data.push({
      name,
      indicatorProgress: {
        count: indicatorsHavingValue,
        total: indicatorsCount,
      },
      actions,
    });
  });

  return data;
};
