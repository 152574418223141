import {
  IroAssessmentDataviz_Answer_IroAssessmentFragment,
  IroAssessmentDataviz_AnswerFragment,
} from '../../../../../graphql/generated';
import React from 'react';
import { IroAssessmentsTable } from './IroAssessmentsTable';
import { useTranslation } from '@hooks/useTranslation';
import { iroTypenameToIroType } from '../../../iro/iroTypenameToIroType';

export const IroAssessmentsDataviz = ({
  answers,
}: {
  answers: IroAssessmentDataviz_AnswerFragment[];
}) => {
  const { translateProperty } = useTranslation();

  const dataByIro = answers.reduce(
    (acc, answer) => {
      if (!answer.iroAssessments) {
        return acc;
      }

      for (const iroAssessment of answer.iroAssessments) {
        const iro = iroAssessment.iro;
        if (!iro) {
          continue;
        }

        const iroId = iro.id;
        if (!acc[iroId]) {
          acc[iroId] = [];
        }

        acc[iroId].push(iroAssessment);
      }
      return acc;
    },
    {} as Record<string, IroAssessmentDataviz_Answer_IroAssessmentFragment[]>,
  );

  return (
    <div className="flex flex-col gap-20">
      {Object.entries(dataByIro).map(([iroId, iroassessments]) => {
        const firstAssessment = iroassessments[0];

        return (
          <div className="flex flex-col space-y-10" key={iroId}>
            <h4>
              {firstAssessment &&
                translateProperty(firstAssessment.iro, 'name')}
            </h4>
            <IroAssessmentsTable
              data={iroassessments}
              iroType={iroTypenameToIroType(firstAssessment.iro.__typename)}
            />
          </div>
        );
      })}
    </div>
  );
};
