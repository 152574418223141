import {
  IroType,
  QuestionIroAssessment_IroFragment,
  TimeHorizon,
} from '../../../../../graphql/generated';
import React from 'react';
import { IroAssessmentOptionRadio } from './radioButton/IroAssessmentOptionRadio';
import { IroAssessmentButtonGroup } from './IroAssessmentButtonGroup';
import { useTranslation } from '@hooks/useTranslation';
import { CriteriaType } from '../../../../../types/iro.types';
import { useFormContext } from 'react-hook-form';
import { IroAssessmentFormValues } from '../IroAssessmentForm.types';

export const IroAssessmentTimeHorizonButtonGroup = ({
  iro,
}: {
  iro: QuestionIroAssessment_IroFragment;
}) => {
  const { t } = useTranslation();
  const name = `iroAssessments.${iro.id}.timeHorizon` as const;
  const { watch } = useFormContext<IroAssessmentFormValues>();
  const value = watch(name);

  return (
    <IroAssessmentButtonGroup
      criteria={CriteriaType.TIME_HORIZON}
      label={t('iro.criteria.timeHorizon.title')}
      iroType={iro.__typename as IroType}
    >
      <div className="w-56 flex items-center justify-evenly gap-2">
        <IroAssessmentOptionRadio<TimeHorizon>
          {...{ name, value }}
          level={TimeHorizon.ShortTerm}
          placeholder={'1'}
        />
        <IroAssessmentOptionRadio<TimeHorizon>
          {...{ name, value }}
          level={TimeHorizon.MediumTerm}
          placeholder={'2'}
        />
        <IroAssessmentOptionRadio<TimeHorizon>
          {...{ name, value }}
          level={TimeHorizon.LongTerm}
          placeholder={'3'}
        />
      </div>
    </IroAssessmentButtonGroup>
  );
};
