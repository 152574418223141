import { ThemeColor } from '../graphql/generated';

export function readCssVar(varName: string) {
  if (typeof window === 'undefined') {
    return '';
  }
  return getComputedStyle(document.documentElement)
    .getPropertyValue(varName)
    .trim();
}

export function mapThemeColorToTailwindClass(
  themeColor: ThemeColor | null | undefined,
  shade: number,
  type: 'bg' | 'text',
): string {
  switch (themeColor) {
    case ThemeColor.Blue:
      return `${type}-blue-${shade}`;
    case ThemeColor.Green:
      return `${type}-green-${shade}`;
    case ThemeColor.Purple:
      return `${type}-purple-${shade}`;
    case ThemeColor.Red:
      return `${type}-red-${shade}`;
    case ThemeColor.Yellow:
      return `${type}-yellow-${shade}`;
    case ThemeColor.Gray:
    default:
      return `${type}-gray-${shade}`;
  }
}
