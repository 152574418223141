import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import {
  IndicatorCollectDisclosureRequirement_DatapointFragment,
  IndicatorCollectDisclosureRequirement_DisclosureRequirementFragment,
  StandardEnum,
  SurveyLanguage,
  useIndicatorCollectDisclosureRequirement_DrBySlugQuery,
} from '../../../../graphql/generated';
import {
  IndicatorCollectDisclosureRequirement_Datapoint_StrapiFragment,
  IndicatorCollectDisclosureRequirement_DisclosureRequirement_StrapiFragment,
  useIndicatorCollectDisclosureRequirement_DpBySlug_StrapiQuery,
  useIndicatorCollectDisclosureRequirement_DrBySlug_StrapiQuery,
} from '../../../../graphql/cms/generated';
import { cmsClient } from '../../../../graphql/clients/cmsClient';
import { LoaderFullscreen } from '../../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import {
  DisclosureProvider,
  useDisclosure,
} from '../../gapAnalysis/disclosureRequirement/DisclosureContext';
import { useNavContext } from '../../../../providers/NavContextProvider';
import { IndicatorList } from './indicator/IndicatorList';
import { Card } from '../../../generic/card/Card';
import { DatapointTags } from '../../datapoint/DatapointTags';
import { CheckIcon } from '../../../icons';
import { ReportDataPointProvider } from './ReportDataPointProvider';

export const IndicatorCollectDisclosureRequirement = ({
  referenceSlug,
  readOnly = false,
}: {
  referenceSlug: string;
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const projectContext = useProjectContext();
  const csrdReport = projectContext?.enterprise?.reports?.find(
    (report) => report.standard === StandardEnum.Csrd,
  );

  const disclosureRequirementQuery =
    useIndicatorCollectDisclosureRequirement_DrBySlugQuery({
      variables: { reportId: csrdReport?.id || '', referenceSlug },
      fetchPolicy: 'cache-and-network',
    });

  const disclosureRequirement =
    disclosureRequirementQuery.data
      ?.reportDisclosureRequirementByTopicAndSlug || null;

  if (disclosureRequirementQuery.loading) {
    return <LoaderFullscreen />;
  }

  if (!disclosureRequirement) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {t('global:noResult')}
      </MessageBox>
    );
  }

  return (
    <DisclosureProvider
      disclosureRequirementSlug={referenceSlug}
      reportId={csrdReport!.id}
    >
      <IndicatorCollectDisclosureRequirementWithCmsData
        reportDisclosureRequirement={disclosureRequirement}
        readOnly={readOnly}
      />
    </DisclosureProvider>
  );
};

export const IndicatorCollectDisclosureRequirementWithCmsData = ({
  reportDisclosureRequirement,
  readOnly,
}: {
  reportDisclosureRequirement: IndicatorCollectDisclosureRequirement_DisclosureRequirementFragment;
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const datapointsSlugs = reportDisclosureRequirement.dataPoints.map(
    (dp) => dp.standardItemLink.slug,
  );
  const disclosureProvider = useDisclosure();

  const { setTitle } = useNavContext();

  const cmsDrQuery =
    useIndicatorCollectDisclosureRequirement_DrBySlug_StrapiQuery({
      client: cmsClient,
      variables: {
        locale: SurveyLanguage.Fr, // No EN translation available yet
        slug: reportDisclosureRequirement.standardItemLink.slug,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data.disclosureRequirements[0]) {
          setTitle(
            `${data.disclosureRequirements[0].code} ${data.disclosureRequirements[0].title}`,
          );
          // Select first datapoint
          const firstDatapointSlug =
            reportDisclosureRequirement.dataPoints[0]?.standardItemLink.slug;
          if (firstDatapointSlug) {
            disclosureProvider?.selectDatapointSlug(firstDatapointSlug);
          }
        }
      },
    });

  const cmsDpQuery =
    useIndicatorCollectDisclosureRequirement_DpBySlug_StrapiQuery({
      client: cmsClient,
      variables: {
        locale: SurveyLanguage.Fr, // No EN translation available yet
        slugs: datapointsSlugs,
      },
      fetchPolicy: 'cache-and-network',
    });

  if (cmsDrQuery.loading || cmsDpQuery.loading) {
    return <LoaderFullscreen />;
  }

  const cmsDisclosureRequirement =
    cmsDrQuery.data?.disclosureRequirements[0] || null;
  const cmsDatapoints = cmsDpQuery.data?.datapoints || [];
  const selectedDatapointSlug = disclosureProvider?.selectedDatapointSlug;
  const selectedReportDatapoint = reportDisclosureRequirement.dataPoints.find(
    (dp) => dp.standardItemLink.slug === selectedDatapointSlug,
  );
  const selectedCmsDatapoint = cmsDatapoints.find(
    (dp) => dp?.slug === selectedDatapointSlug,
  );

  if (!cmsDisclosureRequirement || !selectedReportDatapoint) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {t('global:noResult')}
      </MessageBox>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <Header cmsDisclosureRequirement={cmsDisclosureRequirement} />
      <div className="flex w-full bg-gray-50 border-t border-gray-100 overflow-hidden">
        <div className="flex items-stretch w-full overflow-hidden">
          <div className="main-content flex items-stretch w-full gap-4 py-4 overflow-hidden">
            <div className="w-4/12 space-y-4 overflow-y-scroll pr-2">
              <h3>
                {t(
                  'disclosure_requirements.disclosure_requirement.datapoints.title',
                )}
              </h3>
              {cmsDatapoints.length === 0 && (
                <div className="italic text-gray-500 text-sm text-center py-8">
                  {t('global:none')}
                </div>
              )}
              <DatapointsNav
                apiDatapoints={reportDisclosureRequirement.dataPoints}
                cmsDatapoints={cmsDatapoints}
              />
            </div>
            <div className="w-8/12 overflow-y-scroll px-2">
              {selectedReportDatapoint && selectedCmsDatapoint && (
                <ReportDataPointProvider
                  reportDataPoint={selectedReportDatapoint.id}
                >
                  <IndicatorList
                    reportDatapoint={selectedReportDatapoint}
                    cmsDatapoint={selectedCmsDatapoint}
                    readOnly={readOnly}
                  />
                </ReportDataPointProvider>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function DatapointsNav({
  apiDatapoints,
  cmsDatapoints,
}: {
  apiDatapoints: Array<IndicatorCollectDisclosureRequirement_DatapointFragment>;
  cmsDatapoints: Array<IndicatorCollectDisclosureRequirement_Datapoint_StrapiFragment | null>;
}) {
  const disclosureProvider = useDisclosure();

  return (
    <div className="space-y-2">
      {apiDatapoints.map((dp) => {
        const cmsDp = cmsDatapoints.find(
          (cmsDp) => cmsDp?.slug === dp.standardItemLink.slug,
        );
        if (cmsDp) {
          const indicatorsCounts = dp.indicators?.length || 0;
          const indicatorsHavingValue = dp.indicators?.filter((indicator) => {
            const countAggregate = indicator.valuesAggregate.find(
              (agg) => agg.count,
            );
            return countAggregate?.count?.id || 0 > 0;
          }).length;
          const allIndicatorsHaveAValue =
            indicatorsCounts > 0 && indicatorsCounts === indicatorsHavingValue;

          return (
            <Card
              key={dp.id}
              onClick={() => {
                disclosureProvider?.selectDatapointSlug(
                  dp.standardItemLink.slug,
                );
              }}
              isSelected={
                disclosureProvider?.selectedDatapointSlug ===
                dp.standardItemLink.slug
              }
            >
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <div className="font-bold grow">{cmsDp.identifier}</div>
                  {allIndicatorsHaveAValue ? (
                    <CheckIcon className="text-green-500" />
                  ) : (
                    <div className="font-bold text-gray-500 text-sm">
                      {indicatorsHavingValue}/{indicatorsCounts}
                    </div>
                  )}
                </div>
                <DatapointTags datapoint={cmsDp} />
              </div>
            </Card>
          );
        }
        return null;
      })}
    </div>
  );
}

function Header({
  cmsDisclosureRequirement,
}: {
  cmsDisclosureRequirement: IndicatorCollectDisclosureRequirement_DisclosureRequirement_StrapiFragment;
}) {
  return (
    <div className="bg-white">
      <div className="main-content py-8 flex justify-between items-center gap-4">
        <div className="space-y-2 grow">
          <div className="text-gray-500 text-sm">
            {cmsDisclosureRequirement.esrs?.name}
          </div>
          <h1 className="title-h2">
            {cmsDisclosureRequirement.code} {cmsDisclosureRequirement.title}
          </h1>
        </div>
      </div>
    </div>
  );
}
