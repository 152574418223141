import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import {
  CompanyFieldsFragment,
  useEnterpriseQuery,
} from '../graphql/generated';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../components/layout/Toast';
import clsx from 'clsx';
import { useCurrentUser } from './CurrentUserProvider';
import { isCoachUser } from '../services/CompanyService';
import { AppRoutes } from '../screens/AppRoutes';
import {
  ChooseProjectTemplateModal,
  chooseProjectTemplateModalConfig,
} from '../components/project/project/ChooseProjectTemplate/ChooseProjectTemplateModal';
import { useModal } from '../components/layout/Modal';
import { XIcon } from '../components/icons';
import coachIllustration from '../assets/images/illustrations/coach.png';
import { useTranslation } from '@hooks/useTranslation';

export type ProjectContextType = {
  enterprise: CompanyFieldsFragment | null;
  updateEnterprise: (
    companyId: string | null,
    redirectToHomePageAfterUpdate: boolean,
  ) => void;
  refreshEnterprise: () => void;
  isLoadingEnterprise: boolean;
};
const ProjectContext = createContext<ProjectContextType | null>(null);
export const useProjectContext = () => useContext(ProjectContext);

export function ProjectContextProvider({ children }: { children: ReactNode }) {
  const toast = useToast();
  const [enterprise, setEnterprise] = useState<CompanyFieldsFragment | null>(
    null,
  );
  const [isLoadingEnterprise, setIsLoadingEnterprise] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const isCoach = isCoachUser(currentUser);
  // Reload previous company from cookie if it exists
  const ACTIVE_ENTERPRISE_COOKIE_NAME = 'activeCompanyId';
  if (!isCoach && currentUser?.company?.id) {
    Cookies.set(ACTIVE_ENTERPRISE_COOKIE_NAME, currentUser?.company?.id);
  }

  const [activeEnterpriseId, setActiveEnterpriseId] = useState<string | null>(
    Cookies.get(ACTIVE_ENTERPRISE_COOKIE_NAME) || null,
  );
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { refetch } = useEnterpriseQuery({
    variables: {
      id: activeEnterpriseId || '',
    },
    skip: !activeEnterpriseId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      // const excludedPaths = [];
      // if (excludedPaths.includes(location.pathname as ProRoutes)) {
      //   setIsLoadingCompany(false);
      //   return;
      // }
      if (activeEnterpriseId) {
        setIsLoadingEnterprise(true);
        refetch({ id: activeEnterpriseId })
          .then((res) => {
            setEnterprise(res.data?.enterprise || null);
          })
          .catch((err) => {
            // Company does not exist or user does not have access anymore
            console.error(err);
            setActiveEnterpriseId(null);
            Cookies.remove(ACTIVE_ENTERPRISE_COOKIE_NAME);
          })
          .finally(() => setIsLoadingEnterprise(false));
      } else {
        setIsLoadingEnterprise(false);
      }
    }
  }, [activeEnterpriseId, refetch, location.pathname, isFirstRender]);

  const updateEnterprise = useCallback(
    (companyId: string | null, redirectToHomePageAfterUpdate: boolean) => {
      // Close project
      if (companyId === null) {
        setEnterprise(null);
        setActiveEnterpriseId(null);
        Cookies.remove(ACTIVE_ENTERPRISE_COOKIE_NAME);
        if (redirectToHomePageAfterUpdate) {
          navigate(AppRoutes.Home);
        }
        return;
      }

      setIsLoadingEnterprise(true);
      refetch({ id: companyId })
        .then((res) => {
          if (res.data.enterprise) {
            setEnterprise(res.data.enterprise);
            Cookies.set(ACTIVE_ENTERPRISE_COOKIE_NAME, companyId, {
              expires: 7,
            });
            if (redirectToHomePageAfterUpdate) {
              navigate(AppRoutes.Project);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          toast.openToastWithError(err.message);
          setEnterprise(null);
          Cookies.remove(ACTIVE_ENTERPRISE_COOKIE_NAME);
          navigate(AppRoutes.Home);
        })
        .finally(() => setIsLoadingEnterprise(false));
    },
    [refetch],
  );

  const refreshEnterprise = useCallback(() => {
    if (enterprise?.id) {
      refetch({
        id: enterprise.id,
      })
        .then((res) => {
          setEnterprise(res.data.enterprise);
        })
        .catch((err) => console.error(err));
    }
  }, [enterprise, refetch]);

  useEffect(() => {
    if (currentUser && !isCoach && currentUser.company && !activeEnterpriseId) {
      updateEnterprise(currentUser.company.id, false);
    }
  }, [currentUser, isCoach, updateEnterprise]);

  const contextValue = useMemo(
    () => ({
      enterprise,
      updateEnterprise,
      refreshEnterprise,
      isLoadingEnterprise,
    }),
    [enterprise, updateEnterprise, refreshEnterprise, isLoadingEnterprise],
  );

  return (
    <ProjectContext.Provider value={contextValue}>
      <div className={clsx('relative', isLoadingEnterprise && 'opacity-50')}>
        {enterprise?.referential === null && !enterprise.isTemplate && (
          <CurrentProjectHasNoReferentialYet />
        )}
        {children}
      </div>
    </ProjectContext.Provider>
  );
}

function CurrentProjectHasNoReferentialYet() {
  const modal = useModal();
  const { t } = useTranslation();
  const projectContext = useProjectContext();
  const enterpriseId = projectContext?.enterprise?.id;

  if (!enterpriseId) return null;

  return (
    <div>
      <div
        className={clsx('absolute inset-0 bg-gray-500 z-40 opacity-50')}
      ></div>
      <div
        className={clsx(
          'absolute inset-0 z-40 flex items-center justify-center',
        )}
      >
        <div className="rounded-xl bg-white flex flex-col items-center p-8 gap-8 w-96">
          <button
            className="tertiary place-self-start"
            onClick={() => projectContext?.updateEnterprise(null, true)}
          >
            <XIcon />
          </button>

          <img className="w-40" src={coachIllustration} alt={''} />

          <h2>{t('project.currentProjectHasNoReferentialYet.title')}</h2>

          <p className="text-center text-gray-500">
            {t('project.currentProjectHasNoReferentialYet.description')}
          </p>

          <div>
            <button
              className="primary purple"
              onClick={() =>
                modal.openModal(
                  <ChooseProjectTemplateModal
                    applyTemplateToCompanyId={enterpriseId}
                  />,
                  {
                    ...chooseProjectTemplateModalConfig,
                  },
                )
              }
            >
              {t('project.currentProjectHasNoReferentialYet.cta')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
