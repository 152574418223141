import React, { useState } from 'react';
import {
  CreateIndicatorValueNarrativeInput,
  CreateManyNarrativeIndicatorValuesInput,
  IndicatorValueForm_LatestIndicatorValue_NarrativeIndicatorValueFragment,
  IndicatorValueForm_LatestIndicatorValueFragment,
  NarrativeIndicatorValueFormMutationFn,
} from '../../../../../../../../../graphql/generated';
import { useIndicatorValueForm } from '../../../IndicatorValueFormContext';
import RichTextEditorTextarea from '../../../../../../../../generic/form/RichTextEditorTextarea';

export type NarrativeIndicatorValueType =
  IndicatorValueForm_LatestIndicatorValueFragment &
    IndicatorValueForm_LatestIndicatorValue_NarrativeIndicatorValueFragment;

export type NarrativeIndicatorValueFormMutationTypes = {
  input: CreateManyNarrativeIndicatorValuesInput;
  item: CreateIndicatorValueNarrativeInput;
  mutation: NarrativeIndicatorValueFormMutationFn;
}

export const NarrativeIndicatorValueForm = (
  {
    indicatorValue,
    readOnly = false
  }: {
    indicatorValue: CreateIndicatorValueNarrativeInput;
    readOnly?: boolean;
  }) => {
  const { addIndicatorValue } = useIndicatorValueForm<NarrativeIndicatorValueFormMutationTypes>()
  const [ updatedIndicatorValue, updateIndicatorValue ] = useState<CreateIndicatorValueNarrativeInput['narrative_value']>(
    indicatorValue.narrative_value
  )
  const setValue = (value: string)=> {
    updateIndicatorValue(value)
    addIndicatorValue({
      ...indicatorValue,
      narrative_value: value,
    })
  }

  return (
    <div className={'w-[450px]'}>
      <RichTextEditorTextarea value={updatedIndicatorValue ?? ''} onChange={setValue} disabled={readOnly}/>
    </div>
  );
};
