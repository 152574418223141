import {
  CurrentUserDocument,
  EnterpriseRoleEnum,
  JoinEnterpriseList_EnterpriseFragment,
  useJoinEnterpriseList_JoinMutation,
  useJoinEnterpriseListQuery,
} from 'graphql/generated';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { LoaderFullscreen } from '../layout/Loader';
import React from 'react';
import { useToast } from '../layout/Toast';
import { Loader } from '../generic/Loader';
import { ChevronDownIcon } from '../icons';
import { useDateTimeFromIsoString } from '@hooks/useDateTimeFromIsoString';

export function JoinEnterpriseList() {
  const { currentUser } = useCurrentUser();

  const { data, loading } = useJoinEnterpriseListQuery({
    skip: !currentUser?.company?.id,
    variables: { companyId: currentUser?.company?.id! },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  const companyProjects = data?.enterprisesOwnedByEnterprise || [];

  const companyIdsUserHasAccess =
    currentUser?.userAccesses
      ?.map((userAccess) => userAccess.enterprise)
      .map((company) => company?.id) || [];

  const joinableProjects = companyProjects
    .toSorted((a, b) => a.name.localeCompare(b.name))
    // remove projects already joined
    .filter((company) => !companyIdsUserHasAccess.includes(company.id))
    // remove templates
    .filter((company) => !company.isTemplate);

  return (
    <div className="max-w-6xl mt-16 pb-16">
      {joinableProjects && joinableProjects.length > 0 ? (
        <EnterprisesList companies={joinableProjects} />
      ) : null}
    </div>
  );
}

function EnterprisesList({
  companies,
}: {
  companies: JoinEnterpriseList_EnterpriseFragment[];
}) {
  const [displayProjects, setDisplayProjects] = React.useState(false);
  return (
    <table className="w-full">
      <thead>
        {displayProjects ? (
          <tr>
            <th>Rejoignez les autres projets de votre entreprise</th>
            <th className="w-40"></th>
          </tr>
        ) : (
          <tr>
            <th>Rejoignez les autres projets de votre entreprise</th>
            <th></th>
          </tr>
        )}
      </thead>
      <tbody>
        {displayProjects ? (
          companies.map((company) => (
            <EnterpriseItem company={company} key={company.id} />
          ))
        ) : (
          <tr>
            <td>
              <button
                className="tertiary p-0 text-sm text-gray-500"
                onClick={() => setDisplayProjects(true)}
              >
                <div>Voir les autres projets disponibles</div>
                <div className="tag gray">{companies.length}</div>
                <ChevronDownIcon />
              </button>
            </td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function EnterpriseItem({
  company,
}: {
  company: JoinEnterpriseList_EnterpriseFragment;
}) {
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const { currentUser } = useCurrentUser();
  const toast = useToast();

  const [isJoining, setIsJoining] = React.useState(false);
  const [joinEnterprise] = useJoinEnterpriseList_JoinMutation({
    refetchQueries: () => [{ query: CurrentUserDocument }],
  });
  const join = () => {
    setIsJoining(true);
    joinEnterprise({
      variables: {
        input: {
          enterprise: {
            id: company.id,
          },
          user: {
            id: currentUser?.id || '',
          },
          role: EnterpriseRoleEnum.Administrator, // TODO which role should be used here ?
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(
          `Vous avez rejoint le projet ${company.name} !`,
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsJoining(false);
      });
  };

  return (
    <tr key={company.id}>
      <td>
        <div>
          <div className="font-bold">{company.name}</div>
          <div className="text-xs text-gray-500 italic font-light">
            Démarré le {createdAt.date}
            {company.createdBy?.firstName &&
              ` par ${company.createdBy?.firstName} ${company.createdBy?.lastName}`}
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-end">
          <button
            className="primary purple small"
            onClick={join}
            disabled={isJoining}
          >
            {isJoining && <Loader />}
            <div>Rejoindre</div>
          </button>
        </div>
      </td>
    </tr>
  );
}
