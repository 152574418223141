import React, { Fragment, ReactNode, useContext, useState } from 'react';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';

export type RightDrawerContextType = {
  visibility: boolean;
  component: ReactNode | null;
  openDrawerWithComponent: (component: ReactNode) => void;
  closeDrawer: () => void;
};

const initialContext: RightDrawerContextType = {
  visibility: false,
  component: null,
  openDrawerWithComponent: () => {
    // do nothing
  },
  closeDrawer: () => {
    // do nothing
  },
};

const RightDrawerContext = React.createContext(initialContext);

export function RightDrawerProvider({ children }: { children: ReactNode }) {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [component, setComponent] = useState<ReactNode | null>(null);

  function openDrawerWithComponent(component: ReactNode) {
    setComponent(component);
    setVisibility(true);
  }

  function closeDrawer() {
    setVisibility(false);
  }

  return (
    <RightDrawerContext.Provider
      value={{
        visibility,
        openDrawerWithComponent,
        closeDrawer,
        component,
      }}
    >
      {children}
    </RightDrawerContext.Provider>
  );
}

export const useRightDrawer = () => useContext(RightDrawerContext);

export function RightDrawer() {
  const { visibility, closeDrawer, component } = useContext(RightDrawerContext);

  const content = visibility && component ? component : null;

  return (
    <Transition show={visibility} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={closeDrawer}>
        <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity z-40" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden z-50">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="flex flex-col pointer-events-auto w-sidebar shadow-xl bg-white h-screen overflow-y-scroll">
                  {content}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
