import React, { useEffect, useMemo, useState } from 'react';
import {
  QuestionIroAssessment_IroFragment,
  SurveyQuestionSummaryFieldsFragment,
  SurveySubmitAnswerInput,
  useQuestionIroAssessment_GetStakeIrosLazyQuery,
} from '../../../../graphql/generated';
import { QuestionDescription } from '../QuestionDescription';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { mapThemeColorToTailwindClass } from '../../../../services/TailwindService';
import { PillarIcon } from '../../../stake/PillarIcon';
import { IroCriteriaValuesDefinitionsProvider } from '../../../project/iro/evaluation/IroCriteriaValuesDefinitionsContext';
import { NavDirection } from '../../../../types/nav.types';
import { IroAssessmentForm } from './IroAssessmentForm';
import { IroAssessmentFormValues } from './IroAssessmentForm.types';
import { storeSurveyAnswersToLocalStorage } from '../../../../services/SurveyService';
import { useSurveyResponse } from '../../response/SurveyResponseContext';
import { ChevronRightIcon } from '../../../icons';
import { IllustratedExplanationMessage } from '../../../generic/IllustratedExplanationMessage';
import { MessageBoxType } from '../../../layout/MessageBox';

export const QuestionIroAssessment = ({
  question,
  answer,
  setIsAnswerValid,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
  setAnswer: (answer: SurveySubmitAnswerInput | null) => void;
  setIsAnswerValid: (isValid: boolean) => void;
}) => {
  const { t, translateProperty } = useTranslation();
  const pillar = question.pillar;
  const stake = question.stake;
  const hasDescription =
    question.description &&
    question.description !== '<p class="ql-align-center"></p>';

  const [iros, setIros] = useState<QuestionIroAssessment_IroFragment[]>(
    question.iroAssessment?.iros || [],
  );
  const [error, setError] = useState<string>();
  const [getStakeIros, { loading }] =
    useQuestionIroAssessment_GetStakeIrosLazyQuery();

  useEffect(() => {
    if (iros.length === 0 && !error) {
      setError(t('survey.question.iroAssessment.errors.noIro'));
    }
    setIros(question.iroAssessment?.iros || []);
  }, [iros.length, error, getStakeIros, t, question.stake]);

  useEffect(() => {
    if (iros.length === 0 && !error && !loading) {
      setIsAnswerValid(true);
    }
  }, [iros.length, error, loading, setIsAnswerValid]);

  return (
    <div className="bg-yellow-50 p-8 lg:px-16">
      <div className="flex flex-col items-center gap-4 w-full">
        {question.parent && (
          <div className="font-title font-extrabold text-center">
            {question.parent.title}
          </div>
        )}
        <div className="form-title">{question.title}</div>
        {hasDescription && (
          <QuestionDescription description={question.description!} />
        )}
        <div className={'flex gap-2 items-center'}>
          <div className={'flex gap-2 items-center'}>
            <div
              className={clsx(
                'p-2 rounded-lg',
                mapThemeColorToTailwindClass(pillar?.color, 100, 'bg'),
                mapThemeColorToTailwindClass(pillar?.color, 900, 'text'),
              )}
            >
              <PillarIcon pillar={pillar} />
            </div>
            <span className={'font-bold'}>
              {translateProperty(pillar, 'name')}
            </span>
          </div>
          <ChevronRightIcon />

          {translateProperty(stake, 'name')}
        </div>
        {(iros && iros.length > 0 && (
          <QuestionIroAssessmentInner
            question={question}
            iros={iros.filter(Boolean) as QuestionIroAssessment_IroFragment[]}
            answer={answer}
          />
        )) ||
          (error && (
            <div className={'w-1/4 mt-10'}>
              <IllustratedExplanationMessage
                title={t('global:oups')}
                description={error}
                type={MessageBoxType.Error}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export const QuestionIroAssessmentInner = ({
  iros,
  question,
  answer,
}: {
  iros: QuestionIroAssessment_IroFragment[];
  question: SurveyQuestionSummaryFieldsFragment;
  answer: SurveySubmitAnswerInput | undefined;
}) => {
  const { t } = useTranslation();
  const { answers } = useSurveyResponse();
  const [currentIroIndex, setCurrentIroIndex] = useState(0);
  const methods = useForm<IroAssessmentFormValues>({
    defaultValues: {
      iroAssessments: Array.isArray(answer?.iroAssessments)
        ? answer?.iroAssessments?.reduce<
            IroAssessmentFormValues['iroAssessments']
          >(
            (acc, assessment) => {
              if (!assessment.iro?.id) return acc;
              acc[assessment.iro.id] = {
                iro: { id: assessment.iro.id },
                scopeCriteria: assessment.scopeCriteria?.value
                  ? { value: assessment.scopeCriteria.value }
                  : undefined,
                scaleCriteria: assessment.scaleCriteria?.value
                  ? { value: assessment.scaleCriteria.value }
                  : undefined,
                irremediabilityCriteria: assessment.irremediabilityCriteria
                  ?.value
                  ? { value: assessment.irremediabilityCriteria.value }
                  : undefined,
                likelihoodCriteria: assessment.likelihoodCriteria?.value
                  ? { value: assessment.likelihoodCriteria.value }
                  : undefined,
                timeHorizon: assessment.timeHorizon || undefined,
              };
              return acc;
            },
            {} as IroAssessmentFormValues['iroAssessments'],
          )
        : {},
    },
  });

  const flatOrderedIros = useMemo(() => {
    const groups = iros.reduce((acc, iro) => {
      const type = iro.__typename;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(iro);
      return acc;
    }, {} as Record<string, QuestionIroAssessment_IroFragment[]>);

    const orderedTypes = Object.keys(groups).sort();
    let orderedIros: QuestionIroAssessment_IroFragment[] = [];
    orderedTypes.forEach((type) => {
      orderedIros = orderedIros.concat(groups[type]);
    });
    return orderedIros;
  }, [iros]);

  const currentIro = flatOrderedIros[currentIroIndex];
  const referential = question.pillar?.referential;

  const navigateIro = (direction: NavDirection) => {
    setCurrentIroIndex((prev) => {
      if (direction === NavDirection.NEXT) {
        return Math.min(prev + 1, flatOrderedIros.length - 1);
      } else {
        return Math.max(prev - 1, 0);
      }
    });
    storeSurveyAnswersToLocalStorage(
      question.survey.id,
      JSON.stringify(answers),
    );
  };

  return (
    <IroCriteriaValuesDefinitionsProvider
      definitions={referential?.iroCriteriaValuesDefinitions}
    >
      <FormProvider {...methods}>
        <IroAssessmentForm
          question={question}
          iro={currentIro}
          iros={flatOrderedIros}
          chooseIro={(index) => {
            setCurrentIroIndex(index);
          }}
          bottomNav={
            <div className="flex justify-between mt-4">
              <button
                type="button"
                onClick={() => navigateIro(NavDirection.PREVIOUS)}
                disabled={currentIroIndex === 0}
              >
                {t('survey.question.iroAssessment.nav.previous_iro')}
              </button>
              <div />
              <button
                type="button"
                onClick={() => navigateIro(NavDirection.NEXT)}
                disabled={currentIroIndex === flatOrderedIros.length - 1}
              >
                {t('survey.question.iroAssessment.nav.next_iro')}
              </button>
            </div>
          }
        />
      </FormProvider>
    </IroCriteriaValuesDefinitionsProvider>
  );
};
