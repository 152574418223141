import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { Table } from '../../../molecules/table/Table';
import { IroSummaryFilters } from './IroSummaryFilters';
import {
  IroSummaryStakeRow,
  useIroSummaryColumns,
} from './useIroSummaryColumns';
import {
  IroSummaryScreenFragment,
  IroSummaryScreenScoresFragment,
} from '../../../../graphql/generated';
import { transformData } from './transformData';
import { getSortedRowModel } from '@tanstack/react-table';
import { SummaryDetailIroRow } from './SummaryDetailIroRow';
import clsx from 'clsx';
import { NumberCircle } from '../../../generic/NumberCircle';
import { Toggle } from '../../../generic/form/Toggle';
import { ZapThinIcon } from '../../../icons';

export const IroSummary = ({
  referential,
  scores,
  readOnly,
}: {
  referential: IroSummaryScreenFragment;
  scores: IroSummaryScreenScoresFragment[];
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  // @ts-ignore
  const enterprise = referential.company;
  const [materialFilter, setMaterialFilter] = useState<boolean>(false);
  const [currentPillarId, setCurrentPillarId] = useState<string | null>(null);
  const columns = useIroSummaryColumns(readOnly);
  const data = transformData({
    response: referential.pillars,
    scores: scores,
    impactMaterialityThreshold: enterprise?.impactMaterialityThreshold,
    financialMaterialityThreshold: enterprise?.financialMaterialityThreshold,
    filters: currentPillarId
      ? {
          pillarId: [currentPillarId],
        }
      : undefined,
    readOnly,
  });

  const getCellClassName = (row: IroSummaryStakeRow, columnId: string) =>
    clsx('text-base', {
      'border-l-4! border-l-purple-500! bg-linear-to-r from-purple-100 to-transparent':
        !row.stake.isDisabled && columnId === 'stake', // Appliquez les classes uniquement à la cellule 'stake'
    });

  function toggleMaterialFilter() {
    setMaterialFilter(!materialFilter);
  }

  const filterMateriality = (
    data: IroSummaryStakeRow[],
  ): IroSummaryStakeRow[] => {
    if (!enterprise) return data;
    if (
      !enterprise.financialMaterialityThreshold &&
      !enterprise.impactMaterialityThreshold
    )
      return data;
    return data.filter((row) => {
      return !!(
        row.financialMateriality?.isMaterial ||
        row.impactMateriality?.isMaterial
      );
    });
  };

  const filteredData = materialFilter ? filterMateriality(data) : data;

  return (
    <div className="w-full p-8 space-8">
      <div className="pb-16 space-y-8">
        <div className="mt-5">
          <div className="flex items-center gap-2">
            <h4>{t('iro.summary.table.header.stake.label')}</h4>
            <NumberCircle number={filteredData.length} size={8} />
            <div
              className={clsx(
                'flex items-center ml-2 gap-2 font-semibold',
                !materialFilter && 'text-gray-500',
              )}
            >
              <Toggle state={materialFilter} setState={toggleMaterialFilter} />
              <ZapThinIcon className="h-5 w-5" />
              {t('iro.summary.filterByMateriality')}
            </div>
            <div className="grow"></div>
            <IroSummaryFilters
              {...{
                currentPillarId,
                setCurrentPillarId,
              }}
            />
          </div>
        </div>
        {referential.pillars && (
          <Table<IroSummaryStakeRow>
            {...{
              tableClassName: 'table table-fixed',
              columns,
              data: filteredData,
              enableSorting: true,
              enableExpanding: true,
              initialState: {
                sorting: [
                  {
                    id: 'maxMateriality',
                    desc: true,
                  },
                ],
              },
              getSortedRowModel: getSortedRowModel(),
              expandedRowRenderFn: (data, columnLength) => (
                <SummaryDetailIroRow data={data} columnLength={columnLength} />
              ),
              getCellClassName,
            }}
          />
        )}
      </div>
    </div>
  );
};
