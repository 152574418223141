import { FeatureEnum } from '../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';

export const getSortedFeaturesByModule = () => {
  const { t } = useTranslation();
  const modules: Record<string, { identifier: FeatureEnum; name: string }[]> =
    {};

  getSortedFeatures().forEach((identifier) => {
    const [module, feature] = identifier.split('__', 2);
    if (!modules[module]) {
      modules[module] = [];
    }
    modules[module].push({
      identifier,
      name: t(`project.roles.modules.${module}.features.${feature}`),
    });
  });

  return Object.keys(modules).map((module) => ({
    module,
    features: modules[module].sort((a, b) => a.name.localeCompare(b.name)),
  }));
};

// Custom sorting of features: respect the order of modules in left nav
const getSortedFeatures = () => {
  const sortedFeatures = [
    // Project
    FeatureEnum.ProjectCompany,
    FeatureEnum.ProjectMethodology,
    FeatureEnum.ProjectNotes,
    FeatureEnum.ProjectUsers,
    // Referential
    FeatureEnum.ReferentialManagement,
    // Stakeholder
    FeatureEnum.StakeholderManagement,
    // IRO
    FeatureEnum.IroCotation,
    FeatureEnum.IroMaterialityThresholds,
    // Maturity evaluation
    FeatureEnum.MaturityEvaluationManagement,
    // Survey
    FeatureEnum.ConsultationManagement,
    FeatureEnum.ConsultationResults,
    // Gap analysis & Report
    FeatureEnum.GapAnalysisManagement,
    FeatureEnum.IndicatorsCollect,
    FeatureEnum.IndicatorsCollectValidation,
    FeatureEnum.IndicatorsManagement,
    FeatureEnum.ReportManagement,
    // Deliverables
    FeatureEnum.DeliverableDoubleMaterialityMatrix,
    FeatureEnum.DeliverableMaterialityMatrix,
    FeatureEnum.DeliverableMaturityScore,
    FeatureEnum.DeliverablePrioritizationMatrix,
    FeatureEnum.RoadmapManagement,
    // Audit
    FeatureEnum.AuditTrail,
    FeatureEnum.AuditValidate,
  ];

  // Fallback: add missing features if any
  Object.values(FeatureEnum).forEach((feature) => {
    if (!sortedFeatures.includes(feature)) {
      sortedFeatures.push(feature);
    }
  });

  return sortedFeatures;
};
