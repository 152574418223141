import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown.types';
import {
  CompanySector,
  EnterpriseStructureType,
} from '../../../../graphql/generated';
import { CountryDropdown } from '../../../generic/dropdown/CountryDropdown';
import { InfoIcon } from '../../../icons';
import { NumberInput } from '../../../generic/form/NumberEditor';
import { CompanySectorDropdown } from '../CompanySectorDropdown';
import StringDropdown, {
  enumKeysToDropdownItems,
} from '../../../generic/dropdown/StringDropdown';

export type EnterpriseInformationFormProps = {
  initialData?: {
    name: string;
    sector?: CompanySector;
    size?: number;
    annualTurnover?: string;
    identificationNumber?: string;
    country?: string;
    structureType?: EnterpriseStructureType | null;
  };
  onSave: (data: {
    name: string;
    sector?: CompanySector;
    size?: number;
    identificationNumber?: string;
    country?: string;
    annualTurnover?: string;
    structureType?: StringDropdownItem | null;
  }) => void;
  isLoading?: boolean;
};

export const EnterpriseInformationForm = forwardRef(
  function EnterpriseInformationForm(
    { initialData, onSave, isLoading = false }: EnterpriseInformationFormProps,
    ref,
  ) {
    const { t } = useTranslation();

    const structureTypeItems: StringDropdownItem[] = enumKeysToDropdownItems(
      EnterpriseStructureType,
      (key: string) => t(`company.organizationalUnit.structureType.${key}`),
    );
    const structureTypeItem = initialData?.structureType
      ? structureTypeItems.find((item) => item.id === initialData.structureType)
      : null;
    const [name, setName] = useState<string>(initialData?.name || '');
    const [sector, setSector] = useState<CompanySector | undefined>(
      initialData?.sector,
    );
    const [size, setSize] = useState<number | undefined>(initialData?.size);
    const [annualTurnover, setAnnualTurnover] = useState<string>(
      initialData?.annualTurnover || '',
    );
    const [identificationNumber, setIdentificationNumber] = useState<string>(
      initialData?.identificationNumber || '',
    );
    const [country, setCountry] = useState<string | undefined>(
      initialData?.country,
    );
    const [structureType, setStructureType] =
      useState<StringDropdownItem | null>(structureTypeItem || null);

    const handleSave = () => {
      onSave({
        name,
        sector,
        size,
        annualTurnover,
        identificationNumber,
        country,
        structureType,
      });
    };
    const canSubmit = !isLoading && name !== '';

    useImperativeHandle(ref, () => ({
      submit: handleSave,
      canSubmit,
    }));

    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="name" className="form-input-label">
            {t('company.information.form.name')}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="form-input-text"
            value={name}
            onChange={(event) => setName(event.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="flex gap-2">
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="structureType" className="form-input-label">
              {t('company.information.form.country')}
            </label>
            <CountryDropdown
              selectedCountry={country}
              chooseItem={(itemId) => setCountry(itemId ?? undefined)}
            />
          </div>

          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="structureType" className="form-input-label">
              {t('company.information.form.structureType')}
            </label>
            <StringDropdown
              availableItems={structureTypeItems}
              item={structureType}
              setItem={setStructureType}
              allowEmpty={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="sector" className="form-input-label">
            {t('company.information.form.sector')}
          </label>
          <CompanySectorDropdown sector={sector} setSector={setSector} />
        </div>

        <div className="flex flex-col gap-2">
          <label htmlFor="annualTurnOver" className="form-input-label">
            {t('company.information.form.identificationNumber.label')}
          </label>
          <input
            type="text"
            name="identificationNumber"
            id="identificationNumber"
            className="form-input-text"
            placeholder={t(
              'company.information.form.identificationNumber.placeholder',
            )}
            value={identificationNumber}
            onChange={(event) => setIdentificationNumber(event.target.value)}
          />
          <div className="font-light text-xs text-gray-500 flex gap-3 items-center">
            <InfoIcon />
            {t('company.information.form.identificationNumber.help')}
          </div>
        </div>

        <div className="flex gap-2">
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="sector" className="form-input-label">
              {t('company.information.form.size')}
            </label>
            <NumberInput
              value={size}
              setValue={setSize}
              className={'w-full text-left!'}
            />
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <label htmlFor="annualTurnOver" className="form-input-label">
              {t('company.information.form.annualTurnover.label')}
            </label>
            <input
              type="text"
              name="annualTurnOver"
              id="annualTurnOver"
              className="form-input-text"
              placeholder={t(
                'company.information.form.annualTurnover.placeholder',
              )}
              value={annualTurnover}
              onChange={(event) => setAnnualTurnover(event.target.value)}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    );
  },
);
