import { FeatureEnum } from '../../../graphql/generated';
import { NavItem } from './NavItem';
import {
  MaterialityIcon,
  MaturityIcon,
  PriorityUpIcon,
  RoadmapIcon,
} from '../../icons';
import { AppRoutes } from '../../../screens/AppRoutes';
import React, { ReactNode } from 'react';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { useTranslation } from '@hooks/useTranslation';

export const useDeliverableNavItems = () => {
  const { isGranted } = usePermissionChecker();
  const { t } = useTranslation();
  const items: { key: string; element: ReactNode }[] = [];

  if (isGranted(FeatureEnum.DeliverableMaturityScore)) {
    items.push({
      key: 'maturity_evaluation',
      element: (
        <NavItem
          name={t('nav:page.maturity_score')}
          Icon={MaturityIcon}
          href={AppRoutes.PublicationMaturity}
        />
      ),
    });
  }
  if (isGranted(FeatureEnum.DeliverableMaterialityMatrix)) {
    items.push({
      key: 'materiality_matrix',
      element: (
        <NavItem
          name={t('nav:page.materiality_matrix')}
          Icon={MaterialityIcon}
          href={AppRoutes.PublicationMaterialityMatrix}
        />
      ),
    });
  }
  if (isGranted(FeatureEnum.DeliverableDoubleMaterialityMatrix)) {
    items.push({
      key: 'double_materiality_matrix',
      element: (
        <NavItem
          name={t('nav:page.double_materiality_matrix')}
          Icon={MaterialityIcon}
          href={AppRoutes.PublicationDoubleMaterialityMatrixIro}
        />
      ),
    });
  }
  if (isGranted(FeatureEnum.DeliverablePrioritizationMatrix)) {
    items.push({
      key: 'prioritization_matrix',
      element: (
        <NavItem
          name={t('nav:page.prioritization_matrix')}
          Icon={PriorityUpIcon}
          href={AppRoutes.PublicationPrioritizationMatrix}
        />
      ),
    });
  }
  if (isGranted(FeatureEnum.RoadmapManagement)) {
    items.push({
      key: 'roadmap',
      element: (
        <NavItem
          name={t('nav:page.roadmap')}
          Icon={RoadmapIcon}
          href={AppRoutes.Roadmap}
        />
      ),
    });
  }
  return items;
};
