import React from 'react';
import {
  FeatureEnum,
  JustificationType,
  PermissionEnum,
  useJustifyStandardTopicNotCoveredButtonQuery,
} from '../../../../../graphql/generated';
import { JustifyButton, JustifyButtonStyle } from '../JustifyButton';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export const JustifyStandardTopicNotCoveredButton = ({
  referentialId,
}: {
  referentialId: string;
}) => {
  const { isGranted } = usePermissionChecker();
  const type = JustificationType.StandardTopicsNotCovered;

  const query = useJustifyStandardTopicNotCoveredButtonQuery({
    variables: {
      type,
      id: referentialId,
    },
  });

  const latestJustificationId =
    query.data?.standardTopicNotCoveredJustifications?.edges[0]?.node.id;

  return (
    <JustifyButton
      type={type}
      entityId={referentialId}
      latestJustificationId={latestJustificationId}
      callback={query.refetch}
      style={JustifyButtonStyle.WithTagLabel}
      readOnly={
        !isGranted(FeatureEnum.ReferentialManagement, PermissionEnum.Write)
      }
    />
  );
};
