import React from 'react';
import { getSortedRowModel } from '@tanstack/react-table';
import {
  prepareData,
  SurveyIroAssessmentAnswersTableColumns,
  useSurveyIroAssessmentAnswersTableColumns,
} from './useSurveyIroAssessmentAnswersTableColumns';
import { Table } from '../../../../molecules/table/Table';
import {
  IroAssessmentDataviz_Answer_IroAssessmentFragment,
  IroType,
} from '../../../../../graphql/generated';

export const IroAssessmentsTable = ({
  iroType,
  data,
}: {
  iroType: IroType;
  data: IroAssessmentDataviz_Answer_IroAssessmentFragment[];
}) => {
  const columns = useSurveyIroAssessmentAnswersTableColumns(iroType);
  const tableData = prepareData(data);

  return (
    <Table<SurveyIroAssessmentAnswersTableColumns>
      tableClassName="table table-fixed"
      columns={columns}
      data={tableData}
      enableSorting={true}
      initialState={{ sorting: [] }}
      getSortedRowModel={getSortedRowModel()}
    />
  );
};
