import StringDropdown from '../../../../generic/dropdown/StringDropdown';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../../icons';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { PageInfo } from '../../../../../graphql/generated';

export const IroConsultationResultsPager = ({
  numberPerPage,
  setNumberPerPage,
  pageInfo,
  previous,
  next,
}: {
  numberPerPage: number;
  setNumberPerPage: (number: number) => void;
  pageInfo: PageInfo;
  previous: () => void;
  next: () => void;
}) => {
  const { t } = useTranslation();
  
  return (
    <div className="flex justify-end gap-2">
      <div className="w-30">
        <StringDropdown
          availableItems={[
            {
              id: '10',
              label: t('global:pager.itemsPerPage', { count: 10 }),
            },
            {
              id: '20',
              label: t('global:pager.itemsPerPage', { count: 20 }),
            },
            {
              id: '50',
              label: t('global:pager.itemsPerPage', { count: 50 }),
            },
          ]}
          item={{
            id: numberPerPage.toString(),
            label: t('global:pager.itemsPerPage', {
              count: numberPerPage,
            }),
          }}
          setItem={(item) => {
            if (item?.id) {
              setNumberPerPage(parseInt(item.id));
            }
          }}
          extraPositionClassName="-translate-y-full"
        />
      </div>
      <button
        aria-label={t('global:pager.previous')}
        disabled={!pageInfo.hasPreviousPage}
        onClick={previous}
      >
        <ChevronLeftIcon />
      </button>
      <button
        aria-label={t('global:pager.next')}
        disabled={!pageInfo.hasNextPage}
        onClick={next}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};
