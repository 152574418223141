import {
  IroType,
  QuestionIroAssessment_IroFragment,
} from '../../../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';
import { PillarIcon } from '../../../stake/PillarIcon';
import { CheckIcon } from '../../../icons';
import React, { useMemo } from 'react';
import { iroTypenameToIroType } from '../../../project/iro/iroTypenameToIroType';

export const IrosNav = ({
  iros,
  currentIro,
  chooseIro,
  answeredIroIds,
}: {
  iros: QuestionIroAssessment_IroFragment[];
  currentIro: QuestionIroAssessment_IroFragment | null;
  chooseIro: (index: number) => void;
  answeredIroIds: string[];
}) => {
  const { t, translateProperty } = useTranslation();
  // groupe by iro type (using __typename), keep the index
  const irosByType = useMemo(() => {
    return iros.reduce(
      (acc, iro, index) => {
        const type = iro.__typename;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push({ iro, index });
        return acc;
      },
      {} as Record<
        string,
        { iro: QuestionIroAssessment_IroFragment; index: number }[]
      >,
    );
  }, [iros]);

  return (
    <div>
      <div className="hidden sm:block space-y-4">
        {Object.keys(irosByType).map((type) => (
          <div key={type} className="space-y-1">
            <h6>
              {t(
                `survey.question.iroAssessment.${iroTypenameToIroType(
                  type as IroType,
                )}.title`,
              )}
            </h6>
            {irosByType[type].map(({ iro, index }) => (
              <div
                key={iro.id}
                className={clsx(
                  'rounded-lg h-10 border-2 border-gray-900 flex items-center gap-2 p-2 cursor-pointer hover:bg-gray-900 hover:text-white',
                  currentIro?.id === iro.id
                    ? 'bg-gray-900 text-white'
                    : 'bg-white text-gray-900',
                )}
                onClick={() => chooseIro(index)}
              >
                {iro.stake?.pillar && <PillarIcon pillar={iro.stake.pillar} />}
                <div className="font-bold text-sm grow truncate text-ellipsis max-w-[20rem]">
                  {translateProperty(iro, 'name')}
                </div>
                {answeredIroIds.includes(iro.id) && (
                  <CheckIcon className="shrink-0 w-4 h-4 bg-green-500 text-white rounded-full p-0.5" />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

