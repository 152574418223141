import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { IllustratedExplanationMessage } from '../../../generic/IllustratedExplanationMessage';
import loginIllustration from '../../../../assets/images/illustrations/login.png';
import { CreateChecklistButton } from '../../../task/CreateChecklistButton';
import { Checklist } from '../../../task/Checklist';
import React from 'react';
import { LoaderFullscreen } from '../../../layout/Loader';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { FeatureEnum, PermissionEnum } from '../../../../graphql/generated';

export const ProjectMethodologyContent = () => {
  const projectContext = useProjectContext();
  const { isGranted } = usePermissionChecker();
  const readOnly = !isGranted(
    FeatureEnum.ProjectMethodology,
    PermissionEnum.Read,
    true,
  );
  if (!projectContext?.enterprise) {
    return <LoaderFullscreen />;
  }
  if (!projectContext?.enterprise?.checklist?.id) {
    return (
      <div className="grow w-full py-8 px-16 max-w-6xl">
        <IllustratedExplanationMessage
          illustration={
            <img className="w-1/2" src={loginIllustration} alt="" />
          }
          title="Créez une liste de tâches"
          description="Menez votre projet à terme en créant une liste de tâches !"
        >
          <CreateChecklistButton
            companyId={projectContext?.enterprise?.id || ''}
            disabled={readOnly}
          />
        </IllustratedExplanationMessage>
      </div>
    );
  }

  return (
    <div className="grow bg-gray-50 w-full py-8 px-16">
      {projectContext?.enterprise?.checklist?.id && (
        <div className="max-w-4xl">
          <Checklist
            checklistId={projectContext.enterprise.checklist.id}
            readOnly={readOnly}
          />
        </div>
      )}
    </div>
  );
};
