import React from 'react';
import { Loader } from '../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import {
  ExportSurveyButtonFragment,
  SurveyLanguage,
} from '../../../graphql/generated';
import { ApiDownloadRoutes, useDownloadFile } from '@hooks/useDownloadFile';
import { generatePath } from 'react-router-dom';

export function ExportSurveyButton({
  survey,
}: {
  survey: ExportSurveyButtonFragment;
}) {
  // TODO: wrap HTTP API REST logic (using Swagger?) to make this code more re-usable
  const { t } = useTranslation();
  const { downloadFile, loading } = useDownloadFile({
    path: generatePath(ApiDownloadRoutes.SurveyExport, {
      surveyId: survey.id,
    }),
    fileName: t('survey.settings.export.filename', {
      surveyId: survey.id,
    }),
    errorToastMessage: t('toast:survey.export.error'),
    exportToLanguage: survey.language || SurveyLanguage.Fr,
  });

  return (
    <div className="bg-blue-50 p-4 rounded-xl flex items-center justify-between">
      <div className="text-blue-900 text-sm">
        {t('survey.settings.export.help')}
      </div>
      <button
        className="primary blue"
        onClick={downloadFile}
        disabled={loading}
      >
        {loading && <Loader />}
        {t('global:export')}
      </button>
    </div>
  );
}
