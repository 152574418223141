import {
  useTranslation as useI18nextTranslation,
  UseTranslationResponse,
} from 'react-i18next';
import type { FlatNamespace, KeyPrefix, Namespace } from 'i18next';
import { SurveyLanguage } from '../graphql/generated';
import { FallbackNs, UseTranslationOptions } from 'react-i18next/index';
import type { $Tuple } from 'react-i18next/helpers';
import {
  ComponentI18NTranslation,
  Enum_Componenti18Ntranslation_Language,
} from '../graphql/cms/generated';

type Translation = {
  language: SurveyLanguage;
  [key: string]: any;
};

type TranslatableStrapiComponent = Omit<
  ComponentI18NTranslation,
  'id' | '__typename'
>;

export type TranslatableObject = {
  i18n?: Translation[] | null;
};

type ExtendedUseTranslationResponse = UseTranslationResponse<Namespace, ''> & {
  translateProperty: <
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    property: K,
    language?: SurveyLanguage,
    fallbackDefault?: boolean,
  ) => string;
  translateStrapiProperty: <
    T extends (TranslatableStrapiComponent | null)[],
    K extends keyof TranslatableStrapiComponent,
  >(
    obj: T | undefined | null,
    property: K,
    language?: Enum_Componenti18Ntranslation_Language,
    fallbackDefault?: boolean,
  ) => string;
  getMissingTranslations: <
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    properties: K[],
    languages: SurveyLanguage[],
  ) => SurveyLanguage[];
};

export const useTranslation = <
  Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(
  ns?: Ns,
  options?: UseTranslationOptions<KPrefix>,
): ExtendedUseTranslationResponse => {
  const i18nextTranslation = useI18nextTranslation(ns, options);

  function translateProperty<
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    property: K,
    language?: SurveyLanguage,
    fallbackDefault = true,
  ): string {
    const currentLanguage = language || i18nextTranslation.i18n.language;
    const fallbackLanguage = process.env.DEFAULT_LANGUAGE as SurveyLanguage;

    const translation = obj?.i18n?.find(
      (i18n) => i18n.language === currentLanguage,
    );

    const fallbackTranslation = fallbackDefault
      ? obj?.i18n?.find((i18n) => i18n.language === fallbackLanguage)
      : null;

    if (translation && translation[property]) {
      return translation[property];
    } else if (
      fallbackDefault &&
      fallbackTranslation &&
      fallbackTranslation[property]
    ) {
      return fallbackTranslation[property];
    } else {
      return '';
    }
  }

  function translateStrapiProperty<
    T extends (TranslatableStrapiComponent | null)[],
    K extends keyof TranslatableStrapiComponent,
  >(
    obj: T | undefined | null,
    property: K,
    language?: Enum_Componenti18Ntranslation_Language,
    fallbackDefault = true,
  ): string {
    const currentLanguage = language || i18nextTranslation.i18n.language;
    const fallbackLanguage = process.env
      .DEFAULT_LANGUAGE as Enum_Componenti18Ntranslation_Language;

    const translation = obj?.find((t) => t?.language === currentLanguage);

    const fallbackTranslation = fallbackDefault
      ? obj?.find((t) => t?.language === fallbackLanguage)
      : null;

    if (translation && property in translation && translation[property]) {
      return translation[property] as string;
    } else if (
      fallbackDefault &&
      fallbackTranslation &&
      property in fallbackTranslation
    ) {
      return fallbackTranslation[property] as string;
    } else {
      return '';
    }
  }

  function getMissingTranslations<
    T extends TranslatableObject,
    K extends keyof Translation,
  >(
    obj: T | undefined | null,
    properties: K[],
    languages: SurveyLanguage[],
  ): SurveyLanguage[] {
    if (!obj || !obj.i18n) {
      return languages;
    }

    return languages.filter((language) => {
      return properties.some((property) => {
        const translation = obj.i18n?.find(
          (i18n) => i18n.language === language,
        );
        return !(translation && translation[property]);
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return {
    ...i18nextTranslation,
    translateProperty,
    translateStrapiProperty,
    getMissingTranslations,
  };
};
