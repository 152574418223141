import React, { Fragment } from 'react';
import StringDropdown from '../../../generic/dropdown/StringDropdown';
import {
  EnterpriseRoleEnum,
  FeatureEnum,
  PermissionEnum,
  ProjectRolesManagerDocument,
  useProjectRolesManagerQuery,
  useProjectRolesManagerUpdateRoleMutation,
} from '../../../../graphql/generated';
import { getSortedFeaturesByModule } from '../../../../services/FeatureService';
import { useTranslation } from '@hooks/useTranslation';
import { TableSkeletonLoader } from '../../../generic/loader/TableSkeletonLoader';
import { usePermissionDropdownItem } from './usePermissionDropdownItem';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { useToast } from '../../../layout/Toast';

export const ProjectRolesManager = ({
  enterpriseId,
}: {
  enterpriseId: string;
}) => {
  const toast = useToast();
  const roles = Object.values(EnterpriseRoleEnum);
  const [updateRole] = useProjectRolesManagerUpdateRoleMutation();
  const featuresByModules = getSortedFeaturesByModule();
  const { t } = useTranslation();
  const { data, loading } = useProjectRolesManagerQuery({
    variables: { enterpriseId },
  });
  const { isGranted } = usePermissionChecker();
  const canManageRoles = isGranted(
    FeatureEnum.ProjectUsers,
    PermissionEnum.Write,
  );

  if (loading) {
    return (
      <div className="w-full py-8 px-16 max-w-7xl">
        <TableSkeletonLoader
          columns={roles.length + 1}
          rows={30}
          randomSizes={{
            width: [
              'w-1/2',
              'w-7/12',
              'w-3/4',
              'w-9/12',
              'w-10/12',
              'w-11/12',
              'w-12/12',
            ],
          }}
        />
      </div>
    );
  }

  if (!data?.enterprise) {
    return null;
  }

  const rolePermissionsMap = new Map<string, Map<string, PermissionEnum>>();

  data.enterprise.roles?.forEach((role) => {
    const featurePermissions = new Map<string, PermissionEnum>();
    role.roleFeaturePermissions.forEach((permission) => {
      featurePermissions.set(permission.feature, permission.permission);
    });
    rolePermissionsMap.set(role.role, featurePermissions);
  });

  const defaultPermissionForAdmin = (role: string): PermissionEnum => {
    const isAdmin = data.enterprise.roles?.some(
      (r) => r.role === 'administrator' && r.role === role,
    );
    if (isAdmin) {
      return PermissionEnum.Write;
    }
    return PermissionEnum.None;
  };

  return (
    <div className="w-full py-8 px-16 max-w-7xl">
      <table>
        <thead>
          <tr>
            <th></th>
            {roles.map((role) => (
              <th key={role} className={'font-bold text-sm'}>
                {t(`enum:enterpriseRole.${role}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {featuresByModules.map((item) => {
            return (
              <Fragment key={item.module}>
                <tr>
                  <td
                    colSpan={roles.length + 1}
                    className="font-semibold border-y-2 border-y-gray-200 bg-gray-50 py-4 px-6 text-sm"
                  >
                    <strong>
                      {t(`project.roles.modules.${item.module}.name`)}
                    </strong>
                  </td>
                </tr>
                {item.features.map((feature) => (
                  <tr key={feature.identifier}>
                    <td>
                      <strong>{feature.name}</strong>
                    </td>
                    {roles.map((role) => {
                      const permission =
                        rolePermissionsMap.get(role)?.get(feature.identifier) ||
                        defaultPermissionForAdmin(role);

                      return (
                        <td key={`${feature.identifier}-${role}`}>
                          <RolePermissionDropdown
                            permission={permission}
                            disabled={!canManageRoles}
                            setItem={(item) => {
                              updateRole({
                                variables: {
                                  input: {
                                    enterprise: {
                                      id: enterpriseId,
                                    },
                                    role,
                                    feature: feature.identifier,
                                    permission: item?.id!,
                                  },
                                },
                                refetchQueries: [
                                  {
                                    query: ProjectRolesManagerDocument,
                                    variables: {
                                      enterpriseId,
                                    },
                                  },
                                ],
                              }).then(() =>
                                toast.openToastWithMessage(
                                  t('toast:project.users.updateRole.success'),
                                ),
                              );
                            }}
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const RolePermissionDropdown = ({
  permission,
  setItem,
  disabled,
}: {
  permission: PermissionEnum | null;
  setItem: (item: any) => void;
  disabled?: boolean;
}) => {
  return (
    <StringDropdown
      availableItems={[
        usePermissionDropdownItem(PermissionEnum.None),
        usePermissionDropdownItem(PermissionEnum.Read),
        usePermissionDropdownItem(PermissionEnum.Write),
      ]}
      item={usePermissionDropdownItem(permission)}
      setItem={setItem}
      disabled={disabled}
    />
  );
};
