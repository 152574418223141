import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import IroScalePicker from '../picker/IroScalePicker';
import IroScopePicker from '../picker/IroScopePicker';
import IroLikelihoodPicker from '../picker/IroLikelihoodPicker';
import { IroScore } from './IroScore';
import { IroIrremediabilityPicker } from '../picker/IroIrremediabilityPicker';
import {
  FinancialOpportunityRow_OpportunityIroFragment,
  FinancialRiskRow_RiskIroFragment,
  IroCellName_BaseIroFragment,
  IroEvaluationHeader_StakeFragment,
  IroType,
  NegativeImpactRow_NegativeImpactIroFragment,
  PositiveImpactRow_PositiveImpactIroFragment,
  useIroEvaluation_IroAssessmentsQuery,
} from '../../../../../graphql/generated';
import { IroNameCell } from './cell/IroNameCell';
import clsx from 'clsx';
import {
  CriteriaType,
  hasHasPotentialNegativeHumanRightsImpactCriteria,
  hasIrremediabilityCriteria,
  hasLikelihoodCriteria,
  hasScaleCriteria,
  hasScopeCriteria,
} from '../../../../../types/iro.types';
import { IroTimeHorizonPicker } from '../picker/IroTimeHorizonPicker';
import { IroIcon } from '../../IroIcon';
import { useIroCriteriaValuesDefinitions } from '../IroCriteriaValuesDefinitionsContext';
import { TooltipWrapper } from '../../../../generic/TooltipWrapper';
import { InfoIcon } from '../../../../icons';
import { CreateIroMenu } from './CreateIroMenu';
import { useCriteriaDefinitionDrawer } from './openCriteriaDefinitionDrawer';

export type IroFragment = IroCellName_BaseIroFragment &
  (
    | NegativeImpactRow_NegativeImpactIroFragment
    | PositiveImpactRow_PositiveImpactIroFragment
    | FinancialRiskRow_RiskIroFragment
    | FinancialOpportunityRow_OpportunityIroFragment
  );

export const IroRow = <T extends IroFragment>({
  referentialId,
  topics,
  stake,
  iros,
  iroType,
  lastSection = false,
  readOnly = false,
}: {
  referentialId: string;
  topics: string[];
  stake: IroEvaluationHeader_StakeFragment;
  iros: T[];
  iroType: IroType;
  lastSection?: boolean;
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const hasIros = iros.length > 0;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [leftShadowVisible, setLeftShadowVisible] = useState<boolean>(false);
  const [rightShadowVisible, setRightShadowVisible] = useState<boolean>(true);
  const openCriteriaDefinitionDrawer = useCriteriaDefinitionDrawer();

  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

    setLeftShadowVisible(scrollLeft > 0);
    setRightShadowVisible(scrollLeft + clientWidth < scrollWidth);
  };
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleResize = () => {
      handleScroll();
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', handleResize);
    handleScroll();

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const firstIro = iros.length > 0 ? iros[0] : null;

  const columnNumber = [
    firstIro ? hasScaleCriteria(firstIro) : false,
    firstIro ? hasScopeCriteria(firstIro) : false,
    firstIro ? hasIrremediabilityCriteria(firstIro) : false,
    firstIro ? hasLikelihoodCriteria(firstIro) : false,
    firstIro !== null,
  ].reduce((acc, hasCriteria) => acc + (hasCriteria ? 1 : 0), 2);

  const iroIds = iros?.map((iro) => iro!.id) ?? [];
  const { data: assessmentsData } = useIroEvaluation_IroAssessmentsQuery({
    variables: { iroIds },
    skip: iroIds.length === 0,
  });

  const assessmentsCountByIro = useMemo(() => {
    if (!assessmentsData?.surveyAnswerIroAssessments) return {};
    return assessmentsData.surveyAnswerIroAssessments.edges.reduce(
      (acc, edge) => {
        const iroId = edge.node.iro.id;
        acc[iroId] = (acc[iroId] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>,
    );
  }, [assessmentsData]);

  const hasAssessments = (iroId: string): boolean => {
    return assessmentsCountByIro[iroId] > 0;
  };

  const iroCriteriaValuesDefinitions = useIroCriteriaValuesDefinitions();

  return (
    <div className="w-full no-scrollbar" ref={scrollContainerRef}>
      <table className="min-w-full table-fixed ">
        <thead>
          <tr>
            <th
              className={clsx(
                'left-0 font-bold text-base w-[20vw] xl:w-[33vw] max-w-[26rem]',
                leftShadowVisible && 'left-sticky-col-shadow',
              )}
            >
              <div className="flex flex-col xl:flex-row items-center gap-2  justify-between">
                <div className="flex items-center gap-2 text-gray-900 capitalize">
                  <IroIcon iroTypename={iroTypeToTypename(iroType)} />
                  {t(`translation:iro.types.${iroType}`)}
                </div>
                {!readOnly && hasIros && (
                  <CreateIroMenu
                    stake={stake}
                    referentialId={referentialId}
                    iros={iros}
                    topics={topics}
                    iroType={iroType}
                  ></CreateIroMenu>
                )}
              </div>
            </th>

            {firstIro && hasScaleCriteria(firstIro) && (
              <th className="w-36 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    openCriteriaDefinitionDrawer(
                      iroType,
                      CriteriaType.SCALE,
                      iroCriteriaValuesDefinitions.definitions || [],
                    )
                  }
                >
                  {t('translation:iro.criteria.scale.title')}
                </div>
              </th>
            )}
            {firstIro && hasScopeCriteria(firstIro) && (
              <th className="w-36 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    openCriteriaDefinitionDrawer(
                      iroType,
                      CriteriaType.SCOPE,
                      iroCriteriaValuesDefinitions.definitions || [],
                    )
                  }
                >
                  {t('translation:iro.criteria.scope.title')}
                </div>
              </th>
            )}
            {firstIro && hasIrremediabilityCriteria(firstIro) && (
              <th className="w-40 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    openCriteriaDefinitionDrawer(
                      iroType,
                      CriteriaType.IRREMEDIABILITY,
                      iroCriteriaValuesDefinitions.definitions || [],
                    )
                  }
                >
                  {t('translation:iro.criteria.irremediability.title')}
                </div>
              </th>
            )}
            {firstIro && hasLikelihoodCriteria(firstIro) && (
              <th className="w-48 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    openCriteriaDefinitionDrawer(
                      iroType,
                      CriteriaType.LIKELIHOOD,
                      iroCriteriaValuesDefinitions.definitions || [],
                    )
                  }
                >
                  {t('translation:iro.criteria.likelihood.title')}
                </div>
              </th>
            )}
            {firstIro && (
              <th className="w-44 text-center">
                <div
                  className="capitalize cursor-help"
                  onClick={() =>
                    openCriteriaDefinitionDrawer(
                      iroType,
                      CriteriaType.TIME_HORIZON,
                      iroCriteriaValuesDefinitions.definitions || [],
                    )
                  }
                >
                  {t('translation:iro.criteria.timeHorizon.title')}
                </div>
              </th>
            )}
            <th
              className={clsx(
                'md:sticky md:right-0 text-center w-24',
                rightShadowVisible && 'md:right-sticky-col-shadow',
              )}
            >
              {t('screen.iro.materiality_score')}
            </th>
          </tr>
        </thead>
        <tbody>
          {!hasIros && (
            <tr>
              <td colSpan={columnNumber} className="text-center">
                {(!readOnly && (
                  <div className="relative flex flex-col items-center gap-4 py-4">
                    <div className="font-bold">
                      {t(`iro.create.add_to_stake.${iroType}`)}
                    </div>
                    <CreateIroMenu
                      stake={stake}
                      referentialId={referentialId}
                      iros={iros}
                      topics={topics}
                      iroType={iroType}
                    />
                  </div>
                )) || (
                  <div className="font-bold color-gray-300">
                    {t(`iro.list.table.emptyView.default`)}
                  </div>
                )}
              </td>
            </tr>
          )}
          {iros.map((iro, index) => {
            const contextualMenuPosition = {};
            let pickerPositionClassName = undefined;
            if (lastSection && iros.length === index + 1) {
              pickerPositionClassName = '-translate-y-full';
            }
            return (
              <tr key={iro.id}>
                <td
                  className={clsx(
                    'left-0 bg-white border-r border-gray-100 group',
                    leftShadowVisible && 'left-sticky-col-shadow',
                  )}
                >
                  
                  <IroNameCell {...{ iro, hasConsultationAssessments: hasAssessments(iro.id), readOnly, ...contextualMenuPosition }} />
                </td>
                {hasScaleCriteria(iro) && (
                  <td className="w-36 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center">
                      <IroScalePicker
                        iro={iro}
                        extraPositionClassName={pickerPositionClassName}
                        readOnly={readOnly}
                      />
                    </div>
                  </td>
                )}
                {hasScopeCriteria(iro) && (
                  <td className="w-32 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center">
                      <IroScopePicker
                        iro={iro}
                        extraPositionClassName={pickerPositionClassName}
                        readOnly={readOnly}
                      />
                    </div>
                  </td>
                )}
                {hasIrremediabilityCriteria(iro) && (
                  <td className="w-40 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center">
                      <IroIrremediabilityPicker
                        iro={iro}
                        extraPositionClassName={pickerPositionClassName}
                        readOnly={readOnly}
                      />
                    </div>
                  </td>
                )}
                {hasLikelihoodCriteria(iro) && (
                  <td className="w-48 border-r border-gray-100 text-center">
                    <div className="w-full flex items-center justify-center gap-0.5">
                      <LikelihoodPicker
                        {...{ iro, pickerPositionClassName }}
                        readOnly={readOnly}
                      />
                    </div>
                  </td>
                )}
                <td className="w-44 border-r border-gray-100 text-center">
                  <div className="w-full flex items-center justify-center">
                    <IroTimeHorizonPicker
                      iro={iro}
                      extraPositionClassName={pickerPositionClassName}
                      readOnly={readOnly}
                    />
                  </div>
                </td>
                <td
                  className={clsx(
                    'md:sticky md:right-0 w-24  bg-white text-center',
                    rightShadowVisible && 'md:right-sticky-col-shadow',
                  )}
                >
                  <div className="flex items-center gap-1">
                    <IroScore score={iro.score} />
                    <div className="shrink-0 text-sm text-gray-500">/4</div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

function LikelihoodPicker({
  iro,
  pickerPositionClassName,
  readOnly = false,
}: {
  iro: IroFragment;
  pickerPositionClassName: string | undefined;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();
  const potentialNegativeHumanRightsImpactCriteria =
    hasHasPotentialNegativeHumanRightsImpactCriteria(iro) &&
    iro.hasPotentialNegativeHumanRightsImpact === true;
  if (potentialNegativeHumanRightsImpactCriteria) {
    return (
      <TooltipWrapper
        label={t('iro.rules.hasPotentialNegativeHumanRightsImpact')}
      >
        <div className="flex items-center gap-0.5">
          <IroLikelihoodPicker
            iro={iro}
            extraPositionClassName={pickerPositionClassName}
            disabled={true}
          />
          <InfoIcon className="text-gray-300 w-4 h-4" />
        </div>
      </TooltipWrapper>
    );
  }
  return (
    <IroLikelihoodPicker
      iro={iro}
      extraPositionClassName={pickerPositionClassName}
      disabled={
        (hasHasPotentialNegativeHumanRightsImpactCriteria(iro) &&
          iro.hasPotentialNegativeHumanRightsImpact === true) ||
        readOnly
      }
    />
  );
}

export function iroTypeToTypename(iroType: IroType) {
  switch (iroType) {
    case IroType.NegativeImpact:
      return 'NegativeImpactIro';
    case IroType.PositiveImpact:
      return 'PositiveImpactIro';
    case IroType.Risk:
      return 'RiskIro';
    case IroType.Opportunity:
      return 'OpportunityIro';
  }
}

