import React from 'react';
import { LoaderFullscreen } from '../../../layout/Loader';
import {
  FeatureEnum,
  GapAnalysisChecklistDocument,
  PermissionEnum,
  StandardEnum,
  useGapAnalysisChecklistQuery,
  useGenerateCheckListGapAnalysisMutation,
} from '../../../../graphql/generated';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { CreateChecklistButton } from '../../../task/CreateChecklistButton';
import { Checklist } from '../../../task/Checklist';
import { NoData } from '../../../generic/NoData';
import { AutoIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import clsx from 'clsx';
import { Loader } from '../../../generic/Loader';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export function GapAnalysisChecklist() {
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker();
  const readOnly = isGranted(
    FeatureEnum.GapAnalysisManagement,
    PermissionEnum.Read,
    true,
  );
  const context = useProjectContext();
  const [checkListMutation, { data, loading, error }] =
    useGenerateCheckListGapAnalysisMutation();

  const updateOrBuildCheckList = async (reportId: string) => {
    checkListMutation({
      variables: { input: { reportId: reportId } },
      refetchQueries: [
        {
          query: GapAnalysisChecklistDocument,
          variables: {
            input: {
              company: { id: context?.enterprise?.id! },
              standard: StandardEnum.Csrd,
            },
          },
        },
      ],
    });
  };

  const apiQuery = useGapAnalysisChecklistQuery({
    variables: {
      input: {
        company: { id: context?.enterprise?.id! },
        standard: StandardEnum.Csrd,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  if (apiQuery.loading) {
    return (
      <div className="px-16 py-8 main-content">
        <LoaderFullscreen />
      </div>
    );
  }

  if (apiQuery.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>
        {apiQuery.error.message}
      </MessageBox>
    );
  }

  const createChecklistButton = (
    <CreateChecklistButton
      reportId={apiQuery.data?.companyReport?.id || ''}
      className="primary purple"
    />
  );

  const generateChecklistButton = (
    <button
      className={clsx('secondary purple', loading && 'disabled')}
      disabled={loading}
      onClick={() =>
        apiQuery.data?.companyReport?.id &&
        updateOrBuildCheckList(apiQuery.data?.companyReport?.id)
      }
    >
      {loading && <Loader />}
      {!loading && <AutoIcon />}
      {apiQuery.data?.companyReport?.checklist
        ? t('gap_analysis.checklist.update')
        : t('gap_analysis.checklist.generate')}
    </button>
  );

  if (apiQuery.data?.companyReport) {
    if (apiQuery.data?.companyReport?.checklist) {
      return (
        <div className="py-8 main-content">
          <div className={'w-full flex'}>
            <div className={'grow'}></div>
            {!readOnly && (
              <div className={'mb-2 '}>{generateChecklistButton}</div>
            )}
          </div>
          <Checklist
            checklistId={apiQuery.data.companyReport.checklist.id}
            readOnly={readOnly}
          />
        </div>
      );
    } else {
      return (
        <div className="py-8 main-content">
          <NoData
            text={t('gap_analysis.checklist.no_checklist_label', {
              context: readOnly ? 'readOnly' : undefined,
            })}
            actions={
              (readOnly && []) || [
                {
                  key: 'create-checklist',
                  component: createChecklistButton,
                },
                {
                  key: 'generate-checklist',
                  component: generateChecklistButton,
                },
              ]
            }
          />
        </div>
      );
    }
  }
}
