import React from 'react';
import { useModal } from '../../layout/Modal';
import { InboxIcon, PlusIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { SurveyTemplateGalleryModal } from './SurveyTemplateGalleryModal/SurveyTemplateGalleryModal';
import SplitButton from '../../generic/button/SplitButton/SplitButton';
import { ImportExternalConsultationModal } from './ImportExternalConsultationModal/ImportExternalConsultationModal';

export function OpenSurveyTemplateModalButton() {
  const modal = useModal();
  const { t } = useTranslation();
  const secondaryActions = [
    {
      title: t('survey.import.button.title'),
      description: t('survey.import.button.description'),
      icon: <InboxIcon />,
      onClick: () =>
        modal.openModalWithComponent(
          <ImportExternalConsultationModal />,
          null,
          false,
          false,
          '',
          'p-0',
        ),
    },
  ];
  return (
    <div>
      <SplitButton
        onClick={() =>
          modal.openModalWithComponent(
            <SurveyTemplateGalleryModal />,
            null,
            true,
            false,
            'w-10/12',
          )
        }
        secondaryActions={secondaryActions}
      >
        <PlusIcon className="w-4 h-4" />
        <span>{t('survey.add')}</span>
      </SplitButton>
    </div>
  );
}
