import { NavLink, useParams } from 'react-router-dom';
import { Action } from '../../../../components/publications/roadmap/Action';
import {
  ActionFieldsFragment,
  FeatureEnum,
  PermissionEnum,
  useActionQuery,
} from '../../../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../../../../components/layout/Loader';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { UpdateActionButton } from '../../../../components/project/roadmap/UpdateActionButton';
import { ChevronRightIcon } from '../../../../components/icons';
import { AppRoutes } from '../../../AppRoutes';
import { withProjectContext } from '../../../../providers/withProjectContext';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

function ActionScreenInner() {
  const { actionId } = useParams();
  const { isGranted } = usePermissionChecker();
  const readOnly = isGranted(
    FeatureEnum.RoadmapManagement,
    PermissionEnum.Read,
    true,
  );

  const { data, loading } = useActionQuery({
    variables: {
      id: actionId || '',
    },
    skip: !actionId,
  });

  if (loading) {
    return <LoaderFullscreen />;
  }

  if (data?.action) {
    return (
      <>
        <ScreenTopBar action={data.action} readOnly={readOnly} />
        <Action action={data.action} readOnly={readOnly} />
      </>
    );
  } else {
    return (
      <MessageBox type={MessageBoxType.Error}>
        <p>Une erreur est survenue lors de la récupération de l'action.</p>
      </MessageBox>
    );
  }
}

function ScreenTopBar({ action, readOnly = false }: { action: ActionFieldsFragment, readOnly?: boolean }) {
  return (
    <div className="sticky top-0 bg-white py-4 px-8 flex items-center gap-4 justify-between">
      <h1 className="title-h5 flex items-center gap-1">
        <NavLink to={AppRoutes.Roadmap}>
          <span>Feuille de route</span>
        </NavLink>
        <span>
          <ChevronRightIcon className="shrink-0" />
        </span>
        <span>{action.title}</span>
      </h1>
      {!readOnly && <UpdateActionButton action={action} displayLabel={true} />}
    </div>
  );
}

export const ActionScreen = withProjectContext(ActionScreenInner, {
  requiredCompany: true,
});
