import {
  SurveyQuestionSummaryFieldsFragment,
  SurveyQuestionUpdateInput,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { ToggleRequired } from './atoms/ToggleRequired';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { Toggle } from '../../../../generic/form/Toggle';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';
import { QuestionPillarStake } from './atoms/QuestionPillarStake';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionStakesRankingForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  const [canAddOtherChoice, setCanAddOtherChoice] = useState(
    questionDraft.canAddOtherChoice,
  );

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();

  const updateQuestion = (question: SurveyQuestionUpdateInput) => {
    updateQuestionMutation({
      variables: {
        input: question,
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  const toggleCanAddOtherChoice = (newState: boolean): void => {
    setCanAddOtherChoice(newState);
    updateQuestion({
      id: questionDraft.id,
      canAddOtherChoice: newState,
    });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
        <ToggleRequired question={questionDraft} />
      </div>

      <div className="pt-8 space-y-2">
        <QuestionPillarStake
          question={questionDraft}
          enableStakePicker={false}
          enablePillarPicker={true}
        />
        {question.pillar === null && (
          <MessageBox type={MessageBoxType.Error}>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'survey.edit.questions.type.common.pillarStakeInput.emptyPillarWarning',
                ),
              }}
            />
          </MessageBox>
        )}
      </div>

      <div className="pt-8 space-y-2">
        <h3>{t('survey.edit.questions.content')}</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="title" className="form-input-label mb-2">
              {t('translation:survey.edit.questions.type.stakesRanking.top')}
            </label>
            <input
              type="number"
              name="valueMax"
              min={(questionDraft.valueMin || 0) + 1}
              max={10}
              id="valueMax"
              className="form-input-text"
              value={questionDraft.valueMax || 0}
              onChange={(e) => {
                const newQuestionDraft = { ...questionDraft };
                newQuestionDraft.valueMax = parseInt(e.target.value);
                setQuestionDraft(newQuestionDraft);
                updateQuestion({
                  id: questionDraft.id,
                  valueMax: parseInt(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-2 justify-end">
            <div className="flex items-center gap-2 text-sm pb-2">
              <Toggle
                state={canAddOtherChoice}
                setState={toggleCanAddOtherChoice}
              />
              <label className="form-input-label">
                {t('survey.edit.questions.type.common.freeComment')}
              </label>
            </div>
          </div>
        </div>
        <div>
          <label htmlFor="title" className="form-input-label mb-2">
            {t('survey.edit.questions.type.common.questionLabel')}
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              setQuestionDraft({ ...questionDraft, title: e.target.value });
            }}
            onBlur={() =>
              updateQuestion({
                id: questionDraft.id,
                title: questionDraft.title,
              })
            }
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>
      </div>
    </div>
  );
}
