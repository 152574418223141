import {
  SurveyQuestionSummaryFieldsFragment,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionGroupForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const updateQuestion = () => {
    updateQuestionMutation({
      variables: {
        input: {
          id: questionDraft.id,
          title: questionDraft.title,
          required: false, // Forced to false
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="space-y-8">
      <QuestionTypeInput question={questionDraft} />
      <MessageBox type={MessageBoxType.Info}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('survey.edit.questions.type.group.description'),
          }}
        />
      </MessageBox>
      <div>
        <label htmlFor="title" className="form-input-label mb-2">
          {t('survey.edit.questions.type.group.tagline')}
        </label>
        <input
          type="text"
          name="title"
          id="title"
          className="form-input-text"
          value={questionDraft.title}
          onChange={(e) => {
            const newQuestionDraft = { ...questionDraft };
            newQuestionDraft.title = e.target.value;
            setQuestionDraft(newQuestionDraft);
          }}
          onBlur={() => updateQuestion()}
        />
      </div>
    </div>
  );
}
