import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { useTranslation } from '@hooks/useTranslation';
import { FeatureEnum, PermissionEnum } from '../../../graphql/generated';
import { ProjectSelect } from '../../project/ProjectSelect';
import { NavItem } from './NavItem';
import { CalendarIcon, StakesIcon, UserIcon } from '../../icons';
import { RequiredBadge } from '../../badges/RequiredBadge';
import { NavSection } from './NavSection';
import { CreateNoteButton } from '../../project/notebook/CreateNoteButton';
import React from 'react';
import { AppRoutes } from '../../../screens/AppRoutes';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { useDataCollectNavItems } from './useDataCollectNavItems';
import { useDeliverableNavItems } from './useDeliverableNavItems';

export const NavItems = () => {
  const projectContext = useProjectContext();

  const hasReferential = projectContext?.enterprise?.referential !== null;
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker();
  const dataCollectNavItems = useDataCollectNavItems();
  const deliverableNavItems = useDeliverableNavItems();

  return (
    <div className="flex flex-col">
      {<ProjectSelect />}
      {projectContext?.enterprise && (
        <div className="mt-4 space-y-0.5">
          {(isGranted(FeatureEnum.ProjectCompany) ||
            isGranted(FeatureEnum.ProjectUsers) ||
            isGranted(FeatureEnum.ProjectMethodology) ||
            isGranted(FeatureEnum.ProjectNotes)) && (
            <NavItem
              name={t('nav:page.project_management')}
              Icon={CalendarIcon}
              href={AppRoutes.Project}
            />
          )}

          {isGranted(FeatureEnum.ReferentialManagement) && (
            <NavItem
              name={t('nav:page.referential')}
              Icon={StakesIcon}
              href={AppRoutes.Stakes}
              badge={!hasReferential && <RequiredBadge />}
            />
          )}
          {isGranted(FeatureEnum.StakeholderManagement) && (
            <NavItem
              name={t('nav:page.stakeholder_mapping')}
              Icon={UserIcon}
              href={AppRoutes.StakeholderMapping}
            />
          )}
          {isGranted(FeatureEnum.IroCotation) && (
            <NavItem
              name={t('nav:page.iro_rating')}
              Icon={StakesIcon}
              href={AppRoutes.Iro}
            />
          )}
          {dataCollectNavItems.length > 0 && (
            <NavSection label={t('nav:leftNav.section.data_collect')}>
              {dataCollectNavItems.map(({ key, element }) => (
                <div key={key}>{element}</div>
              ))}
            </NavSection>
          )}
          {deliverableNavItems.length > 0 && (
            <NavSection label={t('nav:leftNav.section.publications')}>
              {deliverableNavItems.map(({ key, element }) => (
                <div key={key}>{element}</div>
              ))}
            </NavSection>
          )}
          {projectContext?.enterprise?.notebook &&
            isGranted(FeatureEnum.ProjectNotes, PermissionEnum.Write) && (
              <div className="py-4">
                <CreateNoteButton
                  notebook={projectContext.enterprise.notebook}
                  className="w-full secondary purple small"
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};
