import { useModal } from '../../../layout/Modal';
import { CreateOrganizationalUnit } from './CreateOrganizationalUnit';
import React from 'react';
import { PlusIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { OrganizationalUnitCard_OrganizationalUnitFragment } from '../../../../graphql/generated';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { Loader } from '../../../generic/Loader';

export function AddOrganizationalUnitButton({
  parent,
  onAdd,
}: {
  parent?: OrganizationalUnitCard_OrganizationalUnitFragment;
  onAdd?: () => void;
}) {
  const modal = useModal();
  const { t } = useTranslation();
  const context = useProjectContext();
  if (!context?.enterprise) {
    return <Loader />;
  }

  function openAddCompanyModal(companyId?: string) {
    modal.openModalWithComponent(
      <CreateOrganizationalUnit
        orgUnitParent={parent}
        enterprise={context!.enterprise!}
        onSave={() => {
          modal.closeModal();
          if (onAdd) onAdd();
        }}
      />,
      t('company.organizationalUnit.addOrganizationalUnit'),
    );
  }

  return (
    <button
      className="contextual-menu-item"
      onClick={() => openAddCompanyModal(parent?.id)}
    >
      <PlusIcon />
      {t('company.organizationalUnit.addOrganizationalUnit')}
    </button>
  );
}
