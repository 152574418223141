import React from 'react';
import { CopyTextToClipboardButton } from './CopyTextToClipboardButton';
import { LinkIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';

export function CopyableUrlInput({ urlToCopy }: { urlToCopy: string }) {
  const { t } = useTranslation();
  return (
    <div className="rounded-xl border py-1 pr-1 pl-2 grow">
      <div className="flex justify-between gap-4 items-center">
        <LinkIcon className="text-gray-500 w-4 h-4 shrink-0" />
        <input
          type="text"
          className="form-input-text-inline text-gray-500 pl-0 -ml-2"
          value={urlToCopy}
          readOnly={true}
        />
        <CopyTextToClipboardButton
          textToCopy={urlToCopy}
          buttonLabel={t('survey.stakeholderLists.shareLink.copyLink')}
          className="small shrink-0 purple"
        />
      </div>
    </div>
  );
}
