import React, { useState } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import {
  AdminCompaniesScreenDocument,
  AdminEnterprisesWithLicenseScreenDocument,
  AdminEnterprisesWithoutLicenseScreenDocument,
  EnterpriseLicenseEditor_CompanyFragment,
  useLicenseEditor_UpdateLicenseMutationMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { Checkbox } from '../../form/Checkbox';

export function EnterpriseLicenseEditor({
  company,
}: {
  company: EnterpriseLicenseEditor_CompanyFragment;
}) {
  const toast = useToast();
  const { t } = useTranslation();

  const [hasLicense, setHasLicense] = useState<boolean>(
    (company.license?.licensesCount && company.license?.licensesCount > 0) ||
      false,
  );

  const toggleHasLicense = (newHasLicense: boolean) => {
    setHasLicense(newHasLicense);
    saveLicenseCount(newHasLicense ? 1 : 0);
  };

  const [updateCompany, { loading }] =
    useLicenseEditor_UpdateLicenseMutationMutation();
  const saveLicenseCount = (licensesCount: number | null) => {
    updateCompany({
      variables: {
        input: {
          id: company.id,
          license: {
            id: company.license?.id || undefined,
            licensesCount: licensesCount,
          },
        },
      },
      refetchQueries: [
        AdminCompaniesScreenDocument,
        AdminEnterprisesWithLicenseScreenDocument,
        AdminEnterprisesWithoutLicenseScreenDocument,
      ],
    })
      .then(() => {
        toast.openToastWithMessage(t('admin:companies.toast.limit_updated'));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="flex items-center gap-2">
      <label className="form-input-label">
        {t('admin:companies.list.item.has_license')}
      </label>
      {loading ? (
        <Loader />
      ) : (
        <Checkbox
          onClick={() => toggleHasLicense(!hasLicense)}
          checked={hasLicense}
        />
      )}
    </div>
  );
}
