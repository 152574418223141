import clsx from 'clsx';
import React, { useState } from 'react';
import { CopyIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';

export function CopyTextToClipboardButton({
  textToCopy,
  buttonLabel,
  className,
  disabled = false,
}: {
  textToCopy: string;
  buttonLabel: string;
  className?: string;
  disabled?: boolean;
}) {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const { t } = useTranslation();
  return (
    <button
      className={clsx('primary', className, isCopiedToClipboard && 'green')}
      onClick={() => {
        void navigator.clipboard.writeText(textToCopy || '');
        setIsCopiedToClipboard(true);
        setTimeout(() => {
          setIsCopiedToClipboard(false);
        }, 2000);
      }}
      disabled={disabled}
    >
      <CopyIcon className="w-5 h-5 text-white" />
      <span>
        {isCopiedToClipboard ? t('global:clipboard.copied') : buttonLabel}
      </span>
    </button>
  );
}
