import React from 'react';
import { SkeletonLoader } from './SkeletonLoader';

type TableSkeletonLoaderProps = {
  columns: number;
  rows: number;
  randomSizes?: {
    width?: string[];
    height?: string[];
  };
};

export const TableSkeletonLoader = ({ columns, rows, randomSizes = {
  width: [
    'w-1/2',
    'w-7/12',
    'w-3/4',
    'w-9/12',
    'w-10/12',
    'w-11/12',
    'w-12/12',
  ],
} }: TableSkeletonLoaderProps) => {
  const defaultRandomSizes = {
    ...randomSizes,
    height: randomSizes?.height || ['h-6'],
  };

  return (
    <table>
      <thead>
      <tr className="space-x-2">
        {Array.from({ length: columns }).map((_, index) => (
          <th key={`header-${index}`} className="p-2">
            <SkeletonLoader
              size={{ height: 'h-6' }}
              randomSizes={defaultRandomSizes}
            />
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <tr key={`row-${rowIndex}`} className="space-x-2">
          {Array.from({ length: columns }).map((_, colIndex) => (
            <td key={`cell-${rowIndex}-${colIndex}`} className="p-2">
              <SkeletonLoader
                randomSizes={defaultRandomSizes}
              />
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  );
};
