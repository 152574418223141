import { useTranslation } from '@hooks/useTranslation';
import { AppRoutes } from '../AppRoutes';

export const enum ADMIN_TABS {
  CONSULTING_COMPANIES = 'CONSULTING_COMPANIES',
  ENTERPRISES = 'ENTERPRISES',
  USERS = 'USERS',
}

export const useAdminTabs = () => {
  const { t } = useTranslation();

  return [
    {
      key: ADMIN_TABS.CONSULTING_COMPANIES,
      name: t('nav:page.admin.consulting_companies'),
      path: AppRoutes.AdminConsultingCompanies,
    },
    {
      key: ADMIN_TABS.ENTERPRISES,
      name: 'Entreprises',
      path: AppRoutes.AdminEnterprises,
    },
    {
      key: ADMIN_TABS.USERS,
      name: 'Utilisateurs',
      path: AppRoutes.AdminUsers,
    },
  ];
};
