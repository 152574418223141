import { useModal } from '../../../layout/Modal';
import React, { useCallback, useRef } from 'react';
import { EditIcon } from '../../../icons';
import { useTranslation } from '@hooks/useTranslation';
import { DeleteSubsidiaryButton } from './DeleteSubsidiaryButton';
import {
  CompanySector,
  EditSubsidiaryButton_OrgUnitDocument,
  EnterpriseStructureType,
  useEditSubsidiaryButton_OrgUnitQuery,
  useEditSubsidiaryButtonMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown.types';
import { LoaderFullscreen } from '../../../layout/Loader';
import { EnterpriseInformationForm } from './EnterpriseForm';
import { CompanyLogoInput } from '../CompanyLogoInput';

export function EditSubsidiaryButton({ companyId }: { companyId: string }) {
  const modal = useModal();
  const { t } = useTranslation();

  function openEditCompanyModal(companyId: string) {
    if (companyId)
      modal.openModalWithComponent(
        <SubsidiaryUpdateInfo companyId={companyId} />,
        t('global:edit'),
      );
  }

  return (
    <button
      className="contextual-menu-item"
      onClick={() => openEditCompanyModal(companyId)}
    >
      <EditIcon />
      {t('global:edit')}
    </button>
  );
}

function SubsidiaryUpdateInfo({ companyId }: { companyId: string }) {
  const modal = useModal();
  const { t } = useTranslation();
  const formRef = useRef<{ submit: () => void } | null>(null);
  const companyQuery = useEditSubsidiaryButton_OrgUnitQuery({
    variables: {
      id: companyId,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateCompanyMutation, { loading }] =
    useEditSubsidiaryButtonMutation();
  const toast = useToast();
  const projectContext = useProjectContext();

  const handleSave = useCallback(
    (data: {
      name: string;
      sector?: string;
      size?: number;
      annualTurnover?: string;
      country?: string;
      identificationNumber?: string;
      structureType?: StringDropdownItem | null;
    }) => {
      if (!companyQuery.data?.organizationalUnit) {
        throw new Error('enterprise id is required');
      }
      updateCompanyMutation({
        variables: {
          input: {
            id: companyQuery.data.organizationalUnit.id,
            name: data.name,
            structureType: data.structureType?.id as EnterpriseStructureType,
            country: data.country,
            metadata: {
              sector: data.sector as CompanySector,
              size: data.size,
              annualTurnover: data.annualTurnover,
              identificationNumber: data.identificationNumber,
            },
          },
        },
        refetchQueries: [
          {
            query: EditSubsidiaryButton_OrgUnitDocument,
            variables: {
              id: companyId,
            },
          },
        ],
      })
        .then(() => {
          toast.openToastWithMessage(
            t('toast:company.settings.updated.success'),
          );
          projectContext?.refreshEnterprise();
          modal.closeModal();
        })
        .catch((err: { message: string }) => {
          toast.openToastWithError(err.message);
        });
    },
    [companyQuery],
  );

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  const enterprise = companyQuery.data?.organizationalUnit;

  return (
    <div className="space-y-4">
      <div className="space-y-8 divide-y divide-gray-100">
        <div className="grid grid-cols-2 gap-8 divide-x divide-gray-100">
          {enterprise && (
            <EnterpriseInformationForm
              ref={formRef}
              initialData={{
                name: enterprise.name,
                country: enterprise.country ?? undefined,
                sector: enterprise.metadata?.sector ?? undefined,
                size: enterprise.metadata?.size ?? undefined,
                identificationNumber:
                  enterprise.metadata?.identificationNumber ?? undefined,
                annualTurnover:
                  enterprise.metadata?.annualTurnover ?? undefined,
                structureType: enterprise.structureType,
              }}
              onSave={handleSave}
              isLoading={companyQuery.loading || loading}
            />
          )}
          <div className="pl-4">
            {enterprise && <CompanyLogoInput company={enterprise} />}
          </div>
        </div>
        <div className="py-8 flex justify-between">
          <DeleteSubsidiaryButton companyId={companyId} />
          <button className="primary" onClick={() => formRef.current?.submit()}>
            {t('global:save')}
          </button>
        </div>
      </div>
    </div>
  );
}
