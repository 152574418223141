import { PlacesType, Tooltip } from 'react-tooltip';
import React, { ReactNode } from 'react';
import { getTooltipUniqueId } from '../../services/UtilsService';
import { InnerHtml } from './InnerHtml';
import { isString } from '../../types/isString';

export function TooltipWrapper({
  label,
  children,
  place = 'top',
}: {
  children: React.ReactNode;
  label: ReactNode | null | undefined;
  place?: PlacesType;
}) {
  if (!label) {
    return <>{children}</>;
  }
  const id = getTooltipUniqueId('TooltipWrapper');
  return (
    <div data-tooltip-id={id} className="flex">
      {children}
      <Tooltip id={id} className="tooltip" place={place}>
        {isString(label) ? <InnerHtml html={label} /> : label}
      </Tooltip>
    </div>
  );
}
