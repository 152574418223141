import {
  IroType,
  QuestionIroAssessment_IroFragment,
  ScopeValue,
  ScopeValueDefinition,
} from '../../../../../graphql/generated';
import React from 'react';
import { IroAssessmentOptionRadio } from './radioButton/IroAssessmentOptionRadio';
import { IroAssessmentButtonGroup } from './IroAssessmentButtonGroup';
import { useTranslation } from '@hooks/useTranslation';
import { CriteriaType } from '../../../../../types/iro.types';
import { IroAssessmentOptionRadioTooltip } from './radioButton/IroAssessmentOptionRadioTooltip';
import { useFormContext } from 'react-hook-form';
import { IroAssessmentFormValues } from '../IroAssessmentForm.types';

export const IroAssessmentScopeButtonGroup = ({
  iro,
  scopeValuesDefinitions,
}: {
  iro: QuestionIroAssessment_IroFragment;
  scopeValuesDefinitions: ScopeValueDefinition[];
}) => {
  const { t } = useTranslation();
  const name = `iroAssessments.${iro.id}.scopeCriteria.value` as const;
  const { watch } = useFormContext<IroAssessmentFormValues>();
  const value = watch(name);

  return (
    <IroAssessmentButtonGroup
      criteria={CriteriaType.SCOPE}
      label={t('iro.criteria.scope.title')}
      iroType={iro.__typename as IroType}
    >
      <div className="w-56 flex items-center justify-evenly gap-2">
        <IroAssessmentOptionRadio<ScopeValue>
          {...{ name, value }}
          level={ScopeValue.Local}
          placeholder={'1'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScopeValue, ScopeValueDefinition>
              title={t('enum:iroCriteria.scope.Local')}
              definitions={scopeValuesDefinitions}
              value={ScopeValue.Local}
            />
          }
        />
        <IroAssessmentOptionRadio<ScopeValue>
          {...{ name, value }}
          level={ScopeValue.Limited}
          placeholder={'2'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScopeValue, ScopeValueDefinition>
              title={t('enum:iroCriteria.scope.Limited')}
              definitions={scopeValuesDefinitions}
              value={ScopeValue.Limited}
            />
          }
        />
        <IroAssessmentOptionRadio<ScopeValue>
          {...{ name, value }}
          level={ScopeValue.Large}
          placeholder={'3'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScopeValue, ScopeValueDefinition>
              title={t('enum:iroCriteria.scope.Large')}
              definitions={scopeValuesDefinitions}
              value={ScopeValue.Large}
            />
          }
        />
        <IroAssessmentOptionRadio<ScopeValue>
          {...{ name, value }}
          level={ScopeValue.Widespread}
          placeholder={'4'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<ScopeValue, ScopeValueDefinition>
              title={t('enum:iroCriteria.scope.Widespread')}
              definitions={scopeValuesDefinitions}
              value={ScopeValue.Widespread}
            />
          }
        />
      </div>
    </IroAssessmentButtonGroup>
  );
};


