import { StringDropdownItem } from '../../../generic/dropdown/StringDropdown.types';
import StringDropdown from '../../../generic/dropdown/StringDropdown';
import React from 'react';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../layout/Loader';
import { CompanyLogo } from '../CompanyLogo';
import { useOrganizationalUnitsDropdownQuery } from '../../../../graphql/generated';

export function OrganizationalUnitsDropdown({
  selectedOrgUnitIds,
  setSelectedOrgUnitIds,
  extraPositionClassName,
  disabled = false,
}: {
  selectedOrgUnitIds: string[] | null;
  setSelectedOrgUnitIds: (selectedIds: string[] | null) => void;
  extraPositionClassName?: string;
  disabled?: boolean;
}) {
  const currentCompany = useProjectContext();
  const companyQuery = useOrganizationalUnitsDropdownQuery({
    variables: {
      companyId: currentCompany?.enterprise?.id || '',
    },
    fetchPolicy: 'network-only',
  });

  if (companyQuery.loading) {
    return <LoaderFullscreen />;
  }

  const orgUnits = companyQuery.data?.enterprise.organizationalUnits || [];
  const availableItems: StringDropdownItem[] = orgUnits.flatMap((orgUnit) => [
    // level 1
    buildStringDropdownItem(orgUnit, 1),
    // level 2
    ...(orgUnit.subsidiaries || []).map((subsidiary) =>
      buildStringDropdownItem(subsidiary, 2),
    ),
    // level 3
    ...(orgUnit.subsidiaries || []).flatMap((subsidiary) =>
      (subsidiary.subsidiaries || []).map((subSubsidiary) =>
        buildStringDropdownItem(subSubsidiary, 3),
      ),
    ),
  ]);

  const multipleItems: StringDropdownItem[] = selectedOrgUnitIds
    ? availableItems.filter(
        (item) => item.id && selectedOrgUnitIds.includes(item.id),
      )
    : [];

  const setItems = (items: StringDropdownItem[] | null) => {
    const ids = items ? (items.map((item) => item.id) as string[]) : [];
    const selectedOrgUnitids = Array.isArray(ids) ? ids : [ids];
    setSelectedOrgUnitIds(selectedOrgUnitids);
  };

  return (
    <StringDropdown
      availableItems={availableItems}
      item={multipleItems}
      setItem={setItems}
      extraPositionClassName={extraPositionClassName}
      multiple={true}
      disabled={disabled}
    />
  );
}

function buildStringDropdownItem(
  enterprise: {
    id: string;
    name: string;
  },
  level: number,
): StringDropdownItem {
  return {
    id: enterprise.id,
    label: enterprise.name,
    icon: (
      <div className={`ml-${(level - 1) * 2}`}>
        <CompanyLogo
          companyId={enterprise.id}
          size={'small'}
          fallbackBgColor="bg-yellow-100"
        />
      </div>
    ),
  };
}
