import React, { ReactNode, useState } from 'react';
import { SurveyQuestionIcon } from '../../../survey/SurveyQuestionIcon';
import {
  AddQuestionButton_SurveyFragment,
  MaterialityTypes,
  SurveyLanguage,
  SurveyQuestionAddInput,
  SurveyQuestionType,
  useAddQuestionButton_AddMutation,
} from '../../../../graphql/generated';
import { useToast } from '../../../layout/Toast';
import { useModal } from '../../../layout/Modal';
import clsx from 'clsx';
import { Loader } from '../../../generic/Loader';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { PlusIcon, StarIcon } from '../../../icons';
import { isSurvey } from '../../../../types/survey.types';
import { Tag } from 'components/badges/Tag';
import { useTranslation } from '@hooks/useTranslation';

export enum AddQuestionButtonStyle {
  BUTTON = 'BUTTON',
  PLUS_ICON = 'PLUS_ICON',
}

export function AddQuestionButton({
  survey,
  position,
  style,
  callback,
  parentId,
}: {
  survey: AddQuestionButton_SurveyFragment;
  position: number;
  style: AddQuestionButtonStyle;
  callback?: (newQuestionId: string) => void;
  parentId?: string | null | undefined;
}) {
  const modal = useModal();
  const { t } = useTranslation();
  const isSurveyConsultation = isSurvey(survey);

  return (
    <button
      className={clsx(
        style === AddQuestionButtonStyle.BUTTON && 'primary purple w-full',
        style === AddQuestionButtonStyle.PLUS_ICON && 'unstyled text-gray-500',
      )}
      onClick={() => {
        if (isSurveyConsultation) {
          modal.openModalWithComponent(
            <AddQuestionForStakeholderSurveyModal
              survey={survey}
              position={position}
              callback={callback}
              parentId={parentId ? parentId : undefined}
            />,
            t('survey.edit.questions.add.modal.title'),
            true,
            true,
            'w-4/5',
          );
        } else {
          modal.openModalWithComponent(
            <AddQuestionForMaturityEvaluationSurveyModal
              survey={survey}
              position={position}
              callback={callback}
              parentId={parentId ? parentId : undefined}
            />,
            t('survey.edit.questions.add.modal.title'),
            true,
            true,
            'w-4/5',
          );
        }
      }}
    >
      <PlusIcon className="" />
      {style === AddQuestionButtonStyle.BUTTON && (
        <div>{t('survey.edit.questions.add.button')}</div>
      )}
    </button>
  );
}

function AddQuestionForMaturityEvaluationSurveyModal({
  survey,
  position,
  callback,
  parentId,
}: {
  survey: AddQuestionButton_SurveyFragment;
  position: number;
  callback?: (newQuestionId: string) => void;
  parentId?: string;
}) {
  const { t } = useTranslation();
  const defaultQuestionValues: SurveyQuestionAddInput = {
    survey: {
      id: survey.id,
    },
    position,
    parent: parentId
      ? {
          id: parentId,
        }
      : undefined,
    required: true,
    title: t('survey.edit.questions.type.common.defaultNewValues.title'),
    description: '<p class="ql-align-center"></p>',
    type: SurveyQuestionType.ShortText,
  };
  const language = survey.language || SurveyLanguage.Fr

  return (
    <div className="grid grid-cols-2 gap-8 2xl:gap-16 w-full p-6 2xl:p-8">
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">{t('survey.edit.questions.add.modal.structure')}</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Statement}
            label={t('survey.edit.questions.type.statement.name')}
            description=""
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStatementQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Group}
            label={t('survey.edit.questions.type.group.name')}
            description={t('survey.edit.questions.type.group.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getGroupQuestionTemplate(language)}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">{t('survey.edit.questions.add.modal.simpleQuestions')}</h5>
          <QuestionItem
            questionType={SurveyQuestionType.ShortText}
            label={t('survey.edit.questions.type.shortText.name')}
            description={t('survey.edit.questions.type.shortText.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getShortTextQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MultipleChoice}
            label={t('survey.edit.questions.type.multipleChoice.name')}
            description={t('survey.edit.questions.type.multipleChoice.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMultipleChoiceQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Number}
            label={t('survey.edit.questions.type.number.name')}
            description={t('survey.edit.questions.type.number.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getNumberQuestionTemplate(language)}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5 opacity-40">{t('survey.edit.questions.add.modal.scores')}</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Rating}
            label={t('survey.edit.questions.type.rating.name')}
            description={t('survey.edit.questions.type.rating.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getRatingQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Enps}
            label={t('survey.edit.questions.type.enps.name')}
            description={t('survey.edit.questions.type.enps.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEnpsQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
        </div>
      </div>
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5 opacity-40">{t('survey.edit.questions.add.modal.identification')}</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Email}
            label={t('survey.edit.questions.type.identity.name')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEmailQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">{t('survey.edit.questions.add.modal.stakesAssessment')}</h5>
          <QuestionItem
            questionType={SurveyQuestionType.StakesRanking}
            label={t('survey.edit.questions.type.stakesRanking.name')}
            description={t('survey.edit.questions.type.stakesRanking.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakesRankingQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MaturityEvaluation}
            label={t('survey.edit.questions.type.maturityEvaluation.name')}
            description={t('survey.edit.questions.type.maturityEvaluation.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMaturityEvaluationQuestionTemplate(language)}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5 opacity-40">{t('survey.edit.questions.add.modal.materialityAssessment')}</h5>
          <QuestionItem
            questionType={SurveyQuestionType.Matrix}
            label={t('survey.edit.questions.type.matrix.name')}
            description={t('survey.edit.questions.type.matrix.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMatrixQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label={t('survey.edit.questions.type.stakeDoubleMateriality.financialName')}
            description={t('survey.edit.questions.type.stakeDoubleMateriality.financial.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityFinancialQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label={t('survey.edit.questions.type.stakeDoubleMateriality.impactName')}
            description={t('survey.edit.questions.type.stakeDoubleMateriality.impact.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityImpactQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
}

function AddQuestionForStakeholderSurveyModal({
  survey,
  position,
  callback,
  parentId,
}: {
  survey: AddQuestionButton_SurveyFragment;
  position: number;
  callback?: (newQuestionId: string) => void;
  parentId?: string;
}) {
  const projectContext = useProjectContext();
  const { t } = useTranslation();

  const clientSpecificQuestionTypes =
    projectContext?.enterprise?.metadata?.customQuestionTypes || [];

  const defaultQuestionValues: SurveyQuestionAddInput = {
    survey: {
      id: survey.id,
    },
    position,
    parent: parentId
      ? {
          id: parentId,
        }
      : undefined,
    required: true,
    title: t('survey.edit.questions.type.common.defaultNewValues.title'),
    description: '<p class="ql-align-center"></p>',
    type: SurveyQuestionType.ShortText,
  };
  const language = survey.language || SurveyLanguage.Fr

  return (
    <div className="grid grid-cols-2 gap-8 2xl:gap-16 w-full p-4 2xl:p-8">
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">
            {t('survey.edit.questions.add.modal.structure')}
          </h5>
          <QuestionItem
            questionType={SurveyQuestionType.Statement}
            label={t('survey.edit.questions.type.statement.name')}
            description=""
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStatementQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Group}
            label={t('survey.edit.questions.type.group.name')}
            description={t('survey.edit.questions.type.group.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getGroupQuestionTemplate(language)}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">
            {t('survey.edit.questions.add.modal.simpleQuestions')}
          </h5>
          <QuestionItem
            questionType={SurveyQuestionType.ShortText}
            label={t('survey.edit.questions.type.shortText.name')}
            description={t('survey.edit.questions.type.shortText.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getShortTextQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MultipleChoice}
            label={t('survey.edit.questions.type.multipleChoice.name')}
            description={t('survey.edit.questions.type.multipleChoice.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMultipleChoiceQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Number}
            label={t('survey.edit.questions.type.number.name')}
            description={t('survey.edit.questions.type.number.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getNumberQuestionTemplate(language)}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">
            {t('survey.edit.questions.add.modal.scores')}
          </h5>
          <QuestionItem
            questionType={SurveyQuestionType.Rating}
            label={t('survey.edit.questions.type.rating.name')}
            description={t('survey.edit.questions.type.rating.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getRatingQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.Enps}
            label={t('survey.edit.questions.type.enps.name')}
            description={t('survey.edit.questions.type.enps.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEnpsQuestionTemplate(language)}
            callback={callback}
          />
        </div>
      </div>
      <div className="space-y-4 2xl:space-y-8">
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">
            {t('survey.edit.questions.add.modal.identification')}
          </h5>
          <QuestionItem
            questionType={SurveyQuestionType.Email}
            label={t('survey.edit.questions.type.identity.name')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getEmailQuestionTemplate(language)}
            callback={callback}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">
            {t('survey.edit.questions.add.modal.stakesAssessment')}
          </h5>
          <QuestionItem
            questionType={SurveyQuestionType.StakesRanking}
            label={t('survey.edit.questions.type.stakesRanking.name')}
            description={t('survey.edit.questions.type.stakesRanking.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakesRankingQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.MaturityEvaluation}
            label={t('survey.edit.questions.type.maturityEvaluation.name')}
            description={t('survey.edit.questions.type.maturityEvaluation.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMaturityEvaluationQuestionTemplate(language)}
            callback={callback}
            disabled={true}
          />
        </div>
        <div className="space-y-1 2xl:space-y-2">
          <h5 className="title-h6 2xl:title-h5">
            {t('survey.edit.questions.add.modal.materialityAssessment')}
          </h5>
          <QuestionItem
            questionType={SurveyQuestionType.Matrix}
            label={t('survey.edit.questions.type.matrix.name')}
            description={t('survey.edit.questions.type.matrix.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getMatrixQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label={t(
              'survey.edit.questions.type.stakeDoubleMateriality.financial.name',
            )}
            description={t(
              'survey.edit.questions.type.stakeDoubleMateriality.financial.help',
            )}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityFinancialQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.StakeDoubleMateriality}
            label={t(
              'survey.edit.questions.type.stakeDoubleMateriality.impact.name',
            )}
            description={t(
              'survey.edit.questions.type.stakeDoubleMateriality.impact.help',
            )}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getStakeDoubleMaterialityImpactQuestionTemplate(language)}
            callback={callback}
          />
          <QuestionItem
            questionType={SurveyQuestionType.IroAssessment}
            label={
              <div className={'flex gap-3'}>
                {t('survey.edit.questions.type.iroAssessment.name')}
                <Tag color="green" small>
                  {t('global:new')}
                </Tag>
              </div>
            }
            description={t('survey.edit.questions.type.iroAssessment.help')}
            defaultQuestionValues={defaultQuestionValues}
            questionTemplate={getIroAssessmentQuestionTemplate(language)}
            callback={callback}
          />
        </div>
        {clientSpecificQuestionTypes.length > 0 && (
          <div className="space-y-1 2xl:space-y-2">
            <h5 className="title-h6 2xl:title-h5 flex items-center gap-2">
              <StarIcon className="shrink-0 text-gray-900" />
              <div>{t('global:custom')}</div>
            </h5>
            {clientSpecificQuestionTypes.includes(
              SurveyQuestionType.UtopiesStakeDoubleMateriality,
            ) && (
              <>
                <QuestionItem
                  questionType={
                    SurveyQuestionType.UtopiesStakeDoubleMateriality
                  }
                  label={t('survey.edit.questions.type.utopiesStakeDoubleMateriality.financial.name')}
                  description={t('survey.edit.questions.type.utopiesStakeDoubleMateriality.financial.description')}
                  defaultQuestionValues={defaultQuestionValues}
                  questionTemplate={getUtopiesStakeDoubleMaterialityFinancialQuestionTemplate(language)}
                  callback={callback}
                />
                <QuestionItem
                  questionType={
                    SurveyQuestionType.UtopiesStakeDoubleMateriality
                  }
                  label={t('survey.edit.questions.type.utopiesStakeDoubleMateriality.impact.name')}
                  description={t('survey.edit.questions.type.utopiesStakeDoubleMateriality.impact.description')}
                  defaultQuestionValues={defaultQuestionValues}
                  questionTemplate={getUtopiesStakeDoubleMaterialityImpactQuestionTemplate(language)}
                  callback={callback}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function QuestionItem({
  questionType,
  label,
  description,
  defaultQuestionValues,
  questionTemplate,
  callback,
  disabled,
}: {
  questionType: SurveyQuestionType;
  label: ReactNode;
  description?: string;
  defaultQuestionValues: SurveyQuestionAddInput;
  questionTemplate?: Partial<SurveyQuestionAddInput>;
  callback?: (newQuestionId: string) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const modal = useModal();

  const [isAdding, setIsAdding] = useState(false);

  const [addSurveyQuestionMutation] = useAddQuestionButton_AddMutation();
  const addQuestion = () => {
    if (disabled) {
      return;
    }
    setIsAdding(true);
    const newQuestion: SurveyQuestionAddInput = {
      ...defaultQuestionValues,
      ...questionTemplate,
    };
    addSurveyQuestionMutation({
      variables: {
        input: newQuestion,
      },
    })
      .then((newQuestion) => {
        toast.openToastWithMessage(t('toast:survey.question.create.success'));
        modal.closeModal();
        if (callback && newQuestion.data?.surveyQuestionAdd) {
          callback(newQuestion.data?.surveyQuestionAdd.id);
        }
      })
      .catch(() => {
        toast.openToastWithError(t('toast:survey.question.create.error'));
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  return (
    <div
      className={clsx(
        'group flex items-center gap-4 border border-gray-100 rounded-xl px-2 2xl:px-4 py-1.5 2xl:py-3 shadow-sm',
        disabled
          ? 'bg-gray-50'
          : 'bg-white cursor-pointer hover:bg-gray-50 hover:border-gray-900',
      )}
      onClick={addQuestion}
    >
      <SurveyQuestionIcon questionType={questionType} color={900} />
      <div className={clsx('space-y-1 grow', disabled && 'opacity-40')}>
        <div className="font-semibold text-sm 2xl:text-base">{label}</div>
        {description && (
          <div className="text-gray-500 text-sm">{description}</div>
        )}
      </div>
      {isAdding ? (
        <Loader />
      ) : !disabled ? (
        <PlusIcon className="shrink-0 text-gray-300 group-hover:text-gray-900" />
      ) : null}
    </div>
  );
}

function getStatementQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.Statement,
    title: t('survey.edit.questions.type.statement.name'),
    description: `<p class="ql-align-center">${t('survey.edit.questions.type.statement.description')}</p>`,
  };
}

function getGroupQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.Group,
    title: t('survey.edit.questions.type.group.name'),
  };
}

function getShortTextQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.ShortText,
    title: t('survey.edit.questions.type.shortText.defaultValues.name'),
  };
}

function getNumberQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.Number,
    title: t('survey.edit.questions.type.number.defaultValues.name'),
  };
}

function getMultipleChoiceQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.MultipleChoice,
    title: t('survey.edit.questions.type.multipleChoice.defaultValues.title'),
    choices: [
      {
        label: t('survey.edit.questions.type.multipleChoice.defaultValues.choiceLabel', {number: 1}),
        position: 1,
      },
      {
        label: t('survey.edit.questions.type.multipleChoice.defaultValues.choiceLabel', {number: 2}),
        position: 2,
      },
    ],
    canSelectMultipleChoices: false,
  };
}

function getRatingQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.Rating,
    title: t('survey.edit.questions.type.rating.defaultValues.title'),
    valueMin: 1,
    valueMax: 10,
  };
}

function getEnpsQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.Enps,
    title: t('survey.edit.questions.type.enps.defaultValues.title'),
  };
}

function getEmailQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.Email,
    title: t('survey.edit.questions.type.identity.defaultValues.title'),
    description:
      `<p class="ql-align-center">${t('survey.edit.questions.type.identity.defaultValues.description')}</p>`,
  };
}

function getMaturityEvaluationQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.MaturityEvaluation,
    title: t('survey.edit.questions.type.maturityEvaluation.defaultValues.title'),
    description:
      `<p class="ql-align-center">${t('survey.edit.questions.type.maturityEvaluation.defaultValues.description')}</p>`,
    choices: [
      {
        label: t('survey.edit.questions.type.maturityEvaluation.defaultValues.choices.0'),
        position: 1,
        score: 0,
      },
      {
        label: t('survey.edit.questions.type.maturityEvaluation.defaultValues.choices.1'),
        position: 2,
        score: 1,
      },
      {
        label: t('survey.edit.questions.type.maturityEvaluation.defaultValues.choices.2'),
        position: 3,
        score: 2,
      },
      {
        label: t('survey.edit.questions.type.maturityEvaluation.defaultValues.choices.3'),
        position: 4,
        score: 3,
      },
      {
        label: t('survey.edit.questions.type.maturityEvaluation.defaultValues.choices.4'),
        position: 5,
        score: 4,
      },
    ],
  };
}

function getStakesRankingQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.StakesRanking,
    title: t('survey.edit.questions.type.stakesRanking.defaultValues.title'),
    valueMin: 1,
    valueMax: 5,
  };
}

function getMatrixQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.Matrix,
    title: t('survey.edit.questions.type.matrix.defaultValues.title'),
  };
}

function getStakeDoubleMaterialityImpactQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.StakeDoubleMateriality,
    title: t('translation:survey.edit.questions.type.stakeDoubleMateriality.defaultValues.title'),
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Impact,
      materialityOptions: {
        impactLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.impactLabel'),
        impactNoneLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.impactNoneLabel'),
        impactLowLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.impactLowLabel'),
        impactMediumLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.impactMediumLabel'),
        impactHighLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.impactHighLabel'),
        probabilityLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.probabilityLabel'),
        probabilityLowLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.probabilityLowLabel'),
        probabilityMediumLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.probabilityMediumLabel'),
        probabilityHighLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.probabilityHighLabel'),
        probabilityCertainLabel: t('survey.edit.questions.type.stakeDoubleMateriality.impact.defaultValues.materialityOptions.probabilityCertainLabel'),
      },
    },
  };
}

function getIroAssessmentQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.IroAssessment,
    title: t('survey.edit.questions.type.iroAssessment.defaultValues.title'),
    canAddOtherChoice: false,
  };
}

function getStakeDoubleMaterialityFinancialQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.StakeDoubleMateriality,
    title: t('survey.edit.questions.type.stakeDoubleMateriality.defaultValues.title'),
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Financial,
      materialityOptions: {
        impactLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.impactLabel'),
        impactNoneLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.impactNoneLabel'),
        impactLowLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.impactLowLabel'),
        impactMediumLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.impactMediumLabel'),
        impactHighLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.impactHighLabel'),
        probabilityLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.probabilityLabel'),
        probabilityLowLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.probabilityLowLabel'),
        probabilityMediumLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.probabilityMediumLabel'),
        probabilityHighLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.probabilityHighLabel'),
        probabilityCertainLabel: t('survey.edit.questions.type.stakeDoubleMateriality.financial.defaultValues.materialityOptions.probabilityCertainLabel'),
      },
    },
  };
}

// Client specific questions

function getUtopiesStakeDoubleMaterialityImpactQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.UtopiesStakeDoubleMateriality,
    title: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.title'),
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Impact,
      materialityOptions: {
        qualityLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.qualityLabel'),
        qualityNegativeChoiceLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.qualityNegativeChoiceLabel'),
        qualityNeutralChoiceLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.qualityNeutralChoiceLabel'),
        qualityPositiveChoiceLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.qualityPositiveChoiceLabel'),
        impactLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.impactLabel'),
        impactNoneLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.impactNoneLabel'),
        impactLowLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.impactLowLabel'),
        impactMediumLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.impactMediumLabel'),
        impactHighLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.impactHighLabel'),
        probabilityLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.probabilityLabel'),
        probabilityLowLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.probabilityLowLabel'),
        probabilityMediumLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.probabilityMediumLabel'),
        probabilityHighLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.probabilityHighLabel'),
        probabilityCertainLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.impact.probabilityCertainLabel'),
      },
    },
  };
}

function getUtopiesStakeDoubleMaterialityFinancialQuestionTemplate(language: SurveyLanguage): Partial<SurveyQuestionAddInput> {
  const { t } = useTranslation(undefined, { lng: language});

  return {
    type: SurveyQuestionType.UtopiesStakeDoubleMateriality,
    title: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.title'),
    canAddOtherChoice: false,
    stakeOptions: {
      materialityType: MaterialityTypes.Financial,
      materialityOptions: {
        qualityLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.qualityLabel'),
        qualityNegativeChoiceLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.qualityNegativeChoiceLabel'),
        qualityNeutralChoiceLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.qualityNeutralChoiceLabel'),
        qualityPositiveChoiceLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.qualityPositiveChoiceLabel'),
        impactLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.impactLabel'),
        impactNoneLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.impactNoneLabel'),
        impactLowLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.impactLowLabel'),
        impactMediumLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.impactMediumLabel'),
        impactHighLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.impactHighLabel'),
        probabilityLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.probabilityLabel'),
        probabilityLowLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.probabilityLowLabel'),
        probabilityMediumLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.probabilityMediumLabel'),
        probabilityHighLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.probabilityHighLabel'),
        probabilityCertainLabel: t('survey.edit.questions.type.utopiesStakeDoubleMateriality.defaultValues.financial.probabilityCertainLabel'),
      },
    },
  };
}
