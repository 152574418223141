import React from 'react';
import { SurveyLanguage } from '../../graphql/generated';
import { useTranslation } from '@hooks/useTranslation';
import LanguageDropdown from './LanguageDropdown';

// This component can be used to change the whole app language
export default function ChangeLanguageDropdown() {
  const { i18n } = useTranslation();

  const onChange = (language: SurveyLanguage) => {
    i18n.changeLanguage(language).catch((e) => {
      console.error(e);
    });
  };

  return (
    <LanguageDropdown
      onUpdate={onChange}
      currentLanguage={i18n.language as SurveyLanguage}
    />
  );
}
