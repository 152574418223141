import React, { useRef } from 'react';
import { StakesRankingChart_StakeFragment } from '../../../../graphql/generated';
import { NumberCircle } from '../../../generic/NumberCircle';
import { StakeTag } from '../../../stake/StakeTag';
import { DownloadIcon, ExportIcon } from '../../../icons';
import { ContextualMenu } from '../../../generic/ContextualMenu';
import { useModal } from '../../../layout/Modal';
import { ExportToImageModal } from '../../../generic/ExportableToImage';
import {
  convertArrayToObject,
  getExportFileName,
} from '../../../../services/FileService';
import { utils, writeFileXLSX } from 'xlsx';
import { useTranslation } from '@hooks/useTranslation';

export type RankedStakes = {
  stake: StakesRankingChart_StakeFragment;
  score: number | null;
};

export function StakesRankingChart({
  rankedStakes,
  title,
}: {
  rankedStakes: RankedStakes[];
  title: string;
}) {
  const exportableRef = useRef<HTMLDivElement>(null);

  return (
    <div className="relative">
      <div className="absolute top-4 right-4 z-40">
        <ExportMenu topStakes={rankedStakes} exportableRef={exportableRef} />
      </div>
      <div ref={exportableRef}>
        <div className="font-bold mb-4 pr-8">{title}</div>
        <div className="space-y-2 mb-4">
          {rankedStakes.map((rankedStake, index) => (
            <div key={rankedStake.stake.id} className="flex items-center gap-4">
              <div className="flex items-center space-x-2 grow">
                <NumberCircle number={index + 1} size={8} />
                <div>
                  <StakeTag
                    stake={rankedStake.stake}
                    disableDetailModalOpening={true}
                  />
                </div>
              </div>
              <div className="text-gray-500 text-xs shrink-0">
                score {(rankedStake.score || 0).toFixed(1)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ExportMenu({
  topStakes,
  exportableRef,
}: {
  topStakes: RankedStakes[];
  exportableRef: React.RefObject<HTMLDivElement>;
}) {
  const button = (
    <ExportIcon className="shrink-0 h-3.5 w-3.5 text-gray-500" />
  );
  return (
    <ContextualMenu button={button}>
      <ExportToXlsButton topStakes={topStakes} />
      <ExportToImageButton refToExport={exportableRef} />
    </ContextualMenu>
  );
}

function ExportToXlsButton({ topStakes }: { topStakes: RankedStakes[] }) {
  const { translateProperty } = useTranslation();
  const exportToXls = () => {
    const header = ['Identifiant', 'Enjeu', 'Score', 'Classement'];
    const rows = topStakes.map((rankedStake, index) => {
      const stakeName = translateProperty(rankedStake.stake, 'name');
      return [
        rankedStake.stake.id,
        stakeName,
        rankedStake.score || 0,
        index + 1,
      ];
    });

    // Convert array to object to be able to use xlsx library
    const asAnObject = convertArrayToObject(rows, header);

    const worksheet = utils.json_to_sheet(asAnObject, {
      skipHeader: false,
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'classement');
    writeFileXLSX(workbook, 'Export tableau classement.xlsx', {
      compression: true,
    });
  };

  return (
    <button className="contextual-menu-item" onClick={exportToXls}>
      <DownloadIcon />
      <div>Fichier XLS</div>
    </button>
  );
}

function ExportToImageButton({
  refToExport,
}: {
  refToExport: React.RefObject<HTMLDivElement>;
}) {
  const modal = useModal();
  return (
    <button
      className="contextual-menu-item"
      onClick={() =>
        modal.openModalWithComponent(
          <ExportToImageModal
            refToExport={refToExport}
            name={getExportFileName('Export tableau classement')}
          />,
          "Exporter en tant qu'image",
          false,
          true,
          'w-8/12',
        )
      }
    >
      <DownloadIcon />
      <div>Image</div>
    </button>
  );
}
