import React from 'react';
import { generatePath, useRoutes } from 'react-router-dom';

import { useCurrentAuth } from '../providers/CurrentAuthProvider';
import logo from '../assets/images/logo.svg';

import { useCurrentUser } from '../providers/CurrentUserProvider';
import { useRouteConfig } from './routeConfigs/useRouteConfig';
import { AppRoutes } from './AppRoutes';

export function ScreensRouter() {
  const currentAuth = useCurrentAuth();
  const { currentUser } = useCurrentUser();
  const routes = useRoutes(useRouteConfig());

  return typeof currentAuth === 'undefined' &&
    typeof currentUser === 'undefined' ? (
    <LoadingImage />
  ) : (
    routes
  );
}

function LoadingImage() {
  return (
    <div className="flex h-screen w-screen">
      <img className="m-auto w-24 h-24" src={logo} alt="" />
    </div>
  );
}

export function getActionLink(isCoach: boolean, actionId: string): string {
  if (actionId) {
    return generatePath(AppRoutes.RoadmapAction, {
      actionId,
    });
  } else {
    return generatePath(AppRoutes.Roadmap);
  }
}
