import {
  AddEnterpriseIndicatorForDatapointModal_ReportDatapointFragment,
  IndicatorListDocument,
  IndicatorValueType,
  StandardItemType,
  SurveyLanguage,
  useAddEnterpriseIndicatorForDatapointModalMutation,
} from '../../../../../../graphql/generated';
import { useToast } from '../../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { useState } from 'react';
import StringDropdown, {
  enumKeysToDropdownItems,
} from '../../../../../generic/dropdown/StringDropdown';
import { Loader } from '../../../../../generic/Loader';
import { useModal } from '../../../../../layout/Modal';
import { Toggle } from '../../../../../generic/form/Toggle';
import { AddEnterpriseIndicatorForDatapointModal_DatapointFragment } from '../../../../../../graphql/cms/generated';
import {
  DimensionDropdown,
  DimensionDropdownItem,
} from '../edit/configureDimensionItems/dropdown/DimensionDropdown';
import { StringDropdownItem } from '../../../../../generic/dropdown/StringDropdown.types';
import { useReportDataPoint } from '../../ReportDataPointProvider';
import { useProjectContext } from '../../../../../../providers/ProjectContextProvider';
import clsx from 'clsx';

export const AddEnterpriseIndicatorForDatapointModal = ({
  companyId,
  reportDatapoint,
  cmsDatapoint,
}: {
  companyId: string;
  reportDatapoint: AddEnterpriseIndicatorForDatapointModal_ReportDatapointFragment;
  cmsDatapoint: AddEnterpriseIndicatorForDatapointModal_DatapointFragment;
}) => {
  const modal = useModal();
  const { reportDataPoint } = useReportDataPoint();
  const [addCompanyIndicator, { loading }] =
    useAddEnterpriseIndicatorForDatapointModalMutation();
  const toast = useToast();
  const { t, translateStrapiProperty, i18n } = useTranslation();
  const projectContext = useProjectContext();

  const [name, setName] = useState(
    translateStrapiProperty(cmsDatapoint.translation, 'name'),
  );
  const [selectedDimensions, setSelectedDimensions] = useState<
    DimensionDropdownItem[]
  >([]);
  const [indicatorType, setIndicatorType] = useState<StringDropdownItem | null>(
    null,
  );
  const [splitByOrganizationalUnit, setSplitByOrganizationalUnit] =
    useState(false);

  const addIndicator = () => {
    addCompanyIndicator({
      variables: {
        input: {
          enterprise: { id: companyId },
          reportDatapoint: { id: reportDatapoint.id },
          dimensions: selectedDimensions?.map((item) => {
            return {
              i18n: [
                {
                  name: item.label,
                  language: i18n.language as SurveyLanguage,
                },
              ],
              standardItemLink:
                (item.type === 'standard' &&
                  item.id && {
                    slug: item.id,
                    type: StandardItemType.Dimension,
                  }) ||
                undefined,
            };
          }),
          type: indicatorType?.id as IndicatorValueType,
          splitByOrganizationalUnit,
          i18n: {
            name,
            language: i18n.language as SurveyLanguage,
          },
        },
      },
      refetchQueries: [
        {
          query: IndicatorListDocument,
          variables: {
            reportDataPointId: reportDataPoint,
          },
        },
      ],
      onCompleted: () => {
        modal.closeModal();
        toast.openToastWithMessage(
          t(
            'toast:indicator_collect.disclosure_requirement.indicator.create.success',
          ),
        );
      },
      onError: (e) => {
        toast.openToastWithError(
          t(
            'toast:indicator_collect.disclosure_requirement.indicator.create.error',
          ),
        );
        console.error(e);
      },
    }).catch((e) => {
      toast.openToastWithError(
        t(
          'toast:indicator_collect.disclosure_requirement.indicator.create.error',
        ),
      );
      console.error(e);
    });
  };

  const isFormValid = name.length > 0 && indicatorType !== null;

  const availableIndicatorTypesItems: StringDropdownItem[] =
    enumKeysToDropdownItems(IndicatorValueType, (key: string) =>
      t(`enum:indicator.type.${key}`),
    );
  const canSplitByOrganizationalUnit =
    (projectContext?.enterprise?.organizationalUnits || [])?.length > 0;

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <label className="form-input-label">
          {t(
            'indicator_collect.disclosure_requirement.indicator.create.modal.form.name',
          )}
          <span className="required">*</span>
          <input
            className="form-input-text min-w-96"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
      </div>
      <div className="space-y-2">
        <label className="form-input-label">
          {t(
            'indicator_collect.disclosure_requirement.indicator.create.modal.form.type',
          )}
          <span className="required">*</span>
          <div className="mt-2">
            <StringDropdown
              availableItems={availableIndicatorTypesItems}
              item={indicatorType}
              setItem={setIndicatorType}
            />
          </div>
        </label>
      </div>
      <div className="space-y-2">
        <label className="form-input-label">
          {t(
            'indicator_collect.disclosure_requirement.indicator.create.modal.form.dimension',
          )}
          <div className={'mt-2'}>
            <DimensionDropdown
              multiple={true}
              setItem={(items) => {
                if (items.length > 2) {
                  toast.openToastWithError(
                    t(
                      'toast:indicator_collect.disclosure_requirement.indicator.create.chooseDimension.max2',
                    ),
                  );
                  return;
                }
                setSelectedDimensions(items);
              }}
              item={selectedDimensions}
            />
          </div>
        </label>
      </div>
      <div className="flex items-center gap-2">
        <label
          className={clsx([
            'form-input-label flex gap-2',
            !canSplitByOrganizationalUnit && 'opacity-50',
          ])}
        >
          <Toggle
            state={splitByOrganizationalUnit}
            setState={setSplitByOrganizationalUnit}
            disabled={!canSplitByOrganizationalUnit}
          />
          {t(
            'indicator_collect.disclosure_requirement.indicator.create.modal.form.splitByOrganizationUnit',
          )}
        </label>
      </div>
      <div className="flex justify-end">
        <button
          className="primary"
          disabled={loading || !isFormValid}
          onClick={addIndicator}
        >
          {loading && <Loader />}
          <span>{t('global:add')}</span>
        </button>
      </div>
    </div>
  );
};
