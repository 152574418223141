import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Input as HeadlessUiInput, InputProps } from '@headlessui/react';

export function Input<T extends string | number | undefined>({
  value,
  setValue,
  icon,
  className,
  iconClassName = 'text-gray-300',
  disabled = false,
  rightElement,
  ...inputProps
}: {
  value: T;
  setValue: (value: string) => void;
  className?: string;
  iconClassName?: string;
  icon?: ReactNode;
  rightElement?: ReactNode;
  disabled?: boolean;
} & InputProps) {
  return (
    <div className="relative flex items-center bg-white rounded-md border border-gray-100 shadow-sm px-2">
      {icon && (
        <div className={clsx('text-gray-400', iconClassName)}>{icon}</div>
      )}

      <HeadlessUiInput
        className={clsx(
          'p-2 bg-transparent text-gray-900 text-right border-none focus:ring-0 focus:outline-none',
          'min-w-0',
          className,
        )}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        value={value}
        {...inputProps}
      />

      {rightElement && (
        <div className="ml-2 text-gray-500 text-sm whitespace-nowrap flex-shrink-0">
          {rightElement}
        </div>
      )}
    </div>
  );
}
