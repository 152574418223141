import React from 'react';
import {
  SurveyQuestionModalHeader_QuestionFragment,
  useSurveyQuestionModalQuery,
} from '../../../graphql/generated';
import { useModal } from '../../layout/Modal';
import { SurveyQuestionIcon } from '../../survey/SurveyQuestionIcon';
import { LoaderFullscreen } from '../../layout/Loader';
import { InnerHtml } from '../../generic/InnerHtml';
import { getQuestionTypeLabel } from '../../../services/SurveyService';
import { QuestionAnswersDataviz } from './QuestionAnswersDataviz';
import { QuestionAnswerThemesTags } from '../analysisThemes/QuestionAnswerThemesTags';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { XIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function SurveyQuestionModal({ questionId }: { questionId: string }) {
  const { t } = useTranslation();
  const modal = useModal();
  const query = useSurveyQuestionModalQuery({
    variables: {
      id: questionId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data?.surveyQuestion) {
    return null;
  }

  const question = query.data?.surveyQuestion;

  return (
    <div className="flex flex-col h-full w-full overflow-scroll">
      <div className="bg-white flex items-center gap-2 p-4 w-full justify-between">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-400"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <div className="flex items-center justify-between grow">
          <h4 className="flex items-center justify-center gap-3 w-full">
            <span>{t('survey.results.modal.title')}</span>
          </h4>
        </div>
        <div className="w-12"></div>
      </div>
      <div className="">
        <SurveyQuestionModalHeader question={question} />
      </div>
      <div className={'bg-gray-50 grow px-16 py-8'}>
        <QuestionAnswersDataviz
          question={question}
          answers={question?.answers || []}
          isThumbnail={false}
        />
      </div>
    </div>
  );
}

function SurveyQuestionModalHeader({
  question,
}: {
  question: SurveyQuestionModalHeader_QuestionFragment;
}) {
  return (
    <div className="py-8 px-16 border-y border-gray-100 flex flex-col gap-4">
      {question.parent && (
        <div className="text-gray-500 text-sm">
          <div>{question.parent.title} </div>
        </div>
      )}
      <h1>
        <div>{question.title}</div>
      </h1>
      {question.description && (
        <InnerHtml html={question.description} className="text-gray-500" />
      )}
      <dl className="text-sm grid grid-cols-7 grid-flow-row gap-2 mt-4">
        <dt className="text-gray-500">Type</dt>
        <dd className="flex items-center gap-2 font-bold col-span-6">
          {question?.type && (
            <>
              <SurveyQuestionIcon questionType={question.type} color={900} />
              <div className="text-gray-900 shrink-0">
                {getQuestionTypeLabel(question.type)}
              </div>
            </>
          )}
        </dd>
        <dt className="text-gray-500">Réponses</dt>
        <dd className="text-gray-900 font-bold col-span-6">
          {(question.answers || []).length}
        </dd>
        <dt className="text-gray-500">Thèmes associés</dt>
        <dd className="col-span-6">
          {question && <QuestionAnswerThemesTags question={question} />}
        </dd>
      </dl>
    </div>
  );
}
