import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from '@hooks/useTranslation';
import {
  IroAssessmentDataviz_Answer_IroAssessmentFragment,
  IroType,
} from '../../../../../graphql/generated';
import { GradientDropdown } from '../../../../generic/dropdown/GradientDropdown';
import React from 'react';
import {
  IroScaleItem,
  scaleCriteriaChoices,
} from '../../../iro/evaluation/picker/IroScalePicker';
import {
  CriteriasByIroType,
  CriteriaType,
  CriteriaValues,
} from '../../../../../types/iro.types';
import {
  IroScopeItem,
  scopeCriteriaChoices,
} from '../../../iro/evaluation/picker/IroScopePicker';
import {
  IroLikelihoodItem,
  likelihoodCriteriaChoices,
} from '../../../iro/evaluation/picker/IroLikelihoodPicker';
import {
  IroIrremediabilityItem,
  irremediabilityCriteriaChoices,
} from '../../../iro/evaluation/picker/IroIrremediabilityPicker';
import {
  IroTimeHorizonItem,
  timeHorizonCriteriaChoices,
} from '../../../iro/evaluation/picker/IroTimeHorizonPicker';

export type SurveyIroAssessmentAnswersTableColumns = {
  respondent?: IroAssessmentDataviz_Answer_IroAssessmentFragment['surveyAnswer']['response']['respondent'];
  timeHorizon: IroAssessmentDataviz_Answer_IroAssessmentFragment['timeHorizon'];
  scopeCriteria: IroAssessmentDataviz_Answer_IroAssessmentFragment['scopeCriteria'];
  scaleCriteria: IroAssessmentDataviz_Answer_IroAssessmentFragment['scaleCriteria'];
  irremediabilityCriteria: IroAssessmentDataviz_Answer_IroAssessmentFragment['irremediabilityCriteria'];
  likelihoodCriteria: IroAssessmentDataviz_Answer_IroAssessmentFragment['likelihoodCriteria'];
};

export const useSurveyIroAssessmentAnswersTableColumns = (iroType: IroType): Array<ColumnDef<SurveyIroAssessmentAnswersTableColumns>> => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<SurveyIroAssessmentAnswersTableColumns>();
  const CriteriaDropdown = <T extends IroScopeItem | IroScaleItem | IroLikelihoodItem | IroIrremediabilityItem | IroTimeHorizonItem,>({value, items}:{value: CriteriaValues | null | undefined, items: T[]}) => {
    if (!value) {
      return ''
    }
    const selected = items.find(item => item.id === value)!

    return (
      <GradientDropdown
        {...{
          items,
          selected,
          chooseItemId: () => {},
          disabled: true,
        }}
      />
    );
  }

  return [
      columnHelper.accessor('respondent', {
        cell: (data) => {
          if (!data.getValue()) {
            return <div className="italic text-gray-500">{t('survey.respondents.unknown')}</div>
          }
          const respondent = data.getValue()!
          const { name, role } = respondent

          return <div className={"flex flex-col gap-2"}>
            <div>
              <div className={"max-w-full truncate"}>
                <span className="font-bold mr-2 text-nowrap">{name}</span>
              </div>
              {role && <div className="text-gray-500">{role}</div>}
            </div>
          </div>
        },
        header: () => t('survey.results.iroAssessment.table.header.respondent.label'),
      }),

     ...(CriteriasByIroType[iroType].includes(CriteriaType.SCOPE) ? [columnHelper.accessor('scopeCriteria.value', {
      cell: (data) => {
        const value = data.getValue()
        return <CriteriaDropdown value={value} items={scopeCriteriaChoices(t)} />
      },
      header: () => t('survey.results.iroAssessment.table.header.scope.label'),
    })] : []),
    ...(CriteriasByIroType[iroType].includes(CriteriaType.SCALE) ? [columnHelper.accessor('scaleCriteria.value', {
      cell: (data) => {
        const value = data.getValue()
        return <CriteriaDropdown value={value} items={scaleCriteriaChoices(t)} />
      },
      header: () => t('survey.results.iroAssessment.table.header.scale.label'),
    })] : []),
    ...(CriteriasByIroType[iroType].includes(CriteriaType.LIKELIHOOD) ? [columnHelper.accessor('likelihoodCriteria.value', {
      cell: (data) => {
        const value = data.getValue()
        return <CriteriaDropdown value={value} items={likelihoodCriteriaChoices(t)} />
      },
      header: () => t('survey.results.iroAssessment.table.header.likelihood.label'),
    })] : []),
    ...(CriteriasByIroType[iroType].includes(CriteriaType.IRREMEDIABILITY) ? [columnHelper.accessor('irremediabilityCriteria.value', {
      cell: (data) => {
        const value = data.getValue()
        return <CriteriaDropdown value={value} items={irremediabilityCriteriaChoices(t)} />
      },
      header: () => t('survey.results.iroAssessment.table.header.irremediability.label'),
    })] : []),
    columnHelper.accessor('timeHorizon', {
      cell: (data) => {
        const value = data.getValue()
        return <CriteriaDropdown value={value} items={timeHorizonCriteriaChoices(t)} />
      },
      header: () => t('survey.results.iroAssessment.table.header.timeHorizon.label'),
    })
  ] as Array<ColumnDef<SurveyIroAssessmentAnswersTableColumns>>;

};

export const prepareData = (
  iroAssessments: IroAssessmentDataviz_Answer_IroAssessmentFragment[],
): Array<SurveyIroAssessmentAnswersTableColumns> => {
  return iroAssessments.map((iroAssessment) => {
    const { timeHorizon, irremediabilityCriteria, scopeCriteria, scaleCriteria, likelihoodCriteria } =
      iroAssessment;

    const response = iroAssessment.surveyAnswer?.response;
    const respondent = response?.respondent;

    return {
      respondent,
      timeHorizon,
      irremediabilityCriteria,
      scopeCriteria,
      scaleCriteria,
      likelihoodCriteria,
    };
  });
};

