import { PageTitle } from 'components/nav/PageTitle';
import {
  AdminCompaniesScreen_CompanyFragment,
  AdminCompaniesScreen_ProjectFragment,
  useAdminCompaniesScreenQuery,
} from '../../../graphql/generated';
import { NumberCircle } from '../../../components/generic/NumberCircle';
import { useModal } from '../../../components/layout/Modal';
import React, { useState } from 'react';
import clsx from 'clsx';
import { ConsultingCompanyMaxProjectsCountProgressBar } from '../../../components/project/project/ConsultingCompanyMaxProjectsCountProgressBar';
import { ChevronRightIcon, SearchIcon } from '../../../components/icons';
import { useTranslation } from '@hooks/useTranslation';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { useDateTimeFromIsoString } from '@hooks/useDateTimeFromIsoString';
import { EmployeeItem } from './EmployeeItem';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { TabNavigationLayout } from '../../../components/generic/nav/TabNavigationLayout';
import { ADMIN_TABS, useAdminTabs } from '../useAdminTabs';
import { AdminHero } from '../AdminHero';
import { Tag } from '../../../components/badges/Tag';
import { LicenseEditor } from '../../../components/admin/company/LicenseEditor';
import { DeleteCompanyButton } from '../../../components/admin/company/DeleteCompanyButton';
import { InvitationModalButton } from '../../../components/project/project/users/consultingCompany/InvitationModal';
import { RegisterNewProModal } from '../../../components/admin/company/RegisterNewProModal';

function AdminCompanies() {
  const { t } = useTranslation();
  const query = useAdminCompaniesScreenQuery({
    fetchPolicy: 'network-only',
  });
  const modal = useModal();
  const adminTabs = useAdminTabs();

  const [searchTerm, setSearchTerm] = useState('');

  const openRegisterNewProModal = () => {
    modal.openModalWithComponent(<RegisterNewProModal />, 'Ajouter un pro');
  };

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  const companies = query.data?.consultingCompanies || [];
  const filteredCompanies = companies.filter((company) => {
    const enterpriseConcerned =
      company.projects?.filter((enterprise) =>
        enterprise.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ) || [];
    const employeeConcerned =
      company.employees?.filter((employee) =>
        `${employee.firstName} ${employee.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      ) || [];

    return (
      company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      enterpriseConcerned.length > 0 ||
      employeeConcerned.length > 0
    );
  });

  return (
    <>
      <Breadcrumb />
      <TabNavigationLayout<ADMIN_TABS>
        tabs={adminTabs}
        activeTab={ADMIN_TABS.CONSULTING_COMPANIES}
        renderTabs={(tabsContent) => <AdminHero>{tabsContent}</AdminHero>}
      >
        <PageTitle title="Admin" />
        <div className="w-full main-content p-8">
          <div className="space-y-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <h1>{t('admin:companies.list.title')}</h1>
                {companies && <Tag color={'blue'}>{companies.length}</Tag>}
              </div>
              <button
                className="primary purple"
                onClick={openRegisterNewProModal}
              >
                {t('global:add')}
              </button>
            </div>
            <form className="w-full">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center ps-3 pointer-events-none">
                  <SearchIcon />
                </div>
                <input
                  type="search"
                  id="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={t('admin:companies.list.searchPlaceholder')}
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-700"
                />
              </div>
            </form>
            {query.data && <CompaniesList companies={filteredCompanies} />}
          </div>
        </div>
      </TabNavigationLayout>
    </>
  );
}

function CompaniesList({
  companies,
}: {
  companies: AdminCompaniesScreen_CompanyFragment[];
}) {
  return (
    <ul className="space-y-4">
      {companies.map((company, index) => (
        <CompanyItem company={company} index={index} key={company.id} />
      ))}
    </ul>
  );
}

function CompanyItem({
  company,
  index,
}: {
  company: AdminCompaniesScreen_CompanyFragment;
  index: number;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li className="flex items-center gap-2">
      <NumberCircle number={index + 1} size={8} />
      <div
        className={clsx(
          'border border-gray-100 rounded-xl space-y-6 w-full',
          company.name === 'Good Steps' ? 'bg-gray-50' : 'bg-white',
        )}
      >
        <div
          className="p-4 flex items-center gap-4 justify-between cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div>
            <h3 className="font-bold">{company.name}</h3>
            <div className="text-gray-500 text-xs">
              {t('admin:companies.list.item.createdAtAndBy', {
                createdAt: createdAt.date,
                createdBy: `${company.createdBy?.firstName} ${company.createdBy?.lastName}`,
              })}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="min-w-[15rem]">
              <ConsultingCompanyMaxProjectsCountProgressBar
                companyId={company.id}
              />
            </div>
            <ChevronRightIcon className={clsx(isExpanded && 'rotate-90')} />
          </div>
        </div>

        {isExpanded && (
          <div className="space-y-4 divide-y">
            <div className="p-4 flex items-start gap-8 w-full divide-x divide-gray-100">
              <div className="w-1/2 space-y-2">
                <div className="flex items-center gap-4 justify-between">
                  <div className="flex items-center gap-1">
                    <h6>{t('admin:companies.list.item.employees')}</h6>
                    <div className="tag gray">{company.employees?.length}</div>
                  </div>
                </div>
                <ul className="space-y-1 text-sm">
                  {company.employees?.map((employee) => (
                    <EmployeeItem key={employee.id} employee={employee} />
                  ))}
                </ul>
                <InvitationModalButton consultingCompanyId={company.id} />
              </div>
              <div className="w-1/2 pl-8 space-y-4 divide-y divide-gray-100">
                <CompanyProjects company={company} />
                <CompanyArchivedProjects company={company} />
                <CompanyTemplates company={company} />
              </div>
            </div>
            <div className="p-4 flex justify-end">
              <DeleteCompanyButton companyId={company.id} />
            </div>
          </div>
        )}
      </div>
    </li>
  );
}

function CompanyProjects({
  company,
}: {
  company: AdminCompaniesScreen_CompanyFragment;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const projects: AdminCompaniesScreen_ProjectFragment[] =
    company.projects
      ?.filter((project) => !project.isTemplate)
      .filter((project) => !project.archivedDate) || [];
  return (
    <div className="pt-4">
      <div className="flex items-center justify-between gap-1">
        <div className="flex items-center gap-1">
          <h5>{t('admin:companies.list.item.projects')}</h5>
          <div className="tag gray">{projects.length}</div>
        </div>
        <LicenseEditor company={company} />
      </div>
      <ul className="space-y-1 divide-y divide-gray-100 text-sm w-full">
        {projects?.map((project) => (
          <CompanyProject project={project} key={project.id} />
        ))}
      </ul>
    </div>
  );
}

function CompanyProject({
  project,
}: {
  project: AdminCompaniesScreen_ProjectFragment;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(project.createdAt);
  return (
    <li
      key={project.id}
      className="flex items-center justify-between gap-2 w-full pt-1"
    >
      <div className="space-y-1">
        <div className="font-bold">{project.name}</div>
        <div className="text-gray-500 text-xs">
          {t('admin:companies.list.item.createdAtAndBy', {
            createdAt: createdAt.date,
            createdBy: `${project.createdBy?.firstName} ${project.createdBy?.lastName}`,
          })}
        </div>
      </div>
      <div className="flex items-center gap-0.5">
        <DeleteCompanyButton companyId={project.id} />
      </div>
    </li>
  );
}

function CompanyTemplates({
  company,
}: {
  company: AdminCompaniesScreen_CompanyFragment;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const templates: AdminCompaniesScreen_ProjectFragment[] =
    company.projects?.filter((project) => project.isTemplate) || [];
  return (
    <div className="pt-4">
      <div className="flex items-center justify-between gap-1">
        <div className="flex items-center gap-1">
          <h5>{t('admin:companies.list.item.templates')}</h5>
          <div className="tag gray">{templates.length}</div>
        </div>
      </div>
      <ul className="space-y-1 divide-y divide-gray-100 text-sm w-full">
        {templates?.map((project) => (
          <li
            key={project.id}
            className="flex items-center justify-between gap-2 w-full pt-1"
          >
            <div className="space-y-1">
              <div className="font-bold">{project.name}</div>
              <div className="text-gray-500 text-xs">
                {t('admin:companies.list.item.createdAtAndBy', {
                  createdAt: createdAt.date,
                  createdBy: `${project.createdBy?.firstName} ${project.createdBy?.lastName}`,
                })}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function CompanyArchivedProjects({
  company,
}: {
  company: AdminCompaniesScreen_CompanyFragment;
}) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const projects: AdminCompaniesScreen_ProjectFragment[] =
    company.projects
      ?.filter((project) => !project.isTemplate)
      .filter((project) => project.archivedDate) || [];
  return (
    <div className="pt-4">
      <div className="flex items-center justify-between gap-1">
        <div className="flex items-center gap-1">
          <h5>{t('admin:companies.list.item.archived_projects')}</h5>
          <div className="tag gray">{projects.length}</div>
        </div>
      </div>
      <ul className="space-y-1 divide-y divide-gray-100 text-sm w-full">
        {projects?.map((project) => (
          <li
            key={project.id}
            className="flex items-center justify-between gap-2 w-full pt-1"
          >
            <div className="space-y-1">
              <div className="font-bold">{project.name}</div>
              <div className="text-gray-500 text-xs">
                {t('admin:companies.list.item.createdAtAndBy', {
                  createdAt: createdAt.date,
                  createdBy: `${project.createdBy?.firstName} ${project.createdBy?.lastName}`,
                })}
              </div>
            </div>
            <div className="flex items-center gap-0.5">
              <DeleteCompanyButton companyId={project.id} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export const AdminCompaniesScreen = AdminCompanies;
