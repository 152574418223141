import {
  IroCriteriaValuesDefinitionsContextFragment,
  IroType,
} from '../../../../../graphql/generated';
import { useRightDrawer } from '../../../../layout/RightDrawer';
import { ScaleCriteriaDefinition } from '../iroCriteriaDefinition/ScaleCriteriaDefinition';
import { ScopeCriteriaDefinition } from '../iroCriteriaDefinition/ScopeCriteriaDefinition';
import { IrremediabilityCriteriaDefinition } from '../iroCriteriaDefinition/IrremediabilityCriteriaDefinition';
import { LikelihoodCriteriaDefinition } from '../iroCriteriaDefinition/LikelihoodCriteriaDefinition';
import { TimeHorizonDefinition } from '../iroCriteriaDefinition/TimeHorizonDefinition';
import React from 'react';
import { CriteriaType } from '../../../../../types/iro.types';

export const useCriteriaDefinitionDrawer = () => {
  const rightDrawer = useRightDrawer();

  return (
    iroType: IroType,
    criteria: CriteriaType,
    definitions: IroCriteriaValuesDefinitionsContextFragment[],
  ) => {
    const definition = definitions.find(
      (definition) => definition.iroType === iroType,
    );

    switch (criteria) {
      case CriteriaType.SCALE: {
        rightDrawer.openDrawerWithComponent(
          <ScaleCriteriaDefinition
            definitions={definition?.scaleValuesDefinitions || []}
          />,
        );
        break;
      }
      case CriteriaType.SCOPE: {
        rightDrawer.openDrawerWithComponent(
          <ScopeCriteriaDefinition
            definitions={definition?.scopeValuesDefinitions || []}
          />,
        );
        break;
      }
      case CriteriaType.IRREMEDIABILITY: {
        rightDrawer.openDrawerWithComponent(
          <IrremediabilityCriteriaDefinition
            definitions={definition?.irremediabilityValuesDefinitions || []}
          />,
        );
        break;
      }
      case CriteriaType.LIKELIHOOD: {
        rightDrawer.openDrawerWithComponent(
          <LikelihoodCriteriaDefinition
            definitions={definition?.likelihoodValuesDefinitions || []}
          />,
        );
        break;
      }
      case CriteriaType.TIME_HORIZON: {
        rightDrawer.openDrawerWithComponent(<TimeHorizonDefinition />);
        break;
      }
    }
  };
};
