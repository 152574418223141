import React, { useState } from 'react';
import { ProjectTemplates } from './ProjectTemplates';
import { PreviewCompanyTemplate } from './PreviewCompanyTemplate';
import { usePreviewCompanyTemplateQuery } from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';
import { ModalConfig } from '../../../layout/Modal';

export const chooseProjectTemplateModalConfig: ModalConfig = {
  title: undefined,
  isFullscreen: true,
  displayTitleBar: false,
  widthClass: 'w-4/5',
};

export function ChooseProjectTemplateModal({
  applyTemplateToCompanyId,
}: {
  applyTemplateToCompanyId: string;
}) {
  const [previewCompanyTemplateId, setPreviewCompanyTemplateId] = useState<
    string | null
  >(null);

  const companyTemplateQuery = usePreviewCompanyTemplateQuery({
    variables: {
      id: previewCompanyTemplateId || '',
    },
    skip: !previewCompanyTemplateId,
    fetchPolicy: 'network-only',
  });

  const refreshEnterpriseTemplate = (companyTemplateId: string | null) => {
    setPreviewCompanyTemplateId(companyTemplateId);
    if (companyTemplateId) {
      companyTemplateQuery
        .refetch({
          id: companyTemplateId,
        })
        .catch((err) => {
          console.error(err);
          setPreviewCompanyTemplateId(null);
        });
    }
  };

  if (companyTemplateQuery.loading) return <LoaderFullscreen />;

  return previewCompanyTemplateId &&
    companyTemplateQuery.data?.getCompanyTemplate ? (
    <PreviewCompanyTemplate
      template={companyTemplateQuery.data.getCompanyTemplate}
      setPreviewCompanyTemplateId={refreshEnterpriseTemplate}
      applyTemplateToCompanyId={applyTemplateToCompanyId}
    />
  ) : (
    <ProjectTemplates setPreviewCompanyTemplateId={refreshEnterpriseTemplate} />
  );
}
