import {
  ChecklistDocument,
  FeatureEnum,
  PermissionEnum,
  TaskGroup_TaskGroupFragment,
  TaskGroupContextualMenu_TaskGroupFragment,
  useTaskGroupContextualMenu_DeleteMutation,
} from '../../graphql/generated';
import React, { useState } from 'react';
import { TaskCard } from './TaskCard';
import { CreateTaskButton } from './CreateTaskButton';
import { ContextualMenu } from '../generic/ContextualMenu';
import { useModal } from '../layout/Modal';
import { UpdateTaskGroupModal } from './UpdateTaskGroupModal';
import { sortByNullablePosition } from '../../services/UtilsService';
import { DeleteIcon, EditIcon, VerticalDotsIcon } from '../icons';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export function TaskGroup({
  taskGroup,
}: {
  taskGroup: TaskGroup_TaskGroupFragment;
}) {
  const { isGranted } = usePermissionChecker();
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between gap-4">
        <h5>{taskGroup.name}</h5>
        {isGranted(FeatureEnum.ProjectMethodology, PermissionEnum.Write) && (
          <div className="flex items-center gap-1">
            <CreateTaskButton taskGroup={taskGroup} />
            <TaskGroupContextualMenu taskGroup={taskGroup} />
          </div>
        )}
      </div>
      {(taskGroup.tasks || [])
        .toSorted((a, b) => sortByNullablePosition(a.position, b.position))
        .map((task) => (
          <TaskCard key={task.id} task={task} />
        ))}
    </div>
  );
}

function TaskGroupContextualMenu({
  taskGroup,
}: {
  taskGroup: TaskGroupContextualMenu_TaskGroupFragment;
}) {
  const modal = useModal();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteTaskGroupMutation] = useTaskGroupContextualMenu_DeleteMutation({
    refetchQueries: [
      {
        query: ChecklistDocument,
        variables: {
          id: taskGroup.checklist.id,
        },
      },
    ],
  });
  const deleteTaskGroup = () => {
    setIsDeleting(true);
    deleteTaskGroupMutation({ variables: { id: taskGroup.id } })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsDeleting(false));
  };

  const updateTaskGroup = () => {
    modal.openModalWithComponent(
      <UpdateTaskGroupModal taskGroup={taskGroup} />,
      'Modifier une catégorie',
    );
  };

  return (
    <ContextualMenu
      button={
        <VerticalDotsIcon className="button tertiary p-0.5 bg-transparent w-5 h-5 text-gray-500" />
      }
    >
      <button
        className="contextual-menu-item"
        onClick={() => updateTaskGroup()}
        disabled={isDeleting}
      >
        <EditIcon />
        Modifier
      </button>
      <button
        className="contextual-menu-item"
        onClick={() => deleteTaskGroup()}
        disabled={isDeleting}
      >
        <DeleteIcon />
        Supprimer
      </button>
    </ContextualMenu>
  );
}
