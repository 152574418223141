import React from 'react';
import {
  JustificationType,
  useJustifyReportDatapointButtonQuery,
} from '../../../../../graphql/generated';
import { JustifyButton, JustifyButtonStyle } from '../JustifyButton';

export const JustifyReportDatapointButton = ({
  reportDatapointId,
  readOnly = false,
}: {
  reportDatapointId: string;
  readOnly?: boolean;
}) => {
  const type = JustificationType.ReportDatapoint;

  const query = useJustifyReportDatapointButtonQuery({
    variables: {
      type,
      id: reportDatapointId,
    },
  });

  const latestJustificationId =
    query.data?.reportDatapointJustifications?.edges[0]?.node.id;

  return (
    <JustifyButton
      type={type}
      entityId={reportDatapointId}
      latestJustificationId={latestJustificationId}
      callback={query.refetch}
      style={JustifyButtonStyle.MessageIcon}
      readOnly={readOnly}
    />
  );
};
