import React, { useState } from 'react';
import {
  CreateIndicatorValueNumberInput,
  CreateManyNumberIndicatorValuesInput,
  IndicatorValueForm_IndicatorFragment,
  IndicatorValueForm_LatestIndicatorValue_NumberIndicatorValueFragment,
  IndicatorValueForm_LatestIndicatorValueFragment,
  NumberIndicatorValueFormMutationFn,
} from '../../../../../../../../../graphql/generated';
import { useIndicatorValueForm } from '../../../IndicatorValueFormContext';
import { Input } from '../../../../../../../../generic/form/Input';
import { CaretDown } from '../../../../../../../../icons';

export type NumberIndicatorValueType =
  IndicatorValueForm_LatestIndicatorValueFragment &
    IndicatorValueForm_LatestIndicatorValue_NumberIndicatorValueFragment;

export type NumberIndicatorValueFormMutationTypes = {
  input: CreateManyNumberIndicatorValuesInput;
  item: CreateIndicatorValueNumberInput;
  mutation: NumberIndicatorValueFormMutationFn;
}

export const NumberIndicatorValueForm = (
  {
    indicatorValue,
    indicator,
    readOnly = false
  }: {
    indicatorValue: CreateIndicatorValueNumberInput;
    indicator: IndicatorValueForm_IndicatorFragment;
    readOnly?: boolean;
  }) => {
  const { addIndicatorValue } = useIndicatorValueForm<NumberIndicatorValueFormMutationTypes>()
  const [ updatedIndicatorValue, updateIndicatorValue ] = useState<CreateIndicatorValueNumberInput['number_value']>(indicatorValue.number_value)
  const setValue = (number_value: number)=> {
    updateIndicatorValue(number_value)
    addIndicatorValue({
      ...indicatorValue,
      number_value,
    })
  }
  const rightElement = (indicatorValue.standardUnitItemLink?.slug || indicator.defaultUnit?.slug) &&
    <div className="inline-block shrink-0">
      <div className={'flex gap-2 items-center'}>
        {indicatorValue.standardUnitItemLink?.slug || indicator.defaultUnit?.slug} <CaretDown className="opacity-50" />
      </div>
    </div>

  return (
    <div className="flex items-center gap-2">
      <Input
        type="number"
        value={updatedIndicatorValue}
        setValue={(value: string) => setValue(parseFloat(value))}
        className="no-control w-[130px]"
        rightElement={rightElement}
        disabled={readOnly}
      />
    </div>
  );
};
