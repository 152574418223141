import {
  SurveyQuestionSummaryFieldsFragment,
  SurveyQuestionUpdateInput,
  useSurveyQuestionUpdateMutation,
} from '../../../../../graphql/generated';
import React, { useState } from 'react';
import { useToast } from '../../../../layout/Toast';
import { ToggleRequired } from './atoms/ToggleRequired';
import { QuestionTypeInput } from './atoms/QuestionTypeInput';
import { QuestionDescriptionInput } from './atoms/QuestionDescriptionInput';
import { QuestionPillarStake } from './atoms/QuestionPillarStake';
import { MessageBox, MessageBoxType } from '../../../../layout/MessageBox';
import { useTranslation } from '@hooks/useTranslation';

export function QuestionStakesSimpleMaterialityForm({
  question,
}: {
  question: SurveyQuestionSummaryFieldsFragment;
}) {
  const { t } = useTranslation();
  const toast = useToast();

  const [questionDraft, setQuestionDraft] =
    useState<SurveyQuestionSummaryFieldsFragment>({ ...question });

  // Perform update mutation
  const [updateQuestionMutation] = useSurveyQuestionUpdateMutation();
  const updateQuestion = (question: SurveyQuestionUpdateInput) => {
    updateQuestionMutation({
      variables: {
        input: question,
      },
    })
      .then((r) => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
        if (r.data?.surveyQuestionUpdate) {
          // TODO: returning the question should be enough to propagate the update
          setQuestionDraft(r.data.surveyQuestionUpdate);
        }
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      });
  };

  return (
    <div className="space-y-8 divide-y divide-gray-100">
      <div className="flex items-end gap-4">
        <QuestionTypeInput question={questionDraft} />
        <ToggleRequired question={questionDraft} />
      </div>

      <div className="pt-8 space-y-2">
        <QuestionPillarStake
          question={questionDraft}
          enableStakePicker={false}
          enablePillarPicker={true}
        />
        {question.pillar === null && (
          <MessageBox type={MessageBoxType.Error}>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  'survey.edit.questions.type.common.pillarStakeInput.emptyPillarWarning',
                ),
              }}
            />
          </MessageBox>
        )}
      </div>

      <div className="pt-8 space-y-2">
        <h3>{t('survey.edit.questions.content')}</h3>
        <div className="space-y-2">
          <label htmlFor="title" className="form-input-label">
            {t('survey.edit.questions.type.common.questionLabel')}
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-input-text"
            value={questionDraft.title}
            onChange={(e) => {
              const newQuestionDraft = { ...questionDraft };
              newQuestionDraft.title = e.target.value;
              setQuestionDraft(newQuestionDraft);
            }}
            onBlur={() =>
              updateQuestion({
                id: questionDraft.id,
                title: questionDraft.title,
              })
            }
          />
          <QuestionDescriptionInput
            question={questionDraft}
            setQuestion={setQuestionDraft}
          />
        </div>
        <div className="space-y-2 bg-gray-50 rounded-xl p-4 border border-gray-100">
          <div>
            <label htmlFor="impactLabel" className="form-input-label mb-2">
              {t('survey.edit.questions.type.matrix.importanceLevels.label')}
            </label>
            <div className="space-y-2 ml-4">
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactNoneLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  {t(
                    'survey.edit.questions.type.matrix.importanceLevels.level1',
                  )}
                </label>
                <input
                  type="text"
                  name="impactNoneLabel"
                  id="impactNoneLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactNoneLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactNoneLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactNoneLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactNoneLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactLowLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  {t(
                    'survey.edit.questions.type.matrix.importanceLevels.level2',
                  )}
                </label>
                <input
                  type="text"
                  name="impactLowLabel"
                  id="impactLowLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactLowLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactLowLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactLowLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactLowLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactMediumLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  {t(
                    'survey.edit.questions.type.matrix.importanceLevels.level3',
                  )}
                </label>
                <input
                  type="text"
                  name="impactMediumLabel"
                  id="impactMediumLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactMediumLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactMediumLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactMediumLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactMediumLabel,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="flex items-center gap-4">
                <label
                  htmlFor="impactHighLabel"
                  className="form-input-label w-20 shrink-0 text-sm"
                >
                  {t(
                    'survey.edit.questions.type.matrix.importanceLevels.level4',
                  )}
                </label>
                <input
                  type="text"
                  name="impactHighLabel"
                  id="impactHighLabel"
                  className="form-input-text grow"
                  value={
                    questionDraft.stakeOptions?.materialityOptions
                      ?.impactHighLabel || ''
                  }
                  onChange={(e) => {
                    setQuestionDraft({
                      ...questionDraft,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactHighLabel: e.target.value,
                        },
                      },
                    });
                  }}
                  onBlur={() =>
                    updateQuestion({
                      id: questionDraft.id,
                      stakeOptions: {
                        ...questionDraft.stakeOptions,
                        materialityOptions: {
                          ...questionDraft.stakeOptions?.materialityOptions,
                          impactHighLabel:
                            questionDraft.stakeOptions?.materialityOptions
                              ?.impactHighLabel,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
