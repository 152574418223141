import {
  DiagnosticStakeFieldsFragment,
  IrremediabilityValue,
  LikelihoodValue,
  ScaleValue,
  ScopeValue,
  SurveyAnswerStakeFieldsFragment,
  SurveyQuestionStakeOptionsFieldsFragment,
  SurveySubmitAnswerStakeInput,
  TimeHorizon,
} from '../../../../../../graphql/generated';
import { CheckboxSurveyInput } from '../../../../../generic/form/CheckboxSurveyInput';
import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

export type CheckBoxProps = {
  stake: DiagnosticStakeFieldsFragment | null;
  stakeOptions: SurveyQuestionStakeOptionsFieldsFragment | null | undefined;
  stakeAnswer: SurveyAnswerStakeFieldsFragment | null | undefined;
  mergeAnswerStakeInput: (input: SurveySubmitAnswerStakeInput) => void;
};

export function IroAssessmentOptionRadio<T extends ScaleValue | ScopeValue | LikelihoodValue | IrremediabilityValue | TimeHorizon>({
    name,
    placeholder,
    value,
    level,
    tooltip,
  }: {
  name: string;
  placeholder?: string | null | undefined;
  value?: T;
  level?: T;
  tooltip?: ReactNode | null | undefined;
}) {
  const { setValue } = useFormContext();

  const checked = level === value;

  const check = () => {
    setValue(name, level, { shouldValidate: true });
  };

  return (
    <div>
      <CheckboxSurveyInput
        placeholder={placeholder}
        checked={checked}
        check={check}
        uncheck={() => setValue(name, undefined, { shouldValidate: true })}
        tooltip={tooltip}
      />
    </div>
  );
}

