import React, { Fragment, ReactNode, useContext, useState } from 'react';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { CheckIcon, ErrorIcon, XIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';

type ToastContextType = {
  visibility: boolean;
  isError: boolean;
  component: ReactNode | null;
  openToastWithComponent: (component: ReactNode) => void;
  openToastWithMessage: (message: string) => void;
  openToastWithError: (message: string) => void;
  closeToast: () => void;
};

const initialContext: ToastContextType = {
  visibility: false,
  isError: false,
  component: null,
  openToastWithComponent: (component: ReactNode) => {
    // do nothing
  },
  openToastWithMessage: (message: string) => {
    // do nothing
  },
  openToastWithError: (message: string) => {
    // do nothing
  },
  closeToast: () => {
    // do nothing
  },
};

export const closeToastAfter = 3000;

const ToastContext = React.createContext(initialContext);

export function ToastProvider({ children }: { children: ReactNode }) {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [component, setComponent] = useState<ReactNode | null>(null);

  function openToastWithComponent(component: ReactNode) {
    setComponent(component);
    setVisibility(true);
    setTimeout(() => {
      closeToast();
    }, closeToastAfter);
  }

  function openToastWithMessage(message: string) {
    setComponent(<p>{message}</p>);
    setVisibility(true);
    setTimeout(() => {
      closeToast();
    }, closeToastAfter);
  }

  function openToastWithError(message: string) {
    setIsError(true);
    setComponent(<p>{message}</p>);
    setVisibility(true);
    setTimeout(() => {
      closeToast();
    }, closeToastAfter);
  }

  function closeToast() {
    setVisibility(false);
    setComponent(null);
    setIsError(false);
  }

  return (
    <ToastContext.Provider
      value={{
        visibility,
        isError,
        openToastWithComponent,
        openToastWithMessage,
        openToastWithError,
        closeToast,
        component,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

export const useToast = () => useContext(ToastContext);

export function Toast() {
  const { t } = useTranslation();
  const { visibility, isError, closeToast, component } =
    useContext(ToastContext);
  const content = visibility && component ? component : null;

  return (
    <div
      aria-live="assertive"
      className="z-50 pointer-events-none fixed inset-0 flex items-end justify-end h-screen px-4 py-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={visibility}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg text-white shadow-lg ring-1 ring-black/5',
              isError ? 'bg-red-900' : 'bg-gray-900',
            )}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  {isError ? (
                    <div className="rounded-md w-8 h-8 flex items-center justify-center">
                      <ErrorIcon className="text-white" />
                    </div>
                  ) : (
                    <div className="bg-green-500 rounded-md w-8 h-8 flex items-center justify-center">
                      <div className="border-2 p-0.5 border-white rounded-full flex items-center justify-center">
                        <CheckIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="ml-3 w-0 flex flex-col flex-1 items-start justify-start text-base pt-1">
                  {isError && (
                    <div className="font-bold">{t('global:oups')}</div>
                  )}
                  {content}
                </div>
                <div className="ml-4 flex shrink-0 pt-1">
                  <button
                    className="unstyled text-gray-900"
                    onClick={() => {
                      closeToast();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon
                      className={clsx('h-5 w-5', isError && 'text-white')}
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
