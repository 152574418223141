import { PageTitle } from '../../../components/nav/PageTitle';
import {
  AdminEnterprisesScreen_EnterpriseFragment,
  useAdminEnterprisesWithLicenseScreenQuery,
  useAdminEnterprisesWithoutLicenseScreenQuery,
} from '../../../graphql/generated';
import { NumberCircle } from '../../../components/generic/NumberCircle';
import { useModal } from '../../../components/layout/Modal';
import React, { useState } from 'react';
import clsx from 'clsx';
import { ChevronRightIcon, SearchIcon } from '../../../components/icons';
import { useTranslation } from '@hooks/useTranslation';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { Breadcrumb } from '../../../components/nav/Breadcrumb';
import { TabNavigationLayout } from '../../../components/generic/nav/TabNavigationLayout';
import { ADMIN_TABS, useAdminTabs } from '../useAdminTabs';
import { EmployeeItem } from '../company/EmployeeItem';
import { AdminHero } from '../AdminHero';
import { Tag } from '../../../components/badges/Tag';
import { Toggle } from '../../../components/generic/form/Toggle';
import { EnterpriseLicenseEditor } from '../../../components/admin/enterprise/EnterpriseLicenseEditor';
import {
  RegisterNewProjectModal,
  registerNewProjectModalConfig,
} from '../../../components/project/RegisterNewProjectModal';
import { useDateTimeFromIsoString } from '@hooks/useDateTimeFromIsoString';
import { InvitationModalButton } from '../../../components/project/project/users/InvitationModal';
import { DeleteEnterpriseButton } from '../../../components/admin/enterprise/DeleteEnterpriseButton';

function AdminEnterprises() {
  const { t } = useTranslation();
  const modal = useModal();

  const [onlyWithLicense, setOnlyWithLicense] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState('');

  const enterprisesWithLicenseQuery = useAdminEnterprisesWithLicenseScreenQuery(
    {
      fetchPolicy: 'network-only',
    },
  );
  const enterprisesWithoutLicenseQuery =
    useAdminEnterprisesWithoutLicenseScreenQuery({
      fetchPolicy: 'network-only',
    });

  const adminTabs = useAdminTabs();
  const isLoading =
    enterprisesWithLicenseQuery.loading ||
    enterprisesWithoutLicenseQuery.loading;

  const enterprises = onlyWithLicense
    ? enterprisesWithLicenseQuery.data?.enterprises ?? []
    : enterprisesWithoutLicenseQuery.data?.enterprises ?? [];

  const filteredEnterprises = enterprises.filter(
    (enterprise) =>
      enterprise.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      enterprise.ownedBy?.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const toggleWithLicense = (withLicense: boolean) => {
    setOnlyWithLicense(withLicense);
    if (withLicense) {
      enterprisesWithLicenseQuery.refetch();
    } else {
      enterprisesWithoutLicenseQuery.refetch();
    }
  };

  const openRegisterNewProModal = () => {
    modal.openModal(<RegisterNewProjectModal />, {
      ...registerNewProjectModalConfig,
      title: t('admin:companies.enterprises.add'),
    });
  };

  if (isLoading) {
    return <LoaderFullscreen />;
  }

  return (
    <>
      <Breadcrumb />
      <TabNavigationLayout<ADMIN_TABS>
        tabs={adminTabs}
        activeTab={ADMIN_TABS.ENTERPRISES}
        renderTabs={(tabsContent) => <AdminHero>{tabsContent}</AdminHero>}
      >
        <PageTitle title="Admin" />
        <div className="w-full main-content p-8">
          <div className="space-y-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <h1>{t('admin:companies.enterprises.title')}</h1>
                <Tag color={'blue'}>{enterprises.length}</Tag>
              </div>
              <button
                className="primary purple"
                onClick={openRegisterNewProModal}
              >
                {t('global:add')}
              </button>
            </div>
            <div className="flex items-center gap-1 font-bold">
              <Toggle
                state={onlyWithLicense}
                setState={(withLicense) => toggleWithLicense(withLicense)}
              />
              <div>{t('admin:companies.enterprises.withLicense')}</div>
            </div>
            <form className="w-full">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center ps-3 pointer-events-none">
                  <SearchIcon />
                </div>
                <input
                  type="search"
                  id="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={t(
                    'admin:companies.enterprises.searchPlaceholder',
                  )}
                  className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-700"
                />
              </div>
            </form>
            <div className="space-y-4">
              {filteredEnterprises.map((enterprise, index) => (
                <AdminEnterprise
                  company={enterprise}
                  index={index}
                  key={enterprise.id}
                />
              ))}
            </div>
          </div>
        </div>
      </TabNavigationLayout>
    </>
  );
}

function AdminEnterprise({
    company,
    index,
  }: {
    company: AdminEnterprisesScreen_EnterpriseFragment;
    index: number;
  }) {
  const { t } = useTranslation();
  const createdAt = useDateTimeFromIsoString(company.createdAt);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="flex items-center gap-2">
      <NumberCircle number={index + 1} size={8} />
      <div
        className={clsx(
          'border border-gray-100 rounded-xl space-y-6 w-full',
          company.name === 'Good Steps' ? 'bg-gray-50' : 'bg-white',
        )}
      >
        <div className="p-4 flex items-center gap-4 justify-between">
          <div>
            <h3
              className="font-bold cursor-pointer"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {company.name}
            </h3>
            <div className="text-gray-500 text-xs">
              {company.ownedBy && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('admin:companies.enterprises.ownedBy', {
                      ownedBy: company.ownedBy.name,
                    }),
                  }}
                />
              )}
              {t('admin:companies.list.item.createdAtAndBy', {
                createdAt: createdAt.date,
                createdBy: `${company.createdBy?.firstName} ${company.createdBy?.lastName}`,
              })}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="min-w-[15rem]">
              <EnterpriseLicenseEditor company={company} />
            </div>
            <button className="tertiary" onClick={() => setIsExpanded(!isExpanded)}>
              <ChevronRightIcon
                className={clsx('w-4 h-4', isExpanded && 'rotate-90')}
              />
            </button>
          </div>
        </div>

        {isExpanded && (
          <div className="space-y-4 divide-y">
            <div className="p-4 flex items-start gap-8 w-full divide-x divide-gray-100">
              <div className="w-1/3 space-y-2">
                <div className="flex items-center gap-4 justify-between">
                  <div className="flex items-center gap-1">
                    <h6>{t('admin:companies.list.item.employees')}</h6>
                    <div className="tag gray">{company.employees?.length}</div>
                  </div>
                </div>
                <ul className="space-y-1 text-sm">
                  {company.employees?.map((employee) => (
                    <EmployeeItem key={employee.id} employee={employee} />
                  ))}
                </ul>
                <InvitationModalButton enterpriseId={company.id} />
              </div>
              <div className="w-2/3 pl-8 space-y-4"></div>
            </div>
            <div className="p-4 flex justify-end">
              <DeleteEnterpriseButton enterpriseId={company.id} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const AdminEnterprisesScreen = AdminEnterprises;
