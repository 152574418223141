import React, { useState } from 'react';
import {
  GradientDropdown,
  GradientDropdownStrength,
} from '../../../../generic/dropdown/GradientDropdown';
import {
  IroLikelihoodPicker_NegativeImpactFragment,
  IroLikelihoodPicker_OpportunityFragment,
  IroLikelihoodPicker_PositiveImpactFragment,
  IroLikelihoodPicker_RiskFragment,
  LikelihoodValue,
  useUpdateIroLikelihoodMutation,
} from '../../../../../graphql/generated';
import { useToast } from '../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { TFunction } from 'i18next';
import { useRefetchMaterialities } from './useRefetchMaterialities';

export type IroLikelihoodItem = {
  id: LikelihoodValue | null;
  name: string;
  strength: GradientDropdownStrength;
};

export const likelihoodCriteriaChoices = (
  t: TFunction,
): IroLikelihoodItem[] => {
  return [
    {
      id: LikelihoodValue.Real,
      name: t(`enum:iroCriteria.likelihood.${LikelihoodValue.Real}`),
      strength: GradientDropdownStrength.VeryStrong,
    },
    {
      id: LikelihoodValue.Certain,
      name: t(`enum:iroCriteria.likelihood.${LikelihoodValue.Certain}`),
      strength: GradientDropdownStrength.VeryStrong,
    },
    {
      id: LikelihoodValue.VeryProbable,
      name: t(`enum:iroCriteria.likelihood.${LikelihoodValue.VeryProbable}`),
      strength: GradientDropdownStrength.Strong,
    },
    {
      id: LikelihoodValue.Unlikely,
      name: t(`enum:iroCriteria.likelihood.${LikelihoodValue.Unlikely}`),
      strength: GradientDropdownStrength.Medium,
    },
    {
      id: LikelihoodValue.Impossible,
      name: t(`enum:iroCriteria.likelihood.${LikelihoodValue.Impossible}`),
      strength: GradientDropdownStrength.Light,
    },
    {
      id: null,
      name: '-',
      strength: GradientDropdownStrength.VeryLight,
    },
  ];
};

export default function IroLikelihoodPicker({
  iro,
  extraPositionClassName,
  disabled,
}: {
  iro:
    | IroLikelihoodPicker_RiskFragment
    | IroLikelihoodPicker_OpportunityFragment
    | IroLikelihoodPicker_PositiveImpactFragment
    | IroLikelihoodPicker_NegativeImpactFragment;
  extraPositionClassName?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const items = likelihoodCriteriaChoices(t);
  const initialLikelihoodItem = items.find(
    (item) => item.id === iro.likelihoodCriteria?.value || null,
  );
  const [selected, setSelected] = useState(initialLikelihoodItem || null);
  const [update] = useUpdateIroLikelihoodMutation();
  const toast = useToast();
  const refetchQueries = useRefetchMaterialities();

  const chooseItemId = (itemId: string | null) => {
    const item = items.find((item) => item.id === itemId) || null;
    setSelected(item);

    update({
      variables: {
        input: {
          id: iro.id,
          likelihood: item?.id ?? null,
        },
      },
      refetchQueries,
    })
      .then(() => {
        toast.openToastWithMessage(
          t('toast:iro.criteria.update.success', {
            criteriaName: t('iro.criteria.likelihood.title'),
          }),
        );
      })
      .catch(() => {
        toast.openToastWithError(
          t('toast:iro.criteria.likelihood.update.error'),
        );
      });
  };
  return (
    <GradientDropdown
      {...{
        items,
        selected,
        chooseItemId,
        extraPositionClassName,
        disabled,
      }}
    />
  );
}
