import React, { ReactNode } from 'react';

interface SecondaryButtonProps {
  onClick: () => void;
  title: ReactNode;
  icon?: ReactNode;
  description?: ReactNode;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  onClick,
  icon,
  title,
  description,
}) => {
  return (
    <button
      onClick={onClick}
      className="flex items-baseline gap-2 self-stretch py-2 px-3 bg-white hover:bg-gray-100 focus:outline-hidden"
    >
      {icon && <div className="shrink-0">{icon}</div>}
      <div className="flex flex-col items-start text-left">
        <span className="font-black font-['IBM Plex Sans'] text-sm leading-[140%] font-semibold">
          {title}
        </span>
        {description && (
          <span className="font-['IBM Plex Sans'] text-[#6c738c] text-sm leading-[140%]">
            {description}
          </span>
        )}
      </div>
    </button>
  );
};

export default SecondaryButton;
