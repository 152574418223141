import React from 'react';
import { PageTitle } from '../../../components/nav/PageTitle';
import { useTranslation } from '@hooks/useTranslation';
import { ProjectHeader } from './ProjectHeader';
import { PROJECT_HOME_TABS, useProjectTabs } from './useProjectTabs';
import { TabNavigationLayout } from '../../../components/generic/nav/TabNavigationLayout';
import { ProjectNotebookContent } from '../../../components/project/project/notebook/ProjectNotebookContent';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { FeatureEnum, PermissionEnum } from '../../../graphql/generated';

export const ProjectNotebookScreen = () => {
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker();
  const readOnly = isGranted(
    FeatureEnum.ProjectNotes,
    PermissionEnum.Read,
    true,
  );

  return (
    <>
      <TabNavigationLayout<PROJECT_HOME_TABS>
        tabs={useProjectTabs()}
        activeTab={PROJECT_HOME_TABS.NOTEBOOK}
        renderTabs={(tabsContent) => (
          <>
            <div className="py-12 px-16">
              <ProjectHeader />
            </div>
            {tabsContent}
          </>
        )}
      >
        <PageTitle title={t('screen.project.notebook.meta.title')} />
        <div className="w-full">
          <ProjectNotebookContent readOnly={readOnly} />
        </div>
      </TabNavigationLayout>
    </>
  );
};

