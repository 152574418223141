import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Table } from '../../../molecules/table/Table';
import {
  SurveyResponseRow,
  useSurveyResponsesColumns,
} from './useSurveyResponsesColumns';
import { getSortedRowModel } from '@tanstack/react-table';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';
import { useGroupedStakeholderSegments } from '../../../project/stakeholder/dropdown/useGroupedStakeholderSegments';
import {
  FeatureEnum,
  PermissionEnum,
  SurveyResponses_SurveyFragment,
  useSurveyResponsesTableQuery,
} from '../../../../graphql/generated';
import { LoaderFullscreen } from '../../../layout/Loader';
import { MessageBox, MessageBoxType } from '../../../layout/MessageBox';
import { Loader } from '../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { SurveyReponseRowStakeholderDropdown } from './SurveyReponseRowStakeholderDropdown';
import { SurveyResponseContextualMenu } from '../actions/SurveyResponseContextualMenu';
import StringDropdown from '../../../generic/dropdown/StringDropdown';
import { useProjectContext } from '../../../../providers/ProjectContextProvider';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

type Props = {
  survey: SurveyResponses_SurveyFragment;
  setResponsesCounter?: (responsesCounter: number) => void;
  hasRecipients: boolean;
};

export const ITEMS_PER_PAGE_COOKIE = 'itemsPerPage';
export const DEFAULT_ITEMS_PER_PAGE = '20';

export const SurveyResponsesTable = ({
  hasRecipients,
  survey,
  setResponsesCounter,
}: Props) => {
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker()
  const readOnly = isGranted(FeatureEnum.ConsultationManagement, PermissionEnum.Read, true)

  const initialItemsPerPage = parseInt(
    Cookies.get(ITEMS_PER_PAGE_COOKIE) || DEFAULT_ITEMS_PER_PAGE,
    10,
  );
  const projectContext = useProjectContext();

  const [numberPerPage, setNumberPerPage] = useState(initialItemsPerPage);
  const [cursor, setCursor] = useState<{
    before: string | undefined;
    after: string | undefined;
  }>({ before: undefined, after: undefined });
  const { availableItems } = useGroupedStakeholderSegments(
    projectContext?.enterprise?.id || '',
  );
  const columns = useSurveyResponsesColumns();
  const paging = {
    first: cursor.after || !cursor.before ? numberPerPage : undefined,
    last: cursor.before ? numberPerPage : undefined,
    after: cursor.after,
    before: cursor.before,
  };
  const query = useSurveyResponsesTableQuery({
    variables: {
      surveyId: survey.id,
      paging,
    },
    fetchPolicy: 'network-only', // or 'cache-and-network'
  });

  useEffect(() => {
    Cookies.set(ITEMS_PER_PAGE_COOKIE, numberPerPage .toString());
  }, [numberPerPage]);

  if (query.loading) {
    return <LoaderFullscreen />;
  }

  if (query.error) {
    return (
      <MessageBox type={MessageBoxType.Error}>{query.error.message}</MessageBox>
    );
  }

  if (!query.data) {
    return <Loader />;
  }

  const recipientEmails = survey?.recipients?.map(
    (recipient) => recipient.email,
  );
  const stakeholderIdsByEmail = survey?.recipients?.reduce(
    (acc, recipient) => {
      acc[recipient.email] = recipient.id;
      return acc;
    },
    {} as { [key: string]: string },
  );

  if (setResponsesCounter) {
    setResponsesCounter(query.data.surveyResponses.totalCount);
  }
  const rows = query.data.surveyResponses.edges.map((edge) => {
    const node = edge.node;
    return {
      email: {
        email: node.respondent?.email,
        inContactList:
          !hasRecipients ||
          recipientEmails?.includes(node.respondent?.email ?? '') ||
          false,
        name: node.respondent?.name,
      },
      name: node.respondent?.name,
      segmentId: (
        <SurveyReponseRowStakeholderDropdown
          response={node}
          segment={node.segment}
          defaultSegment={survey.defaultSegment}
          availableItems={availableItems}
          currentPaging={paging}
          readOnly={readOnly}
        />
      ),
      submittedAt: {
        date: node.submittedAt,
        actions: (
          <SurveyResponseContextualMenu
            stakeholderId={
              node.respondent?.email && stakeholderIdsByEmail
                ? stakeholderIdsByEmail[node.respondent?.email]
                : null
            }
            surveyId={survey.id}
            response={node}
          />
        ),
      },
    };
  });

  const next = () => {
    setCursor({
      before: undefined,
      after: query.data?.surveyResponses?.pageInfo.endCursor,
    });
  };

  const previous = () => {
    setCursor({
      before: query.data?.surveyResponses?.pageInfo.startCursor,
      after: undefined,
    });
  };

  return (
    (query.data.surveyResponses.totalCount > 0 && (
      <>
        <Table<SurveyResponseRow>
          {...{
            tableClassName: 'table condensed',
            columns,
            data: rows,
            enableSorting: false,
            getSortedRowModel: getSortedRowModel(),
          }}
        />
        <div className="flex justify-end gap-2">
          <div className="w-30">
            <StringDropdown
              availableItems={[
                {
                  id: '10',
                  label: t('global:pager.itemsPerPage', { count: 10 }),
                },
                {
                  id: '20',
                  label: t('global:pager.itemsPerPage', { count: 20 }),
                },
                {
                  id: '50',
                  label: t('global:pager.itemsPerPage', { count: 50 }),
                },
              ]}
              item={{
                id: numberPerPage.toString(),
                label: t('global:pager.itemsPerPage', {
                  count: numberPerPage,
                }),
              }}
              setItem={(item) => {
                if (item?.id) {
                  setNumberPerPage(parseInt(item.id))
                }
              }}
              extraPositionClassName="-translate-y-full"
            />
          </div>
          <button
            aria-label={t('global:pager.previous')}
            disabled={!query.data?.surveyResponses.pageInfo.hasPreviousPage}
            onClick={previous}
          >
            <ChevronLeftIcon />
          </button>
          <button
            aria-label={t('global:pager.next')}
            disabled={!query.data?.surveyResponses.pageInfo.hasNextPage}
            onClick={next}
          >
            <ChevronRightIcon />
          </button>
        </div>
      </>
    )) || (
      <MessageBox type={MessageBoxType.Info}>
        {t('translation:survey.responses.emtptyState.content')}
      </MessageBox>
    )
  );
};
