import React from 'react';
import { useModal } from '../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import clsx from 'clsx';

export enum ConfirmationModalType {
  Info = 'info',
  Danger = 'danger',
}

const TypeToCtaClass = {
  [ConfirmationModalType.Info]: '',
  [ConfirmationModalType.Danger]: 'danger',
};

export function ConfirmationModal({
  callbackIfConfirmed,
  message,
  type,
}: {
  callbackIfConfirmed: () => void;
  message: string;
  type?: ConfirmationModalType;
}) {
  const modal = useModal();
  const { t } = useTranslation();
  const confirm = () => {
    callbackIfConfirmed();
    modal.closeModal();
  };

  return (
    <div className="space-y-8">
      <p className="text-center">{message}</p>
      <div className="flex justify-between items-center gap-4">
        <button className="secondary" onClick={() => modal.closeModal()}>
          {t('global:cancel')}
        </button>
        <button
          className={clsx('primary danger', type && TypeToCtaClass[type])}
          onClick={confirm}
        >
          {t('global:confirm')}
        </button>
      </div>
    </div>
  );
}
