import { useEnterpriseUserAccessQuery } from '../../../../graphql/generated';
import {
  prepareData,
  UserAccessTableRow,
  useUserAccessTableColumns,
} from './useUserAccessTableColumns';
import { getSortedRowModel } from '@tanstack/react-table';
import { Table } from '../../../molecules/table/Table';
import React from 'react';
import { Loader } from '../../../generic/Loader';

export const UserAccess = ({ company }: { company: { id: string } }) => {
  const { data, loading } = useEnterpriseUserAccessQuery({
    variables: {
      enterpriseId: company.id,
    },
  });
  const columns = useUserAccessTableColumns();

  if (loading) {
    return <Loader />;
  }
  if (!data?.userAccesses) {
    return <div>Error</div>;
  }

  return (
    <div className="space-y-8">
      <Table<UserAccessTableRow>
        {...{
          columns,
          data: prepareData(data.userAccesses),
          getSortedRowModel: getSortedRowModel(),
          initialState: {
            sorting: [
              {
                id: 'name',
                desc: false,
              },
            ],
          },
        }}
      />
    </div>
  );
};
