import { useTranslation } from '@hooks/useTranslation';
import { GradientScore } from '../../../../generic/GradientScore';
import { ImpactScoreRangeSlider } from './ImpactScoreRangeSlider';
import React, { useState } from 'react';
import { ImpactMaterialityScoreFragment } from '../../../../../graphql/generated';
import clsx from 'clsx';
import { ChevronDownIcon } from '../../../../icons';

export function ImpactMaterialityScore({
  stake,
  readOnly = false,
}: {
  stake: ImpactMaterialityScoreFragment;
  readOnly?: boolean;
}) {
  const { t } = useTranslation();
  const [showScoreSlider, setShowScoreSlider] = useState(false);
  return (
    <div className="relative">
      <div className="border border-gray-900 rounded-xl px-4 py-2 bg-white">
        <div className="flex items-center justify-between gap-4">
          <div className="font-bold text-sm">
            {t('screen.iro.computed_impact_materiality_score')}
          </div>
          <GradientScore
            score={
              stake.impactMaterialityScore ||
              stake.persistedImpactMaterialityScore ||
              stake.computedImpactMaterialityScore
            }
          />
          {!readOnly && (
            <button
              className="unstyled"
              onClick={() => setShowScoreSlider(!showScoreSlider)}
            >
              <ChevronDownIcon
                className={clsx(showScoreSlider && 'rotate-180')}
              />
            </button>
          )}
        </div>
      </div>
      {showScoreSlider && !readOnly && (
        <div className="absolute top-full right-0 z-50 mt-2">
          <ImpactScoreRangeSlider stake={stake} />
        </div>
      )}
    </div>
  );
}
