import React, { useMemo, useState } from 'react';
import { useModal } from '../../../layout/Modal';
import { useTranslation } from '@hooks/useTranslation';
import { PlusIcon } from '../../../icons';
import {
  EnterpriseRoleEnum,
  FeatureEnum,
  PermissionEnum,
  useInvitationTokenForEnterpriseAndRoleQuery,
} from '../../../../graphql/generated';
import { Field, Label, RadioGroup } from '@headlessui/react';
import { Radio } from '../../../form/Radio';
import { CopyTextToClipboardButton } from '../../../generic/CopyTextToClipboardButton';
import clsx from 'clsx';
import StringDropdown from '../../../generic/dropdown/StringDropdown';
import { generatePath } from 'react-router-dom';
import { AppRoutes } from '../../../../screens/AppRoutes';
import {
  getDateFromISOString,
  getTimeFromISOString,
} from '../../../../services/DateService';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

const expirationOptions = () => {
  const { t } = useTranslation();

  return [
    { id: '1d', label: t('enum:duration.1d'), duration: 1 },
    { id: '1w', label: t('enum:duration.1w'), duration: 7 },
    { id: '1m', label: t('enum:duration.1m'), duration: 30 },
    { id: 'unlimited', label: t('enum:duration.unlimited'), duration: null },
  ];
};


const InvitationModal = ({ enterpriseId }: { enterpriseId: string }) => {
  const [selectedRole, setSelectedRole] = useState(EnterpriseRoleEnum.Administrator);
  const [selectedExpiration, setSelectedExpiration] = useState<
    { id: string; label: string; duration: number | null } | null
  >(expirationOptions()[0]);
  const { t, i18n } = useTranslation();

  const expirationDate = useMemo(() => {
    if (selectedExpiration?.duration) {
      return new Date(Date.now() + selectedExpiration.duration * 24 * 60 * 60 * 1000);
    }
    return null;
  }, [selectedExpiration?.duration]);

  const { data, loading } = useInvitationTokenForEnterpriseAndRoleQuery({
    variables: {
      entrepriseId: enterpriseId,
      role: selectedRole,
      expirationDate: expirationDate?.toISOString(),
    },
    skip: !enterpriseId,
  });

  const expirationMessage = useMemo(() => {
    if (expirationDate) {
      return t('project.users.invitationModal.expirationMessage', {
        date: getDateFromISOString(expirationDate.toISOString(), i18n.language) + '  ' + getTimeFromISOString(expirationDate.toISOString(), i18n.language),
      });
    }
  }, [expirationDate, t]);

  return (
    <div className="flex flex-col gap-4">
      <h6>{t('project.users.invitationModal.chooseRole')}</h6>
      <RadioGroup value={selectedRole} onChange={setSelectedRole}>
        {Object.values(EnterpriseRoleEnum).map((role) => (
          <Field
            key={role}
            className={clsx(
              'flex items-center gap-2 rounded-xl px-2 py-3 my-2',
              (selectedRole === role && 'bg-gray-50') || 'hover:bg-gray-50',
            )}
          >
            <Radio
              value={role}
              className="group flex size-5 items-center justify-center rounded-full border bg-white"
            >
              <span className="invisible size-2 rounded-full bg-white group-data-checked:visible" />
            </Radio>
            <Label className="font-semibold cursor-pointer">
              {t(`enum:enterpriseRole.${role}`)}
            </Label>
          </Field>
        ))}
      </RadioGroup>

      <div className="flex flex-col gap-2">
        <Field>
          <Label>{t('project.users.invitationModal.chooseExpiration')}</Label>
          <StringDropdown
            availableItems={expirationOptions()}
            item={selectedExpiration}
            setItem={setSelectedExpiration}
            itemFormatter={(item) => item.label}
          />
          <p className="text-sm text-gray-500 mt-2">{expirationMessage}</p>
        </Field>
      </div>

      <CopyTextToClipboardButton
        buttonLabel={t('project.users.invitationModal.copyButton')}
        textToCopy={
          data?.invitationTokenForEnterpriseAndRole.token
            ? `${window.location.origin}${generatePath(AppRoutes.Register, {
              companyId: enterpriseId,
              token: data?.invitationTokenForEnterpriseAndRole.token,
            })}`
            : '#'}
        className="purple small"
        disabled={loading || !data?.invitationTokenForEnterpriseAndRole.token}
      />
    </div>
  );
};

export const InvitationModalButton = ({ enterpriseId }: { enterpriseId: string }) => {
  const modal = useModal();
  const { t } = useTranslation();
  const { isGranted } = usePermissionChecker();

  const openInvitationModal = () => {
    modal.openModal(<InvitationModal enterpriseId={enterpriseId} />, {
      title: t('project.users.invitationModal.title'),
      isFullscreen: false,
      displayTitleBar: true,
    });
  };

  return (
    <button
      className="primary small purple shrink-0"
      onClick={openInvitationModal}
      disabled={!isGranted(FeatureEnum.ProjectUsers, PermissionEnum.Write)}
    >
      <PlusIcon />
      <span>{t('project.users.invite.button')}</span>
    </button>
  );
};
