import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { CompanyLogo } from '../../../components/project/project/CompanyLogo';
import React from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { SkeletonLoader } from '../../../components/generic/loader/SkeletonLoader';

export const ProjectHeader = () => {
  const projectContext = useProjectContext();
  const { t, i18n } = useTranslation();
  return (
    <div className="flex items-center gap-4 justify-start">
      {(projectContext?.enterprise && (
        <CompanyLogo companyId={projectContext.enterprise.id} size={'big'} />
      )) || <SkeletonLoader size={{ width: 'w-20', height: 'h-20' }} />}
      <div>
        <div className="text-gray-500 first-letter:capitalize text-sm">
          {t('screen.project.dashboardWithDate', {
            date: new Date().toLocaleDateString(i18n.language, {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
            }),
            companyName: projectContext?.enterprise?.name,
          })}
        </div>
        {
          <h1 className="text-5xl">
            {t('screen.project.title', {
              companyName: projectContext?.enterprise?.name,
            })}
          </h1>
        }
      </div>
    </div>
  );
};
