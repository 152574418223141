import React, { Fragment, ReactElement, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { ActionPriorityLevel } from '../../../graphql/generated';
import {
  colorFromPriorityLevel,
  titleFromPriorityLevel,
} from '../../badges/PriorityBadge';
import { NumberCircle } from '../../generic/NumberCircle';
import { CheckIcon, ChevronDownIcon } from '../../icons';

type PriorityLevelListItem = {
  id: ActionPriorityLevel | null;
  name: string;
  icon: ReactElement;
};

const defaultPriorityLevelListItem: PriorityLevelListItem = {
  id: ActionPriorityLevel.Urgent,
  name: titleFromPriorityLevel(ActionPriorityLevel.Urgent),
  icon: <NumberCircle number={1} size={7} />,
};

const priorityLevelListItems: PriorityLevelListItem[] = [
  {
    id: ActionPriorityLevel.Urgent,
    name: titleFromPriorityLevel(ActionPriorityLevel.Urgent),
    icon: (
      <NumberCircle
        number={1}
        size={7}
        color={colorFromPriorityLevel(ActionPriorityLevel.Urgent)}
      />
    ),
  },
  {
    id: ActionPriorityLevel.Priority,
    name: titleFromPriorityLevel(ActionPriorityLevel.Priority),
    icon: (
      <NumberCircle
        number={2}
        size={7}
        color={colorFromPriorityLevel(ActionPriorityLevel.Priority)}
      />
    ),
  },
  {
    id: ActionPriorityLevel.Important,
    name: titleFromPriorityLevel(ActionPriorityLevel.Important),
    icon: (
      <NumberCircle
        number={3}
        size={7}
        color={colorFromPriorityLevel(ActionPriorityLevel.Important)}
      />
    ),
  },
  {
    id: ActionPriorityLevel.Unimportant,
    name: titleFromPriorityLevel(ActionPriorityLevel.Unimportant),
    icon: (
      <NumberCircle
        number={4}
        size={7}
        color={colorFromPriorityLevel(ActionPriorityLevel.Unimportant)}
      />
    ),
  },
];

export default function ActionPriorityLevelPicker({
  currentActionPriorityLevel,
  callback,
}: {
  currentActionPriorityLevel: ActionPriorityLevel;
  callback: (input: ActionPriorityLevel) => void;
}) {
  const initialPriorityLevelListItem = priorityLevelListItems.find(
    (item) => item.id === currentActionPriorityLevel,
  );
  const [selected, setSelected] = useState(
    initialPriorityLevelListItem || defaultPriorityLevelListItem,
  );

  const chooseItem = (priorityLevelListItem: PriorityLevelListItem) => {
    setSelected(priorityLevelListItem);
    callback(priorityLevelListItem.id || ActionPriorityLevel.Urgent);
  };
  return (
    <Listbox value={selected} onChange={chooseItem}>
      {({ open }) => (
        <div className="relative w-full">
          <ListboxButton className="form-input-select">
            <span className="flex items-center w-full">
              {selected.icon}
              <span className="ml-3 block truncate">{selected.name}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm">
              {priorityLevelListItems.map((actionPriorityLevelListItem) => (
                <ListboxOption
                  key={actionPriorityLevelListItem.id}
                  className={clsx(
                    'listbox-option relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    'data-[selected]:active data-focus:text-white data-focus:bg-blue-600',
                  )}
                  value={actionPriorityLevelListItem}
                >
                  {({ selected }) => (
                    <>
                      <div className="flex items-center">
                        {actionPriorityLevelListItem.icon}
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'ml-3 block truncate',
                          )}
                        >
                          {actionPriorityLevelListItem.name}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={clsx(
                            'icon absolute inset-y-0 right-0 flex items-center pr-4 text-white',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
