import React from 'react';
import { PageTitle } from 'components/nav/PageTitle';
import {
  AuthRoles,
  AuthRolesAndExperimentalFeatures,
  CoachsSettingsScreenDocument,
  ConsultingCompanyRoleEnum,
  ExperimentalFeatures,
  GiveUserAccess_EnterpriseFragment,
  useAdminUserScreenQuery,
  useAuthUpdateMutation,
  useGiveConsultingCompanyRoleMutation,
} from 'graphql/generated';
import {
  MessageBox,
  MessageBoxType,
} from '../../../../components/layout/MessageBox';
import { withRouter } from '../../../withRouter';
import { useTranslation } from '@hooks/useTranslation';
import { ImpersonateButton } from '../../../../components/impersonate/ImpersonateButton';
import { Loader } from '../../../../components/generic/Loader';
import { CompanyLogo } from '../../../../components/project/project/CompanyLogo';
import { Tag } from '../../../../components/badges/Tag';
import { CopyIcon } from 'components/icons';
import { useToast } from 'components/layout/Toast';
import { DataList } from 'components/generic/DataList';
import { ADMIN_TABS, useAdminTabs } from '../../useAdminTabs';
import { Breadcrumb } from '../../../../components/nav/Breadcrumb';
import { TabNavigationLayout } from '../../../../components/generic/nav/TabNavigationLayout';
import { AdminHero } from '../../AdminHero';
import { ConsultingCompanyUserRoleDropdown } from '../../../../components/project/project/users/consultingCompany/SetUserProjectAccesses';
import { UserConsultingCompanyRoleDropdown } from '../../../../components/project/project/roles/UserConsultingCompanyRoleDropdown';
import { findRole } from '../../../../services/CompanyService';
import {
  isConsultingCompany,
  isEnterprise,
} from '../../../../types/companies.types';

type AdminUserProps = {
  params?: {
    id: string;
  };
};

const AdminUser = withRouter<AdminUserProps>(({ params: { id } }) => {
  const [updateAuthMutation] = useAuthUpdateMutation();
  const { t } = useTranslation();
  const toast = useToast();

  const adminTabs = useAdminTabs();
  const [giveConsultingCompanyRole] = useGiveConsultingCompanyRoleMutation();
  const userQuery = useAdminUserScreenQuery({
    variables: {
      id,
    },
  });
  const user = userQuery.data?.user;

  if (userQuery.error?.message) {
    return (
      <div className="p-8 flex justify-center items-center w-full">
        <MessageBox type={MessageBoxType.Error}>
          {userQuery.error.message}
        </MessageBox>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const userRoles =
    (user?.auth?.roles as AuthRolesAndExperimentalFeatures[]) || [];
  const checkRole = (
    role: AuthRolesAndExperimentalFeatures,
    wasPreviouslyChecked: boolean,
  ) => {
    if (!user.auth) {
      throw new Error("Can't checkRole for nonexistant Auth");
    }

    const clonedRolesArray = [...userRoles];
    const newRoles = wasPreviouslyChecked
      ? [...clonedRolesArray.filter((previousRole) => previousRole !== role)]
      : [...clonedRolesArray, role];

    updateAuthMutation({
      variables: {
        input: {
          id: user.auth.id,
          roles: newRoles,
        },
      },
    })
      .then((r) => console.log(r))
      .catch((err) => console.error(err));
  };
  const role = findRole(user);

  if (userQuery.loading) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumb />
      <TabNavigationLayout<ADMIN_TABS>
        tabs={adminTabs}
        activeTab={ADMIN_TABS.USERS}
        renderTabs={(tabsContent) => <AdminHero>{tabsContent}</AdminHero>}
      >
        <PageTitle
          title={`Admin - ${t('admin:users.form.pageTitle', {
            name: user.firstName.concat(' ', user.lastName),
          })}`}
        />
        <div className="main-content p-8 w-full">
          <div className="flex items-center justify-between">
            <div className={'flex items-center gap-8'}>
              <div className="rounded-xl border border-gray-100 p-1">
                {(user.company && (
                  <CompanyLogo companyId={user.company?.id} size={'big'} />
                )) || (
                  <em className={'text-orange-600'}>
                    {t('admin:users.show.noCompany')}
                  </em>
                )}
              </div>
              <h1>
                {user.firstName} {user.lastName} ({user.company?.name})
              </h1>
            </div>
            <div className="flex items-center gap-4">
            {isConsultingCompany(user.company) &&
            <UserConsultingCompanyRoleDropdown
              item={{
                id: role,
                label: t(`enum:consultingCompanyRole.${role}`),
              }}
              // disabled={!isGranted(FeatureEnum.ProjectUsers, PermissionEnum.Write)}
              setItem={(item) => {
                if (!item?.id) {
                  return;
                }
                giveConsultingCompanyRole({
                  variables: {
                    input: {
                      user: {
                        id: user.id,
                      },
                      consultingCompany: {
                        id: user?.company!.id,
                      },
                      role: item.id as ConsultingCompanyRoleEnum,
                    },
                  },
                  refetchQueries: [{
                    query: CoachsSettingsScreenDocument,
                    variables: {
                      id: user?.company!.id,
                    },
                  }],
                  onCompleted: (item) => {
                    toast.openToastWithMessage(
                      t('toast:project.users.updateRole.success'),
                    );
                  },
                });
              }}
            />}
            {isEnterprise<GiveUserAccess_EnterpriseFragment>(user.company) &&
              <ConsultingCompanyUserRoleDropdown
                key={user.company.id}
                enterprise={user.company}
                user={user}
              />}
            <ImpersonateButton userId={user.id} />
            </div>
          </div>
          <div className="px-4 py-5 sm:p-0">
            <DataList
              items={[
                {
                  label: t('admin:users.form.field.firstname'),
                  value: user.firstName,
                },
                {
                  label: t('admin:users.form.field.lastname'),
                  value: user.lastName,
                },
                {
                  label: t('admin:users.form.field.email'),
                  value: (
                    <div className="flex items-center gap-2">
                      {user.auth?.email}
                      <CopyIcon
                        onClick={() => {
                          navigator.clipboard.writeText(user.auth?.email || '');
                          toast.openToastWithMessage(
                            t('admin:users.form.field.emailCopied'),
                          );
                        }}
                        className="ml-2 cursor-pointer"
                      />
                    </div>
                  ),
                },
                {
                  label: t('admin:users.form.field.roles'),
                  value: !user.auth ? (
                    <div>[not associated with an Auth]</div>
                  ) : (
                    <div className="flex items-center gap-2">
                      {Object.values(AuthRoles).map((role) => {
                        /* @ts-ignore */
                        const checked = userRoles.includes(role);
                        return (
                          <label className="tag purple" key={role}>
                            <input
                              type="checkbox"
                              checked={checked}
                              /* @ts-ignore */
                              onChange={() => checkRole(role, checked)}
                            />
                            <span>{role}</span>
                          </label>
                        );
                      })}
                    </div>
                  ),
                },
                {
                  label: t('admin:users.form.field.featureFlag'),
                  value: !user.auth ? (
                    <div>[not associated with an Auth]</div>
                  ) : (
                    <div className="flex items-center gap-2">
                      {Object.values(ExperimentalFeatures).map((role) => {
                        /* @ts-ignore */
                        const checked = userRoles.includes(role);

                        return (
                          <label className="tag yellow" key={role}>
                            <input
                              type="checkbox"
                              checked={checked}
                              /* @ts-ignore */
                              onChange={() => checkRole(role, checked)}
                            />
                            <span>
                              {t(
                                `${'admin:users.form.featureFlag.'.concat(role)}`,
                              )}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  ),
                },
                {
                  label: t('admin:users.form.field.lastLoggedIn'),
                  value: user.auth?.lastSignInDate
                    ? new Date(user.auth?.lastSignInDate).toLocaleString()
                    : t('admin:users.form.field.neverLoggedIn'),
                },
                isConsultingCompany(user.company) && {
                  label: t('admin:users.form.field.projects'),
                  value: (
                    <div className={'flex flex-col gap-3'}>
                      {user.userAccesses
                        ?.filter((userAccess) => userAccess.enterprise !== null)
                        .sort((a, b) =>
                          a.enterprise!.name.localeCompare(b.enterprise!.name)
                        ).map((userAccess) => (
                        <div className={'flex gap-3 items-center font-bold'}>
                          <CompanyLogo
                            companyId={userAccess.enterprise!.id}
                            size={'small'}
                          />
                          {userAccess.enterprise!.name}
                          {userAccess.enterprise!.reports?.map((report) => {
                            return <Tag>{report.standard}</Tag>;
                          })}
                          <ConsultingCompanyUserRoleDropdown
                            key={userAccess.enterprise!.id}
                            enterprise={userAccess.enterprise!}
                            user={user}
                          />
                        </div>
                      ))}
                    </div>
                  ),
                } || undefined,
              ]}
            />
          </div>
        </div>
      </TabNavigationLayout>
    </>
  );
});

export const AdminUserScreen = AdminUser;
