import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from '@hooks/useTranslation';

export const GradientScoreSlider = ({
  scoreOn1To4Scale,
  onChange,
  onBlur,
  referenceScore,
}: {
  scoreOn1To4Scale: number;
  onChange: (score: number) => void;
  onBlur: (score: number) => void;
  referenceScore: number | null | undefined;
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(scoreOn1To4Scale);

  // Synchronize local state with external score
  useEffect(() => {
    setValue(scoreOn1To4Scale);
  }, [scoreOn1To4Scale]);

  const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  const localOnMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    const newValue = parseFloat((e.target as HTMLInputElement).value);
    setValue(newValue);
    onBlur(newValue);
  };

  const referenceScorePosition = getReferenceScorePosition(referenceScore);
  const barWidth = 100 - (value / 4) * 100;

  const isReferenceScoreAfterCursor =
    referenceScore !== null &&
    referenceScore !== undefined &&
    referenceScore > value;

  return (
    <div className="bg-white space-y-1 pt-4 px-4">
      <div className="flex items-center justify-between text-xs text-gray-500 px-1">
        <div>{t('components.generic.GradientScoreSlider.low')}</div>
        <div>{t('components.generic.GradientScoreSlider.high')}</div>
      </div>
      <div className="relative w-80 h-3 flex items-center">
        <div className="absolute left-0 w-full h-4 bg-white rounded-lg flex items-center shadow-sm">
          <div className="w-full h-3 bg-linear-to-r from-purple-50 to-purple-900 rounded-lg"></div>
        </div>
        {referenceScorePosition !== null && (
          <div className="absolute w-[95%] h-2 mx-2">
            <div
              style={{ left: `${referenceScorePosition}%` }}
              className={clsx(
                'absolute rounded-full w-2 h-2 -ml-1',
                isReferenceScoreAfterCursor ? 'bg-gray-500 z-40' : 'bg-white',
              )}
            />
          </div>
        )}
        <div
          className="absolute right-0 h-3 bg-gray-50 rounded-r-lg"
          style={{ width: `${barWidth}%` }}
        ></div>
        <input
          type="range"
          step={0.1}
          min={0}
          max={4}
          value={value}
          onChange={(e) => localOnChange(e)}
          onMouseUp={(e) => localOnMouseUp(e)}
          className="w-full h-4 bg-transparent rounded-lg appearance-none cursor-pointer z-50 accent-black ring-black"
        />
      </div>
      <div className="flex items-center justify-between text-xs text-gray-500 px-1">
        <div>0</div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
      </div>
    </div>
  );
};

function getReferenceScorePosition(
  referenceScore: number | null | undefined,
): number | null {
  return referenceScore ? (referenceScore / 4) * 100 : null;
}
