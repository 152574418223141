import {
  RightDrawerContextType,
  useRightDrawer,
} from '../../../../layout/RightDrawer';
import {
  IroCriteriaValuesDefinitionsContextFragment,
  IroType,
  IrremediabilityValue,
  LikelihoodValue,
  ScaleValue,
  ScopeValue,
  TimeHorizon,
  useQuestionIroAssessmentCriteriaDefinitionQuery,
} from '../../../../../graphql/generated';
import { ScaleCriteriaDefinition } from './ScaleCriteriaDefinition';
import { ScopeCriteriaDefinition } from './ScopeCriteriaDefinition';
import { IrremediabilityCriteriaDefinition } from './IrremediabilityCriteriaDefinition';
import { LikelihoodCriteriaDefinition } from './LikelihoodCriteriaDefinition';
import { TimeHorizonDefinition } from './TimeHorizonDefinition';
import React from 'react';
import { useProjectContext } from '../../../../../providers/ProjectContextProvider';

export const useIroCriteriaDefinitionDrawer = (
  scaleType:
    | typeof ScaleValue
    | typeof ScopeValue
    | typeof LikelihoodValue
    | typeof IrremediabilityValue
    | typeof TimeHorizon,
): {
  openCriteriaDefinitionDrawer: (iroType: IroType) => void;
} => {
  const rightDrawer = useRightDrawer();
  const projectContext = useProjectContext();

  const mapScaleTypeToCriteriaScaleName = (
    scaleType:
      | typeof ScaleValue
      | typeof ScopeValue
      | typeof LikelihoodValue
      | typeof IrremediabilityValue
      | typeof TimeHorizon,
  ): 'scale' | 'scope' | 'irremediability' | 'likelihood' | 'timeHorizon' => {
    if (scaleType === ScaleValue) {
      return 'scale';
    } else if (scaleType === ScopeValue) {
      return 'scope';
    } else if (scaleType === LikelihoodValue) {
      return 'likelihood';
    } else if (scaleType === IrremediabilityValue) {
      return 'irremediability';
    } else {
      return 'timeHorizon';
    }
  };

  const iroCriteriaValuesDefinitions =
    useQuestionIroAssessmentCriteriaDefinitionQuery({
      variables: {
        id: projectContext?.enterprise?.referential?.id ?? '',
      },
    });
  return {
    openCriteriaDefinitionDrawer: (iroType: IroType) =>
      openCriteriaDefinitionDrawer(
        rightDrawer,
        iroType,
        mapScaleTypeToCriteriaScaleName(scaleType),
        iroCriteriaValuesDefinitions.data?.referential
          .iroCriteriaValuesDefinitions || [],
      ),
  };
};

export const openCriteriaDefinitionDrawer = (
  rightDrawer: RightDrawerContextType,
  iroType: IroType,
  criteria:
    | 'scale'
    | 'scope'
    | 'irremediability'
    | 'likelihood'
    | 'timeHorizon',
  definitions: IroCriteriaValuesDefinitionsContextFragment[],
) => {
  // Fetch the definition from the context
  const definition = definitions.find(
    (definition) => definition.iroType === iroType,
  );

  switch (criteria) {
    case 'scale': {
      rightDrawer.openDrawerWithComponent(
        <ScaleCriteriaDefinition
          definitions={definition?.scaleValuesDefinitions || []}
        />,
      );
      break;
    }
    case 'scope': {
      rightDrawer.openDrawerWithComponent(
        <ScopeCriteriaDefinition
          definitions={definition?.scopeValuesDefinitions || []}
        />,
      );
      break;
    }
    case 'irremediability': {
      rightDrawer.openDrawerWithComponent(
        <IrremediabilityCriteriaDefinition
          definitions={definition?.irremediabilityValuesDefinitions || []}
        />,
      );
      break;
    }
    case 'likelihood': {
      rightDrawer.openDrawerWithComponent(
        <LikelihoodCriteriaDefinition
          definitions={definition?.likelihoodValuesDefinitions || []}
        />,
      );
      break;
    }
    case 'timeHorizon': {
      rightDrawer.openDrawerWithComponent(<TimeHorizonDefinition />);
      break;
    }
  }
};
