import React from 'react';
import {
  ToggleRequired_QuestionFragment,
  useToggleRequiredMutation,
} from '../../../../../../graphql/generated';
import { useToast } from '../../../../../layout/Toast';
import { Toggle } from '../../../../../generic/form/Toggle';
import { Loader } from '../../../../../generic/Loader';
import { useTranslation } from '@hooks/useTranslation';

export function ToggleRequired({
  question,
}: {
  question: ToggleRequired_QuestionFragment;
}) {
  const { t } = useTranslation();
  const [isRequired, setIsRequired] = React.useState<boolean>(
    question.required,
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const toast = useToast();
  const [updateQuestionMutation] = useToggleRequiredMutation();
  const updateRequiredState = (newState: boolean) => {
    setIsRequired(newState);
    const newQuestionDraft = { ...question };
    newQuestionDraft.required = newState;
    updateQuestionMutation({
      variables: {
        input: {
          id: question.id,
          required: newState,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:survey.question.update.success'));
        // setQuestion(newQuestionDraft);
      })
      .catch((error) => {
        toast.openToastWithError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-1/2 flex items-center gap-2 pb-3">
      <Toggle state={isRequired} setState={updateRequiredState} />
      {isLoading && <Loader />}
      <div className="font-bold text-sm">
        {t('survey.edit.questions.type.common.requiredResponse')}
      </div>
    </div>
  );
}
