import React from 'react';
import {
  AddNewStakeholderButton_SurveyFragment,
  ManageStakeholderListDocument,
  useAddNewStakeholderButton_StakeholderCreateMutation,
} from '../../../graphql/generated';
import { Loader } from '../../generic/Loader';
import { PlusIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function AddNewStakeholderButton({
  survey,
}: {
  survey: AddNewStakeholderButton_SurveyFragment;
}) {
  const { t } = useTranslation();
  const [createStakeholder, { loading }] =
    useAddNewStakeholderButton_StakeholderCreateMutation();

  const addNewStakeholder = () => {
    createStakeholder({
      variables: {
        input: {
          stakeholderSurvey: {
            survey: {
              id: survey.id,
            },
            email: '',
            firstName: '',
            lastName: '',
          },
        },
      },
      refetchQueries: [
        {
          query: ManageStakeholderListDocument,
          variables: {
            id: survey.id,
          },
        },
      ],
    }).catch((err) => {
      console.error(err);
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <button
      className="primary purple small"
      onClick={(event) => {
        event.preventDefault();
        addNewStakeholder();
      }}
    >
      <PlusIcon />
      <span>{t('global:add')}</span>
    </button>
  );
}
