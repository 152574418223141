import React from 'react';
import { CompanyLogo } from '../CompanyLogo';
import { ChevronDownIcon, ChevronUpIcon, UserGroupIcon } from '../../../icons';
import { UserProfileInitials } from '../../../user/UserProfileInitials';
import { useTranslation } from '@hooks/useTranslation';
import { EnterpriseCard_EnterpriseFragment } from '../../../../graphql/generated';

export function EnterpriseCard({
  showExpandButton,
  enterprise,
  children,
  expandedState,
  setExpandedState,
}: {
  showExpandButton?: boolean;
  enterprise: EnterpriseCard_EnterpriseFragment | null | undefined;
  children: React.ReactNode;
  expandedState?: string[] | undefined;
  setExpandedState?: (
    value: ((prevState: string[]) => string[]) | string[],
  ) => void;
}) {
  const MAX_AVATARS = 3;

  function toggleEnterpriseIdToExpanded(id: string) {
    if (expandedState?.includes(id)) {
      setExpandedState?.(expandedState.filter((item) => item !== id));
    } else {
      setExpandedState?.([...(expandedState || []), id]);
    }
  }

  return (
    enterprise && (
      <div className="w-full bg-white card px-4 py-4 space-y-4 min-w-96 ">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {showExpandButton &&
              enterprise.organizationalUnits &&
              enterprise.organizationalUnits.length > 0 && (
                <button
                  className="tertiary"
                  onClick={() => toggleEnterpriseIdToExpanded(enterprise?.id)}
                >
                  {!expandedState?.find((id) => id === enterprise.id) && (
                    <ChevronDownIcon className="w-4 h-4" />
                  )}
                  {expandedState?.find((id) => id === enterprise.id) && (
                    <ChevronUpIcon className="w-4 h-4" />
                  )}
                </button>
              )}

            <CompanyLogo
              companyId={enterprise.id}
              size={'medium'}
              fallbackBgColor="bg-yellow-100"
            />
            <div className="ml-3 space-y-2">
              <span className="font-bold">{enterprise.name}</span>
              <EnterpriseMetadata enterprise={enterprise} />
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div className="flex items-center">
              {enterprise.userAccesses
                ?.slice(0, MAX_AVATARS)
                .map((contributor) => (
                  <div className="-ml-2 border border-white rounded-full">
                    <UserProfileInitials
                      user={contributor.user}
                      size={'mini'}
                    />
                  </div>
                ))}
              {enterprise.userAccesses &&
                enterprise.userAccesses?.length > MAX_AVATARS && (
                  <div className="-ml-2 rounded-full bg-gray-900 w-8 h-8 flex items-center justify-center text-white font-title font-extrabold text-base ">
                    +{enterprise.userAccesses?.length - MAX_AVATARS}
                  </div>
                )}
            </div>

            {children}
          </div>
        </div>
      </div>
    )
  );
}

export function EnterpriseMetadata({
  enterprise,
}: {
  enterprise: EnterpriseCard_EnterpriseFragment;
}) {
  const { t } = useTranslation();
  const hasMetadata = enterprise?.metadata?.size;

  if (!hasMetadata) {
    return null;
  }

  return (
    <div className="flex gap-4">
      {enterprise.metadata?.size && (
        <div className="text-gray-500 font-light flex gap-1">
          <UserGroupIcon />
          <span>
            {t(`company.organizationalUnit.size`, {
              count: enterprise.metadata?.size,
            })}
          </span>
        </div>
      )}
    </div>
  );
}
