import {
  IroType,
  LikelihoodValue,
  LikelihoodValueDefinition,
  QuestionIroAssessment_IroFragment,
} from '../../../../../graphql/generated';
import React from 'react';
import { IroAssessmentOptionRadio } from './radioButton/IroAssessmentOptionRadio';
import { IroAssessmentButtonGroup } from './IroAssessmentButtonGroup';
import { useTranslation } from '@hooks/useTranslation';
import { CriteriaType } from '../../../../../types/iro.types';
import { IroAssessmentOptionRadioTooltip } from './radioButton/IroAssessmentOptionRadioTooltip';
import { useFormContext } from 'react-hook-form';
import { IroAssessmentFormValues } from '../IroAssessmentForm.types';

export const IroAssessmentLikelihoodButtonGroup = ({
  iro,
  likelihoodValuesDefinitions,
}: {
  iro: QuestionIroAssessment_IroFragment;
  likelihoodValuesDefinitions: LikelihoodValueDefinition[];
}) => {
  const { t } = useTranslation();
  const name = `iroAssessments.${iro.id}.likelihoodCriteria.value` as const;
  const { watch } = useFormContext<IroAssessmentFormValues>();
  const value = watch(name);
  return (
    <IroAssessmentButtonGroup
      criteria={CriteriaType.LIKELIHOOD}
      label={t('iro.criteria.likelihood.title')}
      iroType={iro.__typename as IroType}
    >
      <div className="w-56 flex items-center justify-evenly gap-2">
        <IroAssessmentOptionRadio<LikelihoodValue>
          {...{ name, value }}
          level={LikelihoodValue.Impossible}
          placeholder={'1'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              LikelihoodValue,
              LikelihoodValueDefinition
            >
              title={t('enum:iroCriteria.likelihood.Impossible')}
              definitions={likelihoodValuesDefinitions}
              value={LikelihoodValue.Impossible}
            />
          }
        />
        <IroAssessmentOptionRadio<LikelihoodValue>
          {...{ name, value }}
          level={LikelihoodValue.Unlikely}
          placeholder={'2'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              LikelihoodValue,
              LikelihoodValueDefinition
            >
              title={t('enum:iroCriteria.likelihood.Unlikely')}
              definitions={likelihoodValuesDefinitions}
              value={LikelihoodValue.Unlikely}
            />
          }
        />
        <IroAssessmentOptionRadio<LikelihoodValue>
          {...{ name, value }}
          level={LikelihoodValue.VeryProbable}
          placeholder={'3'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              LikelihoodValue,
              LikelihoodValueDefinition
            >
              title={t('enum:iroCriteria.likelihood.VeryProbable')}
              definitions={likelihoodValuesDefinitions}
              value={LikelihoodValue.VeryProbable}
            />
          }
        />
        <IroAssessmentOptionRadio<LikelihoodValue>
          {...{ name, value }}
          level={LikelihoodValue.Certain}
          placeholder={'4'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              LikelihoodValue,
              LikelihoodValueDefinition
            >
              title={t('enum:iroCriteria.likelihood.Certain')}
              definitions={likelihoodValuesDefinitions}
              value={LikelihoodValue.Certain}
            />
          }
        />
        <IroAssessmentOptionRadio<LikelihoodValue>
          {...{ name, value }}
          level={LikelihoodValue.Real}
          placeholder={'5'}
          tooltip={
            <IroAssessmentOptionRadioTooltip<
              LikelihoodValue,
              LikelihoodValueDefinition
            >
              title={t('enum:iroCriteria.likelihood.Real')}
              definitions={likelihoodValuesDefinitions}
              value={LikelihoodValue.Real}
            />
          }
        />
      </div>
    </IroAssessmentButtonGroup>
  );
};
