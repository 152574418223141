import React, { Fragment, useEffect, useState } from 'react';
import { CompanyFieldsFragment } from 'graphql/generated';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { Loader } from '../generic/Loader';
import { useProjectContext } from '../../providers/ProjectContextProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { CompanyLogo } from './project/CompanyLogo';
import { CheckIcon, DropIcon } from '../icons';
import { useTranslation } from '@hooks/useTranslation';

export function ProjectSelect() {
  const { t } = useTranslation();
  const projectContext = useProjectContext();
  const { currentUser } = useCurrentUser();

  const [selectedEnterpriseItem, setSelectedEnterpriseItem] =
    useState<CompanyFieldsFragment | null>(projectContext?.enterprise || null);

  // Listen to context change to update selected diagnostic
  useEffect(() => {
    if (projectContext?.enterprise) {
      setSelectedEnterpriseItem(projectContext.enterprise);
    }
  }, [projectContext?.enterprise]);

  const chooseItem = (enterprise: CompanyFieldsFragment) => {
    setSelectedEnterpriseItem(enterprise);
    if (projectContext) {
      projectContext.updateEnterprise(enterprise.id, true);
    }
  };

  const enterprises =
    currentUser?.userAccesses
      ?.filter((userAccess) => userAccess.enterprise !== null)
      .map((userAccess) => userAccess.enterprise!)
      .filter((enterprise) => {
        return (
          enterprise &&
          !enterprise.isTemplate &&
          enterprise.archivedDate === null
        );
      }) || [];

  return enterprises ? (
    <Listbox value={selectedEnterpriseItem} onChange={chooseItem}>
      {({ open }) => (
        <div className="relative">
          <ListboxButton className="relative w-full cursor-default rounded-md border py-3 pl-3 pr-10 text-left shadow-sm text-sm flex gap-2 items-center bg-white border-gray-50 hover:border-gray-500">
            {selectedEnterpriseItem && (
              <CompanyLogo
                companyId={selectedEnterpriseItem.id}
                size={'small'}
              />
            )}
            <div className="grow flex flex-col gap-0.5">
              <label className="font-normal text-xs text-gray-500">
                Projet
              </label>
              <span className="flex items-center w-full">
                <span
                  className={clsx(
                    'block text-gray-900 max-w-[10rem] truncate text-ellipsis',
                    selectedEnterpriseItem?.name || 'italic font-medium',
                  )}
                >
                  {selectedEnterpriseItem?.name || 'Choisir un projet'}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <DropIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </div>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-50 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-black/5 focus:outline-hidden sm:text-sm border border-gray-100">
              {enterprises
                .toSorted((a, b) => (a && b ? a.name.localeCompare(b.name) : 0))
                .map(
                  (companyListItem) =>
                    companyListItem && (
                      <ListboxOption
                        key={companyListItem.id}
                        className={clsx(
                          'data-[selected]:active data-focus:text-white data-focus:bg-purple-500',
                          selectedEnterpriseItem?.id === companyListItem.id &&
                            'bg-purple-50',
                          'listbox-option-purple relative select-none py-2 pl-3 pr-9 cursor-pointer text-gray-900',
                        )}
                        value={companyListItem}
                      >
                        {() => (
                          <div className="flex items-center">
                            <div
                              className={clsx(
                                selectedEnterpriseItem?.id ===
                                  companyListItem.id
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'ml-3 block truncate',
                              )}
                            >
                              {companyListItem.name}
                            </div>
                            {selectedEnterpriseItem?.id ===
                              companyListItem.id && (
                              <span
                                className={clsx(
                                  'icon absolute inset-y-0 right-0 flex items-center pr-4 text-purple-500',
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </div>
                        )}
                      </ListboxOption>
                    ),
                )}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  ) : (
    <div className="w-full flex items-center justify-center gap-2">
      <span className="text-gray-500">{t('global:loading')}</span>
      <Loader />
    </div>
  );
}
