import {
  ExperimentalFeatures,
  FeatureEnum,
  StandardEnum,
} from '../../../graphql/generated';
import { NavItem } from './NavItem';
import {
  GapAnalysisIcon,
  MaturityIcon,
  ResultsIcon,
  SurveyIcon,
} from '../../icons';
import { AppRoutes } from '../../../screens/AppRoutes';
import { SoonBadge } from '../../badges/SoonBadge';
import React, { ReactNode } from 'react';
import { usePermissionChecker } from '@hooks/usePermissionChecker';
import { useTranslation } from '@hooks/useTranslation';
import { useFeatureEnabled } from '@hooks/useFeatureEnabled';
import { useProjectContext } from '../../../providers/ProjectContextProvider';

export const useDataCollectNavItems = () => {
  const { isGranted } = usePermissionChecker();
  const projectContext = useProjectContext();
  const isCSRDenabled =
    (
      projectContext?.enterprise?.reports?.map(
        (report) => report.standard === StandardEnum.Csrd,
      ) ?? []
    ).length > 0;
  const isIndicatorCollectFeatureEnabled = useFeatureEnabled(
    ExperimentalFeatures.CsrdReportCollect,
  );
  const { t } = useTranslation();
  const items: { key: string; element: ReactNode }[] = [];

  if (isGranted(FeatureEnum.MaturityEvaluationManagement)) {
    items.push({
      key: 'page.maturity_evaluation',
      element: (
        <NavItem
          name={t('nav:page.maturity_evaluation')}
          Icon={MaturityIcon}
          href={AppRoutes.MaturityEvaluations}
        />
      ),
    });
  }
  if (isGranted(FeatureEnum.ConsultationManagement)) {
    items.push({
      key: 'page.surveys',
      element: (
        <NavItem
          name={t('nav:page.surveys')}
          Icon={SurveyIcon}
          href={AppRoutes.Survey}
        />
      ),
    });
  }
  if (isCSRDenabled && isGranted(FeatureEnum.GapAnalysisManagement)) {
    items.push({
      key: 'page.gap_analysis.summary',
      element: (
        <NavItem
          name={t('nav:page.gap_analysis.summary')}
          Icon={GapAnalysisIcon}
          href={AppRoutes.GapAnalysis}
        />
      ),
    });
  }
  if (isCSRDenabled && isGranted(FeatureEnum.IndicatorsManagement)) {
    items.push({
      key: 'page.indicator_collect.summary',
      element: (
        <NavItem
          name={t('nav:page.indicator_collect.summary')}
          Icon={ResultsIcon}
          href={
            isIndicatorCollectFeatureEnabled ? AppRoutes.IndicatorCollect : '#'
          }
          badge={!isIndicatorCollectFeatureEnabled && <SoonBadge />}
        />
      ),
    });
  }

  return items;
};
