import {
  ActionFieldsFragment,
  useOwnActionTemplatesQuery,
} from '../../../graphql/generated';
import React from 'react';
import { LoaderFullscreen } from '../../layout/Loader';
import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { AddActionTemplateButton } from './AddActionTemplateButton';
import resultIllustration from '../../../assets/images/illustrations/diagnostic/hammer.png';
import { IllustratedExplanationMessage } from '../../generic/IllustratedExplanationMessage';
import { generatePath, useNavigate } from 'react-router-dom';
import { MessageBox, MessageBoxType } from '../../layout/MessageBox';
import { ActionIllustrationIcon } from '../../publications/roadmap/ActionIllustrationIcon';
import { StakeTag, StakeTagStyle } from '../../stake/StakeTag';
import { ComplexityBadge } from '../../badges/ComplexityBadge';
import { PriceBadge } from '../../badges/PriceBadge';
import { ChevronRightIcon } from '../../icons';
import { ThemeIcon } from '../../badges/ThemeIcon';
import { AppRoutes } from '../../../screens/AppRoutes';

export function OwnActionTemplateList() {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { data, loading, refetch } = useOwnActionTemplatesQuery({
    variables: {
      companyId: currentUser?.company?.id || '',
    },
    skip: !currentUser?.company?.id,
    fetchPolicy: 'network-only',
  });
  const actions = data?.getOwnActionTemplates
    ? [...data.getOwnActionTemplates]
    : [];

  const gotoActionTemplateEditScreen = (
    actionTemplate: ActionFieldsFragment,
  ) => {
    navigate(
      generatePath(AppRoutes.ActionTemplateEdit, {
        actionId: actionTemplate.id,
      }),
    );
  };

  if (loading) {
    return <LoaderFullscreen />;
  }

  return (
    <div className="p-8 space-y-4">
      <IllustratedExplanationMessage
        illustration={<img src={resultIllustration} alt="" />}
        title="Gagnez du temps avec les modèles d'actions !"
        description="Créez des modèles d'actions pour les réutiliser dans vos prochaines feuilles de route !"
      >
        {currentUser?.company && (
          <AddActionTemplateButton
            companyId={currentUser?.company?.id}
            callback={() => {
              refetch().catch((err) => console.error(err));
            }}
          />
        )}
      </IllustratedExplanationMessage>
      <ActionTemplateList
        actions={actions}
        callback={(action) => gotoActionTemplateEditScreen(action)}
      />
    </div>
  );
}

function ActionTemplateList({
  actions,
  callback,
}: {
  actions: ActionFieldsFragment[];
  callback: (action: ActionFieldsFragment) => void;
}) {
  return (
    <div className="space-y-2">
      <div className="flex gap-4 text-gray-500 text-sm">
        <div className="flex items-center gap-2 w-2/3">Actions</div>
        <div className="w-1/3 grid grid-cols-4 gap-2 justify-items-center items-center mr-2">
          <div>Pilier</div>
          <div>Complexité</div>
          <div>Coût</div>
        </div>
      </div>
      <div className="space-y-4">
        {actions.length > 0 ? (
          actions.map((action) => (
            <div className="" key={action.id} onClick={() => callback(action)}>
              <ActionRow action={action} />
            </div>
          ))
        ) : (
          <MessageBox type={MessageBoxType.Info}>
            Aucun modèle ne correspond aux critères de recherche
          </MessageBox>
        )}
      </div>
    </div>
  );
}

function ActionRow({ action }: { action: ActionFieldsFragment }) {
  return (
    <div className="p-2 border border-gray-100 rounded-lg hover:border-gray-900 cursor-pointer flex items-center justify-between gap-2">
      <div className="w-2/3 flex items-center gap-4">
        <ActionIllustrationIcon action={action} />
        <div className="space-y-2">
          <div className="font-bold text-gray-900">{action.title}</div>
          {(action.diagnosticStakes || []).length > 0 && (
            <div className="flex items-center gap-1 flex-wrap">
              {action.diagnosticStakes?.map((stake) => (
                <StakeTag
                  stake={stake}
                  key={stake.id}
                  style={StakeTagStyle.GRAYED}
                  disableDetailModalOpening={true}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="w-1/3 grid grid-cols-4 gap-2 justify-items-center items-center">
        <ThemeIcon theme={action.theme} enableTooltip={true} />
        <ComplexityBadge complexityLevel={action.complexityLevel} />
        <PriceBadge priceLevel={action.priceLevel} />
        <div>
          <button className="primary purple">
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}
