import {
  SurveyLanguage,
  UpdatePillarModal_PillarFragment,
  useUpdatePillarModal_PillarUpdateMutation,
} from '../../../graphql/generated';
import React, { useState } from 'react';
import { useModal } from '../../layout/Modal';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import { ThemeIconPicker } from '../../generic/form/ThemeIconPicker';
import { ThemeColorPicker } from '../../form/ThemeColorPicker';
import i18n from 'i18next';
import { XIcon } from '../../icons';
import LanguageDropdown from '../../form/LanguageDropdown';
import { useTranslation } from '@hooks/useTranslation';
import { DeletePillarButton } from './DeletePillarButton';

export function UpdatePillarModal({
  pillar,
}: {
  pillar: UpdatePillarModal_PillarFragment;
}) {
  const toast = useToast();
  const modal = useModal();
  const { t, translateProperty } = useTranslation();

  const [language, setLanguage] = useState<SurveyLanguage>(SurveyLanguage.Fr);
  const initializeI18nValues = () => {
    // Get i18n values from stake
    const i18nValues = pillar.i18n || [];
    // Inject FR if not present
    if (!i18nValues.find((i18n) => i18n.language === SurveyLanguage.Fr)) {
      i18nValues.push({
        language: SurveyLanguage.Fr,
        name: translateProperty(pillar, 'name', SurveyLanguage.Fr),
      });
    }
    return i18nValues;
  };
  const [i18nValues, setI18nValues] = useState(initializeI18nValues());
  const translation = i18nValues?.find((i18n) => i18n.language === language);

  i18n.on('languageChanged', () => {
    pickLanguage(i18n.language as SurveyLanguage);
  });

  const pickLanguage = (newLanguage: SurveyLanguage) => {
    setLanguage(newLanguage);
    // Inject language if not present
    if (!i18nValues.find((i18n) => i18n.language === newLanguage)) {
      const newI18nValues = [...i18nValues];
      newI18nValues.push({
        language: newLanguage,
        name: '',
      });
      setI18nValues(newI18nValues);
    }
  };

  const [icon, setIcon] = useState(pillar.icon);
  const [color, setColor] = useState(pillar.color);

  const [isUpdating, setIsUpdating] = useState(false);
  const [updatePillarMutation] = useUpdatePillarModal_PillarUpdateMutation();
  const updatePillar = () => {
    setIsUpdating(true);
    updatePillarMutation({
      variables: {
        input: {
          id: pillar.id,
          icon: icon,
          color: color,
          i18n: i18nValues,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage(t('toast:pillar.form.update.success'));
        modal.closeModal();
      })
      .catch((err) => {
        console.error(err);
        toast.openToastWithError(t('toast:pillar.form.update.error'));
      })
      .finally(() => setIsUpdating(false));
  };

  const editField = (field: string, value: string) => {
    const newI18nValues = i18nValues.map((i18n) => {
      if (i18n.language === language) {
        return {
          ...i18n,
          [field]: value.trimStart(),
        };
      }
      return i18n;
    });
    setI18nValues(newI18nValues);
  };

  return (
    <div className="flex flex-col max-w-5xl h-full">
      <div className="bg-gray-50 flex items-center gap-4 p-6 w-full justify-between border-b border-gray-100">
        <div className="w-12">
          <button
            className="tertiary bg-transparent text-gray-400"
            onClick={() => modal.closeModal()}
          >
            <XIcon className="w-4 h-4" />
          </button>
        </div>
        <h4 className="grow">{t('pillar.update.modal.title')}</h4>
        <LanguageDropdown onUpdate={(language) => pickLanguage(language)} />
      </div>
      <div className="p-6 space-y-6 grow overflow-y-scroll">
        <div className="flex flex-col gap-2">
          <label htmlFor="title" className="form-input-label">
            {t('pillar.form.name')}
          </label>
          <input
            type="text"
            id="title"
            className="form-input-text"
            placeholder={t('stake.form.name', {
              context: language,
            })}
            value={translation?.name || ''}
            onChange={(e) => editField('name', e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="icon" className="form-input-label">
            {t('pillar.form.icon')}
          </label>
          <ThemeIconPicker currentIcon={icon} setCurrentIcon={setIcon} />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="color" className="form-input-label">
            {t('pillar.form.color')}
          </label>
          <ThemeColorPicker currentColor={color} setCurrentColor={setColor} />
        </div>
      </div>

      <div className="flex gap-2 justify-between px-6 py-4 border-t border-gray-100">
        <DeletePillarButton pillar={pillar} />
        <button
          className="primary"
          onClick={() => updatePillar()}
          disabled={isUpdating}
        >
          {isUpdating ? <Loader /> : t('global:save')}
        </button>
      </div>
    </div>
  );
}
