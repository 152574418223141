import React, { Fragment, ReactNode, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { OpenLeftDrawerMenuButton } from '../nav/OpenLeftDrawerMenuButton';
import { leftNavWidth } from '../nav/LeftNav/LeftNav';
import clsx from 'clsx';

type LeftDrawerContextType = {
  visibility: boolean;
  component: ReactNode | null;
  openDrawerWithComponent: (component: ReactNode) => void;
  closeDrawer: () => void;
};

const initialContext: LeftDrawerContextType = {
  visibility: false,
  component: null,
  openDrawerWithComponent: (component: ReactNode) => {
    // do nothing
  },
  closeDrawer: () => {
    // do nothing
  },
};

const LeftDrawerContext = React.createContext(initialContext);

export function LeftDrawerProvider({ children }: { children: ReactNode }) {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [component, setComponent] = useState<ReactNode | null>(null);

  function openDrawerWithComponent(component: ReactNode) {
    setComponent(component);
    setVisibility(true);
  }

  function closeDrawer() {
    setVisibility(false);
  }

  return (
    <LeftDrawerContext.Provider
      value={{
        visibility,
        openDrawerWithComponent,
        closeDrawer,
        component,
      }}
    >
      {children}
    </LeftDrawerContext.Provider>
  );
}

export const useLeftDrawer = () => useContext(LeftDrawerContext);

export function LeftDrawer() {
  const { visibility, closeDrawer, component } = useContext(LeftDrawerContext);

  const content = visibility && component ? component : null;

  return (
    <div className="relative">
      <div className="absolute top-0 bottom-0 left-2 py-8 z-50">
        <OpenLeftDrawerMenuButton />
      </div>
      <Transition.Root show={visibility} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeDrawer}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity z-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden z-50">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className="pointer-events-none fixed inset-y-0 left-0 flex h-screen py-8"
                onMouseLeave={closeDrawer}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-3/4"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel
                    className={clsx(
                      leftNavWidth,
                      'flex flex-col pointer-events-auto shadow-xl bg-white overflow-hidden rounded-r-2xl',
                    )}
                  >
                    {content}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
