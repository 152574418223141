import React, { useState } from 'react';
import {
  IroTimeHorizonPickerFragment,
  TimeHorizon,
  useUpdateIroTimeHorizonMutation,
} from '../../../../../graphql/generated';
import { useToast } from '../../../../layout/Toast';
import { useTranslation } from '@hooks/useTranslation';
import { TFunction } from 'i18next';
import {
  GradientDropdown,
  GradientDropdownStrength,
} from '../../../../generic/dropdown/GradientDropdown';
import { useRefetchMaterialities } from './useRefetchMaterialities';

export type IroTimeHorizonItem = {
  id: TimeHorizon | null;
  name: string;
  strength: GradientDropdownStrength;
};

export const timeHorizonCriteriaChoices = (
  t: TFunction,
): IroTimeHorizonItem[] => {
  return [
    {
      id: TimeHorizon.ShortTerm,
      name: t(`enum:iroCriteria.timeHorizon.${TimeHorizon.ShortTerm}`),
      strength: GradientDropdownStrength.Light,
    },
    {
      id: TimeHorizon.MediumTerm,
      name: t(`enum:iroCriteria.timeHorizon.${TimeHorizon.MediumTerm}`),
      strength: GradientDropdownStrength.Light,
    },
    {
      id: TimeHorizon.LongTerm,
      name: t(`enum:iroCriteria.timeHorizon.${TimeHorizon.LongTerm}`),
      strength: GradientDropdownStrength.Light,
    },
    {
      id: null,
      name: '-',
      strength: GradientDropdownStrength.VeryLight,
    },
  ];
};

export const IroTimeHorizonPicker = ({
  iro,
  extraPositionClassName,
  readOnly = false,
}: {
  iro: IroTimeHorizonPickerFragment;
  extraPositionClassName?: string;
  readOnly?: boolean;
}) => {
  const { t } = useTranslation();
  const items = timeHorizonCriteriaChoices(t);
  const initialTimeHorizonItem = items.find(
    (item) => item.id === iro.timeHorizon || null,
  );
  const [selected, setSelected] = useState(initialTimeHorizonItem || null);
  const [update] = useUpdateIroTimeHorizonMutation();
  const toast = useToast();
  const refetchQueries = useRefetchMaterialities();

  const chooseItemId = (itemId: string | null) => {
    const item = items.find((item) => item.id === itemId) || null;
    setSelected(item);

    update({
      variables: {
        input: {
          id: iro.id,
          timeHorizon: item?.id ?? null,
        },
      },
      refetchQueries,
    })
      .then(() => {
        toast.openToastWithMessage(
          t('toast:iro.criteria.update.success', {
            criteriaName: t('iro.criteria.timeHorizon.title'),
          }),
        );
      })
      .catch(() => {
        toast.openToastWithError(
          t('toast:iro.criteria.timeHorizon.update.success'),
        );
      });
  };
  return (
    <GradientDropdown
      {...{
        items,
        selected,
        chooseItemId,
        extraPositionClassName,
        disabled: readOnly,
      }}
    />
  );
};
