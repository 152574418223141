import React, { useEffect, useState } from 'react';
import {
  ActionFieldsFragment,
  ContextualData,
  DiagnosticStakeFieldsFragment,
  FeatureEnum,
  Metric,
  PermissionEnum,
  Resource,
  Solution,
  Theme,
  useActionUpdateMutation,
} from '../../../graphql/generated';
import { useToast } from '../../layout/Toast';
import { Loader } from '../../generic/Loader';
import ThemeFilterDropdown from '../../form/ThemeFilterDropdown';
import RichTextEditorTextarea from '../../generic/form/RichTextEditorTextarea';
import ActionPriorityLevelPicker from './ActionPriorityLevelPicker';
import ActionComplexityLevelPicker from './ActionComplexityLevelPicker';
import ActionPriceLevelPicker from './ActionPriceLevelPicker';
import { ComplexityBadgeTooltip } from '../../badges/ComplexityBadge';
import { getTooltipUniqueId } from '../../../services/UtilsService';
import { PriorityBadgeTooltip } from '../../badges/PriorityBadge';
import { PriceBadgeTooltip } from '../../badges/PriceBadge';
import { ChooseDiagnosticStakeMenu } from './ChooseDiagnosticStakeMenu';
import { StakeTag } from '../../stake/StakeTag';
import { Checklist } from '../../task/Checklist';
import { CreateChecklistButton } from '../../task/CreateChecklistButton';
import { generatePath, NavLink, useNavigate } from 'react-router-dom';
import { IllustratedExplanationMessage } from '../../generic/IllustratedExplanationMessage';
import loginIllustration from '../../../assets/images/illustrations/login.png';
import {
  CheckCircleIcon,
  ChevronRightIcon,
  InfoIcon,
  LinkIcon,
  MetricIcon,
  PlusIcon,
  QuoteWhiteIcon,
  XIcon,
} from '../../icons';
import { useNavContext } from '../../../providers/NavContextProvider';
import { AppRoutes } from '../../../screens/AppRoutes';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export function UpdateAction({ action }: { action: ActionFieldsFragment }) {
  const navContext = useNavContext();
  const navigate = useNavigate();
  const { isGranted } = usePermissionChecker();
  const canManageRoadmap = isGranted(
    FeatureEnum.RoadmapManagement,
    PermissionEnum.Write,
  );
  if (!canManageRoadmap) {
    return null;
  }

  useEffect(() => {
    navContext?.setIsNavCollapsed(true);
    /* eslint-disable-next-line */
  }, []);

  const toast = useToast();

  const [title, setTitle] = useState(action.title);
  const [contextualData, setContextualData] = useState<ContextualData>(
    action.contextualData || { title: '', description: '' },
  );
  const [description, setDescription] = useState(action.description);
  const [showIllustrationInput, setShowIllustrationInput] = useState(false);
  const [illustration, setIllustration] = useState(action.illustration);
  const [coachComment, setCoachComment] = useState(action.coachComment || '');
  const [priorityLevel, setPriorityLevel] = useState(
    action.priorityLevel || '',
  );
  const [complexityLevel, setComplexityLevel] = useState(
    action.complexityLevel || '',
  );
  const [priceLevel, setPriceLevel] = useState(action.priceLevel || '');
  const [theme, setTheme] = useState<Theme | null>(action.theme);
  const [metrics, setMetrics] = useState<Metric[]>(action.metrics || []);
  const [benefits, setBenefits] = useState<string[]>(action.benefits || []);
  const [resources, setResources] = useState<Resource[]>(
    action.resources || [],
  );
  const [solutions, setSolutions] = useState<Solution[]>(
    action.solutions || [],
  );
  const [diagnosticStakes, setDiagnosticStakes] = useState<
    DiagnosticStakeFieldsFragment[]
  >(action.diagnosticStakes || []);

  const [updateActionMutation] = useActionUpdateMutation();
  const [isUpdating, setIsUpdating] = useState(false);

  const updateAction = () => {
    setIsUpdating(true);
    updateActionMutation({
      variables: {
        input: {
          id: action.id,
          title,
          description,
          illustration,
          theme,
          coachComment,
          priorityLevel,
          complexityLevel,
          priceLevel,
          contextualData: {
            title: contextualData.title,
            description: contextualData.description,
          },
          solutions: solutions.map((solution) => ({
            title: solution.title,
            description: solution.description,
          })),
          metrics: metrics.map((metric) => ({
            title: metric.title,
          })),
          resources: resources.map((resource) => ({
            title: resource.title,
            url: resource.url,
          })),
          benefits,
        },
      },
    })
      .then(() => {
        toast.openToastWithMessage('Action mise à jour');
        navigate(
          generatePath(AppRoutes.RoadmapAction, { actionId: action.id }),
        );
        navContext?.setIsNavCollapsed(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsUpdating(false));
  };

  const complexityTooltipId = getTooltipUniqueId('complexity-badge-tooltip');
  const priorityTooltipId = getTooltipUniqueId('priority-badge-tooltip');
  const priceTooltipId = getTooltipUniqueId('price-badge-tooltip');

  return (
    <div className="flex flex-col w-full h-full">
      <div className="sticky top-0 bg-white z-50 px-8 py-4 border-b border-gray-100 shadow-sm flex items-center justify-between">
        <div className="flex items-center gap-1">
          <div className="flex gap-1 title-h5 items-center flex-wrap">
            <span>Feuille de route</span>
            <ChevronRightIcon />
            <span>{action.title}</span>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <NavLink
            to={generatePath(AppRoutes.RoadmapAction, {
              actionId: action.id,
            })}
            className="button secondary purple"
            onClick={() => navContext?.setIsNavCollapsed(false)}
          >
            Annuler
          </NavLink>
          <button
            className="primary purple"
            onClick={updateAction}
            disabled={isUpdating || !title}
          >
            {isUpdating ? <Loader /> : 'Sauvegarder'}
          </button>
        </div>
      </div>

      <div className="flex grow">
        {/* Left col */}
        <div className="w-2/3 flex flex-col gap-4 grow">
          <div
            className="flex-none bg-cover bg-center h-60 relative"
            style={{
              backgroundImage: action.illustration
                ? `url(${action.illustration})`
                : `url(/actions/riccardo-annandale-7e2pe9wjL9M-unsplash.jpg)`,
            }}
          >
            <div className="flex flex-col place-content-end h-full bg-linear-to-b from-gray-900/[.2] to-gray-900/[.5]"></div>
            {showIllustrationInput ? (
              <div className="absolute top-1/2 left-4 w-1/2 card">
                <label htmlFor={'illustration'} className="form-input-label">
                  URL vers une image d&apos;illustration
                </label>
                <input
                  type="text"
                  id="illustration"
                  className="form-input-text"
                  value={illustration || ''}
                  onChange={(e) => setIllustration(e.target.value)}
                />
              </div>
            ) : (
              <button
                className="primary purple absolute top-2/3 left-4"
                onClick={() => setShowIllustrationInput(true)}
              >
                Modifier l&apos;illustration
              </button>
            )}
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 p-8">
              <div className="flex gap-2 items-center">
                <div className="flex flex-col gap-2">
                  <ThemeFilterDropdown
                    selectedTheme={theme}
                    setFilterByTheme={setTheme}
                    displayAllThemes={false}
                    inlined={true}
                  />
                </div>

                <div className="flex flex-col gap-1 grow">
                  <input
                    type="text"
                    id="title"
                    className="form-input-text-inline title-h3 text-lg"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>

              <div className="rounded-xl bg-blue-50 p-8 flex items-center gap-8">
                <div className="shrink-0 w-1/3">
                  <input
                    type="text"
                    id="contextualDataTitle"
                    className="form-input-text-inline font-title font-extrabold text-5xl "
                    placeholder="Data"
                    value={contextualData?.title || ''}
                    onChange={(e) =>
                      setContextualData({
                        title: e.target.value,
                        description: contextualData?.description,
                      })
                    }
                  />
                </div>
                <div className="w-full">
                  <textarea
                    id="contextualDataDescription"
                    className="form-input-text-inline text-gray-500"
                    placeholder="Description de la donnée permettant de mettre en contexte l'action"
                    value={contextualData?.description || ''}
                    onChange={(e) =>
                      setContextualData({
                        title: contextualData?.title,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <RichTextEditorTextarea
                  value={description || ''}
                  placeholder="Description de l'action"
                  inlined={true}
                  onChange={(value) => setDescription(value)}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <h5>Suggestions</h5>
                  <button
                    className="inlined"
                    onClick={() => setSolutions([...solutions, { title: '' }])}
                  >
                    <PlusIcon />
                    <span>Ajouter une suggestion / un exemple</span>
                  </button>
                </div>

                {/* List and edit solutions */}
                <div className="flex flex-col gap-2">
                  {solutions.map((solution, index) => (
                    <div className="flex gap-2" key={index}>
                      <div className="w-full card flex-row items-start">
                        <div className="flex flex-col grow">
                          <input
                            type="text"
                            className="form-input-text-inline title-h6 font-bold"
                            value={solution.title}
                            placeholder="Titre"
                            onChange={(e) => {
                              const newSolutions = [...solutions];
                              const oldResource = newSolutions[index];
                              newSolutions[index] = {
                                title: e.target.value,
                                description: oldResource.description,
                              };
                              setSolutions(newSolutions);
                            }}
                          />
                          <textarea
                            className="form-input-text-inline text-gray-500"
                            value={solution.description || ''}
                            placeholder="description"
                            onChange={(e) => {
                              const newResources = [...solutions];
                              const oldResource = newResources[index];
                              newResources[index] = {
                                title: oldResource.title,
                                description: e.target.value,
                              };
                              setSolutions(newResources);
                            }}
                          />
                        </div>
                        <button
                          className="tertiary small"
                          onClick={() => {
                            const newSolutions = [...solutions];
                            newSolutions.splice(index, 1);
                            setSolutions(newSolutions);
                          }}
                        >
                          <XIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="pt-4 mt-4 flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <h5>Enjeux liés</h5>
                  <ChooseDiagnosticStakeMenu
                    action={action}
                    currentStakes={diagnosticStakes}
                    callback={setDiagnosticStakes}
                  />
                </div>
                <div className="flex gap-2 flex-wrap">
                  {diagnosticStakes.map((diagnosticStake) => (
                    <StakeTag
                      stake={diagnosticStake}
                      key={diagnosticStake.id}
                      disableDetailModalOpening={true}
                    />
                  ))}
                </div>
              </div>
            </div>

            {action.checklist?.id ? (
              <div className="p-8 bg-gray-50 grow">
                <Checklist checklistId={action.checklist.id} />
              </div>
            ) : (
              <div className="p-8">
                <IllustratedExplanationMessage
                  illustration={<img src={loginIllustration} alt="" />}
                  title="Créez une liste de tâches"
                  description="Mettez en place une liste de tâches pour vous mettre en mouvement !"
                >
                  <CreateChecklistButton actionId={action.id} />
                </IllustratedExplanationMessage>
              </div>
            )}
          </div>
        </div>

        {/* Right col */}
        <div className="min-w-[400px] max-w-[500px] flex flex-col p-6 bg-yellow-50 space-y-4 divide-y divide-yellow-300">
          <div className="space-y-2">
            <div className="flex gap-4 mb-2">
              <QuoteWhiteIcon className="shrink-0 inline-block h-12 w-12 rounded-full" />
              <textarea
                className="form-input-text-inline"
                placeholder="Citation / témoignage"
                value={coachComment}
                onChange={(e) => setCoachComment(e.target.value)}
              />
            </div>
          </div>

          <div className="pt-4 space-y-2">
            <h5>À propos</h5>
            {/* edit priority level */}
            <div className="flex gap-2 items-center">
              <label
                htmlFor="priorityLevel"
                className="font-bold text-sm w-1/3"
              >
                Priorité
              </label>
              <div className="flex gap-2 grow items-center">
                <ActionPriorityLevelPicker
                  currentActionPriorityLevel={priorityLevel}
                  callback={setPriorityLevel}
                />
                <div className="" data-tooltip-id={priorityTooltipId}>
                  <InfoIcon />
                  <PriorityBadgeTooltip
                    priorityLevel={priorityLevel}
                    id={priorityTooltipId}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <label
                htmlFor="complexityLevel"
                className="font-bold text-sm w-1/3"
              >
                Complexité
              </label>
              <div className="flex gap-2 grow items-center">
                <ActionComplexityLevelPicker
                  currentActionComplexityLevel={complexityLevel}
                  callback={setComplexityLevel}
                />
                <div className="" data-tooltip-id={complexityTooltipId}>
                  <InfoIcon />
                  <ComplexityBadgeTooltip
                    complexityLevel={complexityLevel}
                    id={complexityTooltipId}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <label htmlFor="priceLevel" className="font-bold text-sm w-1/3">
                Coût
              </label>
              <div className="flex gap-2 grow items-center">
                <ActionPriceLevelPicker
                  currentActionPriceLevel={priceLevel}
                  callback={setPriceLevel}
                />
                <div className="" data-tooltip-id={priceTooltipId}>
                  <InfoIcon />
                  <PriceBadgeTooltip
                    priceLevel={priceLevel}
                    id={priceTooltipId}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 space-y-2">
            <div className="flex justify-between items-center">
              <h5>Bénéfices</h5>
              <button
                className="inlined"
                onClick={() => {
                  setBenefits([...benefits, 'Nouveau bénéfice']);
                }}
              >
                <PlusIcon />
                <span>Ajouter</span>
              </button>
            </div>
            {/* List and edit benefits */}
            <div className="flex flex-col gap-1">
              {benefits.map((benefit, index) => (
                <div className="flex gap-2 items-center" key={index}>
                  <CheckCircleIcon className="h-6 w-6 shrink-0" />
                  <textarea
                    className="form-input-text-inline"
                    value={benefit}
                    onChange={(e) => {
                      const newBenefits = [...benefits];
                      newBenefits[index] = e.target.value;
                      setBenefits(newBenefits);
                    }}
                  />
                  <button
                    className="inlined"
                    onClick={() => {
                      const newBenefits = [...benefits];
                      newBenefits.splice(index, 1);
                      setBenefits(newBenefits);
                    }}
                  >
                    <XIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="pt-4 space-y-2">
            <div className="flex justify-between items-center">
              <h5>Métrique à suivre</h5>
              <button
                className="inlined"
                onClick={() =>
                  setMetrics([...metrics, { title: 'Nouvelle métrique' }])
                }
              >
                <PlusIcon />
                <span>Ajouter</span>
              </button>
            </div>
            {/* List and edit metrics */}
            <div className="flex flex-col gap-1">
              {metrics.map((metric, index) => (
                <div
                  className="flex gap-4 bg-white shadow-sm p-3 rounded-lg justify-start items-center"
                  key={index}
                >
                  <MetricIcon />
                  <textarea
                    className="form-input-text-inline font-bold text-sm text-gray-900"
                    value={metric.title}
                    onChange={(e) => {
                      const newMetrics = [...metrics];
                      newMetrics[index] = { title: e.target.value };
                      setMetrics(newMetrics);
                    }}
                  />
                  <button
                    className="inlined"
                    onClick={() => {
                      const newMetrics = [...metrics];
                      newMetrics.splice(index, 1);
                      setMetrics(newMetrics);
                    }}
                  >
                    <XIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="pt-4 space-y-2">
            <div className="flex justify-between items-center">
              <h5>Ressources</h5>
              <button
                className="inlined"
                onClick={() => setResources([...resources, { title: '' }])}
              >
                <PlusIcon />
                <span>Ajouter</span>
              </button>
            </div>
            {/* List and edit resources */}
            <div className="flex flex-col gap-2">
              {resources.map((resource, index) => (
                <div className="flex gap-2 items-center" key={index}>
                  <LinkIcon className="shrink-0" />
                  <div className="w-full">
                    <textarea
                      className="form-input-text-inline"
                      placeholder="Nom de la ressource"
                      value={resource.title}
                      onChange={(e) => {
                        const newResources = [...resources];
                        const oldResource = newResources[index];
                        newResources[index] = {
                          title: e.target.value,
                          url: oldResource.url,
                        };
                        setResources(newResources);
                      }}
                    />
                    <input
                      type="text"
                      className="form-input-text-inline"
                      value={resource.url || ''}
                      placeholder="URL de la ressource"
                      onChange={(e) => {
                        const newResources = [...resources];
                        const oldResource = newResources[index];
                        newResources[index] = {
                          title: oldResource.title,
                          url: e.target.value,
                        };
                        setResources(newResources);
                      }}
                    />
                  </div>
                  <button
                    className="inlined"
                    onClick={() => {
                      const newResources = [...resources];
                      newResources.splice(index, 1);
                      setResources(newResources);
                    }}
                  >
                    <XIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
