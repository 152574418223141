import React, { Fragment, useState } from 'react';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import {
  ActionFieldsFragment,
  DiagnosticStakeFieldsFragment,
  ReferentialFieldsFragment,
  useActionUpdateMutation,
  useReferentialQuery,
  useReferentialTemplatesQuery,
} from '../../../graphql/generated';
import { StakeTag } from '../../stake/StakeTag';
import { Loader } from '../../generic/Loader';
import { ChevronDownIcon, StakesIcon, XIcon } from '../../icons';
import { useTranslation } from '@hooks/useTranslation';

export function ChooseDiagnosticStakeMenu({
  action,
  currentStakes,
  callback,
}: {
  action: ActionFieldsFragment;
  currentStakes: DiagnosticStakeFieldsFragment[];
  callback: (stakes: DiagnosticStakeFieldsFragment[]) => void;
}) {
  // Fetch template stakes: from referential templates
  const referentialTemplatesDocumentQuery = useReferentialTemplatesQuery({
    skip: !action.isTemplate,
    fetchPolicy: 'network-only',
  });

  // Fetch stakes: own referential stakes
  const projectContext = useProjectContext();
  const companyReferentialDocumentQuery = useReferentialQuery({
    variables: { id: projectContext?.enterprise?.referential?.id || '' },
    skip: !projectContext?.enterprise?.id || action.isTemplate || false,
    fetchPolicy: 'network-only',
  });

  if (
    companyReferentialDocumentQuery.loading ||
    referentialTemplatesDocumentQuery.loading
  ) {
    return <Loader />;
  }

  const availableReferentials = action.isTemplate
    ? referentialTemplatesDocumentQuery.data?.getReferentialTemplates || []
    : companyReferentialDocumentQuery.data?.referential
      ? [companyReferentialDocumentQuery.data.referential]
      : [];

  return (
    <ChooseDiagnosticStakeMenuInner
      availableReferentials={availableReferentials}
      action={action}
      currentStakes={currentStakes}
      callback={callback}
    />
  );
}

function ChooseDiagnosticStakeMenuInner({
  availableReferentials,
  action,
  currentStakes,
  callback,
}: {
  availableReferentials: ReferentialFieldsFragment[];
  action: ActionFieldsFragment;
  currentStakes: DiagnosticStakeFieldsFragment[];
  callback: (stakes: DiagnosticStakeFieldsFragment[]) => void;
}) {
  const { translateProperty } = useTranslation();
  const [updateAction] = useActionUpdateMutation();
  const [isUpdating, setIsUpdating] = useState(false);

  const [expandedReferentialIds, setExpandedReferentialIds] = useState<
    string[]
  >([]);
  const [expandedPillarIds, setExpandedPillarIds] = useState<string[]>([]);

  const availableStakes =
    availableReferentials?.flatMap(
      (referential) =>
        referential.pillars.flatMap((pillar) => pillar.stakes) || [],
    ) || [];

  const [checkedStakes, setCheckedStakes] = useState<
    DiagnosticStakeFieldsFragment[]
  >(
    currentStakes.filter((currentStake) => {
      return availableStakes.some((stake) => stake.id === currentStake.id);
    }),
  );

  const toggleStake = (stakeId: string) => {
    const stake = availableStakes.find((stake) => stake.id === stakeId);
    if (stake) {
      if (checkedStakes.some((currentStake) => currentStake.id === stake.id)) {
        setCheckedStakes(
          checkedStakes.filter((currentStake) => currentStake.id !== stake.id),
        );
      } else {
        setCheckedStakes([...checkedStakes, stake]);
      }
    }
  };

  const update = () => {
    setIsUpdating(true);
    updateAction({
      variables: {
        input: {
          id: action.id,
          diagnosticStakes: checkedStakes.map((stake) => {
            return { id: stake.id };
          }),
        },
      },
    })
      .then(() => {
        callback(checkedStakes);
      })
      .finally(() => setIsUpdating(false))
      .catch((err) => console.error(err));
  };

  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <PopoverButton className={clsx('inlined')}>
            <button className="inlined">
              <StakesIcon />
              <span>Choisir les enjeux liés</span>
            </button>
          </PopoverButton>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="z-50 w-[30rem] h-96 overflow-hidden absolute bg-white bottom-0 left-0 transform -translate-y-8 mt-3 p-2 rounded-lg shadow-lg ring-1 ring-black/5 flex flex-col">
              <div className="flex justify-between items-center">
                <h6>Liste des enjeux</h6>
                <button
                  className="tertiary bg-transparent text-gray-400 small"
                  onClick={() => close()}
                >
                  <XIcon className="w-4 h-4" />
                </button>
              </div>
              <div className="grow overflow-y-scroll flex flex-col gap-1.5">
                {availableReferentials.map((referential) => (
                  <div key={referential.id} className="pl-2">
                    <div className="font-title font-bold flex items-center gap-1 text-sm">
                      <div>{referential.name}</div>
                      <button
                        className="unstyled text-gray-900 small"
                        onClick={() => {
                          if (expandedReferentialIds.includes(referential.id)) {
                            setExpandedReferentialIds(
                              expandedReferentialIds.filter(
                                (id) => id !== referential.id,
                              ),
                            );
                          } else {
                            setExpandedReferentialIds([
                              ...expandedReferentialIds,
                              referential.id,
                            ]);
                          }
                        }}
                      >
                        <ChevronDownIcon
                          className={clsx(
                            'w-4 h-4',
                            expandedReferentialIds.includes(referential.id)
                              ? 'transform rotate-180'
                              : '',
                          )}
                        />
                      </button>
                    </div>
                    {expandedReferentialIds.includes(referential.id) && (
                      <div className="flex flex-col gap-1.5 items-start p-2 overflow-scroll grow border-l border-gray-100">
                        {referential.pillars.map((pillar) => (
                          <div key={pillar.id}>
                            <div className="font-title font-bold flex items-center gap-1 text-sm">
                              <div>{translateProperty(pillar, 'name')}</div>
                              <button
                                className="unstyled text-gray-900 small"
                                onClick={() => {
                                  if (expandedPillarIds.includes(pillar.id)) {
                                    setExpandedPillarIds(
                                      expandedPillarIds.filter(
                                        (id) => id !== pillar.id,
                                      ),
                                    );
                                  } else {
                                    setExpandedPillarIds([
                                      ...expandedPillarIds,
                                      pillar.id,
                                    ]);
                                  }
                                }}
                              >
                                <ChevronDownIcon
                                  className={clsx(
                                    'w-4 h-4',
                                    expandedPillarIds.includes(pillar.id)
                                      ? 'transform rotate-180'
                                      : '',
                                  )}
                                />
                              </button>
                            </div>
                            {expandedPillarIds.includes(pillar.id) && (
                              <div className="flex flex-col gap-1 items-start p-1 overflow-scroll grow">
                                {pillar.stakes.map((stake) => (
                                  <DiagnosticStakeChoice
                                    stake={stake}
                                    key={stake.id}
                                    isChecked={checkedStakes.some(
                                      (currentStake) =>
                                        currentStake.id === stake.id,
                                    )}
                                    toggleChecked={() => toggleStake(stake.id)}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <button
                className="primary small"
                onClick={() => {
                  update();
                  close();
                }}
                disabled={isUpdating}
              >
                {isUpdating && <Loader />}
                <div>Modifier</div>
              </button>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function DiagnosticStakeChoice({
  isChecked,
  toggleChecked,
  stake,
}: {
  isChecked: boolean;
  toggleChecked: () => void;
  stake: DiagnosticStakeFieldsFragment;
}) {
  return (
    <StakeTag stake={stake} disableDetailModalOpening={true}>
      <input
        type="checkbox"
        className="form-input-checkbox"
        checked={isChecked}
        onChange={toggleChecked}
      />
    </StakeTag>
  );
}
