import { useCurrentUser } from '../../../providers/CurrentUserProvider';
import { ProjectList } from '../../../components/project/ProjectList';
import { PageTitle } from '../../../components/nav/PageTitle';
import React, { useEffect, useState } from 'react';
import { JoinEnterpriseList } from '../../../components/project/JoinEnterpriseList';
import { CompanyLogo } from '../../../components/project/project/CompanyLogo';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  ChevronRightIcon,
  CoachIcon,
  CompanyIcon,
  TemplatesIcon,
} from '../../../components/icons';
import { ArchivedProjectList } from '../../../components/project/project/archiveProjects/ArchivedProjectList';
import { isCoachUser } from '../../../services/CompanyService';

import { AppRoutes } from '../../AppRoutes';
import { useProjectContext } from '../../../providers/ProjectContextProvider';
import { LoaderFullscreen } from '../../../components/layout/Loader';
import { useTranslation } from '@hooks/useTranslation';
import { AuthRolesAndExperimentalFeatures } from '../../../graphql/generated';
import { usePermissionChecker } from '@hooks/usePermissionChecker';

export const HomeScreen = () => {
  const { currentUser } = useCurrentUser();
  const projectContext = useProjectContext();
  const isCoach = isCoachUser(currentUser);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!currentUser || projectContext?.isLoadingEnterprise) {
      return;
    }
    setIsLoading(false);
    if (currentUser && !projectContext?.isLoadingEnterprise && !isCoach) {
      navigate(AppRoutes.Project);
    }
  }, [currentUser, isCoach, projectContext, navigate]);

  return isLoading ? (
    <LoaderFullscreen />
  ) : (
    <div className="">
      <PageTitle title="Tableau de bord" />
      <HomeGreeting />
    </div>
  );
};

function HomeGreeting() {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { hasRole } = usePermissionChecker()
  const greeting = currentUser
    ? t('global:hello', {firstname: currentUser.firstName})
    : t('global:welcome');

  return (
    <div className="p-8">
      <div className="flex items-center gap-4 justify-start">
        {currentUser?.company && (
          <CompanyLogo companyId={currentUser?.company.id} size={'big'} />
        )}
        <div>
          <div className="text-gray-500 first-letter:capitalize text-sm">
            {new Date().toLocaleDateString('fr', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
            })}{' '}
            · Tableau de bord {currentUser?.company?.name}
          </div>
          <h1 className="text-5xl">{greeting}</h1>
        </div>
      </div>

      <div className="flex flex-col items-start justify-start gap-16 mt-12 max-w-5xl">
        {hasRole(AuthRolesAndExperimentalFeatures.Administrator) && (
        <div className="grid grid-cols-3 gap-4">
          <LinkToCoachScreen />
          <LinkToTemplatesScreen />
          <LinkToCompanySettingsScreen />
        </div>
        )}
        <div className="w-full space-y-8">
          <ProjectList />
          {hasRole(AuthRolesAndExperimentalFeatures.Administrator, false) && <>
          <JoinEnterpriseList />
          <ArchivedProjectList />
          </>}
        </div>
      </div>
    </div>
  );
}

function LinkToCoachScreen() {
  return (
    <div>
      <NavLink to={AppRoutes.SettingsCoach}>
        <div className="flex items-center gap-4 rounded-xl p-4 border border-gray-100 shadow-sm hover:border-gray-900">
          <div className="w-10 h-10 xl:w-16 xl:h-16 shrink-0 rounded-xl bg-yellow-100 flex items-center justify-center overflow-hidden">
            <CoachIcon />
          </div>
          <div className="space-y-0.5 grow">
            <div className="font-semibold text-sm xl:text-base">
              Gérer les coachs
            </div>
            <div className="text-sm text-gray-500 hidden xl:block">
              C&apos;est ici que vous invitez les coachs et gérez leur compte.
            </div>
          </div>
          <button className="primary purple px-2">
            <ChevronRightIcon className="shrink-0 w-4 h-4" />
          </button>
        </div>
      </NavLink>
    </div>
  );
}

function LinkToTemplatesScreen() {
  return (
    <div>
      <NavLink to={AppRoutes.SettingsTemplates}>
        <div className="flex items-center gap-4 rounded-xl p-4 border border-gray-100 shadow-sm hover:border-gray-900">
          <div className="w-10 h-10 xl:w-16 xl:h-16 shrink-0 rounded-xl bg-blue-100 flex items-center justify-center overflow-hidden">
            <TemplatesIcon />
          </div>
          <div className="space-y-0.5 grow">
            <div className="font-semibold text-sm xl:text-base">
              Gérer les modèles
            </div>
            <div className="text-sm text-gray-500 hidden xl:block">
              Retrouvez ici tous vos modèles de projets et d&apos;actions.
            </div>
          </div>
          <button className="primary purple px-2">
            <ChevronRightIcon className="shrink-0 w-4 h-4" />
          </button>
        </div>
      </NavLink>
    </div>
  );
}

function LinkToCompanySettingsScreen() {
  return (
    <div>
      <NavLink to={AppRoutes.SettingsCompany}>
        <div className="flex items-center gap-4 rounded-xl p-4 border border-gray-100 shadow-sm hover:border-gray-900">
          <div className="w-10 h-10 xl:w-16 xl:h-16 shrink-0 rounded-xl bg-purple-100 flex items-center justify-center overflow-hidden">
            <CompanyIcon />
          </div>
          <div className="space-y-0.5 grow">
            <div className="font-semibold text-sm xl:text-base">
              Gérer l&apos;entreprise
            </div>
            <div className="text-sm text-gray-500 hidden xl:block">
              Paramétrez votre entreprise, son logo, ses spécificités.
            </div>
          </div>
          <button className="primary purple px-2">
            <ChevronRightIcon className="shrink-0 w-4 h-4" />
          </button>
        </div>
      </NavLink>
    </div>
  );
}
